import {
    ALL_LANGUAGES,
    COUNTRY_TOP_LEVEL_DOMAIN,
    FILTERED_LANG
} from '../constants';
import commonLang from '../translation/common';
import heroLang from '../translation/hero';
import metaLang from '../translation/meta';
import routesLang from '../translation/routes';

export function getTopLevelDomain(): string {
    const topLevelDomainArr = window.location.hostname.split('auto24.');
    let topLevelDomain = '';

    if (topLevelDomainArr.includes('localhost'))
        topLevelDomain = topLevelDomainArr[0]; //For local testing
    else topLevelDomain = topLevelDomainArr[topLevelDomainArr.length - 1]; //For production

    return topLevelDomain;
}

export function languagesList() {
    const topLevelDomain = getTopLevelDomain();
    let languagesList: { [key: string]: string } = {};
    FILTERED_LANG.forEach((item) => {
        if (item.includedInDomain.includes(topLevelDomain)) {
            languagesList = {
                ...languagesList,
                [item.label]: item.value
            };
        }
    });
    return languagesList;
}

export function getDefaultLang() {
    const topLevelDomain = getTopLevelDomain();
    let defaultLang = '';

    switch (topLevelDomain) {
        case COUNTRY_TOP_LEVEL_DOMAIN.ma:
            defaultLang = ALL_LANGUAGES.french.value;
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.coza:
            defaultLang = ALL_LANGUAGES.arabic.value;
            break;
        case COUNTRY_TOP_LEVEL_DOMAIN.sn:
            defaultLang = ALL_LANGUAGES.french.value;
            break;

        default:
            defaultLang = ALL_LANGUAGES.english.value;
    }
    return defaultLang;
}

export const DEFAULT_LANG = getDefaultLang();

function fetchAllLangList(): any {
    let allLangList = {};

    for (const lang in ALL_LANGUAGES) {
        if (ALL_LANGUAGES.hasOwnProperty(lang)) {
            allLangList = {
                ...allLangList,
                [ALL_LANGUAGES[lang].label]: {
                    common: commonLang[ALL_LANGUAGES[lang].value],
                    routes: routesLang[ALL_LANGUAGES[lang].value],
                    meta: metaLang[ALL_LANGUAGES[lang].value],
                    hero: heroLang[ALL_LANGUAGES[lang].value]
                }
            };
        }
    }

    return allLangList;
}

// Object for all the languages
// Creates object similar ko constant below with same name
const LANG_LIST = fetchAllLangList();

// Object for all the languages
// const LANG_LIST = {
//     [ALL_LANGUAGES.english.label]: {
//         common: commonLang.en,
//         routes: routesLang.en,
//         meta: metaLang.en,
//         hero: heroLang.en
//     },
//     [ALL_LANGUAGES.french.label]: {
//         common: commonLang.fr,
//         routes: routesLang.fr,
//         meta: metaLang.fr,
//         hero: heroLang.fr
//     },
//     [ALL_LANGUAGES.arabic.label]: {
//         common: commonLang.ar,
//         routes: routesLang.ar,
//         meta: metaLang.ar,
//         hero: heroLang.ar
//     },
//     [ALL_LANGUAGES.xhosa.label]: {
//         common: commonLang.xh,
//         routes: routesLang.xh,
//         meta: metaLang.xh,
//         hero: heroLang.xh
//     },
//     [ALL_LANGUAGES.zulu.label]: {
//         common: commonLang.zu,
//         routes: routesLang.zu,
//         meta: metaLang.zu,
//         hero: heroLang.zu
//     },
//     [ALL_LANGUAGES.kinyarnwanda.label]: {
//         common: commonLang.rw,
//         routes: routesLang.rw,
//         meta: metaLang.rw,
//         hero: heroLang.rw
//     }
// }

// Object for the languages that are available for the domain
// Creates object similar ko function below with same name
export const getTranslationObj = () => {
    const langList = languagesList();
    let availableLang: any = {};
    for (const lang in langList) {
        if (langList.hasOwnProperty(lang)) {
            availableLang = {
                ...availableLang,
                [langList[lang]]: LANG_LIST[lang]
            };
        }
    }
    return availableLang;
};

// export function getTranslationObj () {
//     const topLevelDomain = getTopLevelDomain();
//     let languageList: any = null;

//     switch(topLevelDomain) {
//         case COUNTRY_TOP_LEVEL_DOMAIN.ma:
//             languageList = {
//                 en: LANG_LIST.english,
//                 fr: LANG_LIST.french,
//                 ar: LANG_LIST.arabic
//             }
//             break
//         case COUNTRY_TOP_LEVEL_DOMAIN.ci:
//             languageList = {
//                 en: LANG_LIST.english,
//                 fr: LANG_LIST.french
//             }
//             break
//         case COUNTRY_TOP_LEVEL_DOMAIN.coza:
//             languageList = {
//                 en: LANG_LIST.english,
//                 xh: LANG_LIST.xhosa,
//                 zu: LANG_LIST.zulu
//             }
//             break
//         case COUNTRY_TOP_LEVEL_DOMAIN.rw:
//             languageList = {
//                 en: LANG_LIST.english,
//                 fr: LANG_LIST.french,
//                 rw: LANG_LIST.kinyarwanda
//             }
//             break
//         case COUNTRY_TOP_LEVEL_DOMAIN.tg:
//             languageList = {
//                 en: LANG_LIST.english,
//                 fr: LANG_LIST.french,
//                 ar: LANG_LIST.arabic
//             }
//             break

//         default:
//             languageList = {
//                 en: LANG_LIST.english,
//                 fr: LANG_LIST.french,
//                 ar: LANG_LIST.arabic
//             }
//     }
//     return languageList;
// }
