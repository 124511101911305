import AccordionItem from './Components/AccordionItem';

const Accordion = (props: any) => {
    const { InspectionReport = [] } = props;

    return (
        <>
            {InspectionReport.map((item: any, index: number) => {
                let name = item.options.filter((filtered: any) => {
                    if (filtered?.value || filtered?.images?.length) {
                        return true;
                    } else {
                        return false;
                    }
                }).length;
                return name > 0 ? (
                    <AccordionItem {...item} key={index} />
                ) : null;
            })}
        </>
    );
};

export default Accordion;
