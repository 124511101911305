import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SecSeperator from './ui/SecSeperator';
import { $FIXME } from '../constants';
import { ICombineReducerType } from '../redux/reducers';

const CarIssue = (props: $FIXME) => {
    const { t } = useTranslation();
    const { car } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );

    return (
        <>
        { car?.carIssues &&
        <div className={props.hasHash ? 'carIssue margin-top' : 'carIssue'}>
            <SecSeperator />
            <h2 className="title">{t('carIssue')}</h2>
            <div className="carIssue-container">
                {JSON.parse(car?.carIssues).map((item: any, index: number) => (
                    <div className="carIssue-item" key={index}>
                        <img src={item?.file ?? ""} alt="abcde" />
                        <p>{item?.caption ?? ""}</p>
                    </div>
                ))}
            </div>
        </div>
        }
        </>
    );
};

export default CarIssue;
