import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';

interface IPropType {
    currentLocation?: string;
    currentLocation1?: string;
    current?: string;
}

const BreadCrumbs = (props: IPropType) => {
    const { t } = useTranslation();
    return (
        <div className="bread-crumbs ">
            <Breadcrumb separator="">
                <Breadcrumb.Item>
                    {props.current ? t(props.current) : t('home')}
                </Breadcrumb.Item>
                <div className="breadcrumb-seperator"></div>

                <Breadcrumb.Item>{props.currentLocation}</Breadcrumb.Item>
                {props.currentLocation1 && (
                    <>
                        <div className="breadcrumb-seperator"></div>

                        <Breadcrumb.Item>
                            {t(props.currentLocation1)}
                        </Breadcrumb.Item>
                    </>
                )}
            </Breadcrumb>
        </div>
    );
};

export default BreadCrumbs;
