const getMetaEnglish = (auto24Domain: string) => ({
    homePageTitle: `${auto24Domain} - Certified Used Cars in Morocco | Buy & Sell Cars Nationwide`,
    homePageDesc: `Your go-to marketplace in Morocco for buying and selling certified used cars. Enjoy free evaluations, quick sales, and quality assurance across the nation.`,
    homePageKeywords: `Certified Used Cars, Buy Used Cars Morocco, Sell Used Cars Morocco, Car Marketplace, AUTO24 Morocco, Quality Used Cars, Auto Sales Morocco`,
    homePageTitleOg: `${auto24Domain} - Certified Used Cars in Morocco | Buy & Sell Cars Nationwide`,
    homePageDescOg: `Your go-to marketplace in Morocco for buying and selling certified used cars. Enjoy free evaluations, quick sales, and quality assurance across the nation.`,
    homePageKeywordsOg: `Certified Used Cars, Buy Used Cars Morocco, Sell Used Cars Morocco, Car Marketplace, AUTO24 Morocco, Quality Used Cars, Auto Sales Morocco`,
    buyPageTitle: `Buy Certified Quality Used Cars in Morocco | ${auto24Domain}`,
    buyPageDesc: `Explore a wide selection of certified used cars at ${auto24Domain} . We simplify your search for the perfect car that fits your budget and lifestyle across Morocco.`,
    buyPageKeywords: `Buy Cars Morocco, Certified Used Cars, AUTO24 Morocco, Car Shopping, Affordable Cars Morocco`,
    buyPageTitleOg: `Buy Certified Quality Used Cars in Morocco | ${auto24Domain}`,
    buyPageDescOg: `Explore a wide selection of certified used cars at ${auto24Domain} . We simplify your search for the perfect car that fits your budget and lifestyle across Morocco.`,
    buyPageKeywordsOg: `Buy Cars Morocco, Certified Used Cars, AUTO24 Morocco, Car Shopping, Affordable Cars Morocco`,
    sellPageTitle: `Sell Your Car Fast & Conveniently in Morocco | ${auto24Domain}`,
    sellPageDesc: `Looking to sell your car? ${auto24Domain} offers a hassle-free way to do it, providing free evaluations and quick sales nationwide in Morocco.`,
    sellPageKeywords: `Sell Car Morocco, Used Car Sales Morocco, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Morocco`,
    sellPageTitleOg: `Sell Your Car Fast & Conveniently in Morocco | ${auto24Domain}`,
    sellPageDescOg: `Looking to sell your car? ${auto24Domain} offers a hassle-free way to do it, providing free evaluations and quick sales nationwide in Morocco.`,
    sellPageKeywordsOg: `Sell Car Morocco, Used Car Sales Morocco, Quick Car Sales, AUTO24 Sell Car, Car Evaluation Morocco`,
    aboutPageTitle: `About ${auto24Domain} - Your Trusted Nationwide Used Car Marketplace in Morocco`,
    aboutPageDesc: `Learn about ${auto24Domain} , Morocco's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience across the country.`,
    aboutPageKeywords: `About AUTO24 Morocco, Certified Used Car Marketplace, Trusted Car Sales, Morocco Car Dealership`,
    aboutPageTitleOg: `About ${auto24Domain} - Your Trusted Nationwide Used Car Marketplace in Morocco`,
    aboutPageDescOg: `Learn about ${auto24Domain} , Morocco's premier certified used car marketplace. Offering quality cars and a seamless buying and selling experience across the country.`,
    aboutPageKeywordsOg: `About AUTO24 Morocco, Certified Used Car Marketplace, Trusted Car Sales, Morocco Car Dealership`,
    financePageTitle: `Easily Finance Your Used Car with ${auto24Domain} in Morocco`,
    financePageDesc: `Discover flexible financing options for your used car with ${auto24Domain} . Simplify car purchasing across Morocco.`,
    financePageKeywords: `Car Financing, Auto Credit, AUTO24 Morocco, Financing Options, Auto Loan`,
    financePageTitleOg: `Easily Finance Your Used Car with ${auto24Domain} in Morocco`,
    financePageDescOg: `Discover flexible financing options for your used car with ${auto24Domain} . Simplify car purchasing across Morocco.`,
    financePageKeywordsOg: `Car Financing, Auto Credit, AUTO24 Morocco, Financing Options, Auto Loan`,
    insurePageTitle: `Secure Reliable Car Insurance with ${auto24Domain} in Morocco`,
    insurePageDesc: `Obtain affordable, reliable car insurance for your used car with ${auto24Domain} . Choose from a range of options to meet your needs nationwide.`,
    insurePageKeywords: `Car Insurance, Insurance Coverage, AUTO24 Morocco, Used Car Insurance, Insurance Options`,
    insurePageTitleOg: `Secure Reliable Car Insurance with ${auto24Domain} in Morocco`,
    insurePageDescOg: `Obtain affordable, reliable car insurance for your used car with ${auto24Domain} . Choose from a range of options to meet your needs nationwide.`,
    insurePageKeywordsOg: `Car Insurance, Insurance Coverage, AUTO24 Morocco, Used Car Insurance, Insurance Options`,
    blogPageTitle: `${auto24Domain} Blog - Tips, Tricks, and Industry News in Morocco`,
    blogPageDesc: `Stay updated with the latest news, tips, and tricks in the Moroccan auto market. ${auto24Domain} is your go-to resource for everything used cars.`,
    blogPageKeywords: `AUTO24 Blog, Auto News, Car Tips, Moroccan Auto Market, Car Tricks`,
    blogPageTitleOg: `${auto24Domain} Blog - Tips, Tricks, and Industry News in Morocco`,
    blogPageDescOg: `Stay updated with the latest news, tips, and tricks in the Moroccan auto market. ${auto24Domain} is your go-to resource for everything used cars.`,
    blogPageKeywordsOg: `AUTO24 Blog, Auto News, Car Tips, Moroccan Auto Market, Car Tricks`,
    qNaPageTitle: `AUTO24 - Frequently asked questions `,
    contactUsPageTitle: ` AUTO24 - Contact us`,
    privacyPolicyPageTitle: ` AUTO24 - Privacy policy`,
    termsNusePageTitle: `AUTO24 - Terms & conditions`,
    carDetailPageTitle: `Buy {{make}},{{price}} DH - AUTO24`,
    carDetailPageDesc: `Buy a {{make}} certified used car at {{PRIX}} DH with AUTO24, Abidjan, Ivory Coast`,
    buyPageDescWhenBrand: `Best prices and services for {{BRAND}} used cars in Abidan, Ivory Coast. Refund policy, warranty, insurance, maintenance, and all included!`
})

export default getMetaEnglish;