import { FILTERED_LANG } from "../constants";
import { getTopLevelDomain } from "./translation";

export interface LaguageType {
    code: string;
    name: string;
    langCode: string;
}

function getLanguageWithCode(): LaguageType[] {
    const topLevelDomain = getTopLevelDomain();
    
    let languagesList: LaguageType[] = [];
    FILTERED_LANG.forEach((item) => {
        if(item.includedInDomain.includes(topLevelDomain)) {
            languagesList.push({
                name: item?.label,
                code: item?.code,
                langCode: item?.value
            })
        }
    })
    return languagesList;
}

export const LanguageWithCode: LaguageType[] = getLanguageWithCode();

