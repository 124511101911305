import { useSelector } from 'react-redux';
import { $FIXME } from '../../constants';
import { ICombineReducerType } from '../../redux/reducers';

const BlogType = (props: $FIXME) => {
    const cat = useSelector(
        (state: ICombineReducerType) => state.categories.categories
    );

    return (
        <div className="blog-type">
            {cat.map((items) => {
                return items.totalBlog === 0 ||
                    props.blog?.category?.category === items.category ? null : (
                    <div className="tag" key={items._id}>
                        {items?.category}
                    </div>
                );
            })}
        </div>
    );
};

export default BlogType;
