import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    CurrencyDollar,
    Eye,
    FireSimple,
    GasPump,
    Image,
    PlayCircle,
    ShareNetwork,
    ThumbsUp
} from 'phosphor-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Pagination } from 'swiper';

import { Dropdown, Modal } from 'antd';

import {
    LightgalleryProvider,
    LightgalleryItem,
    useLightgallery
} from 'react-lightgallery';
import 'lightgallery.js/dist/css/lightgallery.css';
import getSymbolFromCurrency from 'currency-symbol-map';

import { LikeOrNot, getDiscountedPriceIfNotSold, shareMenu } from './ui/Card';
import Loading from './ui/Loading';
import SecSeperator from './ui/SecSeperator';
import { CurrencyCodeDropdwon } from './ui/CurrencyCodeDropdown';

import { formatMoney } from './SearchFilter';
import useAudio from '../utils/Hooks/PlayAudio';
import { ICombineReducerType } from '../redux/reducers';
import { $FIXME, IMAGE_URL, whatsappUrl } from '../constants';
import i18n from '../localization';
import { getCurrency } from '../redux/actions/countryActions';
import AuthFlow from './AuthFlow/AuthFlow';
import { ICar } from '../interface/car';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';
import BuyCarConfirm from './ui/BuyCarConfirm';
interface CarviewProps {
    car: ICar;
    newCarFlag?: boolean;
}

const CarView = ({ car, newCarFlag = false }: CarviewProps) => {
    const { phoneNumber, whatsapp } = useSelector(
        (state: ICombineReducerType) => state.contact.contact
    );
    const [showThreeSixty, setShowThreeSixty] = useState(false);
    const [thumbsSwiper, setThumbsSwiper] = useState<any>();
    const [currencyChangeTO, setCurrencyTo] = useState('EUR');
    const [convertedPrice, setConvertedPrice] = useState('');
    const [showBuyCarModal, setShowBuyCarModal] = useState(false);

    let newImageArray: $FIXME = [];
    if (car.images) {
        newImageArray = JSON.parse(car.images);
    }
    const [playing, toggle] = useAudio(`${IMAGE_URL}${car.audio}` ?? '');

    const navigate = useNavigate();

    const goToImInterested = (id: string | undefined) => {
        navigate('../interested/' + id);
    };

    const { t } = useTranslation();
    const pageUrl = window.location.href;

    const message = `${t('whatsappUrlText')} ${car.brand?.brand} ${
        car.model?.model
    } ${car.modelYear}`;
    const whatsappShareUrl = `${whatsappUrl}${phoneNumber?.code?.replace(
        '+',
        ''
    )}${whatsapp}/?text=${message} on ${pageUrl}`;

    const PhotoItem = ({ image, thumb, group }: any) => (
        <div>
            <LightgalleryItem
                group={group}
                src={image}
                thumb={thumb}
                subHtml={`${car?.brand?.brand} ${car?.model?.model} ${car?.modelYear}`}
            >
                <img
                    src={image}
                    alt={`${car?.brand?.brand} ${car?.model?.model} ${car?.modelYear}`}
                    loading="lazy"
                />
            </LightgalleryItem>
        </div>
    );

    const moroccoDihram = 'MAD';

    useEffect(() => {
        if (car?.price) {
            getCurrency(currencyChangeTO, moroccoDihram, car?.price)
                .then((res) => setConvertedPrice(res))
                .catch(() => {});
        }
    }, [currencyChangeTO, car]);

    const OpenButtonWithHook = (props: any) => {
        const { openGallery } = useLightgallery();
        return (
            <button
                {...props}
                onClick={() => openGallery('group2')}
                className="top-btn play"
            >
                <Image size={24} color="#454545" weight="bold" />
                {t('showAllImage')}
            </button>
        );
    };

    const [showCurrencyModal, setCurrencyChangeModal] = useState(false);
    const [showAuthFlow, setAuthFlow] = useState(false);
    const show360Modal = (e: any) => {
        e.preventDefault();
        setShowThreeSixty(true);
    };
    const handleCancel = () => {
        setShowThreeSixty(false);
    };
    const handleOk = () => {
        setShowThreeSixty(false);
    };
    return (
        <>
        <BuyCarConfirm
            open={showBuyCarModal}
            setModalOpen={setShowBuyCarModal}
            carId={car?._id ?? "0"}
        />
        {car && (
            <div className="details padding">
                <div className="car-view-mobile">
                    <Swiper
                        className="mySwiper car-view-swiper"
                        modules={[Navigation, Pagination]}
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                            clickable: true
                        }}
                        navigation
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 16
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            }
                        }}
                    >
                        {newImageArray.map((items: string, index: number) => {
                            return (
                                <div>
                                    <SwiperSlide key={index}>
                                        <div className="carousel-image">
                                            <img
                                                src={`${IMAGE_URL}${items}`}
                                                alt="carview"
                                                onError={addImageFallback}
                                                loading="lazy"
                                            />
                                        </div>
                                    </SwiperSlide>
                                </div>
                            );
                        })}
                        <div className="top-btn-container">
                            {car?.view360?.length ? (
                                <button
                                    className="top-btn three-sixty"
                                    onClick={show360Modal}
                                >
                                    <img
                                        src="/images/360.webp"
                                        alt="360 view button"
                                        loading="lazy"
                                    />
                                </button>
                            ) : null}
                            <button className="top-btn play" onClick={toggle}>
                                {playing ? (
                                    <div className="pause-icon">
                                        <img
                                            src="/svgs/PauseCircle.svg"
                                            alt="pause"
                                            loading="lazy"
                                        />
                                    </div>
                                ) : (
                                    <PlayCircle
                                        size={24}
                                        color="#454545"
                                        weight="light"
                                    />
                                )}
                            </button>
                        </div>
                    </Swiper>
                </div>
                <div className="car-view">
                    {car?.status === 'sold' ? (
                        <div className="card-status">
                            <span className="card-status-text">
                                {t('sold')}
                            </span>
                        </div>
                    ) : (
                        String(car?.discount) !== '0' && (
                            <div
                                className="card-status"
                                style={{ backgroundColor: '#FF4637' }}
                            >
                                <span className="card-status-text">
                                    {t('discountOff').replace(
                                        '{{X}}',
                                        `${String(car?.discount)} ${process.env.REACT_APP_DEFAULT_CURRENCY}`
                                    )}
                                </span>
                            </div>
                        )
                    )}
                    {car?.isNewCar && (
                        <div className="card-status-new">
                            <img alt="new" src="/icon/new.svg" loading="lazy" />
                        </div>
                    )}
                    <div className="top-car">
                        <Swiper
                            spaceBetween={10}
                            navigation={true}
                            modules={[Navigation, Thumbs]}
                            className="mySwiper car-images-slider"
                            grabCursor={true}
                            thumbs={{ swiper: thumbsSwiper }}
                        >
                            {newImageArray.map(
                                (items: string, index: number) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <img
                                                src={`${IMAGE_URL}/${items}`}
                                                alt="carview"
                                                onError={addImageFallback}
                                                loading="lazy"
                                            />
                                        </SwiperSlide>
                                    );
                                }
                            )}
                            <div className="btn-container">
                                <div className="left">
                                    {car?.view360?.length ? (
                                        <button
                                            className="top-btn threesixty"
                                            onClick={show360Modal}
                                        >
                                            <div>
                                                <img
                                                    src="/svgs/360.svg"
                                                    alt="360"
                                                    loading="lazy"
                                                />
                                            </div>
                                            {t('interior')}
                                        </button>
                                    ) : null}

                                    {car?.audio && (
                                        <button
                                            className="top-btn play"
                                            onClick={toggle}
                                        >
                                            {' '}
                                            {playing ? (
                                                <div className="pause-icon">
                                                    <img
                                                        src="/svgs/PauseCircle.svg"
                                                        alt=""
                                                        loading="lazy"
                                                    />
                                                </div>
                                            ) : (
                                                <PlayCircle
                                                    size={24}
                                                    color="#454545"
                                                    weight="bold"
                                                />
                                            )}
                                            {t('engineSound')}
                                        </button>
                                    )}
                                </div>
                                <LightgalleryProvider
                                    lightgallerySettings={{
                                        getCaptionFromTitleOrAlt: true
                                    }}
                                >
                                    <div className="photo-preview">
                                        {newImageArray.map(
                                            (item: any, idx: number) => (
                                                <PhotoItem
                                                    key={idx}
                                                    image={`${IMAGE_URL}${item}`}
                                                    group="group2"
                                                    onError={addImageFallback}
                                                />
                                            )
                                        )}
                                    </div>

                                    <div className="buttons mt-4">
                                        <OpenButtonWithHook />
                                    </div>
                                </LightgalleryProvider>
                            </div>
                        </Swiper>
                    </div>
                    <div className="carousel">
                        <Swiper
                            onSwiper={setThumbsSwiper}
                            modules={[Navigation, Thumbs]}
                            navigation={true}
                            className="mySwiper car-images-slider-thumbs"
                            slidesPerView={1}
                            spaceBetween={10}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 16
                                },
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20
                                },
                                1024: {
                                    slidesPerView: 3.5,
                                    spaceBetween: 16
                                }
                            }}
                        >
                            {newImageArray.map(
                                (items: string, index: number) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className="car-images-slider-thumbs-wrapper">
                                                <img
                                                    src={`${IMAGE_URL}/${items}`}
                                                    alt="carview"
                                                    onError={addImageFallback}
                                                    loading="lazy"
                                                />
                                            </div>
                                        </SwiperSlide>
                                    );
                                }
                            )}
                        </Swiper>
                    </div>
                </div>
                <div className="car-details">
                    <div className="car-detail">
                        <div className="details-top__old-price">
                            <div className="details-top">
                                <h4 className="new-price">
                                    {' '}
                                    {/* {car?.price
                                        ?.toLocaleString()
                                        .replaceAll(',', ' ')}{' '} */}
                                    {car?.status !== 'sold' &&
                                    String(car?.discount) !== '0' &&
                                    String(car?.price) !== '0'
                                        ? getDiscountedPriceIfNotSold(
                                              car?.price ?? 0,
                                              car.discount ?? 0
                                          )
                                        : formatMoney(car?.price)}
                                    {process.env.REACT_APP_DEFAULT_CURRENCY}
                                </h4>
                                <div className="like-share">
                                    {car?._id && (
                                        <LikeOrNot
                                            id={car?._id}
                                            setAuthFlow={setAuthFlow}
                                        />
                                    )}
                                    <Dropdown
                                        overlay={shareMenu(car)}
                                        trigger={['click']}
                                    >
                                        <ShareNetwork
                                            size={24}
                                            color="#454545"
                                            cursor="pointer"
                                        />
                                    </Dropdown>
                                </div>
                            </div>
                            <div>
                                {car?.status !== 'sold' &&
                                    String(car?.discount) !== '0' && (
                                        <span className="old-price-no-discount">
                                            {`${formatMoney(car.price)} ${process.env.REACT_APP_DEFAULT_CURRENCY}`}
                                        </span>
                                    )}
                            </div>
                            {car.newCarPriceFrom && (
                                <p className="old-price">
                                    {t('newPriceFrom')}{' '}
                                    {formatMoney(car.newCarPriceFrom) ?? 'N/A'}{' '}
                                    {process.env.REACT_APP_DEFAULT_CURRENCY}
                                </p>
                            )}
                            <span
                                className="subtitle"
                                onClick={() => {
                                    setCurrencyChangeModal(true);
                                }}
                            >
                                {t('payWithOtherCurrencytitle')}
                            </span>
                        </div>

                        <div className="price-features">
                            {car?.isHotDeal ? (
                                <span className="hot-deal">
                                    <FireSimple size={26} color="#ffffff" />
                                    {t('hotDeal')}{' '}
                                </span>
                            ) : null}
                            {car?.lowPrice ? (
                                <span className="hot-deal">
                                    <CurrencyDollar size={26} color="#ffffff" />
                                    {t('lowPrice')}{' '}
                                </span>
                            ) : null}
                            {car?.clean ? (
                                <span className="hot-deal">
                                    <ThumbsUp size={26} color="#ffffff" />
                                    {t('likeNew')}{' '}
                                </span>
                            ) : null}
                        </div>
                        <div className="car-deets">
                            <h5 className="car-name">
                                {car?.brand?.brand} {car?.model?.model}{' '}
                                {car?.modelYear}
                            </h5>
                            <div
                                className={
                                    i18n.language === 'fr'
                                        ? 'car-features-container fre'
                                        : 'car-features-container'
                                }
                            >
                                <div className="car-features">
                                    <span className="features-container">
                                        <div className="features-icons">
                                            <img
                                                src="/logos/automatic.webp"
                                                alt={t('automatic')}
                                                loading="lazy"
                                            />
                                        </div>
                                        {car?.transmission &&
                                            t(car?.transmission)}
                                    </span>
                                    <span className="features-container">
                                        <GasPump size={17} color="#454545" />
                                        {car?.fuelType && t(car?.fuelType)}
                                    </span>
                                    <div className="features-container">
                                        <div className="features-icons">
                                            <img
                                                src="/logos/timeline.webp"
                                                alt="timeline"
                                                loading="lazy"
                                            />
                                        </div>
                                        {formatMoney(car?.mileage)} KM
                                    </div>
                                </div>
                                <div className="card-bottom">
                                    <div className="card-cert">
                                        <img
                                            alt="cretified"
                                            src="/icon/certified.svg"
                                            loading="lazy"
                                        />

                                        <span>{t('Certified')}</span>
                                    </div>
                                    <div className="dot"></div>
                                    <div className="card-cert">
                                        <img
                                            alt="inspected"
                                            src="/icon/inspected.svg"
                                            loading="lazy"
                                        />

                                        <span>{t('Inspected')}</span>
                                    </div>
                                    <div className="dot"></div>

                                    <div className="card-cert">
                                        <img
                                            alt="warranted"
                                            src="/icon/warranted.svg"
                                            loading="lazy"
                                        />

                                        <span>
                                            6{''} &nbsp;
                                            {t('months')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="btn-container">
                            <button
                                className={`buy-now car-btn ${car?.status?.toLowerCase() === "sold" && "button-disabled"}`}
                                disabled={car?.status?.toLowerCase() === "sold" ?? false}
                                onClick={() => {
                                    if(newCarFlag){
                                        setShowBuyCarModal(true);
                                    }
                                    else goToImInterested(car?._id)
                                }}
                            >
                                {newCarFlag ? t('buyOnline') : t('imInterested')}
                            </button>
                            <a
                                href={whatsappShareUrl}
                                target="_blank"
                                className="whatsapp  car-btn"
                                rel="noreferrer"
                            >
                                <div className="whatsapp-logo-container">
                                    <img
                                        src="/images/auto24-whatsapp.svg"
                                        alt="whatsapp"
                                        loading="lazy"
                                    />
                                </div>
                                WhatsApp
                            </a>
                        </div>
                        <div className="car-viewed">
                            {' '}
                            <Eye size={24} />
                            {t('carViewsXTimes', {
                                number: car.todayTotalViews
                            })}
                        </div>
                    </div>
                    <div className="post-script-container">
                        <div className="post-script">
                            <div className="certified-icon">
                                <img
                                    src="/logos/verified.svg"
                                    alt="certified"
                                    loading="lazy"
                                />
                            </div>
                            <p>
                                {t(
                                    'allcarshavebeenthoroughlyinspectedandreconditionedHowweguaranteecarquality'
                                )}
                            </p>
                        </div>
                        {/*<div className="autofast-image">*/}
                        {/*    <img src="/svgs/logo-autofast.svg" alt="" />*/}
                        {/*</div>*/}
                    </div>
                </div>
                <Modal
                    open={showCurrencyModal}
                    title={null}
                    footer={null}
                    centered
                    onCancel={() => {
                        setCurrencyChangeModal(!showCurrencyModal);
                    }}
                    className="currency-modal"
                >
                    <SecSeperator className="separator" />

                    <h3 className="currencyex-title">
                        {t('payWithOtherCurrencytitle')}
                    </h3>
                    <div className="subtitle">
                        {t('payWithOtherCurrencySubtitle')}
                    </div>
                    <div className="name-price">
                        <p className="subtitle">{car?.name} </p>
                        <p className="price">
                            {car?.price
                                ?.toLocaleString()
                                .replaceAll(',', '   ')}{' '}
                            {process.env.REACT_APP_DEFAULT_CURRENCY}
                        </p>
                    </div>
                    <div className="currency-converter">
                        <div className="currency-dropdown phone-input">
                            <CurrencyCodeDropdwon
                                selectValue={currencyChangeTO}
                                onSelect={(e, name) => {
                                    setCurrencyTo(e);
                                }}
                                selectName="currency"
                            />
                        </div>

                        <div className="preview-box">
                            <span className="subtitle">
                                {t('convertedPrice')}
                            </span>
                            <p className="price">
                                {convertedPrice ? (
                                    <>
                                        {Math.round(parseInt(convertedPrice))
                                            .toLocaleString()
                                            .replaceAll(',', '   ')}{' '}
                                        {getSymbolFromCurrency(
                                            currencyChangeTO
                                        )}
                                    </>
                                ) : (
                                    <p className="price">
                                        {car?.price
                                            ?.toLocaleString()
                                            .replaceAll(',', '   ')}{' '}
                                        {process.env.REACT_APP_DEFAULT_CURRENCY}
                                    </p>
                                )}
                            </p>
                        </div>
                    </div>
                </Modal>
                <Modal
                    className="threesixty-modal"
                    open={showThreeSixty}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    closable={true}
                    centered={true}
                    footer={null}
                >
                    {car?.view360?.includes('iframe') ? (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: car.view360
                            }}
                        ></div>
                    ) : car?.view360?.includes('script') ? (
                        <div>
                            <iframe
                                src={car.view360.split('"')[3]}
                                width="100%"
                                height="100%"
                                title="location"
                                loading="lazy"
                            ></iframe>
                        </div>
                    ) : (
                        <Loading />
                    )}
                </Modal>
                {showAuthFlow && (
                    <AuthFlow
                        isVisible={showAuthFlow}
                        like_id={car._id}
                        handleCancel={() => {
                            setAuthFlow(!showAuthFlow);
                        }}
                    />
                )}
            </div>
        )
        }</>
    );
};

export default CarView;

export const ButtonContainer = () => {
    const { car } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const { whatsapp } = useSelector(
        (state: ICombineReducerType) => state.contact.contact
    );
    const navigate = useNavigate();

    const goToImInterested = (id: string | undefined) => {
        navigate('../interested/' + id);
    };
    const { t } = useTranslation();
    const pageUrl = window.location.href;

    const message = `${t('whatsappUrlText')} ${car.brand?.brand} ${
        car.model?.model
    } ${car.modelYear}`;
    const whatsappShareUrl = `${whatsappUrl}${whatsapp}/?text=${message} on ${pageUrl}`;

    return (
        <div className="button-wrappers padding">
            <div className="car-info">
                <p className="car-name">{`${car?.brand?.brand} ${car.model?.model} ${car?.modelYear}`}</p>
                <p className="price">
                    {formatMoney(car?.price)} {process.env.REACT_APP_DEFAULT_CURRENCY}
                </p>
            </div>
            <div className="button-container">
                <button
                    className="buy-now car-btn"
                    onClick={() => goToImInterested(car?._id)}
                >
                    {t('imInterested')}
                </button>
                <a
                    href={whatsappShareUrl}
                    target="_blank"
                    className="whatsapp  car-btn"
                    rel="noreferrer"
                >
                    <div className="whatsapp-logo-container">
                        <img src="/images/auto24-whatsapp.svg" alt="whatsapp" />
                    </div>
                    WhatsApp
                </a>
            </div>
        </div>
    );
};
