import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import SecSeperator from './ui/SecSeperator';
import Loading from './ui/Loading';

import { ICombineReducerType } from '../redux/reducers';
import { $FIXME, IMAGE_URL } from '../constants';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const Testimonial = () => {
    const { testimonials } = useSelector(
        (state: ICombineReducerType) => state.testimonial
    );
    const { t } = useTranslation();

    return (
        <>
            <div className="testimonial">
                <SecSeperator />
                <h2 className="title">{t('ourTeam')}</h2>
                <p className="subtitle">{t('ourTeamSubtitle')}</p>
                <div className="testimonial__container padding">
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1.7}
                        pagination={{
                            clickable: true
                        }}
                        breakpoints={{
                            '320': {
                                slidesPerView: 1.5,
                                spaceBetween: 10
                            },
                            '768': {
                                slidesPerView: 2.3,
                                spaceBetween: 16
                            },
                            '1024': {
                                slidesPerView: 2.4,
                                spaceBetween: 16
                            },
                            '1100': {
                                slidesPerView: 2.6,
                                spaceBetween: 16
                            },
                            '1200': {
                                slidesPerView: 4,
                                spaceBetween: 24
                            }
                        }}
                        navigation
                        className="testimonial-swiper"
                    >
                        {testimonials?.length !== 0 ? (
                            testimonials?.map(
                                (items: $FIXME, index: $FIXME) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className="testimonial-unit">
                                                <TestimonialCard
                                                    propic={
                                                        IMAGE_URL +
                                                        items?.profile
                                                    }
                                                    name={items?.fullName}
                                                    jobtitle={items?.position}
                                                    whattheysay={items?.message}
                                                    companyName={items?.company}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    );
                                }
                            )
                        ) : (
                            <Loading />
                        )}
                    </Swiper>
                </div>
            </div>
        </>
    );
};

export default Testimonial;

const TestimonialCard = (props: any) => {
    return (
        <div className="testimonial-card">
            <div className="testiminial-image">
                <div className="image">
                    <img
                        src={props.propic}
                        alt={props.jobtitle}
                        onError={addImageFallback}
                    />
                </div>
            </div>
            <p className="testi-name">{props.name}</p>
            <div className="job-title">
                <span className="job_title">{props.jobtitle}</span> at{' '}
                <span className="company_name">{props.companyName}</span>
            </div>
            <div className="quote-image">
                <img src="/images/quote.webp" alt="quote" />
            </div>
            <p className="desc">{props.whattheysay}</p>
        </div>
    );
};
