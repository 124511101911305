import { message } from 'antd';
import { Dispatch } from 'redux';
import { protectedAPI } from '../../helpers/api';
import {
    FETCH_FINANCING_ERROR,
    FETCH_FINANCING_LOADING,
    FETCH_FINANCING_SUCCESS
} from '../types/financingTypes';

export const financement = async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_FINANCING_LOADING });
    const resData: any = await getFinancings();
    if (resData.status === 200) {
        dispatch({
            type: FETCH_FINANCING_SUCCESS,
            payload: resData.data.financings
        });
    } else {
        dispatch({ type: FETCH_FINANCING_ERROR });
        // message.error(resData.response.data?.msg);
    }
};

export const getFinancings = async () => {
    try {
        const result = await protectedAPI.get(`/financings`);
        return result;
    } catch (error) {
        return error;
    }
};
