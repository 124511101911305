import { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { FETCH_FEATURED_CARS } from '../../helpers/constants';
import { request } from '../../helpers/request';

export const fetchFeaturedCars = (dispatch: Dispatch) => {
    return request
        .getFeaturedCars()
        .then((response: AxiosResponse) => {
            dispatch({
                type: FETCH_FEATURED_CARS,
                payload: response.data ?? []
            });
        })

        .catch((err: AxiosError | Error) => {});
};
