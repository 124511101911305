import { useRef, useState } from 'react';

import { Form, Input, InputRef, Select } from 'antd';
import { MagnifyingGlass } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import {
    countryCode,
    countryCodes,
    defaultDailing_Code
} from '../../helpers/countryCode';
import { returnOnlyNumber } from '../../utils/Helper/jsHelpers';
import { $FIXME } from '../../constants';

interface Props {
    placeholder: string;
    prefix?: JSX.Element;
    type: 'number' | 'text' | 'password' | 'email' | 'phone-number';
    onChange: (value: string | number) => void;
    className?: string;
    value: string | number | undefined;
    autoComplete?: 'true' | 'false';
    rules?: any[];
    disabled?: boolean;
    error?: boolean;
    success?: boolean;
    name: string;
    onSelect?: (e: string, name: string) => void;
}

const TextBox = ({
    placeholder,
    prefix,
    type,
    onChange,
    className,
    value = undefined,
    autoComplete = 'false',
    disabled,
    error,
    success,
    name,
    onSelect
}: Props) => {
    const [query, setQuery] = useState('');

    const { t } = useTranslation();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
    };

    const { Option } = Select;

    const FilterCountry = (countryCodes: $FIXME, query: string) => {
        const array = countryCodes.filter((item: $FIXME) => {
            let a = item.country_name?.toLowerCase() + item.dialling_code;

            return a.includes(query);
        });
        return array;
    };

    const inputRef = useRef<InputRef>(null);

    const CountrySearchFocus = () =>
        setTimeout(() => {
            inputRef.current!.focus({ cursor: 'start' });
        }, 500);

    const ClearState = () =>
        setTimeout(() => {
            setQuery('');
        }, 500);

    const prefixSelector = (
        <Form.Item name="code" noStyle>
            <Select
                onChange={(e) => onSelect && onSelect(e, 'code')}
                defaultValue={defaultDailing_Code}
                dropdownMatchSelectWidth={240}
                optionLabelProp="label"
                className="phone-select"
                onDropdownVisibleChange={(open) =>
                    open ? CountrySearchFocus() : ClearState()
                }
                dropdownRender={(menu) => (
                    <>
                        <Form className="dropdown-form">
                            <Input
                                prefix={
                                    <MagnifyingGlass
                                        size={16}
                                        color="#8b8b8b"
                                        weight="bold"
                                    />
                                }
                                placeholder={t('search')}
                                className="dropdown-input"
                                ref={inputRef}
                                value={query}
                                onChange={(e) =>
                                    setQuery(e.target.value.toLowerCase())
                                }
                            />
                        </Form>
                        {menu}
                    </>
                )}
            >
                {FilterCountry(countryCodes, query)?.map(
                    (opt: $FIXME, index: number) => (
                        <Option
                            key={index}
                            value={`${opt.dialling_code}`}
                            label={
                                <div className="phone-label">
                                    <img
                                        alt=""
                                        src={`https://flagcdn.com/16x12/${opt.country_code}.png`}
                                        srcSet={`https://flagcdn.com/32x24/${opt.country_code}.png 2x,
                                          https://flagcdn.com/48x36/${opt.country_code}.png 3x`}
                                    />
                                    <span> ({opt.dialling_code})</span>
                                </div>
                            }
                        >
                            <div className="phone-option">
                                <img
                                    alt=""
                                    src={`https://flagcdn.com/16x12/${opt.country_code}.png`}
                                    srcSet={`https://flagcdn.com/32x24/${opt.country_code}.png 2x,
                                      https://flagcdn.com/48x36/${opt.country_code}.png 3x`}
                                />
                                {`${opt.country_name}  (${opt.dialling_code})`}
                            </div>
                        </Option>
                    )
                )}
            </Select>
        </Form.Item>
    );

    return (
        <>
            {type === 'password' ? (
                <Input.Password
                    size="large"
                    status=""
                    placeholder={placeholder}
                    iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    onChange={handleChange}
                    autoComplete={autoComplete}
                    prefix={prefix ? prefix : null}
                    value={value}
                    bordered={true}
                    disabled={disabled}
                    name={name}
                    className="input-box"
                />
            ) : type === 'phone-number' ? (
                <Input
                    size="large"
                    type="text"
                    autoComplete={'off'}
                    maxLength={10}
                    placeholder={placeholder}
                    prefix={prefix ? prefix : null}
                    onChange={handleChange}
                    value={value ? returnOnlyNumber(value.toString()) : ''}
                    bordered={true}
                    disabled={disabled}
                    addonBefore={prefixSelector}
                    name={name}
                    className="number-input"
                />
            ) : (
                <Input
                    size="large"
                    type={type}
                    autoComplete="off"
                    placeholder={placeholder}
                    prefix={prefix ? prefix : null}
                    onChange={handleChange}
                    className={`input-box 
          ${className} ${error ? 'error-input' : ''} ${
                        success ? 'success-input' : ''
                    }`}
                    value={value}
                    bordered={true}
                    disabled={disabled}
                    name={name}
                />
            )}
        </>
    );
};
export default TextBox;
