import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Modal } from 'antd';
import { User } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import CountryCodeDropdown from './CountryCodeDropdown';

import { $FIXME } from '../../constants';
import { defaultDailing_Code } from '../../helpers/countryCode';
import { request } from '../../helpers/request';
import { ICombineReducerType } from '../../redux/reducers';
import { numcheck, returnOnlyNumber } from '../../utils/Helper/jsHelpers';
export interface IUserDataResquestType {
    fullName: string;
    phoneNumber: {
        number: string;
        code: string;
    };
    category: string;
    subCategory?: string;
}
export const NoCarFound = (props: $FIXME) => {
    const { t } = useTranslation();
    let { user } = useSelector((state: ICombineReducerType) => state.auth);
    const [userData, setUserData] = useState({
        phoneNumber: {
            code: user?.phoneNumber?.code ?? defaultDailing_Code,
            number: user?.phoneNumber?.number ?? ''
        },
        fullName: user?.name ?? '',
        category: 'car not found',
        subCategory: ''
    } as IUserDataResquestType);
    const [error, setError] = useState<any>({});
    const [isvisiable, setVisiable] = useState(false);
    const handleChange = (e: any) => {
        setError('');
        if (e.target.name === 'number') {
            setUserData((prev) => ({
                ...prev,
                phoneNumber: {
                    ...prev.phoneNumber,
                    number: returnOnlyNumber(e.target.value)
                }
            }));
        } else {
            setUserData((prev) => ({
                ...prev,
                [e.target.name]: e.target.value
            }));
        }
    };
    const navigate = useNavigate();
    const Submit = async (e: any) => {
        e.preventDefault();
        if (!userData.phoneNumber?.number) {
            setError('Phone Number is Required');
            return;
        }
        if (!userData.fullName) {
            setError('Full Name is Required');
            return;
        }
        if (await numcheck(userData.phoneNumber, setError)) {
            let {
                fullName,
                phoneNumber: { code, number },
                category,
                subCategory
            } = userData;
            await request.sendInquiry({
                fullName,
                code,
                number,
                category,
                subCategory
            });
            setVisiable(true);
        }
    };
    return (
        <>
            <div className="no-car-found">
                <div className="form-container">
                    <h2 className="title">{t('carNotFound')}</h2>{' '}
                    <div className="image-container">
                        <img
                            src="/images/carNotFound.svg"
                            alt="car not found"
                        />
                    </div>
                    <p className="subtitle">{t('carnotfoundSubtitle')}</p>
                    <form>
                        <div className="phone-input">
                            <label>
                                <User size={24} color="#8b8b8b" />
                            </label>
                            <input
                                type="text"
                                placeholder={t('enterFullName')}
                                name="fullName"
                                value={userData.fullName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="phone-number">
                            <CountryCodeDropdown
                                onInputChange={handleChange}
                                onSelect={(value, key) =>
                                    setUserData((prev) => ({
                                        ...prev,
                                        phoneNumber: {
                                            ...prev.phoneNumber,
                                            [key]: value
                                        }
                                    }))
                                }
                                selectName="code"
                                inputName="number"
                                error={error.phoneNumber ?? null}
                                inputValue={userData?.phoneNumber.number}
                                selectValue={userData?.phoneNumber?.code}
                                inputStyle={{ backgroundColor: 'white' }}
                            />
                        </div>

                        <button className="bottom-btn" onClick={Submit}>
                            {t('getACallBack')}
                        </button>
                        <span className="terms-condt-listing">
                            {t('byClickingXButton', {
                                name: t('getACallBack')
                            })}{' '}
                            <Link
                                to={t('/terms-of-use', { ns: 'routes' })}
                                className="termandpolicy"
                            >
                                {t('termOfUse')}
                            </Link>{' '}
                            {t('and')}{' '}
                            <Link
                                to={t('/privacy-policy', { ns: 'routes' })}
                                className="termandpolicy"
                            >
                                {t('privacyPolicy')}
                            </Link>
                        </span>
                    </form>{' '}
                </div>
            </div>
            <Modal
                className="save-changes-modal  ants-modal"
                title={t('Detailssentsuccessfully')}
                visible={isvisiable}
                onCancel={() => setVisiable(false)}
                closable={true}
                centered={true}
            >
                <p className="subtitle">{t('DetailChangeSubtitle')}</p>
                <div className="done-icon">
                    <img src="/images/done.webp" alt="" />
                </div>
                <div className="modal-btn-container no-car">
                    <button
                        className="back-to-home"
                        onClick={() => navigate(t('home', { ns: 'routes' }))}
                    >
                        {t('backToHome')}
                    </button>
                </div>
            </Modal>
        </>
    );
};
