import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { CalendarBlank, MapPin } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import SecSeperator from './ui/SecSeperator';

import { ICombineReducerType } from '../redux/reducers';
import { $FIXME, IMAGE_URL } from '../constants';
import {
    contentAccordToCurrentLangs,
    contentAccordToCurrentLangTime
} from '../utils/Helper/jsHelpers';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const Location = (props: $FIXME) => {
    const { t } = useTranslation();

    const { stores } = useSelector((state: ICombineReducerType) => state.store);

    const navigate = useNavigate();

    const goToListing = () => {
        navigate(t('/buy-cars', { ns: 'routes' }));
    };

    return (
        <div className="location-holder">
            <div className="location-container padding">
                <div className="location-header">
                    <SecSeperator />
                    <h2 className="title">
                        {t('weAreAroundTheArea', { ns: 'hero' })}
                    </h2>
                    <p className="subtitle">
                        {t('youcanfindusaroundthislocation', { ns: 'hero' })}
                    </p>
                    <button className="bottom-btn" onClick={goToListing}>
                        {t('SearchByLocation', { ns: 'hero' })}
                    </button>
                </div>
                <div className="location">
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1}
                        spaceBetween={10}
                        pagination={{
                            clickable: true
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 16
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 40
                            },
                            1024: {
                                slidesPerView: 1,
                                spaceBetween: 16
                            }
                        }}
                        className="mySwiper"
                        navigation
                    >
                        {stores.map((store: $FIXME, index: number) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="location-card-container">
                                        <LocationCard
                                            imagesrc={store.image}
                                            title={store.name}
                                            address={store.address}
                                            openingtime={
                                                contentAccordToCurrentLangs(
                                                    store
                                                ).openingDay
                                            }
                                            openingDay={
                                                contentAccordToCurrentLangTime(
                                                    store
                                                ).openingTime
                                            }
                                            carQuantity={props.totalCars}
                                        />
                                    </div>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                </div>
                <button className="bottom-btn" onClick={goToListing}>
                    {t('SearchByLocation', { ns: 'hero' })}
                </button>
            </div>
        </div>
    );
};

export default Location;

const LocationCard = (props: any) => {
    return (
        <div className="location-card ">
            <div className="location-card-image">
                <img
                    src={IMAGE_URL + props.imagesrc}
                    alt={props.title}
                    onError={addImageFallback}
                />
            </div>
            <div className="location-card-content">
                <h3 className="content-title">{props.title}</h3>
                <span className="content-description">
                    <MapPin
                        size={20}
                        weight="bold"
                        color="#595959"
                        className="map-pin"
                    />
                    {props.address?.localAddress}, {props.address?.district},
                    {props.address?.province}, {props.address?.country}
                </span>
                <span className="content-description">
                    <CalendarBlank
                        size={20}
                        weight="bold"
                        color="#595959"
                        className="calender"
                    />
                    {props.openingtime} &#40;{props.openingDay}&#41;
                </span>
                <Link
                    to={t('/buy-cars', { ns: 'routes' })}
                    className="view-all-cars-link"
                >
                    <>
                        <span className="view-all-cars">
                            {t('viewAllCars')} ({props.carQuantity})
                        </span>
                        <div className="right-arrow">
                            <img
                                src="./images/rightarrow.webp"
                                alt="right-arrow"
                            />
                        </div>
                    </>
                </Link>
            </div>
        </div>
    );
};
