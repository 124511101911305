import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Brands from '../components/Brands';
import Buysell from '../components/Buysell';
import Featured from '../components/Featured';
import InstaGram from '../components/InstaGram';
import NewsLetter from '../components/NewsLetter';
import PopularBlogs from '../components/PopularBlogs';
import Loading from '../components/ui/Loading';
import { $FIXME, IMAGE_URL } from '../constants';
import Layout from '../layout/Layout';

import { getAllBlogs } from '../redux/actions/blogActions';
import { ICombineReducerType } from '../redux/reducers';
import { IBlogDetailType } from '../redux/types/BlogListTypes';
import { contentAccordToCurrentLang, SetMeta } from '../utils/Helper/jsHelpers';
import text_truncate from '../utils/Helper/TextTruncate';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const Blogs = () => {
    const [blogsArray, setBlogsArray] = useState<IBlogDetailType[]>([]);
    const { blogs, loading, latestBlogs } = useSelector(
        (state: ICombineReducerType) => state.blogs
    );
    const { t } = useTranslation();

    const carBrands = useSelector(
        (state: ICombineReducerType) => state.carBrands.carAvailableBrands
    );
    const dispatch = useDispatch();
    useEffect(() => {
        if (!blogs.length) {
            getAllBlogs(dispatch);
        }
    }, []);

    useEffect(() => {
        if (blogs.length) {
            const result = [];
            const map = new Map();
            for (const item of blogs) {
                if (
                    !map.has(contentAccordToCurrentLang(item.category).category)
                ) {
                    map.set(
                        contentAccordToCurrentLang(item.category).category,
                        true
                    );
                    result.push({
                        ...item
                    });
                }
            }
            setBlogsArray(result);
        }
    }, [blogs]);

    const navigate = useNavigate();

    const BlogLists = () => (
        <div className="banner-images padding">
            {blogsArray.slice(0, 3).map((item: $FIXME, index) => {
                return (
                    <div
                        className={
                            index === 0
                                ? 'banner-container'
                                : index === 2
                                ? 'banner-containers'
                                : 'banners-container'
                        }
                        key={index}
                        onClick={() =>
                            navigate(
                                `${t('/blog', { ns: 'routes' })}/${item.slug}`
                            )
                        }
                    >
                        <div className="banner-image">
                            <img
                                src={IMAGE_URL + item.image}
                                alt=""
                                onError={addImageFallback}
                            />
                            <div className="contents">
                                <div className="blog-type">
                                    {
                                        contentAccordToCurrentLang(
                                            item.category
                                        )?.category
                                    }
                                </div>
                                <h3 className="content-title">
                                    {' '}
                                    {index === 0
                                        ? text_truncate(
                                              contentAccordToCurrentLang(item)
                                                  .title,
                                              38,
                                              '...'
                                          )
                                        : text_truncate(
                                              contentAccordToCurrentLang(item)
                                                  .title,
                                              22,
                                              '...'
                                          )}
                                </h3>
                                <span className="type">
                                    {item.author?.name}
                                </span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );

    return (
        <>
            <SetMeta title="blogPageTitle" desc="blogPageDesc" keywords='blogPageKeywords'
            properties={[
                {
                    key: "og:title",
                    value: "blogPageTitleOg"
                },
                {
                    key: "og:description",
                    value: "blogPageDescOg"
                },
                {
                    key: "og:keywords",
                    value: "blogPageKeywordsOg"
                }
            ]}
            />
            <div className="blog-page">
                <Layout SearchBar>
                    {loading ? null : <BlogLists />}
                    <Featured />
                    <PopularBlogs
                        title="latestPosts"
                        subtitle="lastestPostSubtitle"
                        latestBlogs={latestBlogs}
                    />
                    <Brands carBrandsArrays={carBrands} />
                    <NewsLetter />
                    <InstaGram />
                    <Buysell />
                </Layout>
            </div>
        </>
    );
};
export default Blogs;
