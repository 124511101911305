import { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import '../src/styles/main.scss';
import AccountVerification from './components/AuthFlow/AccountVerfication';
import { ForgetPasword } from './components/AuthFlow/ForgotPassword';
import ProtectedRoute from './components/AuthFlow/ProtectedRoute';
import UnsubscribeBlog from './components/UnSubscribeBlog';
import WhatsAppChatBox from './components/ui/WhatssAppChatBot';
import About from './pages/About';
import Account from './pages/Account';
import BlogDetails from './pages/BlogDetails';
import Blogs from './pages/Blogs';
import Categories from './pages/Categories';
import ContactUs from './pages/ContactUs';
import DetailPage from './pages/DetailPage';
import Faq from './pages/Faq';
import Finance from './pages/Finance';
import Home from './pages/Home';
import Iminterested from './pages/Interest';
import Listingpage from './pages/Listingpage';
import Notfound from './pages/Notfound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SellPage from './pages/SellPage';
import Tab from './pages/Tab';
import TermsOfUse from './pages/TermsOfUse';

import { getCarBrandsList } from './redux/actions/carBrandsAction';
import { getWishlistData } from './redux/actions/wishlistAction';
import { ICombineReducerType } from './redux/reducers';

import Cookies from 'js-cookie';
import Loading from './components/ui/Loading';
import { $FIXME, ROUTES } from './constants';
import i18n from './localization';
import Insure from './pages/Insure';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import { DEFAULT_LANG, languagesList } from './utils/translation';

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
    const dispatch = useDispatch();
    const { isloggedIn, user } = useSelector(
        (state: ICombineReducerType) => state.auth
    );
    !Cookies.get('accessToken') && localStorage.removeItem('user');
    const { t } = useTranslation(['routes']);

    // When user manually tries to navigate for different language
    useEffect(() => {
        const langList = languagesList();
        let isNoLangSelected = true;
        Object.values(langList).forEach((item) => {
            if(location.pathname.includes(`/${item}`)) {
                isNoLangSelected = false;
                i18n.changeLanguage(item);
            }
        });
        if(isNoLangSelected) {
            const hasEnglishLang = Object.values(langList).some(item => item === "en")
            i18n.changeLanguage(hasEnglishLang ? "en" : DEFAULT_LANG);
        }
    }, [])
    
    useEffect(() => {
        if (isTablet && location.pathname === t('/account')) {
            navigate(
                `${t('/account', { ns: 'routes' })}${t('/edit-profile', {
                    ns: 'routes'
                })}`
            );
        }
    }, [isTablet]);
    useEffect(() => {
        getCarBrandsList(dispatch, true);
    }, [dispatch]);
    useEffect(() => {
        if (isloggedIn) {
            getWishlistData(dispatch);
        }
    }, [isloggedIn]);
    const currentPath = useLocation();
    const translate = (url: string) => {
        return t(url, { lng: i18n.language });
    };

    useEffect(() => {
        const languagesObj = languagesList();
        const sanitize = Object.values(languagesObj).some((item) => currentPath.pathname.split('/').includes(item));
        // let sanitize = currentPath.pathname.split('/').includes('fr') || currentPath.pathname.split('/').includes('ar');
        let url;
        let resturl;
        if (sanitize) {
            url = currentPath.pathname.slice(4);
            url = url.split('/').filter((item: $FIXME) => item)[0];
            if (url) {
                resturl = currentPath.pathname.slice(4 + url.length);
            }
        } else {
            url = currentPath.pathname
                .split('/')
                .filter((item: $FIXME) => item)[0];

            url = url ? url : '';
            if (url) {
                resturl = currentPath.pathname.slice(url.length + 1);
            }
        }

        resturl
            ? navigate(t('/' + url, { lng: i18n.language }) + t(resturl))
            : navigate(t('/' + (url ? url : ''), { lng: i18n.language }));
    }, [i18n.language]);

    return (
        <Suspense fallback={<Loading />}>
            <div className="App">
                <Routes>
                    <Route path={translate(ROUTES.home)} element={<Home />} />
                    <Route
                        path={translate(ROUTES.buyCars)}
                        element={<Listingpage />}
                    />
                    <Route
                        path={`${translate(
                            ROUTES.buy
                        )}/:brand/:model/:year/:price/:id`}
                        element={<DetailPage />}
                    />

                    <Route
                        path={translate(ROUTES.sellCar)}
                        element={<SellPage />}
                    />
                    <Route path={translate(ROUTES.finance)} element={<Finance />} />
                    <Route
                        path={translate(ROUTES.carInsurance)}
                        element={<Insure />}
                    />
                    <Route path={translate(ROUTES.about)} element={<About />} />
                    <Route path={translate(ROUTES.faqs)} element={<Faq />} />
                    <Route
                        path={translate(ROUTES.contactUs)}
                        element={<ContactUs />}
                    />
                    <Route path={translate(ROUTES.blog)} element={<Blogs />} />
                    <Route
                        path={`${translate(ROUTES.account)}/:key`}
                        element={
                            <ProtectedRoute user={user}>
                                <Account />
                            </ProtectedRoute>
                        }
                    />
                    <Route path={`${translate(ROUTES.imInterested)}/:id`} element={<Iminterested />} />
                    <Route
                        path={`${translate(ROUTES.category)}/:id`}
                        element={<Categories />}
                    />
                    <Route path={translate(ROUTES.account)} element={<Tab />} />

                    <Route
                        path={`${translate(ROUTES.blog)}/:id`}
                        element={<BlogDetails />}
                    />
                    <Route
                        path="/user/:token"
                        element={<AccountVerification />}
                    />
                    <Route
                        path={translate(ROUTES.privacyPolicy)}
                        element={<PrivacyPolicy />}
                    />
                    <Route
                        path={translate(ROUTES.termsOfUse)}
                        element={<TermsOfUse />}
                    />
                    <Route
                        path={`${translate(ROUTES.unsubscribeBlog)}/:token`}
                        element={<UnsubscribeBlog />}
                    />
                    <Route
                        path="/forgot-password/:token"
                        element={<ForgetPasword />}
                    />

                    <Route path="*" element={<Notfound />} />
                </Routes>
            </div>
            <WhatsAppChatBox />
        </Suspense>
    );
}

export default App;
