import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import ButtonComponent from './ui/ButtonComponent';
import Card from './ui/Card';
import Loading from './ui/Loading';
import SecSeperator from './ui/SecSeperator';

import { getCarData } from '../redux/actions/carListingAction';
import { ICombineReducerType } from '../redux/reducers';

const Favourites = () => {
    const { t } = useTranslation();
    const { loading, wishList } = useSelector(
        (state: ICombineReducerType) => state.wishList
    );
    const { cars, loading: carLoading } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const dispatch = useDispatch();
    useEffect(() => {
        if (!cars.length) {
            getCarData(dispatch);
        }
    }, []);

    const [favourites, setFavourites] = useState<any[]>([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (wishList.length && cars.length) {
            let fav = cars?.filter((item) => {
                for (let i = 0; i < wishList.length; i++) {
                    let fav = wishList[i];
                    if (fav?.car?._id === item?._id) {
                        return true;
                    }
                }
                return false;
            });
            setFavourites(fav ?? []);
        } else {
            setFavourites([]);
        }
    }, [wishList, cars]);
    return (
        <div className="virtual-garage">
            {loading || carLoading ? (
                <Loading />
            ) : favourites.length ? (
                <>
                    <SecSeperator className="virtual-sep" />
                    <h3 className="title virtual-title">{t('favourites')} </h3>
                    <p className="subtitle">{t('postYouHaveLiked')}</p>
                    <div className="virtual-garage__holder">
                        {favourites?.map((items, index) => {
                            return (
                                <div key={index} className="card-holder">
                                    <Card item={items} border="border" />
                                </div>
                            );
                        })}
                    </div>
                </>
            ) : (
                <div className="favourite-notfound padding">
                    <div className="fav-container">
                        <div className="title">{t('noFavouritesFound')}</div>
                        <img src="/images/boyImage.svg" alt="not found" />
                        <div className="subtitle">
                            {t('favouritesNotFoundSubtitle')}
                        </div>
                        <ButtonComponent
                            type="primary"
                            className="explore-btn"
                            onClick={() => {
                                navigate(t('/buy-cars', { ns: 'routes' }));
                            }}
                        >
                            {t('exploreCar', { ns: 'hero' })}
                        </ButtonComponent>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Favourites;
