import { useTranslation } from 'react-i18next';
import SecSeperator from './ui/SecSeperator';

const pressArray = [
    {
        image: '/svgs/presse-techcabal.svg'
    },
    {
        image: '/svgs/press-logo-techinafrica.svg'
    },
    {
        image: '/svgs/presse-lebrief.svg'
    },
    {
        image: '/svgs/presse-lejournaldelautomobile.svg'
    },
    {
        image: '/svgs/presse-largus.svg'
    },
    {
        image: '/images/logo-press-forbes.webp'
    }
];

const Press = () => {
    const { t } = useTranslation();
    return (
        <div className="press">
            <div className="padding">
                <SecSeperator className="center" />
                <h2 className="title">{t('theySpeakAboutUs')}</h2>
                <p className="subtitle">{t('theySpeakAboutUsSubTitle')}</p>
                <div className="image-wrapper top">
                    {pressArray.slice(0, 3).map((item, index) => {
                        return (
                            <div className="image-container" key={index}>
                                <img src={item.image} alt="press" />
                            </div>
                        );
                    })}
                </div>
                <div className="image-wrapper bottom">
                    {pressArray.slice(3).map((item, index) => {
                        return (
                            <div className="image-container" key={index}>
                                <img src={item.image} alt="press" />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Press;
