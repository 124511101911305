import { CheckCircle, WarningCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import {
    LightgalleryProvider,
    LightgalleryItem,
    useLightgallery
} from 'react-lightgallery';
import { ToWords } from 'to-words';

import { $FIXME, IMAGE_URL } from '../../../../constants';
import { addImageFallback } from '../../../../utils/Helper/ImagePlaceholder';

const AccordionOption = (props: $FIXME) => {
    const { t } = useTranslation();

    const { images = [], caption, title } = props;

    const PhotoItem = ({ image, thumb, group }: any) => (
        <div>
            <LightgalleryItem group={group} src={image} thumb={thumb}>
                <img
                    src={image}
                    alt="interior Controls And Switches "
                    onError={addImageFallback}
                />
            </LightgalleryItem>
        </div>
    );

    const ShowPhotos = (props: any) => {
        const { openGallery } = useLightgallery();

        return (
            <p
                {...props}
                onClick={() => openGallery('seePhoto')}
                className="show-photo"
            >
                {t('showPicture')}
            </p>
        );
    };
    const OpenLightBox = (props: any) => {
        const { openGallery } = useLightgallery();

        return (
            <div className="photo-container">
                <img
                    src={`${IMAGE_URL}${props.images[0]}`}
                    alt=""
                    onError={addImageFallback}
                />
                <div
                    className="note-number"
                    onClick={() => openGallery('openlighbox')}
                >
                    <div className="note">{images.length}</div>
                </div>
            </div>
        );
    };

    const toWords = new ToWords();

    const convertToWords = (data: any) => {
        let words = toWords.convert(data);
        return words;
    };

    return title === 'interiorControlsAndSwitches' && images.length ? (
        <li>
            <div className="list-left">
                <div>{t(title)}</div>
            </div>

            <div className="list-right">
                <LightgalleryProvider
                    lightgallerySettings={{
                        getCaptionFromTitleOrAlt: true
                    }}
                >
                    <div className="photo-preview">
                        {images.map((item: any, i: number) => {
                            return (
                                <PhotoItem
                                    image={`${IMAGE_URL}${item}`}
                                    group="seePhoto"
                                />
                            );
                        })}
                    </div>
                    {images ? <ShowPhotos /> : null}
                </LightgalleryProvider>
                <WarningCircle size={24} weight="bold" color="#ffb040" />
            </div>
        </li>
    ) : (
        <li>
            <div className="list-left">
                <div className={caption ? 'note-title' : ''}>{t(title)}</div>
                {!!caption && (
                    <>
                        <div className="caption">
                            {convertToWords(images.length)}{' '}
                            {images.length > 1 ? 'Notes' : 'Note'}
                        </div>
                    </>
                )}
            </div>

            <div className="list-right">
                {images.length ? (
                    <div className="image-container">
                        <LightgalleryProvider
                            lightgallerySettings={{
                                getCaptionFromTitleOrAlt: true
                            }}
                        >
                            <div className="photo-preview">
                                {images.map((item: any, i: number) => {
                                    return (
                                        <PhotoItem
                                            key={i}
                                            image={`${IMAGE_URL}${item}`}
                                            group="openlighbox"
                                        />
                                    );
                                })}
                            </div>

                            <OpenLightBox images={images} />
                        </LightgalleryProvider>
                    </div>
                ) : (
                    <>
                        <CheckCircle size={24} color="#009E60" weight="bold" />
                    </>
                )}
            </div>
        </li>
    );
};

export default AccordionOption;
