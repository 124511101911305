import i18n from '../../localization';

const Button = (props: any) => {
    return (
        <button
            className={
                i18n.language === 'en'
                    ? `primary-btn ${props.class}`
                    : `primary-btn eng ${props.class}`
            }
            onClick={props.onClick}
        >
            {props.text}
        </button>
    );
};

export default Button;
