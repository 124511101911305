import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
    GET_CARS,
    GET_CAR_AVAIL_BODY_TYPES,
    GET_CAR_AVAIL_COLOR,
    GET_CAR_BODY_TYPES,
    GET_CAR_BRANDS,
    GET_CAR_COLORS
} from '../../helpers/constants';
import { request } from '../../helpers/request';
import { ICarParams } from '../../interface/car';

export const getCars = (dispatch: Dispatch, params?: ICarParams) => {
    return request
        .getCars(params)
        .then((response: AxiosResponse) => {
            dispatch({ type: GET_CARS, payload: response.data });
        })

        .catch((err: AxiosError | Error) => {
            if (axios.isAxiosError(err)) {
                console.log(err.response?.data);
            } else {
                console.log(err.message);
            }
        });
};
export const getCarBodyTypes = (
    dispatch: Dispatch,
    isOnlyAvailable = false
) => {
    return (
        !isOnlyAvailable
            ? request.getCarBodyTypes()
            : request.getAvailableCarBodyTypes()
    )
        .then((response: AxiosResponse) => {
            dispatch({
                type: !isOnlyAvailable
                    ? GET_CAR_BODY_TYPES
                    : GET_CAR_AVAIL_BODY_TYPES,
                payload: !isOnlyAvailable
                    ? response.data?.carBodyTypes
                    : response.data.bodyTypes
            });
        })

        .catch((err: AxiosError | Error) => {
            if (axios.isAxiosError(err)) {
                console.log(err.response?.data);
            } else {
                console.log(err.message);
            }
        });
};

export const getCarBrands = (dispatch: Dispatch) => {
    return request
        .getCarBrands()
        .then((response: AxiosResponse) => {
            dispatch({
                type: GET_CAR_BRANDS,
                payload: response.data?.carBrands
            });
        })

        .catch((err: AxiosError | Error) => {
            if (axios.isAxiosError(err)) {
                console.log(err.response?.data);
            } else {
                console.log(err.message);
            }
        });
};

export const getCarColors = (dispatch: Dispatch) => {
    return request
        .getCarColors()
        .then((response: AxiosResponse) => {
            dispatch({
                type: GET_CAR_COLORS,
                payload: response.data?.carColors
            });
        })

        .catch((err: AxiosError | Error) => {
            if (axios.isAxiosError(err)) {
                console.log(err.response?.data);
            } else {
                console.log(err.message);
            }
        });
};
export const getAvailCarColors = (dispatch: Dispatch) => {
    return request
        .getAvailableCarColors()
        .then((response: AxiosResponse) => {
            dispatch({
                type: GET_CAR_AVAIL_COLOR,
                payload: response.data?.carColors
            });
        })

        .catch((err: AxiosError | Error) => {
            if (axios.isAxiosError(err)) {
                console.log(err.response?.data);
            } else {
                console.log(err.message);
            }
        });
};
