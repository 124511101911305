import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IMAGE_URL } from '../constants';
import { IBlogDetailType } from '../redux/types/BlogListTypes';
import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';
import text_truncate from '../utils/Helper/TextTruncate';
import { formatDate } from './BlogDetail';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const CategoriesCard: React.FC<{ item: IBlogDetailType }> = ({ item }) => {
    const { t } = useTranslation(['routes']);
    const date = new Date(item.createdAt.substring(0, 10));
    return (
        <div className="categories-card">
            <Link to={`${t('/blog', { ns: 'routes' })}/${item.slug}`}>
                <div className="image-container">
                    <img
                        src={IMAGE_URL + item.image}
                        alt=""
                        onError={addImageFallback}
                    />
                </div>
                <div className="category-content">
                    <span className="">
                        {contentAccordToCurrentLang(item.category).category ??
                            'Media'}
                    </span>
                    <h3 className="title">
                        {contentAccordToCurrentLang(item).title}
                    </h3>
                    <p
                        className="desc"
                        dangerouslySetInnerHTML={{
                            __html: text_truncate(
                                contentAccordToCurrentLang(item).description,
                                280,
                                '...'
                            ) as string
                        }}
                    ></p>
                    <div className="date-author">
                        <p>{item?.author?.name ?? 'Auto24 Team '}</p>
                        <div className="dot"></div>

                        <p className="date">{formatDate(date)}</p>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default CategoriesCard;
