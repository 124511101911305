import { useRef, useState } from 'react';

import { Image } from 'antd';
interface IDrageNDrop {
    handleFile: (files: any, name: string) => void;
    multiple?: boolean;
    name: string;
    children?: any;
    fileList: any;
}
function DragAndDropFileUpload({
    handleFile,
    multiple = false,
    name,
    children,
    fileList
}: IDrageNDrop) {
    const wrapperRef = useRef<any>(null);
    const [visible, setVisible] = useState(false);
    const [current, setCurrentKey] = useState<number>(0);
    function Drag() {
        wrapperRef.current.classList.add('dragger');
    }
    function Dragleave() {
        wrapperRef.current.classList.remove('dragger');
    }
    function onFileDrop(e: any) {
        const newFile = e.target.files;
        if (newFile) {
            var updatedList = [...fileList, ...newFile];
            handleFile(updatedList, e.target.name);
        }
    }
    function removeFile(file: any) {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        handleFile(updatedList, 'file');
    }
    function openPreviewFile(e: any, key: number) {
        setCurrentKey(key);
        setVisible(true);
    }
    function Preview() {
        return (
            <div className="image-previews">
                <Image.PreviewGroup
                    preview={{
                        visible,
                        current: current,
                        onVisibleChange: (vis) => setVisible(vis)
                    }}
                >
                    {fileList.map((img: any, index: number) => {
                        return (
                            <Image src={URL.createObjectURL(img)} key={index} />
                        );
                    })}
                </Image.PreviewGroup>
            </div>
        );
    }
    return (
        <div
            ref={wrapperRef}
            onDragEnter={Drag}
            onDragLeave={Dragleave}
            className="fileupload-container"
        >
            {children ?? null}
            <input
                id="drag-n-drop"
                type="file"
                className="drag-n-drop-input"
                name={name}
                multiple={multiple}
                onChange={onFileDrop}
                accept=".jpg,.png,.jpeg"
                value=""
            />
            {fileList.length ? (
                <div className="drop-file-preview">
                    <p className="drop-file-preview-title">
                        {' '}
                        Ready to upload{' '}
                        {fileList.length === 1
                            ? '1 image'
                            : `${fileList.length} images`}
                    </p>
                    {fileList?.map((image: any, key: any) => {
                        return (
                            <div className="drop-file-preview-item" key={key}>
                                <Image
                                    preview={{ visible: false }}
                                    src={URL.createObjectURL(image)}
                                    width={100}
                                    onClick={(e) => openPreviewFile(e, key)}
                                />
                                <div className="drop-file-preview-item-info">
                                    <p>{image.name}</p>
                                    <p>{(image.size / 1000).toFixed(2)} Kb </p>
                                </div>
                                <span
                                    className="drop-file-delete"
                                    onClick={() => removeFile(image)}
                                >
                                    X
                                </span>
                            </div>
                        );
                    })}
                    <Preview />
                </div>
            ) : null}
        </div>
    );
}

export default DragAndDropFileUpload;
