import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Blog from '../components/Blog';
import Certification from '../components/Certification';
import Hero from '../components/Hero';
import InstaGram from '../components/InstaGram';
import Loan from '../components/Loan';
import Location from '../components/Location';
import Press from '../components/Press';
import Process, { ProcessBackground } from '../components/Process';
import Promotions from '../components/Promotions';
import Questionnaire from '../components/Questionnaire';
import RecentlyAdded from '../components/RecentlyAdded';
import SearchByCategory from '../components/SearchByCategory';
import Service from '../components/Service';
import Layout from '../layout/Layout';

import { getCarBodyTypes } from '../redux/actions/carActions';
import { getCustomerGallery } from '../redux/actions/customerGalleryActions';
import { fetchFeaturedCars } from '../redux/actions/fetchFeaturedCars';
import { getStores } from '../redux/actions/storeActions';
import { ICombineReducerType } from '../redux/reducers';
import { SetMeta } from '../utils/Helper/jsHelpers';
import StatsArray from '../utils/Stats';
import FooterContent from '../components/FooterContent';
import NewCarList from '../components/NewCarList';
import { fetchNewCarList } from '../redux/actions/fetchNewCarListAction';

const Home = () => {
    const dispatch = useDispatch();
    const { featuredCars } = useSelector(
        (state: ICombineReducerType) => state.featuredCars
    );
    const { newCars } = useSelector(
        (state: ICombineReducerType) => state.newCarList
    );

    console.log(newCars, "newCarsHere")

    useEffect(() => {
        getCustomerGallery(dispatch);
        getStores(dispatch);
        fetchFeaturedCars(dispatch);
        fetchNewCarList(dispatch);
        getCarBodyTypes(dispatch, true);
    }, [dispatch]);

    return (
        <>
            <SetMeta title="homePageTitle" desc="homePageDesc" keywords="homePageKeywords"
            properties={[
                {
                    key: "og:title",
                    value: "homePageTitleOg"
                },
                {
                    key: "og:description",
                    value: "homePageDescOg"
                },
                {
                    key: "og:keywords",
                    value: "homePageKeywordsOg"
                }
            ]}
            />
            <div className="home">
                <Layout>
                    <Hero />
                    <RecentlyAdded
                        title="recentlyAdded"
                        subtitle="recentlyAddedSubtitle"
                        carData={
                            // featuredCars.cars.length > 6
                            //     ? featuredCars.cars.slice(6)
                            //     : featuredCars.cars
                            featuredCars.cars
                        }
                        // total={featuredCars.totalAvailableCars}
                        total={featuredCars.totalFeatured}
                    />
                    <NewCarList
                        title="newCarList"
                        subtitle="newCarListSubtitle"
                        carData={
                            newCars?.cars ?? []
                        }
                        total={newCars.total ?? 0}
                    />

                    <SearchByCategory />

                    <ProcessBackground StatsArray={StatsArray} />

                    <Process
                        showProcessBackground
                        title="howItWorks"
                        showSwitch
                    />

                    <Promotions />

                    <Certification from="home" />

                    <Loan />

                    <Blog />

                    <Location totalCars={featuredCars?.totalFeatured} />

                    <Service />
                    <Questionnaire />
                    <Press />
                    <InstaGram />
                    <FooterContent />
                </Layout>
            </div>
        </>
    );
};

export default Home;
