import {
    FETCH_NEW_CAR_LIST
} from '../../helpers/constants';
import { ActionType } from '../../interface/actionType';
import { ICar } from '../../interface/car';

export interface INewCarList {
    cars: ICar[];
    total: number | null;
}

export interface INewCarListState {
    newCars: INewCarList;
}

const initialState: INewCarListState = {
    newCars: {
        cars: [],
        total: null
    }
};

const fetchNewCarListReducer = (state = initialState, action: ActionType): INewCarListState => {
    switch (action.type) {
        case FETCH_NEW_CAR_LIST:
            return {
                ...state,
                newCars: {...state.newCars,...action.payload}
            };

        default:
            return state;
    }
};

export default fetchNewCarListReducer;
