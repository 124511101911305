import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';

const Loan = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const toFinance = () => {
        navigate(t('/finance', { ns: 'routes' }));
    };

    return (
        <div className="loan-container">
            <div className="loan padding">
                <div className="loan-left">
                    <SecSeperator
                        borderColor="#091731"
                        bordercolor="#fff"
                        background="#fff"
                    />
                    <h2 className="title loan-title">{t('loanTitle')}</h2>
                    <p className="subtitle">{t('loanHappyCusSubtitle')}</p>
                    <button className="bottom-btn loan-btn" onClick={toFinance}>
                        {t('loanButton')}
                    </button>
                </div>
                <div className="loan-image">
                    <img src="/images/listingcar.webp" alt="loan-car" />
                </div>
            </div>
        </div>
    );
};

export default Loan;
