export const CurrencyAndCode = [
    { country: 'ca', currency: 'CAD' },
    { country: 'ch', currency: 'CHF' },
    { country: 'cn', currency: 'CNY' },
    { country: 'eg', currency: 'EGP' },
    { country: 'eu', currency: 'EUR' },
    { country: 'gb', currency: 'GBP' },
    { country: 'hk', currency: 'HKD' },
    { country: 'il', currency: 'ILS' },
    { country: 'in', currency: 'INR' },
    { country: 'jp', currency: 'JPY' },
    { country: 'kr', currency: 'KRW' },
    { country: 'ma', currency: 'MAD' },
    { country: 'pk', currency: 'PKR' },
    { country: 'tr', currency: 'TRY' },
    { country: 'us', currency: 'USD' },
    { country: 'vn', currency: 'VND' },
    { country: 'za', currency: 'ZAR' }
];
// export const CurrencyAndCode = [
//     { country: 'CA', currency: 'CAD' },
//     { country: 'CH', currency: 'CHF' },
//     { country: 'CN', currency: 'CNY' },
//     { country: 'EG', currency: 'EGP' },
//     { country: 'EU', currency: 'EUR' },
//     { country: 'GB', currency: 'GBP' },
//     { country: 'HK', currency: 'HKD' },
//     { country: 'IL', currency: 'ILS' },
//     { country: 'IN', currency: 'INR' },
//     { country: 'JP', currency: 'JPY' },
//     { country: 'KR', currency: 'KRW' },
//     { country: 'MA', currency: 'MAD' },
//     { country: 'PK', currency: 'PKR' },
//     { country: 'TR', currency: 'TRY' },
//     { country: 'US', currency: 'USD' },
//     { country: 'VN', currency: 'VND' },
//     { country: 'ZA', currency: 'ZAR' }
// ];
