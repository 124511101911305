import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales.min'; // for language change of date

import SecSeperator from './ui/SecSeperator';

import { ICombineReducerType } from '../redux/reducers';
import { $FIXME } from '../constants';
import i18n from '../localization';
import scrollToRef from '../utils/Hooks/ScrollToElement';

export const formatDates = (date: $FIXME) => {
    if (i18n.language === 'fr') {
        return moment(date).locale('fr').format('MMMM YYYY');
    } else {
        return moment(date).format('MMMM YYYY');
    }
};
export const formatMoneys = (n: any) => {
    return (Math.round(n * 100) / 100).toLocaleString('fr');
};
const CarOverview = (props: $FIXME) => {
    const { car } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const { t } = useTranslation();

    const carOverviewRef = useRef(null);
    useEffect(() => {
        if (props.activeNavClick === 'carOverView') {
            scrollToRef(carOverviewRef);
        }
    }, [props.activeNavClick]);

    const OverViewData = [
        {
            icons: '/images/calendarcheck.svg',
            title:
                car.isElectric || car.isNewCar
                    ? 'productionYear'
                    : 'dateFirstUse',
            value:
                car.isElectric || car.isNewCar
                    ? car.modelYear
                    : formatDates(car.dateFirstUse?.slice(0, 10)),
            omit: car.isNewCar
        },
        {
            icons: '/images/meter.webp',
            title: 'mileage',
            value: car.isNewCar
                ? 'n/a'
                : `${car?.mileage
                      ?.toLocaleString()
                      .replaceAll(',', '  ')} KM` ?? 'n/a',
            omit: false
        },
        {
            icons: '/images/fueltype.svg',
            title: 'fuelType',
            value: car.fuelType,
            omit: false
        },
        {
            icons: '/images/transmission.webp',
            title: 'transmission',
            value: car.transmission,
            omit: false
        },

        {
            icons: car.isElectric
                ? '/images/carbon_meter.svg'
                : '/images/engine.webp',
            title: car.isElectric ? 'autonomy' : 'enginePower',
            value: car.isElectric
                ? `${car.autonomy} Km`
                : car.enginePower ?? 'N/A',
            omit: false
        },

        {
            icons: '/images/seats.webp',
            title: 'seats',
            value: `${car.noOfSeats}`,
            omit: false
        }
    ];
    return (
        <div
            className={
                props.hasHash ? 'car-overview margin-top' : 'car-overview'
            }
            id="overview"
            ref={carOverviewRef}
        >
            <SecSeperator />
            <h2 className="title">{t('carOverview')}</h2>
            <div className="overview-container">
                {OverViewData.filter(item => !item.omit).map((items, index) => {
                    return (
                        <div className="overview-item" key={index}>
                            <div className="overview-icon">
                                <img src={items.icons} alt={t(items.title)} />
                            </div>
                            <div className="overview-content">
                                <h3 className="overview-title">
                                    {t(items.title)}
                                </h3>
                                <p className="overview-value">
                                    {items.value && t(items.value.toString())}
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CarOverview;
