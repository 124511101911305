import moment from 'moment';
import { CalendarCheck } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import Accordion from './ui/Accordion';
import SecSeperator from './ui/SecSeperator';

const InspectionReport = (props: any) => {
    const { t } = useTranslation();

    const {
        bodyInspection,
        brakeSystem,
        electricSystem,
        engine,
        interior,
        liquids,
        rearAxlesAndDriveShafts,
        transmission,
        underChassis,
        date,
        totalInteriorImages,
        totalBodyInspectionImages
    } = props;

    const InspectionReport = [
        {
            icons: '/images/engine.webp',
            title: 'engine',
            options: [
                {
                    title: 'frontCrankOilSeal',
                    value: engine?.frontCrankOilSeal
                },
                {
                    title: 'engineOverHaul',
                    value: engine?.engineOverHaul
                },
                {
                    title: 'rearCrankOilSeal',
                    value: engine?.rearCrankOilSeal
                },
                {
                    title: 'engineTuneUp',
                    value: engine?.engineTuneUp
                },
                {
                    title: 'engineMount',
                    value: engine?.engineMount
                },
                {
                    title: 'oilPanGasket',
                    value: engine?.oilPanGasket
                },
                {
                    title: 'valveCoverGasket',
                    value: engine?.valveCoverGasket
                },
                {
                    title: 'oilFilterBracketOring',
                    value: engine?.oilFilterBracketOring
                },
                {
                    title: 'intakeManifoldGasket',
                    value: engine?.intakeManifoldGasket
                },
                {
                    title: 'waterLeak',
                    value: engine?.waterLeak
                },
                {
                    title: 'radiator',
                    value: engine?.radiator
                },
                {
                    title: 'engineTimingCoverGasket',
                    value: engine?.engineTimingCoverGasket
                },
                {
                    title: 'waterPump',
                    value: engine?.waterPump
                },
                {
                    title: 'belts',
                    value: engine?.belts
                },
                {
                    title: 'fuelFilter',
                    value: engine?.fuelFilter
                },
                {
                    title: 'airFilter',
                    value: engine?.airFilter
                }
            ]
        },
        {
            icons: '/svgs/chassis.svg',
            title: 'underChassis',
            options: [
                {
                    title: 'tieRod',
                    value: underChassis?.tieRod
                },
                {
                    title: 'ballJointLandR',
                    value: underChassis?.ballJointLandR
                },
                {
                    title: 'idlerArm',
                    value: underChassis?.idlerArm
                },
                {
                    title: 'pitmanArm',
                    value: underChassis?.pitmanArm
                },
                {
                    title: 'steeringPump',
                    value: underChassis?.steeringPump
                },
                {
                    title: 'steeringHoses',
                    value: underChassis?.steeringHoses
                },
                {
                    title: 'steeringBox',
                    value: underChassis?.steeringBox
                },
                {
                    title: 'steeringRackAndPinion',
                    value: underChassis?.steeringRackAndPinion
                },
                {
                    title: 'frontShockLandR',
                    value: underChassis?.frontShockLandR
                },
                {
                    title: 'rearShockLandR',
                    value: underChassis?.rearShockLandR
                },
                {
                    title: 'stabilizerLinkage',
                    value: underChassis?.stabilizerLinkage
                },
                {
                    title: 'crossJointPropellerShaft',
                    value: underChassis?.crossJointPropellerShaft
                },
                {
                    title: 'upperArmBushing',
                    value: underChassis?.upperArmBushing
                },
                {
                    title: 'rearLowerArmBushing',
                    value: underChassis?.rearLowerArmBushing
                },
                {
                    title: 'suspensionSpring',
                    value: underChassis?.suspensionSpring
                }
            ]
        },
        {
            icons: '/svgs/break.svg',
            title: 'breakSystem',
            options: [
                {
                    title: 'brakePad',
                    value: brakeSystem?.brakePad
                },
                {
                    title: 'brakeDiscRotor',
                    value: brakeSystem?.brakeDiscRotor
                },
                {
                    title: 'wheelBearing',
                    value: brakeSystem?.wheelBearing
                },
                {
                    title: 'brakeMasterCLY',
                    value: brakeSystem?.brakeMasterCLY
                },
                {
                    title: 'backBooster',
                    value: brakeSystem?.backBooster
                },
                {
                    title: 'brakeWheelCall',
                    value: brakeSystem?.brakeWheelCall
                },
                {
                    title: 'handBrakeCable',
                    value: brakeSystem?.handBrakeCable
                },
                {
                    title: 'brakeHoses',
                    value: brakeSystem?.brakeHoses
                }
            ]
        },
        {
            icons: '/svgs/steering.svg',
            title: 'rearAxlesAndDriveShafts',
            options: [
                {
                    title: 'frontAxle',
                    value: rearAxlesAndDriveShafts?.frontAxle
                },
                {
                    title: 'rearAxle',
                    value: rearAxlesAndDriveShafts?.rearAxle
                },
                {
                    title: 'axleDifference',
                    value: rearAxlesAndDriveShafts?.axleDifference
                },
                {
                    title: 'differenceFrontOrRear',
                    value: rearAxlesAndDriveShafts?.differenceFrontOrRear
                },
                {
                    title: 'axleSealFrontOrRear',
                    value: rearAxlesAndDriveShafts?.axleSealFrontOrRear
                }
            ]
        },
        {
            icons: '/images/transmission.webp',
            title: 'transmission',
            options: [
                {
                    title: 'oilPumpGasket',
                    value: transmission?.oilPumpGasket
                },
                {
                    title: 'frontOilSeal',
                    value: transmission?.frontOilSeal
                },
                {
                    title: 'rearOilSeal',
                    value: transmission?.rearOilSeal
                },
                {
                    title: 'transmissionMount',
                    value: transmission?.transmissionMount
                },
                {
                    title: 'oilPanGasket',
                    value: transmission?.oilPanGasket
                },
                {
                    title: 'gearStickSouta',
                    value: transmission?.gearStickSouta
                },
                {
                    title: 'oilCollerPipe',
                    value: transmission?.oilCollerPipe
                },
                {
                    title: 'gearPerformance',
                    value: transmission?.gearPerformance
                }
            ]
        },
        {
            icons: '/svgs/oil.svg',
            title: 'liquidSystem',
            options: [
                {
                    title: 'engineOil',
                    value: liquids?.engineOil
                },
                {
                    title: 'transmissionOil',
                    value: liquids?.transmissionOil
                },
                {
                    title: 'brakeFluid',
                    value: liquids?.brakeFluid
                },
                {
                    title: 'engineCoolant',
                    value: liquids?.engineCoolant
                },
                {
                    title: 'washerFluid',
                    value: liquids?.washerFluid
                }
            ]
        },
        {
            icons: '/svgs/battery.svg',
            title: 'electricitySystem',
            options: [
                {
                    title: 'battery',
                    value: electricSystem?.battery
                },
                {
                    title: 'alternator',
                    value: electricSystem?.alternator
                },
                {
                    title: 'starler',
                    value: electricSystem?.starler
                },
                {
                    title: 'lights',
                    value: electricSystem?.lights
                },
                {
                    title: 'ac',
                    value: electricSystem?.ac
                },
                {
                    title: 'centerLock',
                    value: electricSystem?.centerLock
                },
                {
                    title: 'wiperBlades',
                    value: electricSystem?.wiperBlades
                },
                {
                    title: 'troubleCode',
                    value: electricSystem?.troubleCode
                },
                {
                    title: 'computerCods',
                    value: electricSystem?.computerCods
                },
                {
                    title: 'acCondensor',
                    value: electricSystem?.acCondensor
                },
                {
                    title: 'acRadiator',
                    value: electricSystem?.acRadiator
                }
            ]
        },
        {
            icons: '/images/seats.webp',
            title: 'interior',
            count: totalInteriorImages,
            options: [
                {
                    title: 'seatAndBelts',
                    value: interior?.seatAndBelts
                },
                {
                    title: 'interiorControlsAndSwitches',
                    images: interior?.interiorControlsAndSwitches
                },
                {
                    title: 'sunRoofAndWindows',
                    value: interior?.sunRoofAndWindows
                },
                {
                    title: 'fuelAndTemperatureGauge',
                    value: interior?.fuelAndTemperatureGauge
                },
                {
                    title: 'dashboardAndGauges',
                    value: interior?.dashboardAndGauges
                },
                {
                    title: 'radioOrMusicSystem',
                    value: interior?.radioOrMusicSystem
                },
                {
                    title: 'sRSAirbags',
                    value: interior?.sRSAirbags
                },
                {
                    title: 'steeringWheelTiltOrlock',
                    value: interior?.steeringWheelTiltOrlock
                },
                {
                    title: 'mirrors',
                    value: interior?.mirrors
                }
            ]
        },
        {
            icons: '/svgs/car-door.svg',
            title: 'bodyInspection',
            count: totalBodyInspectionImages,
            options: [
                {
                    title: bodyInspection?.leftSide?.caption,
                    caption: 'One',
                    images: bodyInspection?.leftSide?.images
                },
                {
                    title: bodyInspection?.rightSide?.caption,
                    caption: 'two',
                    images: bodyInspection?.rightSide?.images
                },
                {
                    title: bodyInspection?.frontSide?.caption,
                    caption: 'three',
                    images: bodyInspection?.frontSide?.images
                },
                {
                    title: bodyInspection?.backSide?.caption,
                    caption: 'four',
                    images: bodyInspection?.backSide?.images
                }
            ]
        }
    ];

    const accordionProps = {
        InspectionReport
    };

    const optionsCount = () => {
        let count = 0;
        InspectionReport.map((item: any, index: number) => {
            let filteredData = item.options.filter((filtered: any) => {
                if (
                    filtered?.value ||
                    filtered?.images?.length ||
                    filtered?.value !== '[]'
                ) {
                    return true;
                } else {
                    return false;
                }
            }).length;
            if (filteredData) {
                count += 1;
            }
        });
        if (count) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            {optionsCount() ? (
                <div className="inspection-report">
                    <SecSeperator />
                    <h2 className="title">{t('carInspectionReport')}</h2>
                    <div className="inspection-subtitle">
                        <CalendarCheck
                            size={32}
                            weight="regular"
                            color="#454545"
                        />
                        <p>{t('inspectionDate')} : </p>
                        <b>
                            {moment(date?.substring(0, 10)).format(
                                'YYYY/MM/DD'
                            )}
                        </b>
                    </div>
                    <div className="feature-wrapper">
                        <Accordion {...accordionProps} />
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default InspectionReport;
