import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import BlogDetail from '../components/BlogDetail';
import Buysell from '../components/Buysell';
import PopularBlogs from '../components/PopularBlogs';
import BlogType from '../components/ui/BlogType';
import SocailMedia from '../components/ui/SocailMedia';
import { $FIXME } from '../constants';
import Layout from '../layout/Layout';

import { getAllBlogs, getOneBlog } from '../redux/actions/blogActions';
import { ICombineReducerType } from '../redux/reducers';

const BlogDetails = (props: $FIXME) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { t } = useTranslation();
    const { blog, latestBlogs, blogs } = useSelector(
        (state: ICombineReducerType) => state.blogs
    );

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (id) getOneBlog(dispatch, id);
    }, [id]);
    useEffect(() => {
        if (!blogs.length) getAllBlogs(dispatch);
    }, []);
    return (
        <div className=" blog-details">
            <Layout SearchBar>
                {!!blog && <BlogDetail {...blog} />}
                <div className="type-share padding">
                    <BlogType blog={blog} />
                    {!!blog && SocailMedia(blog)}
                </div>
                <PopularBlogs
                    title={t('popularblog')}
                    subtitle={t('popularblogSub')}
                    latestBlogs={latestBlogs}
                    className="blog-popular"
                />
                <Buysell />
            </Layout>
        </div>
    );
};

export default BlogDetails;
