import { CSSProperties, MouseEvent, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Modal } from 'antd';
import SecSeperator from './ui/SecSeperator';
import {
    CalendarBlank,
    Car,
    CircleWavyQuestion,
    GasPump,
    User
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import Selects from './ui/Select';
import LoadingButton from './ui/LoadingButton';
import CountryCodeDropdown from './ui/CountryCodeDropdown';

import { ICombineReducerType } from '../redux/reducers';
import {
    fetchCarModelByModel,
    getAllCarModels
} from '../redux/actions/carModelsAction';
import { IBrand, ICarBodyType } from '../interface/car';
import { validateNumber } from '../redux/actions/countryActions';
import { defaultDailing_Code } from '../helpers/countryCode';
import {
    createSellingCar,
    userRequirements
} from '../redux/actions/sellCarAction';
import {
    contentAccordToCurrentLang,
    fullNameValidate,
    returnOnlyNumber
} from '../utils/Helper/jsHelpers';
import { CAR_CONDITION, getYearList } from '../helpers/constants';
import { $FIXME } from '../constants';
import { getCarBrandsList } from '../redux/actions/carBrandsAction';
import { getCarBodyTypes } from '../redux/actions/carActions';
import { getMileageOptions } from './SellDetails';

export const setFullNameError = function (
    fullName: $FIXME,
    setErrors: $FIXME,
    t: $FIXME
) {
    if (!fullNameValidate(fullName)) {
        setErrors({
            fullName: t('pleaseEnterFullName')
        });
    }
};
interface IProps {
    title?: string;
    image?: string;
    style?: CSSProperties;
    subtitle?: string;
    from: string;
    buttonText?: string;
    className?: string;
}
const Certification = ({
    title,
    image,
    style,
    subtitle,
    from,
    buttonText,
    className
}: IProps) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = (e: any) => {
        e.preventDefault();
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };

    return (
        <div className={`certification  ${className}`} style={style}>
            <div className="certification-container padding">
                <div className="certi">
                    <SecSeperator />
                    <h2 className="title">
                        {title ? t(title) : t('sellYourCar')}
                    </h2>

                    <p className="subtitle">
                        {subtitle
                            ? t(subtitle)
                            : t('pleaseprovideusyourcardetails')}
                    </p>
                    <div className="mobile-hide">
                        <SellYourCarForm from={from} />
                    </div>
                    <button className="bottom-btn mobile" onClick={showModal}>
                        {buttonText ? t(buttonText) : t('sellYourCar')}
                    </button>
                </div>
                <div className="image-container">
                    <img
                        alt="Sell Your Car"
                        src={image ? image : '/images/sellcar.svg'}
                    />
                </div>
            </div>

            <Modal
                className="certification-modal-container ants-modal"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={true}
                centered={true}
            >
                <SellYourCarForm from={from} />
            </Modal>
        </div>
    );
};

export default Certification;
export const SellYourCarForm = (props: any) => {
    const { t } = useTranslation();
    const [brandList, setBrandList] = useState<any[]>([]);
    const [modelsList, setModelsList] = useState<any[]>([]);
    const [successModal, setSuccessModal] = useState(false);
    const [load, setLoad] = useState(false);
    const initialRender = useRef(true);

    let { user } = useSelector((state: ICombineReducerType) => state.auth);
    const [certificationData, setCertificationData] = useState<any>({
        brand: '',
        model: '',
        year: '',
        mileage: '',
        transmission: '',
        fuelType: '',
        condition: '',
        code: user?.phoneNumber?.code ?? defaultDailing_Code,
        number: user?.phoneNumber?.number ?? '',
        fullName: user?.name ?? ''
    });
    useEffect(() => {
        if (user) {
            setCertificationData((prev: any) => ({
                ...prev,
                code: user?.phoneNumber?.code,
                number: user?.phoneNumber?.number,
                fullName: user?.name
            }));
        } else {
            setCertificationData((prev: any) => ({
                ...prev,
                code: defaultDailing_Code,
                number: '',
                fullName: ''
            }));
        }
    }, [user]);
    const { bodyTypes } = useSelector(
        (state: ICombineReducerType) => state.car
    );
    const [bodyTypesList, setBodyTypeList] = useState<any[]>([]);

    const [errors, setErrors] = useState<any>({});
    const navigate = useNavigate();

    const onSelect = (value: string, key: string) => {
        let obj = JSON.parse(JSON.stringify(errors));
        delete obj[key];
        setErrors(obj);
        if(key === 'brand') {
            setModelsList([]);
            setCertificationData({ ...certificationData, [key]: value, model: '' });
        } else
        setCertificationData({ ...certificationData, [key]: value });
    };

    const dispatch = useDispatch();

    const carBrands = useSelector(
        (state: ICombineReducerType) => state.carBrands.carBrands
    );
    const carModels = useSelector(
        (state: ICombineReducerType) => state.carModels.carModels
    );
    const { loadingSellData } = useSelector(
        (state: ICombineReducerType) => state.sellingCars
    );

    useEffect(() => {
        if (!carBrands.length) getCarBrandsList(dispatch);
    }, []);
    useEffect(() => {
        if (props.from === 'lookingForCar') {
            getAllCarModels(dispatch);
            getCarBodyTypes(dispatch);
        }
    }, []);

    useEffect(() => {
        if (carBrands.length > 0) {
            const brandList: Array<any> = carBrands?.map((items: IBrand) => ({
                label: items.brand,
                value: items._id,
                _id: items._id
            }));
            setBrandList(brandList);
        }
    }, [carBrands]);

    useEffect(() => {
        if (bodyTypes.filter((item) => item).length) {
            if (bodyTypes.length > 0) {
                const bodyTypesList: any[] = bodyTypes.map(
                    (item: ICarBodyType) => {
                        return {
                            label: contentAccordToCurrentLang(item).bodyType,
                            value: item._id,
                            _id: item._id
                        };
                    }
                );
                setBodyTypeList(bodyTypesList);
            }
        }
    }, [bodyTypes]);

    useEffect(() => {
        if (!initialRender.current) {
            validateField();
        }
    }, [certificationData]);
    useEffect(() => {
        if (!initialRender.current) {
            if (!certificationData.brand) {
                if (props.from === 'lookingForCar') getAllCarModels(dispatch);
                else
                    setCertificationData((prv: any) => ({
                        ...prv,
                        model: ''
                    }));
            }
        } else {
            initialRender.current = false;
        }
    }, [certificationData.brand]);
    const [disabled, setDisabled] = useState(true);
    useEffect(() => {
        if (certificationData.brand) {
            fetchCarModelByModel(dispatch, certificationData.brand);
        }
    }, [certificationData?.brand]);
    useEffect(() => {
        if (carModels.length > 0) {
            const carModelsList = carModels.map((items) => ({
                label: `${items.model}`,
                value: items._id,
                _id: items._id
            }));
            setModelsList(carModelsList);
        }
    }, [carModels]);

    const required =
        props.from === 'lookingForCar'
            ? [
                  'brand',
                  'model',
                  'condition',
                  'mileage',
                  'bodyType',
                  'fullName',
                  'number'
              ]
            : ['*'];
    // function resetErrorLookingForUs() {
    //     let rr = delete errors['listingError'];
    //     setErrors((prev: any) => ({
    //         ...prev,
    //         rr
    //     }));
    // }
    // useEffect(() => {
    //     if (props.from === 'lookingForCar') {
    //         resetErrorLookingForUs();
    //     }
    // }, [certificationData]);
    useEffect(() => {
        if (fullNameValidate(certificationData.fullName)) {
            setErrors({});
        }
    }, [certificationData.fullName]);

    function validateField() {
        if (props.from === 'lookingForCar') {
            let o = required
                .slice(0, 5)
                .filter((item) => !certificationData[item]);
            let r = required
                .filter((key) => key === 'number' || key === 'fullName')
                .filter((key) => !certificationData[key]);
            // if (o.length + r.length === required.length) {
            //     setErrors({});
            //     return;
            // } else if (r.length) {
            //     r.forEach((item) =>
            //         setErrors((prv: any) => ({
            //             ...prv,
            //             [item]: t('xIsrequired', { name: t(item) })
            //         }))
            //     );
            // }
            // if (!(o.length < 5)) {
            //     setErrors((prev: any) => ({
            //         ...prev,
            //         listingError: 'pleaseSelectAtleastOneField'
            //     }));
            // } else {
            //     resetErrorLookingForUs();
            // }
            // if (isloggedIn) {
            //     if (r.length === 0 || o.length + r.length === required.length) {
            //         setErrors({});
            //     }
            // }
            if (
                o.length < 5 &&
                !r.length &&
                fullNameValidate(certificationData.fullName) &&
                certificationData.number.toString().length === 10
            ) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            let arr = Object.keys(certificationData).filter(
                (item) => !certificationData[item]
            );
            // if usable in future
            // for (let i = 0; i < arr.length; i++) {
            //     setErrors((prev: any) => ({
            //         ...prev,
            //         [arr[i]]: t('xIsrequired', { name: t(arr[i]) })
            //     }));
            // }
            // if (isloggedIn) {
            //     let boolen =
            //         !arr.includes('fullName') && !arr.includes('number');
            //     if ((boolen && arr.length === 7) || arr.length === 9) {
            //         setErrors({});
            //         return;
            //     }
            // } else {
            //     if (arr.length === 9) {
            //         setErrors({});
            //         return;
            //     }
            // }
            if (
                !arr.length &&
                fullNameValidate(certificationData.fullName) &&
                certificationData.number.toString().length === 10
            ) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }

    const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!disabled) {
            setLoad(true);
            validateNumber(certificationData.code, certificationData.number)
                .then((res) => {
                    if (Object.hasOwn(res, 'valid')) {
                        let data = Object.keys(certificationData).reduce(
                            (data: any, key) => {
                                if (certificationData[key]) {
                                    data[key] = certificationData[key];
                                }
                                return data;
                            },
                            {}
                        );

                        if (res.valid) {
                            setErrors((prev: $FIXME) => ({
                                ...prev,
                                number: undefined
                            }));

                            (props.from === 'lookingForCar'
                                ? userRequirements(dispatch, {
                                      ...data
                                  })
                                : createSellingCar(dispatch, {
                                      ...data
                                  })
                            ).then(() => {
                                setCertificationData({
                                    code:
                                        user?.phoneNumber?.code ??
                                        defaultDailing_Code,
                                    number: user?.phoneNumber?.number ?? '',
                                    fullName: user?.name ?? '',
                                    brand: '',
                                    model: '',
                                    year: '',
                                    mileage: '',
                                    transmission: '',
                                    fuelType: '',
                                    condition: ''
                                });
                                setSuccessModal(true);
                            });
                        } else {
                            setErrors((prev: $FIXME) => ({
                                ...prev,
                                number: 'phonenumberisnotvalid'
                            }));
                        }
                    } else {
                        setErrors((prev: $FIXME) => ({
                            ...prev,
                            number: 'internalError'
                        }));
                    }
                })
                .catch(() => {})
                .finally(() => {
                    setLoad(false);
                    setDisabled(true);
                });
        }
    };

    const goToListings = () => {
        props.from === 'listing-modal' && props.setIsModalVisible(false);
        navigate(t('/buy-cars', { ns: 'routes' }));
        setSuccessModal(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const closeModal = () => {
        props.from === 'listing-modal' && props.setIsModalVisible(false);
        setSuccessModal(false);
    };
    const oncloseModal = () => {
        props.from === 'listing-modal' && props.setIsModalVisible(false);
        setSuccessModal(false);
    };

    return (
        <>
            <div>
                <div className="desktop-hide">
                    <SecSeperator className="left-align" />
                    <h2 className="title">{t('sellYourCar')}</h2>
                    <p className="subtitle start ">
                        {t('knowaboutyourcarbetterandgetevaluation')}
                    </p>
                </div>
                {<span className="error-label">{t(errors?.listingError)}</span>}
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <div className="certification-form">
                        <Selects
                            prefixicon={<Car size={24} color="#8b8b8b" />}
                            placeholder={t('selectbrand')}
                            options={brandList}
                            showSearch={true}
                            onSelect={onSelect}
                            name="brand"
                            value={certificationData.brand}
                            error={errors.brand}
                            className="brand"
                            class="select-cursor"
                        />
                        <div className="certification-top">
                            <Selects
                                error={errors.model}
                                prefixicon={
                                    <img src="/icon/apps.svg" alt="apps" />
                                }
                                placeholder={t('selectmodel')}
                                options={modelsList}
                                showSearch={true}
                                name="model"
                                value={certificationData.model}
                                onSelect={onSelect}
                                disabled={
                                    !certificationData.brand &&
                                    props.from !== 'lookingForCar'
                                        ? true
                                        : false
                                }
                                class={
                                    !certificationData.brand &&
                                    props.from !== 'lookingForCar'
                                        ? ''
                                        : 'select-cursor'
                                }
                            />
                            {props.from === 'lookingForCar' ? (
                                <Selects
                                    prefixicon={
                                        <img
                                            src="/icon/CirclesThreebodytypeicon.svg"
                                            alt={t('bodyType')}
                                        />
                                    }
                                    placeholder={t('selectBodyType')}
                                    error={errors.bodyType}
                                    name="bodyType"
                                    options={bodyTypesList}
                                    value={certificationData.bodyType}
                                    onSelect={onSelect}
                                    showSearch={true}
                                    class="select-cursor"
                                />
                            ) : (
                                <Selects
                                    error={errors.year}
                                    prefixicon={
                                        <CalendarBlank
                                            size={24}
                                            color="#8b8b8b"
                                        />
                                    }
                                    placeholder={t('selectYear')}
                                    name="year"
                                    options={getYearList().map(
                                        (item: any, index: number, arry) => {
                                            // disabled={!isButtonActive}
                                            return index + 1 === arry.length
                                                ? {
                                                      label: t('other'),
                                                      value: 'other'
                                                  }
                                                : {
                                                      label: item,
                                                      value: item
                                                  };
                                        }
                                    )}
                                    value={certificationData.year}
                                    onSelect={onSelect}
                                    showSearch={true}
                                    class="select-cursor"
                                />
                            )}
                        </div>

                        <div className="certification-top">
                            {props.from === 'lookingForCar' ? (
                                <Selects
                                    error={errors.condition}
                                    prefixicon={
                                        <img
                                            src="/icon/time.svg"
                                            alt={t('mileage')}
                                        />
                                    }
                                    placeholder={t('selectACondition')}
                                    size="16"
                                    weight="regular"
                                    options={CAR_CONDITION.map((item) => ({
                                        label: t(item.name),
                                        value: item.value
                                    }))}
                                    onSelect={onSelect}
                                    name="condition"
                                    value={certificationData.condition}
                                    showSearch={true}
                                    class="select-cursor"

                                    // disabled={!selectedOptions.mileage || loading}
                                />
                            ) : (
                                <Selects
                                    error={errors.mileage}
                                    prefixicon={
                                        <img
                                            src="/icon/time.svg"
                                            alt={t('mileage')}
                                        />
                                    }
                                    placeholder={t('selectMileageRange')}
                                    size="16"
                                    weight="regular"
                                    options={getMileageOptions(t)}
                                    onSelect={onSelect}
                                    name="mileage"
                                    value={
                                        certificationData.mileage
                                            ? certificationData.mileage
                                            : ''
                                    }
                                    showSearch={true}
                                    class="select-cursor"

                                    // disabled={!selectedOptions.mileage || loading}
                                />
                            )}
                            {props.from === 'lookingForCar' ? (
                                <Selects
                                    error={errors.mileage}
                                    prefixicon={
                                        <img
                                            src="/icon/time.svg"
                                            alt={t('mileage')}
                                        />
                                    }
                                    placeholder={t('selectMileageRange')}
                                    size="16"
                                    weight="regular"
                                    options={getMileageOptions(t)}
                                    onSelect={onSelect}
                                    name="mileage"
                                    value={
                                        certificationData.mileage
                                            ? certificationData.mileage
                                            : ''
                                    }
                                    showSearch={true}
                                    class="select-cursor"
                                    // disabled={!selectedOptions.mileage || loading}
                                />
                            ) : (
                                <Selects
                                    error={errors.transmission}
                                    prefixicon={
                                        <img
                                            src="/images/transmission.svg"
                                            alt=""
                                        />
                                    }
                                    placeholder={t('selectTransmissionType')}
                                    name="transmission"
                                    options={['automatic', 'manual'].map(
                                        (item) => ({
                                            label: t(item),
                                            value: item
                                        })
                                    )}
                                    value={certificationData.transmission}
                                    onSelect={onSelect}
                                    showSearch={true}
                                    class="select-cursor"
                                />
                            )}
                        </div>
                        {props.from === 'lookingForCar' ? null : (
                            <div className="certification-top">
                                <Selects
                                    error={errors.fuelType}
                                    prefixicon={
                                        <GasPump size={24} color="#8b8b8b" />
                                    }
                                    placeholder={t('selectFuelType')}
                                    name="fuelType"
                                    options={[
                                        'ev',
                                        'hybrid',
                                        'phev',
                                        'petrol',
                                        'diesel',
                                        'carburant',
                                        'other'
                                    ].map((item) => ({
                                        label: t(item),
                                        value: item
                                    }))}
                                    value={certificationData.fuelType}
                                    onSelect={onSelect}
                                    showSearch={true}
                                    class="select-cursor"
                                />

                                <Selects
                                    error={errors.condition}
                                    prefixicon={
                                        <CircleWavyQuestion
                                            size={24}
                                            color="#8b8b8b"
                                        />
                                    }
                                    placeholder={t('selectACondition')}
                                    name="condition"
                                    options={CAR_CONDITION.map((item) => ({
                                        label: t(item.name),
                                        value: item.value
                                    }))}
                                    value={certificationData.condition}
                                    onSelect={onSelect}
                                    showSearch={true}
                                    class="select-cursor"
                                />
                            </div>
                        )}

                        <div className="cert-details">
                            <p className="subtitle">
                                {t('pleaseprovideusyourdetails')}
                            </p>

                            <div>
                                <div
                                    className={`certification-input ${
                                        errors.fullName && 'border-error'
                                    }  `}
                                >
                                    <div className="selects-prefixicon">
                                        <User size={24} color="#8b8b8b" />
                                    </div>
                                    <input
                                        onBlur={() => {
                                            if (certificationData.fullName) {
                                                setFullNameError(
                                                    certificationData.fullName,
                                                    setErrors,
                                                    t
                                                );
                                            }
                                        }}
                                        type="text"
                                        placeholder={t('enterFullName')}
                                        name="fullName"
                                        value={certificationData.fullName ?? ''}
                                        onChange={(e) => {
                                            let obj = JSON.parse(
                                                JSON.stringify(errors)
                                            );
                                            delete obj.fullName;
                                            setErrors(obj);
                                            setCertificationData({
                                                ...certificationData,
                                                fullName: e.target.value
                                            });
                                        }}
                                    />
                                </div>
                                <div>
                                    <span
                                        className={`error-label ${
                                            !!errors.fullName && 'fade-in'
                                        }`}
                                    >
                                        {errors.fullName}
                                    </span>
                                </div>
                            </div>
                            <CountryCodeDropdown
                                onSelect={onSelect}
                                selectName="code"
                                selectValue={certificationData.code}
                                style={{ height: '60px' }}
                                onInputChange={(e) => {
                                    let obj = JSON.parse(
                                        JSON.stringify(errors)
                                    );
                                    delete obj.number;
                                    setErrors(obj);
                                    setCertificationData({
                                        ...certificationData,
                                        number: returnOnlyNumber(e.target.value)
                                    });
                                }}
                                inputName="number"
                                inputValue={
                                    certificationData.number
                                        ? certificationData.number
                                        : ''
                                }
                                error={errors.number}
                            />
                        </div>

                        <div className="cert-submit">
                            <span>
                                {t('byClickingXButton', {
                                    name: t('send')
                                })}{' '}
                                <Link
                                    to={t('/terms-of-use', { ns: 'routes' })}
                                    className="termandpolicy"
                                >
                                    {t('termOfUse')}
                                </Link>{' '}
                                {t('and')}{' '}
                                <Link
                                    to={t('/privacy-policy', { ns: 'routes' })}
                                    className="termandpolicy"
                                >
                                    {t('privacyPolicy')}
                                </Link>
                            </span>
                            {load || loadingSellData ? (
                                <LoadingButton buttonText="sending" />
                            ) : (
                                <button
                                    className="bottom-btn button-primary send"
                                    type="submit"
                                    onClick={handleSubmit}
                                    disabled={disabled}
                                >
                                    {t('send')}
                                </button>
                            )}
                        </div>
                    </div>
                </Form>
            </div>
            <Modal
                className="sell-modal ants-modal"
                title={t('cardetailssendSuccessfully')}
                open={successModal}
                onCancel={oncloseModal}
                closable={false}
                centered={true}
            >
                <p className="subtitle">{t('thanyouforprovidingusdetail')}</p>
                <div className="done-icon">
                    <img src="/images/done.webp" alt="" />
                </div>
                <div className="modal-btn-container">
                    <button className="explore" onClick={goToListings}>
                        {t('exploreCar', { ns: 'hero' })}
                    </button>
                    <button className="close" onClick={closeModal}>
                        {t('cancel')}
                    </button>
                </div>
            </Modal>
        </>
    );
};
