import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import {
    EnvelopeSimple,
    LockSimple,
    Phone,
    SpinnerGap,
    User
} from 'phosphor-react';

import ButtonComponent from '../ui/ButtonComponent';
import TextBox from '../ui/TextBox';
import { useForm } from 'antd/lib/form/Form';
import { register } from '../../redux/actions/authActions';
import { defaultDailing_Code } from '../../helpers/countryCode';
import { validateNumber } from '../../redux/actions/countryActions';
import { $FIXME } from '../../constants';
interface Props {
    handleActiveModal: (id: number) => void;
    handleCancel: () => void;
    setEmail: $FIXME;
    setNumber: $FIXME;
    setToken: $FIXME;
}

const SignUpForm = ({
    handleActiveModal,
    handleCancel,
    setEmail,
    setNumber,
    setToken
}: Props) => {
    const { t } = useTranslation();

    const signUpFormData = [
        {
            placeHolder: 'enterFullName',
            valuePrefix: 'name',
            prefix: <User weight="bold" />,
            type: 'text',
            rules: [{ required: true, message: t('pleaseInputYourName') }]
        },
        {
            placeHolder: 'enteremailAddress',
            valuePrefix: 'email',
            prefix: <EnvelopeSimple weight="bold" />,
            type: 'email',
            rules: [
                { required: true, message: t('pleaseInputYourEmail') },
                {
                    type: 'email',
                    message: t('pleaseInputValidEmail')
                }
            ]
        },
        {
            placeHolder: 'phoneNumber',
            valuePrefix: 'number',
            prefix: <Phone weight="bold" />,
            type: 'phone-number',
            rules: [
                { required: true, message: t('pleaseInputYourPhoneNumber') },
                { max: 10, message: t('maximumXdigitsisallowed', { X: 10 }) },
                { min: 10, message: t('minimumXdigit', { X: 10 }) }
            ]
        },
        {
            placeHolder: 'enterPassword',
            valuePrefix: 'password',
            prefix: <LockSimple weight="bold" />,
            type: 'password',
            rules: [
                { required: true, message: t('passwordisRequired') },
                { min: 8, message: t('minimum8chars') }
            ]
        }
    ];
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        phoneNumber: {
            code: defaultDailing_Code,
            number: ''
        }
    } as any);
    const dispatch = useDispatch();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form] = useForm();
    const onSelect = (value: string, key: string) => {
        setFormData((prev: any) => ({
            ...prev,
            phoneNumber: {
                ...prev.phoneNumber,
                [key]: value
            }
        }));
    };

    const handleChange = (key: any, value: any) => {
        if (key === 'number') {
            setFormData((prev: any) => ({
                ...prev,
                phoneNumber: {
                    ...prev.phoneNumber,
                    [key]: value
                }
            }));
        } else {
            setFormData((formData: any) => ({ ...formData, [key]: value }));
        }
    };

    function backendError(message: string, fieldName: string) {
        form.setFields([
            {
                name: fieldName,
                errors: [message]
            }
        ]);
        handleFieldChange();
    }
    const numcheck = () => {
        if (formData?.phoneNumber?.code && formData?.phoneNumber?.number) {
            setLoading(true);
            return validateNumber(
                formData?.phoneNumber?.code,
                formData?.phoneNumber?.number
            )
                .then((response) => {
                    if (Object.hasOwn(response, 'valid')) {
                        if (response.valid) {
                            return true;
                        } else {
                            backendError(t('phonenumberisnotvalid'), 'number');
                            return false;
                        }
                    } else {
                        backendError(t('internalError'), 'number');
                    }
                })
                .catch((error) => {
                    backendError(t('internalError'), 'number');
                    return false;
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const handleSubmit = async () => {
        setLoading(true);
        setEmail(formData.email);
        setNumber({
            code: formData.phoneNumber.code,
            number: formData.phoneNumber.number
        });
        const valid = await numcheck();
        if (valid) {
            let { email, password, name, phoneNumber } = formData;
            register(dispatch, { email, password, name, ...phoneNumber })
                .then((data) => {
                    // if (typeof data.msg === 'string') {
                    //     setToken(data.phoneNumberVerificationToken);
                    //     handleActiveModal(5);
                    // } else {
                    //     message.success(t('usersuccessfullyregistered'));
                        message.success(data.msg);
                        handleCancel();
                    // }
                })
                .catch((err) => {
                    if (err.err?.email) {
                        backendError(err.err?.email, 'email');
                    } else {
                        // backendError(t('numberMustBeUnique'), 'number');
                        backendError(err?.msg, 'number');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    const handleFieldChange = () => {
        const formInputValues = Object.values(form.getFieldsValue()).filter(
            (x: any) => x?.length > 0
        );
        const formErrors = form.getFieldsError();
        const buttonDisabledCheck = formErrors.filter(
            (field) => field.errors.length > 0
        );
        setButtonDisabled(
            buttonDisabledCheck.length > 0 || formInputValues.length < 4
        );
    };
    function fullNameValidate() {
        if (formData.name) {
            let fullNameValidate =
                formData.name.split(' ').filter((item: any) => item).length > 1;
            if (!fullNameValidate) {
                backendError(t('pleaseEnterFullName'), 'name');
            }
        }
    }
    return (
        <div className="signup-form-wrapper">
            <Form
                onFinish={handleSubmit}
                onFieldsChange={handleFieldChange}
                autoComplete="false"
                form={form}
                onBlur={(e) => {
                    if (e.target.name === 'name') {
                        fullNameValidate();
                    }
                }}
            >
                {signUpFormData.map((formType: any, idx: any) => (
                    <Form.Item
                        name={formType.valuePrefix}
                        rules={formType.rules}
                        className={
                            idx !== signUpFormData.length - 1
                                ? 'user-input-box margin'
                                : 'user-input-box'
                        }
                        key={idx * 10}
                    >
                        <TextBox
                            placeholder={t(formType.placeHolder)}
                            type={formType.type}
                            onChange={(data) =>
                                handleChange(formType.valuePrefix, data)
                            }
                            onSelect={onSelect}
                            prefix={formType.prefix}
                            value={formData[formType.valuePrefix]}
                            autoComplete="false"
                            name={formType.valuePrefix}
                        />
                    </Form.Item>
                ))}

                <span className="terms-condt">
                    {t('byClickingXButton', { name: t('signUp') })}{' '}
                    <Link
                        to={t('/terms-of-use', { ns: 'routes' })}
                        className="termandpolicy"
                    >
                        {t('termOfUse')}
                    </Link>{' '}
                    {t('and')}{' '}
                    <Link
                        to={t('/privacy-policy', { ns: 'routes' })}
                        className="termandpolicy"
                    >
                        {t('privacyPolicy')}
                    </Link>
                </span>
                <div>
                    <Form.Item>
                        <ButtonComponent
                            type="primary"
                            htmlType="submit"
                            disabled={loading || buttonDisabled}
                            className={`sign-up-button ${
                                loading ? 'loading-btn' : ''
                            }`}
                        >
                            {loading ? (
                                <>
                                    <SpinnerGap
                                        size={32}
                                        weight="bold"
                                        className="rotate"
                                    />
                                    {t('signingUp')}
                                </>
                            ) : (
                                t('signUp')
                            )}
                        </ButtonComponent>
                    </Form.Item>
                </div>
            </Form>
            <div className="sign-in-redirect">
                {t('alreadyhaveanAccount')}{' '}
                <span onClick={() => handleActiveModal(1)}>{t('signIn')}</span>
            </div>
        </div>
    );
};
export default SignUpForm;
