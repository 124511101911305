import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import SecSeperator from './ui/SecSeperator';

const WhyFinance = () => {
    const { t } = useTranslation();
    return (
        <div className="why-finance-container">
            <div className="why-finance">
                <SecSeperator />
                <h2 className="title">{t('whyFinanceYourCarwithAuto24')}</h2>
                <p className="subtitle">{t('financeSubtitle')}</p>
                <div className="why-finance-units">
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={10}
                        pagination={{
                            clickable: true
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.2,
                                spaceBetween: 16
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 24
                            }
                        }}
                        className="mySwiper"
                        navigation
                    >
                        <SwiperSlide>
                            <WhyFinnaceUnit
                                title="competitiveRates"
                                desc="competitiveDesc"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <WhyFinnaceUnit
                                title="flexiableDuration"
                                desc="flexiableDes"
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <WhyFinnaceUnit
                                title="Percent100OnlineApproach"
                                desc="Percent100OnlineApproachDesc"
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default WhyFinance;

const WhyFinnaceUnit = (props: any) => {
    const { t } = useTranslation();
    return (
        <div className="why-finance-unit">
            <div className="why-finance-image">
                <img src="/images/Check1.webp" alt="" />
            </div>
            <h3 className="finance-title">{t(props.title)}</h3>
            <p className="why-finance-desc">{t(props.desc)}</p>
        </div>
    );
};
