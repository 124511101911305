import { message } from 'antd';
import { Dispatch } from 'redux';
import { $FIXME, getCars } from '../../constants';
import { request } from '../../helpers/request';
import { ICarParams } from '../../interface/car';
import { Api } from '../Api';
import {
    CAR_LIST_ERROR,
    CAR_LIST_LOADING,
    CAR_LIST_SUCCESS,
    GET_CARS_DETAILS
} from '../types/carListTypes';

export const getCarData = async (dispatch: Dispatch, params?: $FIXME) => {
    dispatch({ type: CAR_LIST_LOADING });
    const resData: any = await getAllCars(params);
    if (resData.status === 200) {
        // if success set the state with api returned value and loading to false
        dispatch({ type: CAR_LIST_SUCCESS, payload: resData.data });
    } else {
        // if no success set error to true and loaidng to false to show user it failed
        dispatch({ type: CAR_LIST_ERROR, payload: resData.data });
    }
};

export const getDetail = async (dispatch: Dispatch, _id: string) => {
    dispatch({ type: CAR_LIST_LOADING });

    const resData: any = await getCarDetails(_id);
    if (resData.status === 200) {
        dispatch({ type: GET_CARS_DETAILS, payload: resData.data });
        return resData.data;
    } else {
        // message.error(resData.response.data.msg);
    }
};

// api call happening here
const getAllCars = async (params?: ICarParams) => {
    try {
        const result = await Api.get(`/${getCars}`, { params });
        return result;
    } catch (error) {
        return error;
    }
};

const getCarDetails = async (_id: string) => {
    try {
        const result = await request.getCarDetail(_id);
        return result;
    } catch (error) {
        return error;
    }
};
