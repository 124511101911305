import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import SecSeperator from './ui/SecSeperator';

import { ICombineReducerType } from '../redux/reducers';
import { COUNTRY_TOP_LEVEL_DOMAIN, IMAGE_URL } from '../constants';
import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';
import { getTopLevelDomain } from '../utils/translation';

const Promotions = () => {
    const { gallery } = useSelector(
        (state: ICombineReducerType) => state.customerGallery
    );
    const { t } = useTranslation();

    function getGoogleRatingImgSrcAndLink () {
        const topLevelDomain = getTopLevelDomain();
        switch(topLevelDomain) {
            case COUNTRY_TOP_LEVEL_DOMAIN.ma:
                return {image: '/images/google-rating-48.svg', link: 'https://maps.app.goo.gl/A8ds8b3ZpydPJkbt6'}
            case COUNTRY_TOP_LEVEL_DOMAIN.coza:
                return {image: '/images/google-rating-5.svg', link: 'https://maps.app.goo.gl/svtNoLtcks7i4Way6'}
            case COUNTRY_TOP_LEVEL_DOMAIN.ci:
                return {image: '/images/google-rating-48.svg', link: 'https://maps.app.goo.gl/zXfBWYRfRGpbERbcA'}
            case COUNTRY_TOP_LEVEL_DOMAIN.rw:
                return {image: '/images/google-rating-47.svg', link: 'https://maps.app.goo.gl/Ukg6Ajckf82K7UqG8'}
            case COUNTRY_TOP_LEVEL_DOMAIN.sn:
                return {image: '/images/google-rating-5.svg', link: ''}
            case COUNTRY_TOP_LEVEL_DOMAIN.tg:
                return {image: '/images/google-rating-5.svg', link: ''}
            case COUNTRY_TOP_LEVEL_DOMAIN.asterdio:
                return {image: '/images/google-rating-5.svg', link: ''}
        }
    }

    return (
        <>{
        gallery && Array.isArray(gallery) && gallery.length > 0 &&
        <div className="promotions-container">
            <div className="promotions padding">
                <div className="promotions-left">
                    <SecSeperator />
                    <h2 className="title">
                        {t('happycustomerHappyus', { ns: 'hero' })}
                    </h2>
                    <p className="subtitle">{t('loanHappyCusSubtitle')}</p>
                    <a href={getGoogleRatingImgSrcAndLink()?.link} target="_blank" rel="noreferrer">
                    `<img  src={getGoogleRatingImgSrcAndLink()?.image} alt="google-rating" />
                    </a>
                </div>
                <div className="promotions-right">
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView={1.7}
                        spaceBetween={16}
                        pagination={{
                            clickable: true
                        }}
                        breakpoints={{
                            '320': {
                                slidesPerView: 1.5,
                                spaceBetween: 10
                            },
                            '768': {
                                slidesPerView: 2.3,
                                spaceBetween: 16
                            },
                            '1024': {
                                slidesPerView: 2.4,
                                spaceBetween: 16
                            },
                            '1100': {
                                slidesPerView: 2.6,
                                spaceBetween: 16
                            },
                            '1200': {
                                slidesPerView: 2.8,
                                spaceBetween: 16
                            },
                            '1300': {
                                slidesPerView: 3.1,
                                spaceBetween: 16
                            }
                        }}
                        navigation
                        className="mySwiper"
                    >
                        {gallery.map((items, index) => {
                            let image =
                                Array.isArray(items.images) &&
                                items?.images?.length
                                    ? items?.images[0]
                                    : undefined;

                            return image ? (
                                <SwiperSlide key={index}>
                                    <div className="carousel-item">
                                        <div className="carousel-image">
                                            <img
                                                src={IMAGE_URL + image}
                                                alt={items?.caption}
                                                onError={addImageFallback}
                                            />
                                        </div>
                                        <p className="carousel-title">
                                            {
                                                contentAccordToCurrentLang(
                                                    items
                                                )?.caption
                                            }{' '}
                                            <b>
                                                {items?.customerName},{' '}
                                                {items?.customerAddress}
                                            </b>
                                        </p>
                                    </div>
                                </SwiperSlide>
                            ) : null;
                        })}
                    </Swiper>
                </div>
            </div>
        </div>
        }</>
    );
};

export default Promotions;
