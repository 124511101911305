import Buysell from '../components/Buysell';
import Questionnaire from '../components/Questionnaire';
import Layout from '../layout/Layout';

import { SetMeta } from '../utils/Helper/jsHelpers';

const Faq = () => {
    return (
        <>
            <SetMeta title="qNaPageTitle" />
            <div className="faq-page">
                <Layout showWhiteNavbar>
                    <div className="faqs">
                        <Questionnaire title="questions" showAskQuestion />
                    </div>
                    <Buysell />
                </Layout>
            </div>
        </>
    );
};

export default Faq;
