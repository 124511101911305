import { ChangeEventHandler, CSSProperties, useRef, useState } from 'react';

import { Form, Input, InputRef, Select } from 'antd';
import { CaretDown, MagnifyingGlass, User } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import { countryCodes, defaultDailing_Code } from '../../helpers/countryCode';
import { $FIXME } from '../../constants';

interface IProps {
    selectName?: string;
    onSelect?: (e: string, name: string) => void;
    selectValue?: string;
    style?: CSSProperties;
    inputName?: string;
    inputValue?: string | number | readonly string[];
    onInputChange?: ChangeEventHandler<HTMLInputElement>;
    error?: string;
    inputStyle?: CSSProperties;
    disable?: boolean;
    inputClass?: string;
    selectClass?: string;
    inputMaxlen?: number;
    required?: boolean;
    className?: string;
    validateFullName?: $FIXME;
}
export const FilterCountry = (countryCodes: $FIXME, query: string) => {
    const array = countryCodes.filter((item: $FIXME) => {
        let a = item.country_name?.toLowerCase() + item.dialling_code;

        return a.includes(query);
    });
    return array;
};

const CountryCodeDropdown = ({
    selectName,
    onSelect,
    selectValue = '',
    style,
    inputName,
    inputValue,
    onInputChange,
    error,
    disable = false,
    inputStyle,
    inputClass,
    selectClass,
    className = '',
    required = true
}: IProps) => {
    const [query, setQuery] = useState('');
    const { t } = useTranslation();
    const { Option } = Select;

    const inputRef = useRef<InputRef>(null);
    const CountrySearchFocus = () =>
        setTimeout(() => {
            inputRef.current!.focus({ cursor: 'start' });
        }, 500);
    const ClearState = () =>
        setTimeout(() => {
            setQuery('');
        }, 500);

    return (
        <div style={style} className={className}>
            <Input.Group
                compact
                className={`phone-input ${error && 'border-error'}`}
                style={inputStyle}
            >
                <Select
                    onChange={(e) =>
                        onSelect && !!selectName
                            ? onSelect(e, selectName)
                            : undefined
                    }
                    defaultValue={defaultDailing_Code}
                    dropdownMatchSelectWidth={240}
                    optionLabelProp="label"
                    className={`phone-select ${selectClass}`}
                    onDropdownVisibleChange={(open) =>
                        open ? CountrySearchFocus() : ClearState()
                    }
                    suffixIcon={
                        <CaretDown size={20} color="#b3b3b3" weight="regular" />
                    }
                    popupClassName="phone-input-dropdown"
                    dropdownRender={(menu) => (
                        <>
                            <Form className="dropdown-form">
                                <Input
                                    prefix={
                                        <MagnifyingGlass
                                            size={16}
                                            color="#8b8b8b"
                                            weight="bold"
                                        />
                                    }
                                    ref={inputRef}
                                    placeholder={t('search')}
                                    className="dropdown-input"
                                    value={query}
                                    onChange={(e) =>
                                        setQuery(e.target.value.toLowerCase())
                                    }
                                />
                            </Form>
                            {menu}
                        </>
                    )}
                    value={selectValue}
                >
                    {FilterCountry(countryCodes, query)?.map(
                        (opt: $FIXME, index: number) => (
                            <Option
                                key={index}
                                value={`${opt.dialling_code}`}
                                label={
                                    <div className="phone-label">
                                        <img
                                            alt=""
                                            src={`https://flagcdn.com/16x12/${opt.country_code}.png`}
                                            srcSet={`https://flagcdn.com/32x24/${opt.country_code}.png 2x,
                                          https://flagcdn.com/48x36/${opt.country_code}.png 3x`}
                                            width="16"
                                            height="12"
                                            // src={`https://countryflagsapi.com/svg/${opt.country_code}`}
                                        />
                                        <span> ({opt.dialling_code})</span>
                                    </div>
                                }
                            >
                                <div className="phone-option">
                                    <img
                                        alt=""
                                        src={`https://flagcdn.com/16x12/${opt.country_code}.png`}
                                        srcSet={`https://flagcdn.com/32x24/${opt.country_code}.png 2x,
                                          https://flagcdn.com/48x36/${opt.country_code}.png 3x`}
                                        width="16"
                                        height="12"
                                        // src={`https://countryflagsapi.com/svg/${opt.country_code}`}
                                    />
                                    {`${opt.country_name}  (${opt.dialling_code})`}
                                </div>
                            </Option>
                        )
                    )}
                </Select>

                <div className="input-divider" />

                <Input
                    placeholder={t('phoneNumber')}
                    className={`phone-inputbox ${inputClass}`}
                    value={inputValue}
                    onChange={onInputChange}
                    name={inputName}
                    type="text"
                    maxLength={10}
                    disabled={disable}
                    required={required}
                />
            </Input.Group>
            {!!error && (
                <span className={`error-label ${error && 'fade-in'}`}>
                    {t(error)}
                </span>
            )}
        </div>
    );
};

export default CountryCodeDropdown;

export const FullNameInputComp = ({
    handleChange,
    value,
    t,
    err,
    setFullNameError
}: {
    handleChange: any;
    value: $FIXME;
    t: $FIXME;
    err: string | undefined;
    setFullNameError: $FIXME;
}) => {
    return (
        <div className=" phone-input overflow  relative  ">
            <label>
                <User size={24} color="#8b8b8b" />
            </label>
            <input
                onBlur={() => setFullNameError()}
                type="text"
                placeholder={t('enterFullName')}
                onChange={handleChange}
                name="fullName"
                value={value}
                required
                autoComplete="off"
            />
            {err && (
                <div
                    className="error-label absolute fade-in "
                    style={{ bottom: '-25px', left: 0 }}
                >
                    {t(err)}
                </div>
            )}
        </div>
    );
};
