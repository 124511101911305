import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import LoadMore from './ui/LoadMore';
import SecSeperator from './ui/SecSeperator';
import GetACallBack from './ui/GetACallBack';

import ServiceData from '../utils/ServiceData';

interface IPropType {
    imagesrc: string;
    title: string;
    subtitle: string;
    subCategory: string;
}

const Service = () => {
    const { t } = useTranslation();
    return (
        <div className="service ">
            <div className="padding">
                <SecSeperator className="center margin-bottom" />
                <h2 className="title center">{t('serviceTitle')}</h2>
                <p className="subtitle center">{t('serviceSubtitle')} </p>
                <div className="service-container">
                    {ServiceData.map((service, index) => {
                        return (
                            <ServiceUnit
                                key={index}
                                imagesrc={service.image}
                                title={service.title}
                                subtitle={service.subtitle}
                                subCategory={service.subCategory}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Service;

const ServiceUnit = (props: IPropType) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = useTranslation();
    const showModal = (e: any) => {
        setIsModalVisible(true);
        e.preventDefault();
    };

    return (
        <div className="service-unit">
            <div className="icon-container">
                <img src={props.imagesrc} alt={props.title} />
            </div>
            <h3 className="title">{t(props.title)}</h3>
            <p className="subtitle">{t(props.subtitle)}</p>
            <LoadMore text="learnMore" class="width" onclick={showModal} />
            <GetACallBack
                open={isModalVisible}
                setModalOpen={setIsModalVisible}
                category="service"
                subCategory={props.subCategory}
            />
        </div>
    );
};
