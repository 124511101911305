import { useEffect, useState } from 'react';

import { message } from 'antd';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { LockSimple, SpinnerGap } from 'phosphor-react';

import { $FIXME } from '../../constants';
import { request } from '../../helpers/request';
import ButtonComponent from '../ui/ButtonComponent';
import TextBox from '../ui/TextBox';

interface Props {
    handleActiveModal: (id: number) => void;
    token: string;
    handleCancel: $FIXME;
}

const CreateNewPassword = ({
    handleActiveModal,
    token,
    handleCancel
}: Props) => {
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    } as any);
    const [ismatch, setIsMatch] = useState(false);
    const [vaidpass, setIsValidPass] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (key: any, value: any) => {
        setFormData((formData: any) => ({ ...formData, [key]: value }));
    };

    const { t } = useTranslation();

    useEffect(() => {
        if (formData.password.length >= 8) {
            setIsValidPass(true);
        } else {
            setIsValidPass(false);
        }
        if (formData.password === formData.confirmPassword) {
            setIsMatch(true);
        } else {
            setIsMatch(false);
        }
    }, [formData]);
    function handleSubmit(e: any) {
        setLoading(true);
        e.preventDefault();
        request
            .createPassword(formData, token)
            .then((res: AxiosResponse) => {
                message.success(res.data.msg);
                handleCancel();
                return;
            })
            .catch((err) => {
                // message.error(err.response.data.msg);
                handleCancel();
                return;
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <div className="new-password-form">
                <span className="form-text">
                    {t('enterEmailToGetResetLinkInfo')}
                </span>
                <form onSubmit={handleSubmit}>
                    <div className="text-box-wrapper">
                        <TextBox
                            name={'password'}
                            placeholder={t('enterNewPassword')}
                            type="password"
                            onChange={(data) => handleChange('password', data)}
                            prefix={<LockSimple weight="bold" />}
                            value={formData.password}
                        />
                        <span
                            className={`text-box-info ${
                                !vaidpass ? 'error-text' : 'success-text'
                            } `}
                        >
                            {t('mustBeAtleast8Characters')}
                        </span>
                    </div>
                    <div className="text-box-wrapper">
                        <TextBox
                            name={'confirmPassword'}
                            placeholder={t('confirmPassword')}
                            type="password"
                            onChange={(data) =>
                                handleChange('confirmPassword', data)
                            }
                            prefix={<LockSimple weight="bold" />}
                            value={formData.email}
                        />
                        <span
                            className={`text-box-info ${
                                !ismatch ? 'error-text' : 'success-text'
                            } `}
                        >
                            {t('bothPasswordMustMatch')}
                        </span>
                    </div>

                    <ButtonComponent
                        type="primary"
                        className="button"
                        htmlType="submit"
                        disabled={loading || !ismatch}
                    >
                        {' '}
                        {loading ? (
                            <SpinnerGap
                                size={32}
                                weight="bold"
                                className="rotate"
                            />
                        ) : (
                            t('resetPassword')
                        )}
                    </ButtonComponent>
                </form>
                <div className="sign-in-redirect">
                    {t('returnTo')}{' '}
                    <span onClick={() => handleCancel()}>{t('signIn')}</span>
                </div>
            </div>
        </>
    );
};

export default CreateNewPassword;
