import { Dispatch } from 'redux';
import { serviceHistoryByCarIds } from '../../constants';
import { Api } from '../Api';
import {
    SERVICE_HISTORY_ERROR,
    SERVICE_HISTORY_LOADING,
    SERVICE_HISTORY_SUCCESS
} from '../types/serviceHistoryTypes';
import i18n from '../../localization';

export const getServiceData = async (dispatch: Dispatch, _id: string) => {
    dispatch({ type: SERVICE_HISTORY_LOADING });

    const resData: any = await serviceHistoryByCarId(_id);
    if (resData.status === 200) {
        dispatch({
            type: SERVICE_HISTORY_SUCCESS,
            payload: resData.data.serviceHistories
        });
    } else if (resData.status === 404) {
        return null;
    } else {
        dispatch({
            type: SERVICE_HISTORY_ERROR,
            payload: resData.message || (i18n.t('somethingWentWrong') as string)
        });
        // message.error(resData.message || 'something went wrong')
    }
};

// const getserviceHistory = async (_id: string) => {
//   try {
//     const result = await Api.get(`/${getservicehistories}?slug=${_id} `)
//     return result
//   } catch (error) {
//     return error
//   }
// }

const serviceHistoryByCarId = async (_id: string) => {
    try {
        const result = await Api.get(`/${serviceHistoryByCarIds}/${_id}`);
        return result;
    } catch (error) {
        return error;
    }
};
