import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FinanceTop from '../components/FinanceTop';
import { ProcessBackground } from '../components/Process';
import Testimonial from '../components/Testimonial';
import Layout from '../layout/Layout';

import { ICombineReducerType } from '../redux/reducers';
import { pagesContent, resetPagesContent } from '../redux/actions/pagesAction';
import { getTestimonials } from '../redux/actions/testimonialActions';
import StatsArray from '../utils/Stats';
import FinancingPartner from '../components/FinancingPartner';
import { financePartners } from '../redux/actions/finacePartnerAction';
import { SetMeta } from '../utils/Helper/jsHelpers';

const About = () => {
    const dispatch = useDispatch();
    const { financingPartners } = useSelector(
        (state: ICombineReducerType) => state.financePartners
    );

    const { testimonials } = useSelector(
        (state: ICombineReducerType) => state.testimonial
    );

    const { pages } = useSelector((state: ICombineReducerType) => state.pages);

    useEffect(() => {
        pagesContent(dispatch, 'about');
        getTestimonials(dispatch);
        financePartners(dispatch, 'about');
        return () => {
            resetPagesContent(dispatch);
        }
    }, [dispatch]);

    return (
        <>
            <SetMeta title="aboutPageTitle" desc="aboutPageDesc" keywords='aboutPageKeywords'
            properties={[
                {
                    key: "og:title",
                    value: "aboutPageTitleOg"
                },
                {
                    key: "og:description",
                    value: "aboutPageDescOg"
                },
                {
                    key: "og:keywords",
                    value: "aboutPageKeywordOg"
                }
            ]}
            />
            <div className="about">
                <Layout showWhiteNavbar>
                    <FinanceTop pages={pages} image="/images/about-left.webp" />
                    <ProcessBackground StatsArray={StatsArray} />
                    {testimonials.length ? <Testimonial /> : null}
                    <FinancingPartner
                        title="ourPartners"
                        subtitle
                        financingPartners={financingPartners}
                        subtitles="aboutFinanceSub"
                    />
                </Layout>
            </div>
        </>
    );
};

export default About;
