import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { request } from '../../helpers/request';
import { AuthTypes } from '../../redux/types/AuthTypes';
import Loading from '../ui/Loading';
import Modal from '../ui/Modal';
import CreateNewPassword from './CreateNewPassword';

export const ForgetPasword = () => {
    const [loading, setloading] = useState(true);
    const [showModal1, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useParams();
    function handleClose() {
        navigate(t('home', { ns: 'routes' }));
        dispatch({ type: AuthTypes.SHOW_LOGIN_MODAL });
        setShowModal(false);
    }
    useEffect(() => {
        request
            .verifyToken(token ?? '')
            .then((res) => setShowModal(true))
            .catch((err) => setShowModal2(true))
            .then(() => setloading(false));
    }, []);
    useEffect(() => {}, []);
    const modalLayout: any[] = [
        {
            header: 'createNewPassword',
            backdropType: 'blur',
            data: (
                <CreateNewPassword
                    token={token ?? ''}
                    handleCancel={handleClose}
                    handleActiveModal={(id) => {}}
                />
            ),
            rightPadding: '40',
            closable: false,
            modalSize: 'medium'
        },
        {
            header: 'forgotTokenExpired',
            backdropType: 'blur',
            data: <></>,
            rightPadding: '40',
            closable: true,
            modalSize: 'medium'
        }
    ];
    return (
        <div>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Modal
                        closable={modalLayout[0].closable}
                        isLeftRequired={modalLayout[0].isLeftRequired}
                        isVisible={showModal1}
                        handleCancel={handleClose}
                        header={t(modalLayout[0].header, { ns: 'common' })}
                        backdropType={modalLayout[0].backdropType}
                        rightPadding={modalLayout[0].rightPadding}
                        size={modalLayout[0].modalSize}
                        secSeperator={modalLayout[0].secSeperator}
                    >
                        {modalLayout[0].data}
                    </Modal>
                    <Modal
                        closable={modalLayout[1].closable}
                        isLeftRequired={modalLayout[1].isLeftRequired}
                        isVisible={showModal2}
                        handleCancel={handleClose}
                        header={t(modalLayout[1].header, { ns: 'common' })}
                        backdropType={modalLayout[1].backdropType}
                        rightPadding={modalLayout[1].rightPadding}
                        size={modalLayout[1].modalSize}
                        secSeperator={modalLayout[1].secSeperator}
                    >
                        {modalLayout[1].data}
                    </Modal>
                </>
            )}
        </div>
    );
};
