import { message } from 'antd';
import { Dispatch } from 'redux';
import { contactQuery } from '../../constants';
import { Api } from '../Api';
import {
    CREATE_CONTACT_US_ERROR,
    CREATE_CONTACT_US_LOADING,
    CREATE_CONTACT_US_SUCCESS
} from '../types/contactUsTypes';
import i18n from '../../localization';

export const contactQueryAction = async (dispatch: Dispatch, data: any) => {
    dispatch({ type: CREATE_CONTACT_US_LOADING });
    const resData: any = await contactQuerys(data);
    if (resData.status === 200) {
        dispatch({
            type: CREATE_CONTACT_US_SUCCESS,
            payload: resData.data.contactQuery
        });
        message.success(i18n.t('querySubmittedSuccessfully') as string);
        return true;
    } else {
        dispatch({
            type: CREATE_CONTACT_US_ERROR
        });
        // message.error(i18n.t('somethingWentWrong') as string);
        return false;
    }
};

export const contactQuerys = async (data: any) => {
    try {
        const result = await Api.post(`/${contactQuery}`, { ...data });
        return result;
    } catch (error) {
        return error;
    }
};
