import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'antd';
import {
    CalendarBlank,
    Car,
    CircleWavyQuestion,
    GasPump
} from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';
import Selects from './ui/Select';
import LoadingButton from './ui/LoadingButton';
import DragAndDropFileUpload from './ui/DragAndDropFIleUpload';

import { useMediaQueryHook } from '../utils/Hooks/MediaQuery';
import { ICombineReducerType } from '../redux/reducers';
import { defaultDailing_Code } from '../helpers/countryCode';
import { fetchCarModelByModel } from '../redux/actions/carModelsAction';
import { $FIXME } from '../constants';
import { createSellingCar } from '../redux/actions/sellCarAction';
import { CAR_CONDITION, getYearList } from '../helpers/constants';
import { getCarBrandsList } from '../redux/actions/carBrandsAction';
import { getMileageOptions } from './SellDetails';

const ListYourCar = () => {
    const [errors, setErrors] = useState<any>({});
    const [images, setImages] = useState<any>([]);
    const [saveModal, setSaveModalVisible] = useState(false);

    const { t } = useTranslation();

    const { user } = useSelector((state: ICombineReducerType) => state.auth);
    const { loadingSellData } = useSelector(
        (state: ICombineReducerType) => state.sellingCars
    );
    const brandsList = useSelector((state: ICombineReducerType) =>
        state.carBrands.carBrands.map((car) => ({
            label: car.brand,
            value: car._id,
            _id: car._id
        }))
    );
    const carModelsList = useSelector((state: ICombineReducerType) =>
        state.carModels.carModels.map((model) => ({
            label: model.model,
            value: model._id,
            _id: model._id
        }))
    );
    const [state, setState] = useState<any>({
        code: user?.phoneNumber?.code ?? defaultDailing_Code,
        number: user?.phoneNumber?.number,
        fullName: user?.name,
        brand: '',
        model: '',
        year: '',
        mileage: '',
        transmission: '',
        fuelType: '',
        condition: ''
    });
    let requiredParams = [
        'code',
        'number',
        'fullName',
        'brand',
        'model',
        'year',
        'mileage',
        'transmission',
        'fuelType',
        'condition'
    ];

    const dispatch = useDispatch();

    let navigate = useNavigate();

    function resetFields() {
        setImages([]);
        setState({
            code: user?.phoneNumber?.code ?? defaultDailing_Code,
            number: user?.phoneNumber?.number,
            fullName: user?.name,
            brand: '',
            model: '',
            year: '',
            mileage: '',
            transmission: '',
            fuelType: '',
            condition: '',
            expectedPrice: '',
            shortDesc: ''
        });
    }
    useEffect(() => {
        setState((prev: any) => ({
            ...prev,
            model: ''
        }));
    }, [state.brand]);
    useEffect(() => {
        if (!brandsList.length) {
            getCarBrandsList(dispatch);
        }
    }, []);
    useEffect(() => {
        if (state.brand) fetchCarModelByModel(dispatch, state.brand);
    }, [state.brand, dispatch]);

    const err = requiredParams.filter((k) => !state[k]);

    const SaveChanges = (e: any) => {
        e.preventDefault();
        if (!err.length) {
            let formData = new FormData();
            if (Object.keys(images).length) {
                for (const key of Object.keys(images)) {
                    formData.append('images', images[key]);
                }
            }
            for (const key of Object.keys(state).filter((k) => !!state[k])) {
                formData.append(key, state[key]);
            }
            createSellingCar(dispatch, formData);
            resetFields();
            setSaveModalVisible(true);
        }
    };

    const goToListing = () => {
        setSaveModalVisible(false);
        navigate(0);
    };
    const goToHome = () => {
        setSaveModalVisible(false);
        navigate(t('home', { ns: 'routes' }));
    };
    const cancelHandler = () => {
        setSaveModalVisible(false);
    };
    function handleSelect(val: $FIXME, key: string) {
        delete errors[key];
        setState((prev: any) => ({
            ...prev,
            [key]: val
        }));
        setErrors(errors);
    }
    return (
        <div className="list-your-car">
            <SecSeperator className="listcar-sep" />
            <h3 className="title listcar-title">{t('sellYourCar')}</h3>
            <div className="basic-details">
                <h4 className="section-title">{t('basicDetails')}</h4>
                <div className="basic-details-top">
                    <Selects
                        prefixicon={<Car size={24} color="#8b8b8b" />}
                        placeholder={t('selectbrand')}
                        size="16"
                        options={brandsList}
                        name="brand"
                        onSelect={handleSelect}
                        showSearch={true}
                        value={state.brand}
                        weight="regular"
                        error={errors.brand}
                        allowClear={true}
                        class="select-cursor"
                    />
                    <div className="basic-details-bottom">
                        <Selects
                            prefixicon={<img src="/icon/apps.svg" alt="apps" />}
                            placeholder={t('selectmodel')}
                            size="16"
                            weight="regular"
                            error={errors.model}
                            name="model"
                            showSearch={true}
                            options={carModelsList}
                            onSelect={handleSelect}
                            value={state.model}
                            disabled={!state.brand && true}
                            class={!state.brand ? '' : 'select-cursor'}
                        />
                        <Selects
                            prefixicon={
                                <CalendarBlank size={24} color="#8b8b8b" />
                            }
                            placeholder={t('selectYear')}
                            weight="regular"
                            size="16"
                            name="year"
                            error={errors.year}
                            showSearch={true}
                            options={getYearList().map(
                                (item: any, index: number, arry) => {
                                    return index + 1 === arry.length
                                        ? {
                                              label: t('other'),
                                              value: 'other'
                                          }
                                        : {
                                              label: item,
                                              value: item
                                          };
                                }
                            )}
                            onSelect={handleSelect}
                            value={state.year}
                            class="select-cursor"
                        />

                        <Selects
                            prefixicon={
                                <img src="/svgs/select-mileage.svg" alt="" />
                            }
                            placeholder={t('selectMileageRange')}
                            size="16"
                            weight="regular"
                            error={errors.mileage}
                            options={getMileageOptions(t)}
                            name="mileage"
                            value={state.mileage ? state.mileage : ''}
                            onSelect={handleSelect}
                            showSearch={true}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={
                                <img
                                    src="/icon/automatic.svg"
                                    alt={t('automatic')}
                                />
                            }
                            placeholder={t('selectTransmissionType')}
                            size="16"
                            weight="regular"
                            options={['automatic', 'manual'].map((item) => ({
                                label: t(item),
                                value: item
                            }))}
                            error={errors.transmission}
                            name="transmission"
                            value={state.transmission}
                            onSelect={handleSelect}
                            showSearch={true}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={<GasPump size={24} color="#8b8b8b" />}
                            placeholder={t('selectFuelType')}
                            size="16"
                            name="fuelType"
                            weight="regular"
                            error={errors.fuelType}
                            options={[
                                'petrol',
                                'diesel',
                                'electric',
                                'other'
                            ].map((item) => ({
                                label: t(item),
                                value: item
                            }))}
                            value={state.fuelType}
                            showSearch={true}
                            onSelect={handleSelect}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={
                                <CircleWavyQuestion size={24} color="#8b8b8b" />
                            }
                            placeholder={t('selectACondition')}
                            weight="regular"
                            size="16"
                            name="condition"
                            error={errors.condition}
                            options={CAR_CONDITION.map((item) => ({
                                label: t(item.name),
                                value: item.value
                            }))}
                            value={state.condition}
                            onSelect={handleSelect}
                            showSearch={true}
                            class="select-cursor"
                        />
                    </div>
                </div>

                <textarea
                    name="shortDesc"
                    cols={30}
                    rows={5}
                    value={state.shortDesc}
                    autoComplete="off"
                    onChange={(e) =>
                        handleSelect(e.target.value, e.target.name)
                    }
                    placeholder={t('shortDescription')}
                ></textarea>
                <span className="error-label">{errors.shorDesc}</span>
            </div>
            <div className="car-image">
                <h4 className="section-title">{t('carImages')}</h4>
                <DragAndDropFileUpload
                    name="images"
                    handleFile={(files, name) => {
                        setImages(files);
                    }}
                    multiple={true}
                    fileList={images}
                >
                    <div className="car-images">
                        <div className="upload-icon">
                            <img
                                src="/images/Uploadicon.webp"
                                alt="upload-icon"
                            />
                        </div>
                        <div className="upload-text">
                            {useMediaQueryHook() ? (
                                <p className="upload__mobile">
                                    {' '}
                                    {t('uploadImage')}{' '}
                                </p>
                            ) : (
                                <div className="file-format-container">
                                    <p className="file-format drag">
                                        {t('dragAndDrop')}
                                    </p>
                                    <p className="file-format drag browse ">
                                        &nbsp;{t('browse')}
                                    </p>
                                </div>
                            )}

                            <p className="file-format">{t('imageFormat')}</p>
                        </div>
                    </div>
                </DragAndDropFileUpload>
                <div className="btns-container">
                    {loadingSellData ? (
                        <LoadingButton buttonText="sending" />
                    ) : (
                        <button
                            disabled={!err.length ? false : true}
                            className="form-btn send button-primary"
                            onClick={SaveChanges}
                        >
                            {t('send')}
                        </button>
                    )}
                    <button className="form-btn cancel">{t('cancel')}</button>
                </div>
            </div>
            <Modal
                className="save-changes-modal ants-modal"
                title={t('listCarRequestSentSucessfully')}
                open={saveModal}
                onOk={goToListing}
                onCancel={cancelHandler}
                closable={false}
            >
                <p className="subtitle">{t('thanyouforprovidingusdetail')} </p>
                <div className="done-icon">
                    <img src="/images/done.webp" alt="" />
                </div>
                <div className="modal-btn-container">
                    <button className="explore" onClick={goToListing}>
                        {t('listAnother')}
                    </button>
                    <button className="back-to-home" onClick={goToHome}>
                        {t('backToHome')}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ListYourCar;
