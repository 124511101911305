const StatsArray = [
    {
        imagesrc: '/svgs/certified-icon.svg',
        alt: 'certifiedUsedCars',
        description: 'certifiedUsedCars',
        descs: 'certifiedUsedCarsSubtitle'
    },
    {
        imagesrc: '/svgs/inspection-point.svg',
        alt: 'inpectionsPoints',
        description: 'inpectionsPoints',
        descs: 'inpectionsPointsSubtitle'
    },
    {
        imagesrc: '/svgs/refund-icon.svg',
        alt: 'refundPolicy',
        description: 'refundPolicy',
        descs: 'refundPolicySubtitle'
    },
    {
        imagesrc: '/svgs/warranty-icon.svg',
        alt: 'warrentyTitle',
        description: 'warrentyTitle',
        descs: 'warrentySubtitles'
    }
];
export default StatsArray;

export const StatsArray2 = [
    {
        imagesrc: '/svgs/100inspection.svg',
        alt: '100 inspection',
        descs: 'inspectionPoints'
    },
    {
        imagesrc: '/svgs/warranty6.svg',
        alt: 'refund',
        descs: 'warranties'
    },
    {
        imagesrc: '/svgs/autofast.svg',
        alt: 'autofast',
        descs: 'maintenances'
    },
    {
        imagesrc: '/svgs/assitance.svg',
        alt: '1 year assistance',
        descs: 'assistance'
    },
    {
        imagesrc: '/svgs/multicurrency.svg',
        alt: 'inspection',
        descs: 'multiCurrency'
    },
    {
        imagesrc: '/svgs/moneyback5.svg',
        alt: 'moneyback',
        descs: 'moneyBack'
    },
    {
        imagesrc: '/svgs/paperwork.svg',
        alt: 'paperWork',
        descs: 'paperWork'
    },
    {
        imagesrc: '/svgs/verify.svg',
        alt: 'verifyhistory',
        descs: 'verifyhistory'
    },
    {
        imagesrc: '/svgs/buy-back.svg',
        alt: 'buyBack',
        descs: 'buyBack'
    }
];
