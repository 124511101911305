import { useMediaQuery } from 'react-responsive';

export const useMediaQueryHook = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    return isMobile;
};
export const useMediaQueryHooks = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    return isMobile;
};

export const useLgMediaQuery = () => {
    const isLg = useMediaQuery({ query: '(max-width: 1024px)' });
    return isLg;
};