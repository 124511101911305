import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { EnvelopeSimple, MapPin, Phone, User } from 'phosphor-react';

import SecSeperator from '../components/ui/SecSeperator';
import CountryCodeDropdown from '../components/ui/CountryCodeDropdown';
import LoadingButton from '../components/ui/LoadingButton';
import { $FIXME, emailRegs } from '../constants';
import Layout from '../layout/Layout';

import { contactInformationList } from '../redux/actions/contactInformationAction';
import { contactQueryAction } from '../redux/actions/contactUsAction';
import { ICombineReducerType } from '../redux/reducers';
import {
    fullNameValidate,
    numcheck,
    returnOnlyNumber,
    SetMeta
} from '../utils/Helper/jsHelpers';
import { defaultDailing_Code } from '../helpers/countryCode';

const ContactUs = () => {
    const { t } = useTranslation();
    let { user } = useSelector((state: ICombineReducerType) => state.auth);

    const [contactUs, setContactUs] = useState({
        fullName: user?.name ?? '',
        code: user?.phoneNumber?.code ?? defaultDailing_Code,
        number: user?.phoneNumber?.number ?? '',
        email: user?.email ?? '',
        message: ''
    });
    const [btnloading, setBtnLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [errors, setErrors] = useState<$FIXME>({
        fullName: user?.name ?? '',
        code: user?.phoneNumber?.code ?? defaultDailing_Code,
        number: user?.phoneNumber?.number ?? '',
        email: user?.email ?? '',
        message: '',
        phoneNumber: ''
    });
    const dispatch = useDispatch();

    const { contactInformation } = useSelector(
        (state: ICombineReducerType) => state.contactInformation
    );
    const { loading } = useSelector(
        (state: ICombineReducerType) => state.contactQuery
    );

    useEffect(() => {
        contactInformationList(dispatch);
    }, [dispatch]);

    const handleInputChange = (e: $FIXME) => {
        setContactUs({
            ...contactUs,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setBtnLoading(true);
        if (
            await numcheck(
                {
                    code: contactUs.code,
                    number: contactUs.number
                },
                setErrors
            )
        ) {
            const res = await contactQueryAction(dispatch, contactUs);
            setBtnLoading(false);
            if (res) {
                setContactUs({
                    fullName: user?.name ?? '',
                    code: user?.phoneNumber?.code ?? defaultDailing_Code,
                    number: user?.phoneNumber?.number ?? '',
                    email: user?.email ?? '',
                    message: ''
                });
            }
        } else {
            setBtnLoading(false);
        }
    };

    useEffect(() => {
        let isValid = true;
        let emailReg = emailRegs;
        let errors = {
            fullName: '',
            number: '',
            email: '',
            message: '',
            code: ''
        };

        if (!fullNameValidate(contactUs.fullName)) {
            isValid = false;
            errors.fullName = t('pleaseEnterFullName');
        }
        if (!emailReg.test(contactUs.email)) {
            isValid = false;
            errors.email = t('pleaseInputYourEmail');
        }

        if (contactUs.message.length === 0) {
            isValid = false;
            errors.message = t('messageIsRequired');
        }
        if (isValid) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
        setErrors(errors);
    }, [contactUs]);

    return (
        <>
            <SetMeta title="contactUsPageTitle" />
            <div className="contact-us">
                <Layout showWhiteNavbar>
                    <div className="contact-top padding">
                        <div className="contact-form">
                            <SecSeperator className="contact-sep" />
                            <h2 className="title">{t('contactUs')}</h2>
                            <p className="subtitle">{t('contactSubtitle')}</p>
                            <form className="form">
                                <div className="form-container">
                                    <span className="contact-input">
                                        <span className="icon">
                                            <User size={24} color="#8b8b8b" />
                                        </span>
                                        <input
                                            type="text"
                                            placeholder={t('enterFullName')}
                                            name="fullName"
                                            value={contactUs.fullName}
                                            onChange={handleInputChange}
                                        />{' '}
                                        {contactUs.fullName && (
                                            <div className="error-label error-message">
                                                {t(errors.fullName)}
                                            </div>
                                        )}
                                    </span>

                                    <span className="contact-input">
                                        <span className="icon">
                                            <EnvelopeSimple
                                                size={24}
                                                color="#8b8b8b"
                                            />
                                        </span>
                                        <input
                                            type="email"
                                            placeholder={t('enteremailAddress')}
                                            name="email"
                                            value={contactUs.email}
                                            onChange={handleInputChange}
                                        />{' '}
                                        {contactUs.email && (
                                            <div className="error-label error-message">
                                                {t(errors.email)}
                                            </div>
                                        )}
                                    </span>

                                    <CountryCodeDropdown
                                        selectName="code"
                                        selectValue={contactUs.code}
                                        onInputChange={(e) => {
                                            let obj = JSON.parse(
                                                JSON.stringify(errors)
                                            );
                                            delete obj.number;
                                            setErrors(obj);
                                            setContactUs({
                                                ...contactUs,
                                                number: returnOnlyNumber(
                                                    e.target.value
                                                )
                                            });
                                        }}
                                        inputName="number"
                                        onSelect={(e) =>
                                            setContactUs((prev) => ({
                                                ...prev,
                                                code: e
                                            }))
                                        }
                                        inputValue={
                                            contactUs.number
                                                ? contactUs.number
                                                : ''
                                        }
                                        error={errors.phoneNumber}
                                    />
                                    <textarea
                                        name="message"
                                        id=""
                                        cols={25}
                                        rows={5}
                                        placeholder={t('yourMessage')}
                                        value={contactUs.message}
                                        onChange={handleInputChange}
                                    ></textarea>

                                    {contactUs.message && (
                                        <p className="error-message">
                                            {t(errors.message)}
                                        </p>
                                    )}
                                    <span className="terms-condt">
                                        {t('byClickingXButton', {
                                            name: t('sendMessage')
                                        })}{' '}
                                        <Link
                                            to={t('/terms-of-use', {
                                                ns: 'routes'
                                            })}
                                            className="termandpolicy"
                                        >
                                            {t('termOfUse')}
                                        </Link>{' '}
                                        {t('and')}{' '}
                                        <Link
                                            to={t('/privacy-policy', {
                                                ns: 'routes'
                                            })}
                                            className="termandpolicy"
                                        >
                                            {t('privacyPolicy')}
                                        </Link>
                                    </span>
                                </div>
                                {loading || btnloading ? (
                                    <LoadingButton buttonText="sending" />
                                ) : (
                                    <button
                                        className="bottom-btn send"
                                        disabled={disabled}
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        {t('send')}
                                    </button>
                                )}
                            </form>
                        </div>
                        <div className="contact-image">
                            <div className="contact-vector">
                                <img src="/images/contact-vector.svg" alt="" />
                            </div>

                            <div className="contact-infos">
                                <div className="seperator"></div>
                                <h3 className="contact-title">
                                    {t('contactInformation')}
                                </h3>
                                <div className="contact-info">
                                    <span className="icon">
                                        <MapPin size={24} color="#ffffff" />
                                    </span>
                                    <p className="contact">
                                        {
                                            contactInformation?.address
                                                ?.localAddress
                                        }
                                        ,{contactInformation.address?.district},
                                        {contactInformation.address?.province},
                                        {contactInformation?.address?.country}
                                    </p>
                                </div>
                                <div className="contact-info">
                                    <span className="icon">
                                        <Phone size={24} color="#ffffff" />
                                    </span>
                                    <p className="contact">
                                        {contactInformation.phoneNumber?.code}{' '}
                                        {contactInformation.phoneNumber?.number}
                                    </p>
                                </div>
                                <div className="contact-info">
                                    <span className="icon">
                                        <EnvelopeSimple
                                            size={24}
                                            color="#ffffff"
                                        />
                                    </span>
                                    <p className="contact">
                                        {contactInformation.email}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="map-container">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3322.3424954973766!2d-7.501843999999999!3d33.6223605!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7cb4eca595829%3A0x1c3550ad176ed55!2sAUTO24.ma!5e0!3m2!1sen!2snp!4v1699610152177!5m2!1sen!2snp"
                            width="600"
                            height="450"
                            title="location"
                            loading="lazy"
                        ></iframe>
                    </div>
                </Layout>
            </div>
        </>
    );
};

export default ContactUs;
