import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { GET_CUSTOMER_GALLERY } from '../../helpers/constants';
import { request } from '../../helpers/request';

export const getCustomerGallery = (dispatch: Dispatch) => {
    return request
        .getCustomerGallery()
        .then((response: AxiosResponse) => {
            dispatch({
                type: GET_CUSTOMER_GALLERY,
                payload: response.data?.customerGalleries
            });
        })

        .catch((err: AxiosError | Error) => {
            if (axios.isAxiosError(err)) {
                console.log(err.response?.data);
            } else {
                console.log(err.message);
            }
        });
};
