import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Tabs } from 'antd';
import { CaretRight } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import SecSeperator from '../components/ui/SecSeperator';
import Layout from '../layout/Layout';
import EditProfiles from '../components/EditProfiles';
import ListYourCar from '../components/ListYourCar';
import Finance from '../components/Finance';
import ResetPw from '../components/ResetPw';
import LogoutModal from '../components/ui/LogoutModal';
import Favourites from '../components/Favourites';

import { useMediaQueryHook } from '../utils/Hooks/MediaQuery';
import { ICombineReducerType } from '../redux/reducers';
import { AuthTypes } from '../redux/types/AuthTypes';
import i18n from '../localization';
import { WISH_LIST_SUCCESS } from '../redux/types/wishListTypes';
import { IMAGE_URL } from '../constants';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const { TabPane } = Tabs;

const Account = (props: any) => {
    const navigate = useNavigate();
    const isloggedIn = useSelector(
        (state: ICombineReducerType) => state.auth.isloggedIn
    );
    const { t } = useTranslation();

    const [logoutModal, setLogoutModal] = useState(false);
    const [activeTab, setActiveTab] = useState('');
    const location = useLocation();
    const dispatch = useDispatch();
    // const currentPath = useLocation();
    let { user } = useSelector((state: ICombineReducerType) => state.auth);

    // useEffect(() => {
    //     let sanitize = currentPath.pathname.split('/').includes('en');
    //     let currentTabName;
    //     if (location.pathname && sanitize) {
    //         currentTabName = location.pathname.slice(11);
    //     } else {
    //         currentTabName = location.pathname.slice(7);
    //     }

    //     setActiveTab(currentTabName);
    // }, [location.pathname, t, i18n.language]);

    useEffect(() => {
        const locationArr = location.pathname.split('/');
        const currentTabName = `/${locationArr[locationArr.length-1]}`

        setActiveTab(currentTabName);
    }, [location.pathname, t, i18n.language]);

    useEffect(() => {
        if (!isloggedIn) {
            const lang = i18n.language;
            if(lang !== "en") {
                navigate(`/${lang}`)
                return;
            }
            navigate('/')
        }
    }, [isloggedIn]);
    const onLogout = () => {
        setLogoutModal(true);
    };

    const cancelHandler = () => {
        setLogoutModal(false);
    };
    const okHandler = () => {
        dispatch({ type: AuthTypes.LOG_OUT });
        dispatch({
            type: WISH_LIST_SUCCESS,
            payload: []
        });
        setLogoutModal(false);
    };

    const handleTabChange = (key: any) => {
        setActiveTab(t('key', { ns: 'routes' }));
        navigate(
            `${t('/account', { ns: 'routes' })}${t(key, { ns: 'routes' })}`
        );
    };
    const TabArray = [
        {
            key: 1,
            imagesrc: '/tabicons/editprofile.svg',
            title: 'editProfile',
            path: t('/edit-profile', { ns: 'routes' }),

            comp: (
                <EditProfiles
                    activeTab={
                        activeTab === t('/edit-profile', { ns: 'routes' })
                    }
                />
            )
        },
        {
            key: 2,
            imagesrc: '/tabicons/sellcar.svg',
            title: 'sellYourCar',
            path: t('/sell-your-car', { ns: 'routes' }),
            comp: <ListYourCar />
        },

        {
            key: 3,
            imagesrc: '/tabicons/financing.svg',
            title: 'financing',
            path: '/financing',
            comp: <Finance />
        },
        {
            key: 4,
            imagesrc: '/tabicons/virtualgarage.svg',
            title: 'favourites',
            path: '/favourites',
            comp: <Favourites />
        },
        {
            key: 6,
            imagesrc: '/tabicons/resetpw.svg',
            title: 'resetPassword',
            path: t('/reset-password', { ns: 'routes' }),
            comp: <ResetPw />
        }
    ];

    return (
        <div className="account-page">
            <Layout
                showWhiteNavbar
                showAccountNav
                headerText={props.showAccount ? 'Account' : 'Back To Account'}
            >
                <div className="account padding">
                    <div className="title-seperator">
                        <SecSeperator />
                        <h2 className="title">{t('myAccount')}</h2>
                    </div>
                    <div className="user-info">
                        <div className="profile-image">
                            <img
                                src={
                                    !!user?.profile
                                        ? IMAGE_URL + user?.profile
                                        : '/images/usericon.webp'
                                }
                                alt={user?.name}
                                onError={addImageFallback}
                            />
                        </div>
                        <div className="user-name-number">
                            <p className="user-name">{user?.name}</p>
                            <p className="user-number">
                                {user?.phoneNumber?.code} &nbsp;
                                {user?.phoneNumber?.number}
                            </p>
                        </div>
                    </div>
                    <div className="account-content">
                        <Tabs
                            activeKey={activeTab}
                            tabPosition="left"
                            onChange={(key) => handleTabChange(key)}
                            className="account-tab"
                        >
                            {TabArray.map((items) => {
                                return (
                                    <TabPane
                                        tab={
                                            <div className="tab-header">
                                                <div className="image-title">
                                                    <div className="icon">
                                                        <img
                                                            src={items.imagesrc}
                                                            alt=""
                                                        />
                                                    </div>
                                                    {t(items.title)}
                                                </div>
                                                <CaretRight
                                                    size={20}
                                                    color="#454545"
                                                    className="tab-right-arrow"
                                                />
                                            </div>
                                        }
                                        key={items.path}
                                    >
                                        {items.comp}
                                    </TabPane>
                                );
                            })}
                        </Tabs>
                        {useMediaQueryHook() &&
                        location.pathname !==
                            t('/account', { ns: 'routes' }) ? null : (
                            <button onClick={onLogout} className="logout-btn">
                                <div className="logout">
                                    <img
                                        src="/tabicons/signout.svg"
                                        alt="signout"
                                    />
                                </div>{' '}
                                {t('logout')}
                            </button>
                        )}
                    </div>
                </div>
            </Layout>

            <LogoutModal
                visible={logoutModal}
                cancelHandler={cancelHandler}
                okHandler={okHandler}
            />
        </div>
    );
};

export default Account;
