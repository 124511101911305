const metaWolof = {
    homePageTitle: "Certified used cars in Ivory Coast with AUTO24",
    homePageDesc: "Used car price, new cars service! Buy reconditioned and certified used cars with 6 to 12 months warranty, all inspected by our auto experts !",
    sellPageTitle: "Sell your car quickly in Ivory Coast - AUTO24",
    sellPageDesc: "Sell your car easily, safely and quickly in Abidjan. We send our inspectors to your home, give you a price in 30 minutes and pay you instantly!",
    buyPageTitle: "Buy certified used cars in Ivory Coast - AUTO24",
    buyPageDesc: "Find the best prices for used cars to buy in Abidjan, Ivory Coast, with 100 points inspection, 5 days refund policy, 6 months warranty and more.",
    financePageTitle: "Finance your car in Ivory Coast - AUTO24",
    financePageDesc: "Get the best financing offers for your used cars with our network of partners in Abidjan, Ivory Coast. Apply and get in touch with our agents quickly.",
    aboutPageTitle: " AUTO24, a new way to buy and sell cars in Africa",
    blogPageTitle: " Automotive news in Ivory Coast with AUTO24",
    blogPageDesc: "Maintenance advice and all the automotive industry news in Ivory Coast with AUTO24.",
    "q&aPageTitle": "AUTO24 - Frequently asked questions ",
    contactUsPageTitle: " AUTO24 - Contact us",
    privacyPolicyPageTitle: " AUTO24 - Privacy policy",
    "terms&usePageTitle": "AUTO24 - Terms & conditions",
    insurePageTitle: "Insure your car in Ivory Coast - AUTO24",
    insurePageDesc: "Get the best insurance offers for your used cars with our network of partners in Abidjan, Ivory Coast. Apply and get in touch with our agents quickly.",
    carDetailPageTitle: "Buy {{make}},{{price}} FCFA - AUTO24",
    carDetailPageDesc: "Buy a {{make}} certified used car at {{PRIX}} FCFA with AUTO24, Abidjan, Ivory Coast",
    buyPageDescWhenBrand: "Best prices and services for {{BRAND}} used cars in Abidan, Ivory Coast. Refund policy, warranty, insurance, maintenance, and all included!"
}

export default metaWolof;