import { useTranslation } from 'react-i18next';

const ListingCard = ({
    color,
    title,
    subtitle,
    btntext,
    imagesrc,
    onClick
}: any) => {
    const { t } = useTranslation();
    return (
        <div className={`listing-card ${color}`}>
            <h3 className="card-title">{t(title)}</h3>
            <p className="card-content">{t(subtitle)}</p>
            <button
                className={`bottom-btn loan-btn ${color}`}
                onClick={onClick}
            >
                {t(btntext)}
            </button>
            <div className="listing-image">
                <img src={imagesrc} alt="" />
            </div>
        </div>
    );
};

export default ListingCard;
