import { useEffect, useState } from 'react';
import { $FIXME } from '../../constants';

export default function useOnScreen(ref: $FIXME) {
    const [isIntersecting, setIntersecting] = useState(false);
    // const [ratio, setRatio] = useState(0);

    const observer = new IntersectionObserver(([entry]) => {
        // setRatio(entry.intersectionRatio);
        // if (entry.isIntersecting && entry.intersectionRatio >= 0.004) {
        setIntersecting(entry?.isIntersecting);
        // }
    });

    useEffect(() => {
        ref?.current && observer.observe(ref?.current);
        // Remove the observer as soon as the component is unmounted
        return () => {
            observer.disconnect();
        };
    }, []);

    return isIntersecting;
}
