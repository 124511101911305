import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Blog from '../components/Blog';
import FinanceTop from '../components/FinanceTop';
import FinancingPartner from '../components/FinancingPartner';
import LoanCalculator from '../components/LoanCalculator';
import Questionnaire from '../components/Questionnaire';
import WhyFinance from '../components/WhyFinance';
import Layout from '../layout/Layout';

import { financePartners } from '../redux/actions/finacePartnerAction';
import { pagesContent, resetPagesContent } from '../redux/actions/pagesAction';
import { ICombineReducerType } from '../redux/reducers';
import { SetMeta } from '../utils/Helper/jsHelpers';
import LoanPaymentSimulator from './LoanPaymentSimulator';

const Finance = () => {
    const dispatch = useDispatch();

    const { pages } = useSelector((state: ICombineReducerType) => state.pages);
    const { financingPartners } = useSelector(
        (state: ICombineReducerType) => state.financePartners
    );
    useEffect(() => {
        pagesContent(dispatch, 'finance');
        financePartners(dispatch, 'finance');
        return () => {
            resetPagesContent(dispatch);
        }
    }, []);
    return (
        <>
            <SetMeta title="financePageTitle" desc="financePageDesc" keywords='financePageKeywords'
            properties={[
                {
                    key: "og:title",
                    value: "financePageTitleOg"
                },
                {
                    key: "og:description",
                    value: "financePageDescOg"
                },
                {
                    key: "og:keywords",
                    value: "financePageKeywordOg"
                }
            ]}
            />
            <div className="Finance">
                <Layout showWhiteNavbar>
                    <FinanceTop
                        pages={pages}
                        image="/images/financeimage.webp"
                    />
                    <WhyFinance />
                    {/* <LoanCalculator /> */}
                    <LoanPaymentSimulator />
                    {financingPartners && financingPartners.length > 0 && (
                    <FinancingPartner
                        title="ourFinancingPartners"
                        financingPartners={financingPartners}
                        subtitles="aboutFinanceSub"
                        class="finance"
                    />
                    )}
                    <Blog />
                    <Questionnaire />
                </Layout>
            </div>
        </>
    );
};

export default Finance;
