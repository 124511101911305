import { useTranslation } from 'react-i18next';

const Services = () => {
    const { t } = useTranslation();
    return (
        <div className="services">
            <div className="service-container padding">
                {ServiceArray.map((items, index) => {
                    return (
                        <div className="service-card-container" key={index}>
                            <ServiceComponent
                                imagesrc={items.imagesrc}
                                alt={items.alt}
                                title={
                                    typeof items.title === 'string'
                                        ? t(items.title)
                                        : t(items.title.key, {
                                              number: items.title.number
                                          })
                                }
                                subtitle={t(items.subtitle)}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Services;

const ServiceComponent = (props: any) => {
    return (
        <div className="service-component">
            <div className="service-image">
                <img src={props.imagesrc} alt={props.alt} />
            </div>
            <div className="service-content">
                <h3 className="service-title">{props.title}</h3>
                <p className="service-desc">{props.subtitle}</p>
            </div>
        </div>
    );
};

const ServiceArray = [
    {
        imagesrc: '/images/moneyback.webp',
        alt: 'moneyback',
        title: 'refundPolicy',
        subtitle: 'moneybackSubtitle'
    },
    {
        imagesrc: '/images/calenderblack.webp',
        alt: 'warrenty',
        title: 'warrentyTitle',
        subtitle: 'warrentySubtitle'
    },
    {
        imagesrc: '/images/wrench.webp',
        alt: 'inspection points',
        title: { key: 'xInspectionPoints', number: 100 },
        subtitle: 'inspectionPointsSubtitle'
    },
    {
        imagesrc: '/images/assistance.webp',
        alt: 'road side assistance',
        title: 'roadSideAssistanceTitle',
        subtitle: 'roadSideAssistanceSubtitle'
    },
    {
        imagesrc: '/images/delivery.webp',
        alt: 'delivery',
        title: 'deliveryTitle',
        subtitle: 'deliverySubtitle'
    }
];
