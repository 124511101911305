import { SpinnerGap } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import { $FIXME } from '../../constants';

const LoadingButton = (props: $FIXME) => {
    const { t } = useTranslation();

    return (
        <button className="loading-btn bottom-btn" disabled>
            <SpinnerGap size={32} weight="bold" className="rotate" />
            {t(props.buttonText)}
        </button>
    );
};

export default LoadingButton;
