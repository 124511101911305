import { useEffect, useState } from 'react';
import axios from 'axios';
import { InstagramLogo } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import SecSeperator from './ui/SecSeperator';
import { Api } from '../redux/Api';
import { instagramUrl } from '../constants';

interface IInstaGramPost {
    id: string;
    media_type: string;
    thumbnail_url: string;
    media_url: string;
    permalink: string;
}

const InstaGram = () => {
    const { t } = useTranslation();

    const [posts, setPosts] = useState<IInstaGramPost[]>([]);

    useEffect(() => {
        Api.get(instagramUrl)
            .then((response) => axios.get(response.data.url))
            .then((response) => setPosts(response.data.data))
            .catch((error) =>
                console.error('Error fetching Instagram posts:', error)
            );
    }, []);

    const openInstaInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="follow-instagram padding">
            <SecSeperator className="follow-sep" />
            <h2 className="title">{t('instaTitle')}</h2>
            <p className="subtitle">{t('instaSubTitle')}</p>
            <div className="insta-container">
                {posts.map((item, index) => {
                    return (
                        <div className="image-container" key={index}>
                            <img
                                src={item.thumbnail_url ?? item?.media_url}
                                alt={'insta'}
                            />
                            <div
                                className="on-hover"
                                onClick={() =>
                                    openInstaInNewTab(`${item?.permalink}`)
                                }
                            >
                                <InstagramLogo size={32} weight="bold" />
                            </div>
                        </div>
                    );
                })}
                {/* <div
                    className="powr-instagram-feed"
                    id="38d504c0_1669019960"
                ></div> */}
            </div>

            <button
                className="bottom-btn follow-btn"
                onClick={() =>
                    openInstaInNewTab(`${process.env.REACT_APP_INSTAGRAM_URL}`)
                }
            >
                {t('instaTitle')}
            </button>
        </div>
    );
};

export default InstaGram;
