import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FeatureCard from './ui/FeatureCard';
import SecSeperator from './ui/SecSeperator';

import { ICombineReducerType } from '../redux/reducers';
import { $FIXME } from '../constants';
import scrollToRef from '../utils/Hooks/ScrollToElement';
import GetFeaturedList from '../utils/Hooks/GetFeaturedList';

const Features = (props: $FIXME) => {
    const [faded, setFaded] = useState(true);
    const handleFade = () => {
        setFaded(!faded);
    };

    const { car } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const { t } = useTranslation();

    const carFeatures = useRef(null);

    useEffect(() => {
        if (props.activeNavClick === 'features') {
            scrollToRef(carFeatures);
        }
    }, [props.activeNavClick]);

    const filteredData = GetFeaturedList(car);

    return filteredData.length ? (
        <div
            className={props.hasHash ? 'features margin-top' : 'features'}
            id="features"
            ref={carFeatures}
        >
            <SecSeperator />
            <h2 className="title">{t('features')}</h2>
            <div className="features-container">
                <div className="features-categories">
                    <div className="features-category">
                        {filteredData.map((items, index) => {
                            // if (faded) {
                            //     if (index < 8) {
                            //         return (
                            //             <div
                            //                 className={
                            //                     index >= 8 &&
                            //                     faded &&
                            //                     filteredData.length > 9
                            //                         ? 'category-card faded'
                            //                         :
                            //                         'category-card'
                            //                 }
                            //                 key={index}
                            //             >
                            //                 <FeatureCard
                            //                     title={t(items.title)}
                            //                     value={items.value}
                            //                 />
                            //             </div>
                            //         );
                            //     }
                            // } else {
                            return (
                                <div className="category-card" key={index}>
                                    <FeatureCard
                                        title={t(items.title)}
                                        value={items.value}
                                    />
                                </div>
                            );
                            // }
                        })}
                    </div>
                </div>
            </div>
            {/* {filteredData.length > 8 ? (
                <button className="show-more" onClick={handleFade}>
                    {faded ? t('showMoreFeatures') : t('showLess')}
                </button>
            ) : null} */}
        </div>
    ) : null;
};

export default Features;
