import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { CaretDown, CaretUp, CheckCircle } from 'phosphor-react';

import { $FIXME } from '../../../../constants';
import AccordionOption from './AccordionOption';

const AccordionItem = (props: $FIXME) => {
    const { title, icons, options = [], count = 0 } = props;
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    return (
        <div className="featured-card-container">
            <div className="feature-card" onClick={() => setShow(!show)}>
                <div className="feature-card-header">
                    <div className="feature-icon">
                        <img src={icons} alt="icon" />
                    </div>
                    <h3 className="feature-title">{t(title)}</h3>
                </div>

                <div className="feature-verified">
                    {count ? (
                        <div className="number">{count}</div>
                    ) : (
                        <CheckCircle size={24} color="#009E60" weight="bold" />
                    )}

                    {show ? (
                        <CaretUp size={20} color="#454545" weight="bold" />
                    ) : (
                        <CaretDown size={20} color="#454545" weight="bold" />
                    )}
                </div>
            </div>
            <div className={show ? 'show-feature show-drops' : 'show-feature'}>
                <ul>
                    {options?.map((item: $FIXME, index: number) => {
                        if (
                            item?.value ||
                            item?.images?.length ||
                            (item.title === 'interiorControlsAndSwitches' &&
                                item?.value?.length)
                        ) {
                            return <AccordionOption {...item} key={index} />;
                        }
                    })}
                </ul>
            </div>
        </div>
    );
};

export default AccordionItem;
