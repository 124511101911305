const metaKinyarwanda = {
    homePageTitle: "AUTO24.rw - Imodoka Zikoreshwa zemerewe mu Rwanda | Gura no Kugurisha mu Rwanda",
    homePageDesc: "Isoko yawe yo mu Rwanda yo gurisha no gukomera imodoka zikoreshwa zemerewe. Reba ubusobanuro bw'ibyerekeye imodoka bwawe, gukomera neza no kugurisha cyane.",
    homePageKeywords: "Imodoka Zikoreshwa, Gura Imodoka Rwanda, Kugurisha Imodoka Rwanda, Isoko rya AUTO24 mu Rwanda, Imodoka z'ubwoko bwiza, Isoko rya Imodoka mu Rwanda",
    homePageTitleOg: "AUTO24.rw - Imodoka Zikoreshwa zemerewe mu Rwanda | Gura no Kugurisha mu Rwanda",
    homePageDescOg: "Isoko yawe yo mu Rwanda yo gurisha no gukomera imodoka zikoreshwa zemerewe. Reba ubusobanuro bw'ibyerekeye imodoka bwawe, gukomera neza no kugurisha cyane.",
    homePageKeywordsOg: "Imodoka Zikoreshwa, Gura Imodoka Rwanda, Kugurisha Imodoka Rwanda, Isoko rya AUTO24 mu Rwanda, Imodoka z'ubwoko bwiza, Isoko rya Imodoka mu Rwanda",
    buyPageTitle: "Gura Imodoka Zikoreshwa Zemerewe mu Rwanda | AUTO24.rw",
    buyPageDesc: "Reba imodoka zikoreshwa zemerewe zishoboka ku isoko rya AUTO24.rw . Dufasha kugira ngo uhite ubona imodoka izagufasha mu bucuruzi n'ubuzima bwawe mu Rwanda.",
    buyPageKeywords: "Gura Imodoka Rwanda, Imodoka Zikoreshwa Zemerewe, AUTO24 Rwanda, Kugura Imodoka, Imodoka Zishoboka",
    buyPageTitleOg: "Gura Imodoka Zikoreshwa Zemerewe mu Rwanda | AUTO24.rw",
    buyPageDescOg: "Reba imodoka zikoreshwa zemerewe zishoboka ku isoko rya AUTO24.rw . Dufasha kugira ngo uhite ubona imodoka izagufasha mu bucuruzi n'ubuzima bwawe mu Rwanda.",
    buyPageKeywordsOg: "Gura Imodoka Rwanda, Imodoka Zikoreshwa Zemerewe, AUTO24 Rwanda, Kugura Imodoka, Imodoka Zishoboka",
    sellPageTitle: "Kugurisha Imodoka yawe Nk'uko Bishakiye mu Rwanda | AUTO24.rw",
    sellPageDesc: "Uri gufasha kugurisha imodoka yawe? AUTO24.rw ifasha kugurisha imodoka yawe mu buryo bworoshye, tugafata ibyerekeye imodoka kandi tugagurisha vuba.",
    sellPageKeywords: "Kugurisha Imodoka Rwanda, Kugurisha Imodoka Zikoreshwa, AUTO24 Kugurisha, Ibisobanuro by'Imodoka",
    sellPageTitleOg: "Kugurisha Imodoka yawe Nk'uko Bishakiye mu Rwanda | AUTO24.rw",
    sellPageDescOg: "Uri gufasha kugurisha imodoka yawe? AUTO24.rw ifasha kugurisha imodoka yawe mu buryo bworoshye, tugafata ibyerekeye imodoka kandi tugagurisha vuba.",
    sellPageKeywordsOg: "Kugurisha Imodoka Rwanda, Kugurisha Imodoka Zikoreshwa, AUTO24 Kugurisha, Ibisobanuro by'Imodoka",
    aboutPageTitle: "Ku Ibisobanuro bya AUTO24.rw - Isoko ry'Imodoka Zikoreshwa Zemerewe Iyobewe mu Rwanda",
    aboutPageDesc: "Menya ibisobanuro bya AUTO24.rw , isoko rya imodoka zikoreshwa zemerewe ryiyobowe mu Rwanda. Dufasha kugurisha imodoka z'ubwoko bwiza.",
    aboutPageKeywords: "Ibisobanuro bya AUTO24 Rwanda, Isoko ry'Imodoka Zikoreshwa, Isoko ry'Imodoka Riyobowe, Isoko ry'Imodoka mu Rwanda",
    aboutPageTitleOg: "Ku Ibisobanuro bya AUTO24.rw - Isoko ry'Imodoka Zikoreshwa Zemerewe Iyobewe mu Rwanda",
    aboutPageDescOg: "Menya ibisobanuro bya AUTO24.rw , isoko rya imodoka zikoreshwa zemerewe ryiyobowe mu Rwanda. Dufasha kugurisha imodoka z'ubwoko bwiza.",
    aboutPageKeywordsOg: "Ibisobanuro bya AUTO24 Rwanda, Isoko ry'Imodoka Zikoreshwa, Isoko ry'Imodoka Riyobowe, Isoko ry'Imodoka mu Rwanda",
    financePageTitle: "Kugura Imodoka yawe mu buryo bworoshye na AUTO24.rw",
    financePageDesc: "Dufasha kugira ngo wohereze imodoka yawe mu buryo bworoshye, nta kibazo. Fata isoko rya AUTO24.rw rizagufasha kugura imodoka yawe.",
    financePageKeywords: "Amansuzi yo Gura Imodoka, AUTO24 Amansuzi, Amansuzi Zishoboka",
    financePageTitleOg: "Kugura Imodoka yawe mu buryo bworoshye na AUTO24.rw",
    financePageDescOg: "Dufasha kugira ngo wohereze imodoka yawe mu buryo bworoshye, nta kibazo. Fata isoko rya AUTO24.rw rizagufasha kugura imodoka yawe.",
    financePageKeywordsOg: "Amansuzi yo Gura Imodoka, AUTO24 Amansuzi, Amansuzi Zishoboka",
    insurePageTitle: "Gutanga Umutekano w'Imodoka yawe na AUTO24.rw",
    insurePageDesc: " Gufata umutekano w'Imodoka yawe ariko ariya muntu wewe. Fata amafaranga yo gutanga umutekano w'Imodoka yawe na AUTO24.rw",
    insurePageKeywords: "Umutekano w'Imodoka, AUTO24 Umutekano, Umutekano mu Rwanda",
    insurePageTitleOg: "Gutanga Umutekano w'Imodoka yawe na AUTO24.rw",
    insurePageDescOg: " Gufata umutekano w'Imodoka yawe ariko ariya muntu wewe. Fata amafaranga yo gutanga umutekano w'Imodoka yawe na AUTO24.rw",
    insurePageKeywordsOg: "Umutekano w'Imodoka, AUTO24 Umutekano, Umutekano mu Rwanda",
    blogPageTitle: "Inkuru za AUTO24.rw - Amakuru y'Ibisobanuro ku Imodoka mu Rwanda",
    blogPageDesc: "Menya amakuru y'ibisobanuro ku imodoka, inama, no ku isoko ry'Imodoka mu Rwanda.",
    blogPageKeywords: "AUTO24 Inkuru, Amakuru y'Imodoka, Amakuru yo Ku Rwanda, Amakuru y'Ibisobanuro",
    blogPageTitleOg: "Inkuru za AUTO24.rw - Amakuru y'Ibisobanuro ku Imodoka mu Rwanda",
    blogPageDescOg: "Menya amakuru y'ibisobanuro ku imodoka, inama, no ku isoko ry'Imodoka mu Rwanda.",
    blogPageKeywordsOg: "AUTO24 Inkuru, Amakuru y'Imodoka, Amakuru yo Ku Rwanda, Amakuru y'Ibisobanuro",
    qNaPageTitle: "AUTO24 - Frequently asked questions ",
    contactUsPageTitle: " AUTO24 - Contact us",
    privacyPolicyPageTitle: " AUTO24 - Privacy policy",
    termsNusePageTitle: "AUTO24 - Terms & conditions",
    carDetailPageTitle: "Buy {{make}},{{price}} RWF - AUTO24",
    carDetailPageDesc: "Buy a {{make}} certified used car at {{PRIX}} RWF with AUTO24, Abidjan, Ivory Coast",
    buyPageDescWhenBrand: "Best prices and services for {{BRAND}} used cars in Abidan, Ivory Coast. Refund policy, warranty, insurance, maintenance, and all included!"
}

export default metaKinyarwanda;