import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';

const Buysell = () => {
    let navigate = useNavigate();
    const { t } = useTranslation();
    const handleSell = () => {
        navigate(t('/sell-car', { ns: 'routes' }));
    };
    const handleListing = () => {
        navigate(t('/buy-cars', { ns: 'routes' }));
    };
    return (
        <div className="buy-sell">
            <SecSeperator borderColor="#ffffff" backgroundColor="#ffffff" />
            <h2 className="title">{t('buySellTitle')}</h2>
            <p className="subtitle">{t('buySellSubtitle')}</p>
            <div className="buy-sell-btns padding">
                <button className="bottom-btn" onClick={handleListing}>
                    {t('buyaCar')}
                </button>
                <button className="bottom-btn" onClick={handleSell}>
                    {t('sellYourCar')}
                </button>
            </div>
        </div>
    );
};

export default Buysell;
