import { useLayoutEffect, useState } from 'react';

// Restrict value to be between the range [0, value]
const clamp = (value: number) => Math.max(0, value);

// Check if number is between two values
const isBetween = (value: number, floor: number, ceil: number) =>
    value >= floor && value <= ceil;

// ids - the list of sections IDs that we want to spy
// offset - optional, offset from page top, by default set to 0
const useScrollspy = (ids: string[], offset: number = 0) => {
    const [activeId, setActiveId] = useState('');

    useLayoutEffect(() => {
        const listener = () => {
            const scroll = window.pageYOffset;

            const position = ids
                .map((id) => {
                    const element = document.getElementById(id);

                    if (!element) return { id, top: -1, bottom: -1 };

                    const rect = element.getBoundingClientRect();
                    const top = clamp(rect.top + scroll - offset);
                    const bottom = clamp(rect.bottom + scroll - offset);

                    return { id, top, bottom };
                })
                .find(({ top, bottom }) => isBetween(scroll, top, bottom));

            setActiveId(position?.id || '');
        };

        listener();

        window.addEventListener('resize', listener);
        window.addEventListener('scroll', listener);

        return () => {
            window.removeEventListener('resize', listener);
            window.removeEventListener('scroll', listener);
        };
    }, [ids, offset]);

    return activeId;
};
export default useScrollspy;

//what it does
// Calculating the top and bottom positions of spied sections
// Checking if current scroll position is between these two values
// Returning id of section which is currently in the viewport
// Repeating the whole process on each scroll and resize event (since content height might change on window resize)
