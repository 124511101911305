import { ALL_LANGUAGES } from "../../constants"
import metaArabic from "./arabic";
import getMetaEnglish from "./english";
import getMetaFrench from "./french";
import metaKinyarwanda from "./kinyarnwanda";
import metaXhosa from "./xhosa";
import metaZulu from "./zulu";
import metaWolof from "./wolof";
import { getTopLevelDomain } from "../../utils/translation";

const topLevelDomain = getTopLevelDomain();
const auto24Domain = `AUTO24.${topLevelDomain}`;

const metaLang = {
    [ALL_LANGUAGES.english.value]: getMetaEnglish(auto24Domain),  
    [ALL_LANGUAGES.french.value]: getMetaFrench(auto24Domain), 
    [ALL_LANGUAGES.arabic.value]: metaArabic,  
    [ALL_LANGUAGES.zulu.value]: metaZulu, 
    [ALL_LANGUAGES.xhosa.value]: metaXhosa,
    [ALL_LANGUAGES.kinyarnwanda.value]: metaKinyarwanda, 
    [ALL_LANGUAGES.wolof.value]: metaWolof, 
}

export default metaLang;