import SecSeperator from './ui/SecSeperator';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ICombineReducerType } from '../redux/reducers';
import { $FIXME, IMAGE_URL } from '../constants';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import Loading from './ui/Loading';
import { PopularCard } from './PopularBlogs';

import { formatDate } from './BlogDetail';
import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';
import { getAllBlogs } from '../redux/actions/blogActions';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const Blog = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { blogs } = useSelector((state: ICombineReducerType) => state.blogs);
    useEffect(() => {
        if (!blogs.length) getAllBlogs(dispatch);
    }, []);
    const { t } = useTranslation();

    return blogs.length ? (
        <div className="popular-blogs blog padding">
            <SecSeperator />
            <h2 className="title">
                {props.title ? t(props.title) : t('blogTitle')}
            </h2>
            <p className="subtitle">
                {props.subtitle ? t(props.subtitle) : t('blogSubtitle')}
            </p>
            <div className="popular-blogs__container">
                {blogs?.slice(0, 4).map((blog: $FIXME) => {
                    return (
                        <div className="blog-holder" key={blog._id}>
                            <Link
                                to={`${t('/blog', { ns: 'routes' })}/${
                                    blog.slug
                                }`}
                            >
                                <PopularCard
                                    image={blog?.image}
                                    title={
                                        contentAccordToCurrentLang(blog)?.title
                                    }
                                    desc={
                                        contentAccordToCurrentLang(blog)
                                            ?.description
                                    }
                                    type={
                                        contentAccordToCurrentLang(
                                            blog.category
                                        ).category
                                    }
                                    date={blog?.createdAt}
                                    authors={blog?.author?.name}
                                />
                            </Link>
                        </div>
                    );
                })}
            </div>
            <button
                className="bottom-btn primary big-font"
                onClick={() => navigate(t('/blog', { ns: 'routes' }))}
            >
                {t('exploreBlogs')}
            </button>
        </div>
    ) : (
        <></>
    );
};

export default Blog;

const BlogCard = (props: any) => {
    return (
        <div className="blog-card">
            <div className="blog-card-image">
                <img
                    src={IMAGE_URL + props.cover}
                    alt={props.username}
                    onError={addImageFallback}
                />
            </div>
            <div className="blog-body">
                <h3 className="blog-title">{props.title}</h3>
                <p className="blog-content">
                    {props.content?.replace(/<[^>]+>/g, '')}
                </p>
                <div className="profile-container">
                    <p className="author-name">
                        {props.author ?? 'Auto24 Team'}
                    </p>
                    <p className="user-name">
                        {formatDate(props.date.substring(0, 10))}
                    </p>
                </div>
            </div>
        </div>
    );
};
