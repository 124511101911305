import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';

import { IMAGE_URL } from '../constants';
import { ICombineReducerType } from '../redux/reducers';
import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const SearchByCategory = () => {
    const [active, setActive] = useState<string>();
    const [activebodyType, setActivebodyType] = useState<string>();

    const bodyTypes = useSelector(
        (state: ICombineReducerType) => state.car.availableBodyTypes
    );

    const { carAvailableBrands } = useSelector(
        (state: ICombineReducerType) => state.carBrands
    );
    const { t } = useTranslation(['hero']);

    const navigate = useNavigate();

    const searchedList = () => {
        navigate(
            `${t('/buy-cars', { ns: 'routes' })}?${
                !!activebodyType ? `&bodyType=${activebodyType}` : ''
            }${!!active ? `&brand=${active}` : ''}`
        );
    };

    return (
        <div className="search-by-category-wrapper">
            <div className="search-by-category">
                <SecSeperator />
                <h2 className="title">{t('searchByCategory')}</h2>
                <p className="subtitle">{t('searchCarByTheirBodyType')}</p>
                <div className="search-category-bodytype">
                    <div className="body-type-items">
                        {bodyTypes.map((bodyType, index) => {
                            return (
                                <div
                                    key={bodyType.slug}
                                    onClick={() =>
                                        setActivebodyType(bodyType.slug)
                                    }
                                    className={
                                        activebodyType === bodyType.slug
                                            ? 'body-type-item active'
                                            : 'body-type-item'
                                    }
                                >
                                    <div className="bodytype-image">
                                        <img
                                            src={IMAGE_URL + bodyType.image}
                                            alt={bodyType.bodyType}
                                            onError={addImageFallback}
                                        />
                                    </div>
                                    <p className="bodytype-name">
                                        {contentAccordToCurrentLang(bodyType)
                                            .bodyType ?? 'n/a'}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="search-category-brandname">
                    <p className="subtitle">{t('searhcarbybrands')}</p>
                    <div className="brand-name-items">
                        {carAvailableBrands.map((brand, index) => {
                            return (
                                <div
                                    className={
                                        active === brand.slug
                                            ? 'brand-image active'
                                            : 'brand-image'
                                    }
                                    key={index}
                                    onClick={() => setActive(brand.slug)}
                                >
                                    <div className="image-container">
                                        <img
                                            src={IMAGE_URL + brand.image}
                                            alt={brand.brand}
                                            onError={addImageFallback}
                                        />
                                    </div>
                                    <p className=" brand-name">
                                        {brand?.brand}
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <button className="bottom-btn" onClick={searchedList}>
                    {t('common:showCars')}
                </button>
            </div>
        </div>
    );
};

export default SearchByCategory;
