import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import AuthFlow from '../components/AuthFlow/AuthFlow';
import { $FIXME } from '../constants';
import { getContactInfo } from '../redux/actions/contactActions';
import { ICombineReducerType } from '../redux/reducers';
import useOnScreen from '../utils/Hooks/ViewPortDetect';

type IFooterProps = {
    openAuthModal?: $FIXME;
    props?: $FIXME;
    setIsFooterVisible: $FIXME;
};

export const SocialIcons = () => {
    const { social } = useSelector(
        (state: ICombineReducerType) => state.contact.contact
    );
    return (
        <>
            {!!social?.facebook && (
                <a
                    rel="noopener noreferrer"
                    href={social.facebook}
                    target="_blank"
                >
                    <img
                        src="/images/facebook-circle-fill.svg"
                        alt="facebook"
                    />
                </a>
            )}
            {!!social?.linkedIn && (
                <a
                    rel="noopener noreferrer"
                    href={social.linkedIn}
                    target="_blank"
                >
                    <img src="/images/linkedin-fill.svg" alt="linkedin" />
                </a>
            )}
            {!!social?.twitter && (
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={social.twitter}
                >
                    <img src="/images/twitter.svg" alt="twitter" />
                </a>
            )}{' '}
            {!!social?.tiktok && (
                <a
                    rel="noopener noreferrer"
                    href={social.tiktok}
                    target="_blank"
                >
                    <img src="/images/tiktok.svg" alt="youtube" />
                </a>
            )}
            {!!social?.instagram && (
                <a
                    rel="noopener noreferrer"
                    href={social.instagram}
                    target="_blank"
                >
                    <img src="/images/instagram-fill.svg" alt="instagram" />
                </a>
            )}
            {!!social?.youtube && (
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={social.youtube}
                >
                    <img src="/images/youtube.svg" alt="youtube" />
                </a>
            )}
            {!!social?.whatsapp && (
                <a
                    rel="noopener noreferrer"
                    href={social?.whatsapp}
                    target="_blank"
                >
                    <img src="/images/whatsapp-fill.svg" alt="whatsapp" />
                </a>
            )}
        </>
    );
};
const Footer: React.FC<IFooterProps> = (props: $FIXME) => {
    const currentLocation = useLocation();

    const [showAuthModal, setShowAuthModal] = useState(false);
    const { map, email, phoneNumber, address, copyright_details, footer_details } = useSelector(
        (state: ICombineReducerType) => state.contact.contact
    );
    const { isloggedIn } = useSelector(
        (state: ICombineReducerType) => state.auth
    );
    const { carAvailableBrands } = useSelector(
        (state: ICombineReducerType) => state.carBrands
    );
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const footerRef = useRef(null);
    const isVisible = useOnScreen(footerRef);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!address) getContactInfo(dispatch);
    }, []);
    useEffect(() => {
        props.setIsFooterVisible(isVisible);
    }, [isVisible]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);
    const changeModal = () => {
        setShowAuthModal(!showAuthModal);
    };
    const copyToClipboard = (str: any) => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };
    const sendTo = (item: $FIXME) => {
        if (item.items === 'account' && !isloggedIn) {
            setShowAuthModal(!showAuthModal);
        } else {
            navigate({ pathname: t(item.path, { ns: 'routes' }) });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };
    function handleClick(brand: any) {
        navigate({
            pathname: t('/buy-cars', { ns: 'routes' }),
            search: `?brand=${brand.slug}`
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    const Quicklinks = [
        {
            items: 'FAQ',
            path: '/faqs'
        },
        {
            items: 'finance',
            path: '/finance'
        },
        {
            items: 'contactUs',
            path: '/contact-us'
        },
        {
            items: 'aboutUs',
            path: '/about'
        },
        {
            items: 'account',
            path: `${t('/account', { ns: 'routes' })}${t('/edit-profile', {
                ns: 'routes'
            })}`
        },
        {
            items: 'sell',
            path: '/sell-car'
        },
        {
            items: 'buy',
            path: '/buy-cars'
        },

        {
            items: 'blogs',
            path: '/blog'
        },
        {
            items: 'termOfUse',
            path: '/terms-of-use'
        },
        {
            items: 'privacyPolicy',
            path: '/privacy-policy'
        }
    ];

    const getFooterText = useCallback(() => {
        if(footer_details) {
            let currentLang = "";
            if(currentLocation?.pathname.split('/').includes('en'))
                currentLang = "en"
            else if(currentLocation?.pathname.split('/').includes('ar'))
                currentLang = "ar"
            else
                currentLang = "fr"

            const footerDetail = footer_details.find((item) => item.lang === currentLang);
            return footerDetail?.footer
        }
        return ""
    }, [footer_details, currentLocation?.pathname])

    const getCopyrightText = useCallback(() => {
        if(copyright_details) {
            let currentLang = "";
            if(currentLocation?.pathname.split('/').includes('en'))
                currentLang = "en"
            else if(currentLocation?.pathname.split('/').includes('ar'))
                currentLang = "ar"
            else
                currentLang = "fr"

            const footerDetail = copyright_details.find((item) => item.lang === currentLang);
            if(footerDetail)
                return footerDetail ? footerDetail.copyright : ""
            return ""
        }
        return ""
    }, [copyright_details, currentLocation?.pathname])

    const carBrandListDesktop = () => {
        let carGroup = carAvailableBrands.reduce(
            (r, e, i) =>
                (i % 2
                    ? (r[r.length - 1] as any).push(e)
                    : (r.push as any)([e])) && r,
            []
        );
        return (
            <>
                {carGroup.map((ele: any, i) => {
                    return (
                        <ul key={i} className="footer-lists">
                            {ele.map((e: any, index: any) => (
                                <li
                                    key={index}
                                    onClick={() => handleClick(e)}
                                    className="footer-list"
                                >
                                    <span>{t(e.brand)}</span>
                                    <span className="seperator"></span>
                                </li>
                            ))}
                        </ul>
                    );
                })}
            </>
        );
    };
    const carBrandListInMobile = () => {
        let carGroup = carAvailableBrands.reduce(
            (r, e, i) =>
                (i % 3
                    ? (r[r.length - 1] as any).push(e)
                    : (r.push as any)([e])) && r,
            []
        );
        return (
            <>
                {carGroup.map((ele: any, i) => {
                    return (
                        <ul key={i} className="footer-lists">
                            {ele.map((e: any, index: any) => (
                                <li
                                    key={index}
                                    onClick={() => handleClick(e)}
                                    className="footer-list"
                                >
                                    <span>{t(e.brand)}</span>
                                    <span className="seperator"></span>
                                </li>
                            ))}
                        </ul>
                    );
                })}
            </>
        );
    };

    return (
        <>
            <div className="footer-container" ref={footerRef}>
                <div className="footer padding">
                    <div className="footer-top">
                        <div className="footer-top-top">
                            <div className="footer-logo-container">
                                <img
                                    src="/images/auto24-logo.webp"
                                    alt="auto24"
                                />
                            </div>
                            {/* <p>{t('footerInfo')}</p> */}
                            <p dir={ i18n.language === "ar" ? "rtl" : "ltr" }>{getFooterText()  }</p>
                        </div>
                        <div className="footer-top-available">
                            <h4 className="footer-subtitle">
                                {t('availableNowOn')}
                            </h4>
                            <div className="footer-top-btn-holder">
                                <Link to="#">
                                    <img
                                        src="/images/googleplay.svg"
                                        alt="googleplay"
                                    />
                                </Link>
                                <Link to="#">
                                    <img src="/images/appstore.svg" alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="footer-content">
                        <div className="footer-brands">
                            <div className="border-top"></div>
                            <h4 className="footer-subtitle">
                                {t('ourBrands')}
                            </h4>
                            <div className="footer-brand-lists">
                                {carBrandListInMobile()}
                            </div>
                            <div className="footer-brand-lists-desktop">
                                {carBrandListDesktop()}
                            </div>
                        </div>
                        <div className="footer-quick-links">
                            <div className="border-top"></div>
                            <h4 className="footer-subtitle">
                                {t('QuickLinks')}
                            </h4>
                            <div className="quick-links">
                                <ul className="footer-lists">
                                    {Quicklinks.slice(0, 4).map(
                                        (items, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className="footer-list-quick"
                                                    onClick={() =>
                                                        sendTo(items)
                                                    }
                                                >
                                                    {t(items.items)}
                                                </li>
                                            );
                                        }
                                    )}
                                    <li
                                    className="footer-list-quick"
                                    >
                                        <a
                                            rel="noopener noreferrer"
                                            href="https://apply.workable.com/auto24/"
                                            target="_blank"
                                        >
                                            {t('careers')}
                                        </a>
                                    </li>
                                </ul>
                                <ul className="footer-lists">
                                    {Quicklinks.slice(4, 8).map(
                                        (items, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className="footer-list-quick"
                                                    onClick={() =>
                                                        sendTo(items)
                                                    }
                                                >
                                                    {t(items.items)}
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                                <ul className="footer-lists">
                                    {Quicklinks.slice(8, 10).map(
                                        (items, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className="footer-list-quick"
                                                    onClick={() =>
                                                        sendTo(items)
                                                    }
                                                >
                                                    {t(items.items)}
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                            <div className="quick-links-desktop">
                                <ul className="footer-lists">
                                    {Quicklinks.slice(0, 5).map(
                                        (items, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className="footer-list-quick"
                                                >
                                                    <span
                                                        onClick={() =>
                                                            sendTo(items)
                                                        }
                                                    >
                                                        {t(items.items)}
                                                    </span>
                                                </li>
                                            );
                                        }
                                    )}
                                    <li
                                    className="footer-list-quick"
                                    >
                                        <a
                                            rel="noopener noreferrer"
                                            href="https://apply.workable.com/auto24/"
                                            target="_blank"
                                        >
                                            {t('careers')}
                                        </a>
                                    </li>
                                </ul>
                                <ul className="footer-lists">
                                    {Quicklinks.slice(5, 10).map(
                                        (items, index) => {
                                            return (
                                                <li
                                                    key={index}
                                                    className="footer-list-quick"
                                                >
                                                    <span
                                                        onClick={() =>
                                                            sendTo(items)
                                                        }
                                                    >
                                                        {t(items.items)}
                                                    </span>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="footer-address-line">
                            <div className="border-top"></div>
                            <h4 className="footer-subtitle">
                                {t('addressLine')}
                            </h4>
                            <a
                                href={map}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <p className="footer-address">
                                        {address?.localAddress},
                                        <br />
                                        {address?.province}, {address?.district},{' '}
                                        {address?.country}
                                </p>
                            </a>
                            <p className="footer-email">{email}</p>
                            <p
                                className="footer-number"
                                onClick={copyToClipboard}
                            >
                                {phoneNumber?.code} {phoneNumber?.number}
                            </p>
                            <div className="footer-socials">
                                <SocialIcons />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <div className="padding" dir={ i18n.language === "ar" ? "rtl" : "ltr" }  dangerouslySetInnerHTML={{ __html: getCopyrightText() }}>
                        {/* {t('copyrightfooter')} */}
                        {/* <p>{t('copyrightInfo')}</p> */}
                    </div>
                    <p>
                        { i18n.language === "en" ?
                            <>
                                Buy and sell used cars in&nbsp;
                                <a
                                    rel="noopener noreferrer"
                                    href="https://auto24.ci/"
                                    target="_blank"
                                    className="country-link"
                                >
                                    Côte d’Ivoire <img className="flag-img" src="/images/flags/cote-divoire.webp" alt="Côte d’Ivoire"/>
                                </a>
                                <a
                                    rel="noopener noreferrer"
                                    href="https://auto24.ci/"
                                    target="_blank"
                                    className="country-link"
                                >
                                    , Morocco <img className="flag-img" src="/images/flags/morocco.webp" alt="Morocco"/>
                                </a>
                                <a
                                    rel="noopener noreferrer"
                                    href="https://auto24.ci/"
                                    target="_blank"
                                    className="country-link"
                                >
                                    , Rwanda <img className="flag-img" src="/images/flags/rwanda.webp" alt="Rwanda"/>
                                </a>
                                , Senegal <img className="flag-img" src="/images/flags/senegal.webp" alt="Senegal"/>
                                and&nbsp;
                                <a
                                    rel="noopener noreferrer"
                                    href="https://auto24.ci/"
                                    target="_blank"
                                    className="country-link"
                                >
                                    South Africa <img className="flag-img" src="/images/flags/south-africa.webp" alt="South Africa"/>
                                </a>
                                &nbsp;with AUTO24.africa.
                            </>
                            : i18n.language === "fr" ?
                                <>
                                    Achetez et vendez des voitures d'occasion en&nbsp;
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://auto24.co.za/"
                                        target="_blank"
                                        className="country-link"
                                    >
                                    Afrique du Sud <img className="flag-img" src="/images/flags/south-africa.webp" alt="South Africa"/>
                                    </a>
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://auto24.ci/"
                                        target="_blank"
                                        className="country-link"
                                    >
                                    , en Côte d’Ivoire <img className="flag-img" src="/images/flags/cote-divoire.webp" alt="Côte d’Ivoire"/>
                                    </a>
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://auto24.ma/"
                                        target="_blank"
                                        className="country-link"
                                    >
                                    , au Maroc <img className="flag-img" src="/images/flags/morocco.webp" alt="Morocco"/>,
                                    </a>
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://auto24.rw/"
                                        target="_blank"
                                        className="country-link"
                                    >
                                    au Rwanda <img className="flag-img" src="/images/flags/rwanda.webp" alt="Rwanda"/>
                                    </a>
                                    &nbsp;et au Sénégal <img className="flag-img" src="/images/flags/senegal.webp" alt="Senegal"/>
                                    &nbsp;avec AUTO24.africa.
                                </>
                                :
                                <>
                                    Achetez et vendez des voitures d'occasion en&nbsp;
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://auto24.co.za/"
                                        target="_blank"
                                        className="country-link"
                                    >
                                        Afrique du Sud <img className="flag-img" src="/images/flags/south-africa.webp" alt="South Africa"/>
                                    </a>
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://auto24.ci/"
                                        target="_blank"
                                        className="country-link"
                                    >
                                        , en Côte d’Ivoire <img className="flag-img" src="/images/flags/cote-divoire.webp" alt="Côte d’Ivoire"/>
                                    </a>
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://auto24.ma/"
                                        target="_blank"
                                        className="country-link"
                                    >
                                        , au Maroc <img className="flag-img" src="/images/flags/morocco.webp" alt="Morocco"/>,
                                    </a>
                                    <a
                                        rel="noopener noreferrer"
                                        href="https://auto24.rw/"
                                        target="_blank"
                                        className="country-link"
                                    >
                                        au Rwanda <img className="flag-img" src="/images/flags/rwanda.webp" alt="Rwanda"/>
                                    </a>
                                    &nbsp;et au Sénégal <img className="flag-img" src="/images/flags/senegal.webp" alt="Senegal"/>
                                    &nbsp;avec AUTO24.africa.
                                </>
                        }
                    </p>
                </div>
            </div>
            {showAuthModal && (
                <AuthFlow
                    isVisible={showAuthModal}
                    handleCancel={changeModal}
                />
            )}
        </>
    );
};

export default Footer;
