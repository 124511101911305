import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IUserInitialType } from '../../redux/types/AuthTypes';

export default function ProtectedRoute({
    user,
    children
}: {
    user: IUserInitialType;
    children: JSX.Element;
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    if (!user) {
        navigate(t('home', { ns: 'routes' }), { replace: true });
    }
    return children;
}
