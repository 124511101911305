import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';
import SummaryCard from './ui/SummaryCard';
import Loading from './ui/Loading';

import { ICombineReducerType } from '../redux/reducers';
import { $FIXME } from '../constants';
import scrollToRef from '../utils/Hooks/ScrollToElement';
import GetSpecification, {
    GetSpecificationWeight
} from '../utils/Hooks/GetSpecification';

const Specifications = (props: $FIXME) => {
    const { t } = useTranslation();
    const { car, loading } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const carSpecification = useRef(null);
    useEffect(() => {
        if (props.activeNavClick === 'specifications') {
            scrollToRef(carSpecification);
        }
    }, [props.activeNavClick]);

    const filteredData = GetSpecification(car);
    const filteredDataWeight = GetSpecificationWeight(car);

    return loading ? (
        <Loading />
    ) : filteredData.length === 0 && filteredDataWeight.length === 0 ? null : (
        <div
            className={
                props.hasHash ? 'specifications margin-top' : 'specifications'
            }
            id="specification"
            ref={carSpecification}
        >
            <SecSeperator />
            <h2 className="title">{t('specifications')}</h2>
            <div className="specifications-container">
                {loading ? (
                    <Loading />
                ) : filteredData.length !== 0 ? (
                    <div className="performance-economy">
                        <h3 className="category">{t('performanceNEconomy')}</h3>
                        {filteredData.map((items, index) => {
                            return (
                                <div
                                    className="specification-card-container"
                                    key={index}
                                >
                                    <SummaryCard
                                        icons={items.icons}
                                        alt={t(items.title)}
                                        title={t(items.title)}
                                        value={items.value ?? 'n/a'}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {loading ? (
                    <Loading />
                ) : filteredDataWeight.length !== 0 ? (
                    <div className="performance-economy">
                        <h3 className="category">{t('weightNDimensions')}</h3>
                        {filteredDataWeight.map((items, index) => {
                            return (
                                <div
                                    className="specification-card-container"
                                    key={index}
                                >
                                    <SummaryCard
                                        icons={items.icons}
                                        alt={t(items.title)}
                                        title={t(items.title)}
                                        value={items.value}
                                        unit={items.unit}
                                    />
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Specifications;
