import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider } from 'antd';

import SecSeperator from '../components/ui/SecSeperator';
import { formatMoney } from '../components/SearchFilter';

const LOAN_INFO = {
    creditRequestedAmount: 'creditRequestedAmount',
    noOfMonthlyPayments: 'noOfMonthlyPayments',
    monthlyInsurance: 'monthlyInsurance',
    frauseDossier: 'frauseDossier',
    // teg: "teg",
    costOfCredit: 'costOfCredit',
    simulatorContribution: 'simulatorContribution',
    percentageOfContribution: 'percentageOfContribution'
} as const;

type ILoanInfo = keyof typeof LOAN_INFO;

interface ILoanCalculatorProps {
    carPrice?: number | null;
    downPayment?: number;
    interestRate?: number;
    timeFrame?: number;
    carId?: string;
}

interface ILoanData {
    vehiclePrice: number | null;
    downpayment: number | null;
    downpaymentPercent: number | null;
    numberOfMonthlyPayments: number | null;
    monthlyPaymentAmount: number | null;
    minMonthlyPaymentAmount: number | null;
    maxMonthlyPaymentAmount: number | null;
}

const LoanPaymentSimulator = (props: ILoanCalculatorProps) => {
    const { t } = useTranslation();

    const minVehiclePrice = props.carPrice ? Math.round(props.carPrice * 0.1) : 20000;
    const maxVehiclePrice = props.carPrice ?? 1000000;
    const minDownPayment = 0;
    const maxDownPayment = 60;
    const minNumberOfMonthlyPayments = 24;
    const maxNumberOfMonthlyPayments = 108;
    const defaultVehicleCostIncrBy = 1000;
    const downPaymentIncrBy = 1;
    // Other constants
    const interetIntercalaireVal = 0;
    const tauxFraisProportionel = 0;
    const fraisDossierFix = 0;
    const fraisMin = 0;
    let tauxInteret = 9;
    const tauxInteretCalculeMonsulate = (tauxInteret * 1.1) / 100;
    tauxInteret = (tauxInteret * 1) / 100; // need this answer. why is this done?
    const tauxAssurance = 0.077;
    // const mensualiteDefault = 60;

    const [loanData, setLoanData] = useState<ILoanData>({
        // vehiclePrice: minVehiclePrice,
        vehiclePrice: 60000,
        downpayment: minVehiclePrice,
        // downpaymentPercent: minDownPayment,
        downpaymentPercent: 0,
        // numberOfMonthlyPayments: minNumberOfMonthlyPayments,
        numberOfMonthlyPayments: 60,
        // monthlyPaymentAmount: minVehiclePrice,
        monthlyPaymentAmount: 1272,
        // minMonthlyPaymentAmount: minmonthlyPaymentAmount,
        minMonthlyPaymentAmount: 841.48,
        // maxMonthlyPaymentAmount: maxmonthlyPaymentAmount
        maxMonthlyPaymentAmount: 2765
    });
    const [loanPaymentInfo, setLoanPaymentInfo] = useState({
        creditRequestedAmount: `60 000 ${process.env.REACT_APP_DEFAULT_CURRENCY}`,
        noOfMonthlyPayments: `60 ${t('months')}`,
        monthlyInsurance: `46.20 ${process.env.REACT_APP_DEFAULT_CURRENCY}`,
        frauseDossier: `0.00 ${process.env.REACT_APP_DEFAULT_CURRENCY}`,
        teg: `${tauxInteret * 100} %`,
        costOfCredit: `16 366 ${process.env.REACT_APP_DEFAULT_CURRENCY}`,
        simulatorContribution: `0 ${process.env.REACT_APP_DEFAULT_CURRENCY}`,
        percentageOfContribution: `0 %`
    });
    // console.log(loanData, 'CalculateTheValueloanDataHere');

    function calculMonthlyPayment(
        loan: number,
        rate: number,
        duration: number
    ) {
        let V, R, N, Y, Ax, amount;
        if (loan == null || rate == null || duration == null) {
            amount = 0;
        } else {
            if (rate == 0) {
                Ax = Math.round((100 * loan) / duration) / 100;
            } else {
                V = loan * (rate / 12);
                R = 1 + rate / 12;
                N = Math.pow(R, duration * -1);
                Y = 1 - N;
                Ax = Math.round((V * 100) / Y) / 100;
            }
            amount = Ax;
        }

        return amount;
    }

    function calculationNumberMonthlyPayment(
        loan: number,
        rate: number,
        amount: number
    ) {
        let P, I, duration, Nx;
        let num,
            a = 0; // added by me

        if (loan == null || rate == null || amount == null) {
            num = 0;
        } else {
            if (rate == 0) {
                Nx = Math.round(a);
            } else {
                P = loan / amount;
                I = rate / 12;
                duration = -(Math.log(1 - P * I) / Math.log(1 + I));
                Nx = Math.round(duration);
            }

            num = Nx;
        }
        return num;
    }

    function calculateUpdated(
        amountCredit: number,
        rateFeeProportional: number,
        updatedFix: number,
        costsMin: number
    ) {
        const amountMultiRateFee = amountCredit * rateFeeProportional;
        const result =
            Math.round(100 * (updatedFix + amountMultiRateFee)) / 100;

        return Math.max(result, costsMin);
    }

    function calculateAmountTotal(
        amountMonthlyPayment: number,
        durationMonth: number,
        updated: number
    ) {
        const amountTotalNoFees = amountMonthlyPayment * durationMonth;

        return amountTotalNoFees + updated;
    }

    function calculateRateTegSimple(
        loan: number,
        duration: number,
        rate: number,
        amountTotal: number
    ) {
        return rate * 100;
    }

    function currencyFormat(num: number, digit = 0) {
        return num.toFixed(digit).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    }

    function calculationCostOverall(
        amountMonthlyPayment: number,
        updated: number,
        numberMonthlyPayments: number,
        interetIntercalaire: number,
        amountCredit: number,
        costInsurance: number
    ) {
        // console.log(amountMonthlyPayment, updated, numberMonthlyPayments, interetIntercalaire, amountCredit,  costInsurance, "CalculateTheValueList")
        // console.log("(", amountMonthlyPayment, "*", numberMonthlyPayments,") +", updated, "+", interetIntercalaire, "+", costInsurance, "-",  costInsurance, "CalculateTheValue")
        const totalPayment = amountMonthlyPayment * numberMonthlyPayments;
        const result =
            totalPayment +
            updated +
            interetIntercalaire +
            costInsurance -
            amountCredit;

        return Math.round(result * 100) / 100;
        // debugger
        // return Math.round(result);
    }

    function changeMonthlyPaymentAmountByDownPaymentAndTenure(
        vehiclePrice: number | null,
        downpaymentPercent: number | null,
        numberOfMonthlyPayments: number | null
    ) {
        if (
            vehiclePrice &&
            (downpaymentPercent || downpaymentPercent === 0) &&
            numberOfMonthlyPayments
        ) {
            const downPaymentAmt =
                Number(vehiclePrice) * (Number(downpaymentPercent) / 100);
            const amountBurrowed = Number(vehiclePrice) - downPaymentAmt;
            setLoanData((prevLoanData) => ({
                ...prevLoanData,
                downpayment: downPaymentAmt
            }));

            const amountMonthlyPayment = calculMonthlyPayment(
                amountBurrowed,
                tauxInteretCalculeMonsulate,
                Number(numberOfMonthlyPayments)
            );
            const amountMonthlyPaymentMin = calculMonthlyPayment(
                amountBurrowed,
                tauxInteretCalculeMonsulate,
                Number(maxNumberOfMonthlyPayments)
            );
            const amountMonthlyPaymentMax = calculMonthlyPayment(
                amountBurrowed,
                tauxInteretCalculeMonsulate,
                Number(minNumberOfMonthlyPayments)
            );

            setLoanData((prevLoanData) => ({
                ...prevLoanData,
                monthlyPaymentAmount: amountMonthlyPayment,
                minMonthlyPaymentAmount: amountMonthlyPaymentMin,
                maxMonthlyPaymentAmount: amountMonthlyPaymentMax
            }));

            // let stringApport =
            //     currencyFormat(amountContribution) +
            //     ' MAD SOIT ' +
            //     currencyFormat(contributionPercentageReal, 2) +
            //     ' %';
            // $('.blockApport').html(stringApport);

            let stringAmountBorrowing = `${currencyFormat(amountBurrowed)} ${
                process.env.REACT_APP_DEFAULT_CURRENCY
            }`; // Got it
            // $('.block-amount .chiffre').html(stringAmountBorrowing);
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                creditRequestedAmount: stringAmountBorrowing
            }));

            let stringAmountMonthlyPayment = currencyFormat(
                amountMonthlyPayment,
                2
            );
            // $('.block-monthlyPayment .chiffre').html(
            //     stringAmountMonthlyPayment
            // );
            // $('.detail-simulation .row-monthlyPayment .chiffre').html(
            //     stringAmountMonthlyPayment
            // );

            // let stringAmountApport = currencyFormat(downPaymentAmt, 2);
            // $('.block-monthlyPayment .chiffre').html(stringAmountApport);
        }
    }

    // Added here
    function changeBlockAmountMonthlyPayment(
        vehiclePrice: number | null,
        downpaymentPercent: number | null,
        numberOfMonthlyPayments: number | null,
        monthlyPaymentAmount: number | null,
        isMonthlyPaymentAmount = false
    ) {
        if (
            vehiclePrice &&
            (downpaymentPercent || downpaymentPercent === 0) &&
            (monthlyPaymentAmount || monthlyPaymentAmount === 0) &&
            numberOfMonthlyPayments
        ) {
            let numberMonthlyPayments = numberOfMonthlyPayments;
            const downPaymentAmt =
                Number(vehiclePrice) * (Number(downpaymentPercent) / 100);
            const amountBurrowed = Number(vehiclePrice) - downPaymentAmt;
            setLoanData((prevLoanData) => ({
                ...prevLoanData,
                downpayment: downPaymentAmt
            }));

            const monthlyPaymentAmountCalc = calculMonthlyPayment(
                amountBurrowed,
                tauxInteretCalculeMonsulate,
                Number(numberOfMonthlyPayments)
            ); // Added this function
            // const amountMonthlyPaymentMin = calculMonthlyPayment(
            //     amountBurrowed,
            //     tauxInteretCalculeMonsulate,
            //     Number(maxNumberOfMonthlyPayments)
            // );
            // const amountMonthlyPaymentMax = calculMonthlyPayment(
            //     amountBurrowed,
            //     tauxInteretCalculeMonsulate,
            //     Number(minNumberOfMonthlyPayments)
            // );

            // setLoanData((prevLoanData) => ({ ...prevLoanData, monthlyPaymentAmount: amountMonthlyPayment, minMonthlyPaymentAmount: amountMonthlyPaymentMin, maxMonthlyPaymentAmount: amountMonthlyPaymentMax }));

            // console.log(monthlyPaymentAmount, monthlyPaymentAmountCalc, "CalculateTheValueM")

            let amountInsuranceMonthly =
                Math.round(amountBurrowed * tauxAssurance) / 100;

            if (isMonthlyPaymentAmount) {
                numberMonthlyPayments = calculationNumberMonthlyPayment(
                    amountBurrowed,
                    tauxInteretCalculeMonsulate,
                    monthlyPaymentAmount
                );
                setLoanData((prevLoanData) => ({
                    ...prevLoanData,
                    numberOfMonthlyPayments: numberMonthlyPayments
                }));
            }

            const updated = calculateUpdated(
                amountBurrowed,
                tauxFraisProportionel,
                fraisDossierFix,
                fraisMin
            );

            const amountTotal = calculateAmountTotal(
                monthlyPaymentAmountCalc, //monthlyPaymentAmount,
                numberMonthlyPayments,
                updated
            );

            const rateTEG = calculateRateTegSimple(
                amountBurrowed,
                numberMonthlyPayments,
                tauxInteret,
                amountTotal
            );

            let stringAmountMonthlyPayment =
                // currencyFormat(monthlyPaymentAmount) + ' MAD/Month';
                currencyFormat(monthlyPaymentAmountCalc) + ' MAD/Month';
            // $('.blockAmountMonthlyPayment').html(stringAmountMonthlyPayment); // need this id. Where is it?

            let stringUpdated = `${currencyFormat(updated, 2)} ${
                process.env.REACT_APP_DEFAULT_CURRENCY
            }`; // Got it.
            // $('.block-costs-dossier .chiffre').html(stringUpdated);
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                frauseDossier: stringUpdated
            }));

            let stringAmountApport = `${currencyFormat(downPaymentAmt)} ${
                process.env.REACT_APP_DEFAULT_CURRENCY
            }`; // Got it.
            // $('.block-apport .chiffre').html(stringAmountApport);
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                simulatorContribution: stringAmountApport
            }));

            let stringApportPorcentageReal = `${currencyFormat(
                Number(downpaymentPercent)
            )} %`; // Got it.
            // $('.block-pourcentage-apport .chiffre').html(
            //     stringApportPorcentageReal
            // );
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                percentageOfContribution: stringApportPorcentageReal
            }));

            let stringRateTEG = `${currencyFormat(rateTEG, 2)} %`; // Got it.
            // $('.block-teg .chiffre').html(stringRateTEG);
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                teg: stringRateTEG
            }));

            let coutGlobal = calculationCostOverall(
                monthlyPaymentAmountCalc, //monthlyPaymentAmount,
                updated,
                numberMonthlyPayments,
                interetIntercalaireVal,
                amountBurrowed,
                amountInsuranceMonthly
            );

            let stringCoutGlobal = `${currencyFormat(coutGlobal)} ${
                process.env.REACT_APP_DEFAULT_CURRENCY
            }`; // Got it
            // $('.block-cout .chiffre').html(stringCoutGlobal);
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                costOfCredit: stringCoutGlobal
            }));

            let stringNumberMonthlyPayments =
                currencyFormat(numberMonthlyPayments) + ' Month';
            // $('.blockNombreMonthlyPayments').html(stringNumberMonthlyPayments); // need this id. Where is it?

            let stringAmountBorrowing = `${currencyFormat(amountBurrowed)} ${
                process.env.REACT_APP_DEFAULT_CURRENCY
            }`; // Got it
            // $('.block-amount .chiffre').html(stringAmountBorrowing);
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                creditRequestedAmount: stringAmountBorrowing
            }));

            stringAmountMonthlyPayment = currencyFormat(
                monthlyPaymentAmountCalc, //monthlyPaymentAmount,
                2
            );
            // $('.block-monthlyPayment .chiffre').html(
            //     stringAmountMonthlyPayment
            // ); // Got it
            // setLoanPaymentInfo(prevLoanPaymentInfo => ({...prevLoanPaymentInfo, noOfMonthlyPayments: stringAmountMonthlyPayment}));

            // $('.detail-simulation .row-monthlyPayment .chiffre').html(
            //     stringAmountMonthlyPayment
            // ); // need this id. Where is it?

            const stringAmountInsuranceMonthly = `${currencyFormat(
                amountInsuranceMonthly,
                2
            )} ${process.env.REACT_APP_DEFAULT_CURRENCY}`;
            // $('.block-assurance .chiffre').html(stringAmountInsuranceMonthly); // Got it
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                monthlyInsurance: stringAmountInsuranceMonthly
            }));

            stringNumberMonthlyPayments = `${currencyFormat(
                numberMonthlyPayments
            )} ${t('months')}`;
            // $('.block-nombre-monthlyPayment .chiffre').html(
            //     stringNumberMonthlyPayments
            // ); // Got it
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                noOfMonthlyPayments: stringNumberMonthlyPayments
            }));
        }
    }

    function changeBlockNombreMonthlyPayments(
        vehiclePrice: number | null,
        downpaymentPercent: number | null,
        numberOfMonthlyPayments: number | null,
        monthlyPaymentAmount: number | null
    ) {
        if (
            vehiclePrice &&
            (downpaymentPercent || downpaymentPercent === 0) &&
            (monthlyPaymentAmount || monthlyPaymentAmount === 0) &&
            numberOfMonthlyPayments
        ) {
            let stringNumberMonthlyPayments =
                currencyFormat(numberOfMonthlyPayments) + ' Month';
            // $('.blockNombreMonthlyPayments').html(stringNumberMonthlyPayments); // need this id. Where is it?

            const downPaymentAmt =
                Number(vehiclePrice) * (Number(downpaymentPercent) / 100);
            const amountBurrowed = Number(vehiclePrice) - downPaymentAmt;

            const amountMonthlyPayment = calculMonthlyPayment(
                amountBurrowed,
                tauxInteretCalculeMonsulate,
                numberOfMonthlyPayments
                // mensualiteDefault // need this value? but why?
            );

            let stringAmountMonthlyPayment = currencyFormat(
                amountMonthlyPayment,
                2
            );
            // $('.block-monthlyPayment .chiffre').html(
            //     stringAmountMonthlyPayment
            // ); // Got it

            setLoanData((prevLoanData) => ({
                ...prevLoanData,
                monthlyPaymentAmount: amountMonthlyPayment
            }));

            stringAmountMonthlyPayment =
                currencyFormat(amountMonthlyPayment) + ' MAD/Month';

            // $('.blockAmountMonthlyPayment').html(stringAmountMonthlyPayment);
            // $('.detail-simulation .row-monthlyPayment .chiffre').html(
            //     stringAmountMonthlyPayment
            // ); // need this id. Where is it?

            let stringAmountBorrowing = `${currencyFormat(amountBurrowed)} ${
                process.env.REACT_APP_DEFAULT_CURRENCY
            }`;
            // $('.block-amount .chiffre').html(stringAmountBorrowing); // Got it
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                creditRequestedAmount: stringAmountBorrowing
            }));

            stringAmountMonthlyPayment = currencyFormat(
                amountMonthlyPayment,
                2
            );
            // $('.block-monthlyPayment .chiffre').html(
            //     stringAmountMonthlyPayment
            // ); // Got it
            // setLoanPaymentInfo(prevLoanPaymentInfo => ({...prevLoanPaymentInfo, noOfMonthlyPayments: stringAmountMonthlyPayment}));

            stringNumberMonthlyPayments = `${currencyFormat(
                numberOfMonthlyPayments
            )} ${t('months')}`;
            // $('.block-nombre-monthlyPayment .chiffre').html(
            //     stringNumberMonthlyPayments
            // ); // Got it
            setLoanPaymentInfo((prevLoanPaymentInfo) => ({
                ...prevLoanPaymentInfo,
                noOfMonthlyPayments: stringNumberMonthlyPayments
            }));
        }
    }

    function viewLoanPaymentInfo(keyName: ILoanInfo, t: any) {
        switch (keyName) {
            case LOAN_INFO.creditRequestedAmount:
                return {
                    label: t('creditRequestedAmount'),
                    value: loanPaymentInfo[LOAN_INFO.creditRequestedAmount]
                };
            case LOAN_INFO.noOfMonthlyPayments:
                return {
                    label: t('noOfMonthlyPayments'),
                    value: loanPaymentInfo[LOAN_INFO.noOfMonthlyPayments]
                };
            case LOAN_INFO.monthlyInsurance:
                return {
                    label: t('monthlyInsurance'),
                    value: loanPaymentInfo[LOAN_INFO.monthlyInsurance]
                };
            case LOAN_INFO.frauseDossier:
                return {
                    label: t('applicationFees'),
                    value: loanPaymentInfo[LOAN_INFO.frauseDossier]
                };
            // case LOAN_INFO.teg:
            //     return {
            //         label: "T.E.G.",
            //         value: loanPaymentInfo[LOAN_INFO.teg]
            //     }
            case LOAN_INFO.costOfCredit:
                return {
                    label: t('overallCostOfCredit'),
                    value: loanPaymentInfo[LOAN_INFO.costOfCredit]
                };
            case LOAN_INFO.simulatorContribution:
                return {
                    label: t('simulatorContribution'),
                    value: loanPaymentInfo[LOAN_INFO.simulatorContribution]
                };
            case LOAN_INFO.percentageOfContribution:
                return {
                    label: t('percentageOfContribution'),
                    value: loanPaymentInfo[LOAN_INFO.percentageOfContribution]
                };
            default:
                return {
                    label: '',
                    value: ''
                };
        }
    }

    return (
        <>
            <div className="loan-calculator padding" id="#loan-calculator">
                <SecSeperator />
                <h2 className="title">{t('loanPaymentSimulator')}</h2>
                <div className="form-container full-width">
                    <div className="form-inputs">
                        <div className="form-input-container">
                            <label>
                                <span className="label-title">
                                    {t('vehiclePrice')}
                                </span>
                            </label>
                            <div className="loan-range-cal slider-values">
                                <div className="min-value-container">
                                    <span>{t('min')}</span>
                                    <p>
                                        <span id="min-cal">
                                            {formatMoney(minVehiclePrice)} DH
                                        </span>
                                    </p>
                                </div>
                                <p>
                                    <span id="loan-value-cal">
                                        {formatMoney(loanData.vehiclePrice)} DH
                                    </span>
                                </p>
                                <div className="max-value-container">
                                    <span>{t('max')}</span>
                                    <p>
                                        <span id="max-cal">
                                            {formatMoney(maxVehiclePrice)} DH
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <Slider
                                className="loan-slider"
                                min={minVehiclePrice}
                                max={maxVehiclePrice}
                                step={defaultVehicleCostIncrBy}
                                defaultValue={0}
                                value={
                                    typeof loanData.vehiclePrice === 'number'
                                        ? loanData.vehiclePrice
                                        : 0
                                }
                                onChange={(e) => {
                                    setLoanData((prevLoanData) => ({
                                        ...prevLoanData,
                                        vehiclePrice: e
                                    }));
                                    changeMonthlyPaymentAmountByDownPaymentAndTenure(
                                        e,
                                        loanData?.downpaymentPercent,
                                        loanData?.numberOfMonthlyPayments
                                    );
                                    changeBlockAmountMonthlyPayment(
                                        e,
                                        loanData?.downpaymentPercent,
                                        loanData?.numberOfMonthlyPayments,
                                        loanData.monthlyPaymentAmount
                                    );
                                    changeBlockNombreMonthlyPayments(
                                        e,
                                        loanData?.downpaymentPercent,
                                        loanData?.numberOfMonthlyPayments,
                                        loanData.monthlyPaymentAmount
                                    );
                                }}
                            />
                        </div>
                        <div className="form-input-container">
                            <label>
                                <span className="label-title">
                                    {t('downPayment')}
                                </span>
                            </label>
                            <div className="loan-range-cal slider-values">
                                <div className="min-value-container">
                                    <span>{t('min')}</span>
                                    <p>
                                        <span id="min-cal">
                                            {formatMoney(minDownPayment)} %
                                        </span>
                                    </p>
                                </div>
                                <p>
                                    <span id="loan-value-cal">
                                        {formatMoney(loanData.downpayment)} DH{' '}
                                        {formatMoney(
                                            loanData.downpaymentPercent
                                        )}{' '}
                                        %
                                    </span>
                                </p>
                                <div className="max-value-container">
                                    <span>{t('max')}</span>
                                    <p>
                                        <span id="max-cal">
                                            {formatMoney(maxDownPayment)} %
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <Slider
                                className="loan-slider"
                                min={minDownPayment}
                                max={maxDownPayment}
                                step={downPaymentIncrBy}
                                defaultValue={0}
                                value={
                                    typeof loanData.downpaymentPercent ===
                                    'number'
                                        ? loanData.downpaymentPercent
                                        : 0
                                }
                                onChange={(e) => {
                                    setLoanData((prevLoanData) => ({
                                        ...prevLoanData,
                                        downpaymentPercent: e
                                    }));
                                    changeMonthlyPaymentAmountByDownPaymentAndTenure(
                                        loanData?.vehiclePrice,
                                        e,
                                        loanData?.numberOfMonthlyPayments
                                    );
                                    changeBlockAmountMonthlyPayment(
                                        loanData?.vehiclePrice,
                                        e,
                                        loanData?.numberOfMonthlyPayments,
                                        loanData.monthlyPaymentAmount
                                    );
                                    changeBlockNombreMonthlyPayments(
                                        loanData?.vehiclePrice,
                                        e,
                                        loanData?.numberOfMonthlyPayments,
                                        loanData.monthlyPaymentAmount
                                    );
                                }}
                            />
                        </div>
                        <div className="form-input-container">
                            <label>
                                <span className="label-title">
                                    {t('noOfMonthlyPayments')}
                                </span>
                            </label>
                            <div className="loan-range-cal slider-values">
                                <div className="min-value-container">
                                    <span>{t('min')}</span>
                                    <p>
                                        <span id="min-cal">
                                            {formatMoney(
                                                minNumberOfMonthlyPayments
                                            )}{' '}
                                            {t('months')}
                                        </span>
                                    </p>
                                </div>
                                <p>
                                    <span id="loan-value-cal">
                                        {formatMoney(
                                            loanData.numberOfMonthlyPayments
                                        )}{' '}
                                        {t('months')}
                                    </span>
                                </p>
                                <div className="max-value-container">
                                    <span>{t('max')}</span>
                                    <p>
                                        <span id="max-cal">
                                            {formatMoney(
                                                maxNumberOfMonthlyPayments
                                            )}{' '}
                                            {t('months')}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <Slider
                                className="loan-slider"
                                min={minNumberOfMonthlyPayments}
                                max={maxNumberOfMonthlyPayments}
                                step={downPaymentIncrBy}
                                defaultValue={0}
                                value={
                                    typeof loanData.numberOfMonthlyPayments ===
                                    'number'
                                        ? loanData.numberOfMonthlyPayments
                                        : 0
                                }
                                onChange={(e) => {
                                    setLoanData((prevLoanData) => ({
                                        ...prevLoanData,
                                        numberOfMonthlyPayments: e
                                    }));
                                    changeMonthlyPaymentAmountByDownPaymentAndTenure(
                                        loanData?.vehiclePrice,
                                        loanData?.downpaymentPercent,
                                        e
                                    );
                                    changeBlockNombreMonthlyPayments(
                                        loanData?.vehiclePrice,
                                        loanData?.downpaymentPercent,
                                        e,
                                        loanData.monthlyPaymentAmount
                                    );
                                    changeBlockAmountMonthlyPayment(
                                        loanData?.vehiclePrice,
                                        loanData?.downpaymentPercent,
                                        e,
                                        loanData.monthlyPaymentAmount
                                    );
                                }}
                            />
                        </div>
                        <div className="form-input-container">
                            <label>
                                <span className="label-title">
                                    {t('monthlyPaymentAmount')}
                                </span>
                            </label>
                            <div className="loan-range-cal slider-values">
                                <div className="min-value-container">
                                    <span>{t('min')}</span>
                                    <p>
                                        <span id="min-cal">
                                            {currencyFormat(
                                                loanData?.minMonthlyPaymentAmount!
                                            )}{' '}
                                            DH/Month
                                        </span>
                                    </p>
                                </div>
                                <p>
                                    <span id="loan-value-cal">
                                        {currencyFormat(
                                            loanData?.monthlyPaymentAmount!
                                        )}{' '}
                                        DH/Month
                                    </span>
                                </p>
                                <div className="max-value-container">
                                    <span>{t('max')}</span>
                                    <p>
                                        <span id="max-cal">
                                            {currencyFormat(
                                                loanData?.maxMonthlyPaymentAmount!
                                            )}{' '}
                                            DH/Month
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <Slider
                                className="loan-slider"
                                min={
                                    typeof loanData.minMonthlyPaymentAmount ===
                                    'number'
                                        ? loanData.minMonthlyPaymentAmount
                                        : 0
                                }
                                max={
                                    typeof loanData.maxMonthlyPaymentAmount ===
                                    'number'
                                        ? loanData.maxMonthlyPaymentAmount
                                        : 0
                                }
                                step={downPaymentIncrBy}
                                defaultValue={0}
                                value={
                                    typeof loanData.monthlyPaymentAmount ===
                                    'number'
                                        ? loanData.monthlyPaymentAmount
                                        : Number(loanData?.monthlyPaymentAmount)
                                }
                                onChange={(e) => {
                                    setLoanData((prevLoanData) => ({
                                        ...prevLoanData,
                                        monthlyPaymentAmount: e
                                    }));

                                    changeBlockAmountMonthlyPayment(
                                        loanData?.vehiclePrice,
                                        loanData?.downpaymentPercent,
                                        loanData.numberOfMonthlyPayments,
                                        e,
                                        true
                                    );
                                }}
                            />
                        </div>
                    </div>

                    <div className="loan-total">
                        {Object.keys(LOAN_INFO).map((item, index) => {
                            const { label, value } = viewLoanPaymentInfo(
                                item as keyof typeof LOAN_INFO, t
                            );

                            return (
                                <div
                                    key={index}
                                    className="loan-info-container"
                                >
                                    <p className="loan-info-title">{label} </p>
                                    <p className="loan-info-value">
                                        {value ? value : 0}
                                    </p>
                                </div>
                            );
                        })}

                        {/* <button className="bottom-btn" onClick={showModal}>
                            {t('getACallBack')}
                        </button> */}
                        {/* <GetACallBack
                            open={isModalVisible}
                            setModalOpen={setIsModalVisible}
                            category="financing"
                            interestRate={loanData.interestRate}
                            timeFrame={loanData.timeFrame}
                            downPayment={loanData.downpayment}
                            carId={props.carId}
                        /> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoanPaymentSimulator;
