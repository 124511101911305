import { ChangeEventHandler, CSSProperties, useRef, useState } from 'react';

import { Form, Input, InputRef, Select } from 'antd';
import { CaretDown, MagnifyingGlass } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import { CurrencyAndCode } from '../../helpers/currencyCode';

interface IProps {
    selectName?: string;
    onSelect?: (e: string, name: string) => void;
    selectValue?: string;
    style?: CSSProperties;
    inputName?: string;
    inputValue?: string | number | readonly string[];
    onInputChange?: ChangeEventHandler<HTMLInputElement>;
    error?: string;
    inputStyle?: CSSProperties;
    disable?: boolean;
    inputClass?: string;
    selectClass?: string;
    inputMaxlen?: number;
    required?: boolean;
}
export const CurrencyCodeDropdwon = ({
    onSelect,
    selectValue,
    selectName
}: IProps) => {
    const { Option } = Select;
    const [t] = useTranslation();
    const [query, setQuery] = useState('');
    const inputRef = useRef<InputRef>(null);
    const CountrySearchFocus = () =>
        setTimeout(() => {
            inputRef.current!.focus({ cursor: 'start' });
        }, 500);
    const ClearState = () =>
        setTimeout(() => {
            setQuery('');
        }, 500);
    function filterCurrency(query: string) {
        return !query
            ? CurrencyAndCode
            : CurrencyAndCode.filter((i) =>
                  i.currency
                      .toLocaleLowerCase()
                      .includes(query.toLocaleLowerCase())
              );
    }
    return (
        <Select
            className={`phone-select`}
            onChange={(e) =>
                onSelect && !!selectName ? onSelect(e, selectName) : undefined
            }
            onDropdownVisibleChange={(open) =>
                open ? CountrySearchFocus() : ClearState()
            }
            defaultValue={selectValue}
            optionLabelProp="label"
            suffixIcon={<CaretDown size={24} color="#b3b3b3" weight="bold" />}
            dropdownRender={(menu) => (
                <>
                    <Form className="dropdown-form">
                        <Input
                            prefix={
                                <MagnifyingGlass
                                    size={16}
                                    color="#8b8b8b"
                                    weight="bold"
                                />
                            }
                            ref={inputRef}
                            placeholder={t('search')}
                            value={query}
                            className="dropdown-input"
                            onChange={(e) =>
                                setQuery(e.target.value.toLowerCase())
                            }
                        />
                    </Form>
                    {menu}
                </>
            )}
        >
            {filterCurrency(query)?.map((opt, index: number) => (
                <Option
                    key={index}
                    value={`${opt.currency}`}
                    label={
                        <div className="phone-label">
                            <img
                                alt=""
                                // src={`https://countryflagsapi.com/svg/${opt.country}`}
                                src={`https://flagcdn.com/16x12/${opt.country}.png`}
                                srcSet={`https://flagcdn.com/32x24/${opt.country}.png 2x,
                                          https://flagcdn.com/48x36/${opt.country}.png 3x`}
                            />
                            <span> {opt.currency}</span>
                        </div>
                    }
                >
                    <div className="phone-option">
                        <img
                            alt=""
                            src={`https://flagcdn.com/16x12/${opt.country}.png`}
                            srcSet={`https://flagcdn.com/32x24/${opt.country}.png 2x,
                                          https://flagcdn.com/48x36/${opt.country}.png 3x`}
                        />
                        {`${opt.currency}`}
                    </div>
                </Option>
            ))}
        </Select>
    );
};
