import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Certification from '../components/Certification';
import Listings from '../components/Listings';
import SearchFilter from '../components/SearchFilter';
import Layout from '../layout/Layout';

import { ICombineReducerType } from '../redux/reducers';
import { SetMeta } from '../utils/Helper/jsHelpers';

const Listingpage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [resetFilter, setResetFilter] = useState(false);
    const { cars } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );

    return (
        <>
            <SetMeta title='buyPageTitle' desc='buyPageDesc' keywords='buyPageKeywords'
            properties={[
                {
                    key: "og:title",
                    value: "buyPageTitleOg"
                },
                {
                    key: "og:description",
                    value: "buyPageDescOg"
                },
                {
                    key: "og:keywords",
                    value: "buyPageKeywordsOg"
                }
            ]}
            />
            <Layout showWhiteNavbar>
                <div className='listing-page'>
                    <SearchFilter
                        resetFilter={resetFilter}
                        setResetFilter={setResetFilter}
                    />
                    <Listings setResetFilter={setResetFilter} />
                    {cars.length ? (
                        <Certification
                            title='lookingForaCarQtellUs'
                            subtitle='desiredPleaseprovideusyourcardetails'
                            image='/images/sellcar-alt.svg'
                            from='lookingForCar'
                            buttonText='lookingForaCarQtellUs'
                            className='listing-certi'
                        />
                    ) : (
                        ''
                    )}
                </div>
            </Layout>
        </>
    );
};
export default Listingpage;
