import { useTranslation } from 'react-i18next';

const LoadMore = (props: any) => {
    const { t } = useTranslation();
    return (
        <div className="load-more-btn">
            <button
                className={`load-more ${props.class}`}
                onClick={props.onclick}
            >
                {t(props.text)}
            </button>
        </div>
    );
};

export default LoadMore;
