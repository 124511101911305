import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Collapse, Modal } from 'antd';
import { Minus, Plus } from 'phosphor-react';

import SecSeperator from './ui/SecSeperator';
import CountryCodeDropdown from './ui/CountryCodeDropdown';
import LoadingButton from './ui/LoadingButton';
import Button from './ui/Button';

import { defaultDailing_Code } from '../helpers/countryCode';
import {
    // contentAccordToCurrentLang,
    numcheck,
    returnOnlyNumber
} from '../utils/Helper/jsHelpers';
import { ICombineReducerType } from '../redux/reducers';
import { $FIXME } from '../constants';
import { useMediaQueryHook } from '../utils/Hooks/MediaQuery';
import { addQuestion, getFaqData } from '../redux/actions/questionnaireAction';
import { IFaqType } from '../redux/types/questionnaireTypes';
import i18n from '../localization';

const { Panel } = Collapse;
interface IQuestionnariesProps {
    slug?: string;
    title?: string;
    showAskQuestion?: boolean;
}
const Questionnaire = (props: IQuestionnariesProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSuccessVisible, setIsSuccessVisible] = useState(false);
    const [error, setError] = useState<any>({});
    const { faqs } = useSelector((state: ICombineReducerType) => state.faqs);

    let { user } = useSelector((state: ICombineReducerType) => state.auth);
    let { loading } = useSelector(
        (state: ICombineReducerType) => state.createFaq
    );

    useEffect(() => {
        if (props.slug) {
            getFaqData(dispatch, props.slug);
        } else {
            getFaqData(dispatch);
        }
    }, []);
    const [disabled, setDisabled] = useState(true);
    const [data, setData] = useState({
        question: '',
        number: user?.phoneNumber?.number ?? '',
        code: user?.phoneNumber?.code ?? defaultDailing_Code
    });

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsSuccessVisible(true);
        setData({
            question: '',
            number: user?.phoneNumber?.number ?? '',
            code: user?.phoneNumber?.code ?? defaultDailing_Code
        });
        setError('');
    };
    function resetState() {
        setData({
            question: '',
            number: user?.phoneNumber?.number ?? '',
            code: user?.phoneNumber?.code ?? defaultDailing_Code
        });
        setError({});
    }
    const handleaskQuestion = async (e: any) => {
        e.preventDefault();
        setDisabled(true);
        if (await numcheck({ ...data }, setError)) {
            addQuestion(dispatch, { ...data }, handleCancel)
                .finally(() => resetState())
                .finally(() => setDisabled(false));
        }
    };
    useEffect(() => {
        if (data.question && data.number) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [data]);
    const handleDataChange = (e: any) => {
        setError('');
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const goToHome = () => {
        navigate(t('home', { ns: 'routes' }));
        setIsSuccessVisible(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="questionnaire-container">
            <div className="questionnaire-image">
                <img src="/images/jeepcar.webp" alt="jeepcar" />
            </div>
            <div className="questionnaire">
                <SecSeperator className="questionnaire-seperator" />
                <h2 className="title">
                    {props.title
                        ? t(props.title, { ns: 'hero' })
                        : t('questions', { ns: 'hero' })}
                </h2>
                <p className="subtitle">
                    {t('questionSubtitle', { ns: 'hero' })}
                </p>
                {!useMediaQueryHook() && !props.showAskQuestion && (
                    <button className="bottom-btn" onClick={showModal}>
                        {t('askQuestions', { ns: 'hero' })}
                    </button>
                )}

                <Collapse
                    expandIcon={({ isActive }: $FIXME) =>
                        isActive ? (
                            <div className="accordian-btn-minus">
                                <Minus size={24} color="#fff" weight="fill" />
                            </div>
                        ) : (
                            <div className="accordian-btn-plus">
                                <Plus size={24} color="#8b8b8b" weight="fill" />
                            </div>
                        )
                    }
                    expandIconPosition="end"
                >
                    {/* {contentAccordToCurrentLang(faqs)?.map(
                        (items: IFaqType, index: any) => {
                            return (
                                <Panel header={items.question} key={index}>
                                    <div>{items?.answer}</div>
                                </Panel>
                            );
                        }
                    )} */}
                    {faqs?.map(
                        (items: IFaqType, index: any) => {
                            const questionAnswer = items.details.find((item) => i18n.language === item.lang);
                            console.log(questionAnswer, "questionAnswer")
                            return (
                                <>
                                { questionAnswer &&
                                    <Panel header={questionAnswer.question} key={index}>
                                        <div>{questionAnswer?.answer}</div>
                                    </Panel>
                                }
                                </>
                            );
                        }
                    )}
                </Collapse>
                {useMediaQueryHook() && !props.showAskQuestion && (
                    <Button
                        text={t('askQuestions', { ns: 'hero' })}
                        onClick={showModal}
                        class="question-btn"
                    />
                )}
                {props.showAskQuestion ? (
                    <div className="didnt-finds-questions">
                        <p>{t('didntFindQuestion')}</p>
                        <button
                            className="bottom-btn ask-question"
                            onClick={showModal}
                        >
                            {t('askQuestions', { ns: 'hero' })}
                        </button>
                    </div>
                ) : null}
            </div>

            <Modal
                className="question-modal ants-modal"
                open={isModalVisible}
                onCancel={() => (setIsModalVisible(false), resetState())}
                closable={true}
                centered={true}
            >
                <SecSeperator className="sec-question" />
                <h3 className=" title question-title">
                    {' '}
                    {t('askQuestions', { ns: 'hero' })}
                </h3>
                <form className="question-form" onSubmit={handleaskQuestion}>
                    <textarea
                        placeholder={t('questionTextAreaPlaceHolder', {
                            ns: 'hero'
                        })}
                        rows={5}
                        name="question"
                        value={data.question}
                        onChange={handleDataChange}
                        required
                    ></textarea>
                    <CountryCodeDropdown
                        className="questionnaire-number"
                        inputName="number"
                        selectName="code"
                        inputValue={data.number}
                        onInputChange={(e) => {
                            setError('');
                            setData((prev) => ({
                                ...prev,
                                number: returnOnlyNumber(e.target.value)
                            }));
                        }}
                        error={error.phoneNumber ?? null}
                        selectValue={data.code}
                        onSelect={(e) =>
                            setData((prev) => ({ ...prev, code: e }))
                        }
                    />

                    <div className="question-footer">
                        <div className="terms-condt">
                            {t('byClickingXButton', {
                                name: t('send')
                            })}{' '}
                            <Link
                                to={t('/terms-of-use', { ns: 'routes' })}
                                className="termandpolicy"
                            >
                                {t('termOfUse')}
                            </Link>{' '}
                            {t('and')}{' '}
                            <Link
                                to={t('/privacy-policy', { ns: 'routes' })}
                                className="termandpolicy"
                            >
                                {t('privacyPolicy')}
                            </Link>
                        </div>
                        {loading ? (
                            <LoadingButton buttonText="sending" />
                        ) : (
                            <button
                                type="submit"
                                disabled={disabled}
                                className="bottom-btn  button-primary send"
                            >
                                {t('send')}
                            </button>
                        )}
                    </div>
                </form>
            </Modal>
            <Modal
                className="save-changes-modal ants-modal"
                title={t('questionSentSuccessfully')}
                visible={isSuccessVisible}
                onCancel={() => setIsSuccessVisible(false)}
                closable={true}
                centered={true}
            >
                <p className="subtitle">
                    {t('questionSentSuccessfullySubtitle')}
                </p>
                <div className="done-icon">
                    <img src="/images/done.webp" alt="done" />
                </div>
                <div className="get-call-back">
                    <button className="back-to-home" onClick={() => goToHome()}>
                        {t('backToHome')}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default Questionnaire;
