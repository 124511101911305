const ServiceData = [
    {
        image: '/svgs/checkshield.svg',
        title: 'inspector',
        subtitle: 'inspectorSubtitle',
        subCategory: 'inspector'
    },
    {
        image: '/svgs/insurance.svg',
        title: 'insurance',
        subtitle: 'insuranceSubtitle',
        subCategory: 'insurance'
    },
    {
        image: '/svgs/finance.svg',
        title: 'finance',
        subtitle: 'financeSubtitles',
        subCategory: 'finance'
    },
    {
        image: '/svgs/diagnostic.svg',
        title: 'diagnostic',
        subtitle: 'diagonosticSubtitle',
        subCategory: 'diagnostic'
    },
    {
        image: '/svgs/vin-verify.svg',
        title: 'VINVerify',
        subtitle: 'VINVerifySubtitle',
        subCategory: 'VIN verify'
    }
];

export default ServiceData;
