import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import SecSeperator from './ui/SecSeperator';

import { ICombineReducerType } from '../redux/reducers';
import { getServiceData } from '../redux/actions/serviceHistoryAction';
import { $FIXME } from '../constants';
import scrollToRef from '../utils/Hooks/ScrollToElement';

const { Panel } = Collapse;

const genExtra = (index: number, t: any) => {
    return (
        <>
            {index === 0 ? (
                <div className="latest-service">{t('latestService')}</div>
            ) : (
                ''
            )}
        </>
    );
};

const ServiceHistory = (props: $FIXME) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { serviceHistory } = useSelector(
        (state: ICombineReducerType) => state.serviceHistory
    );

    const { car } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    useEffect(() => {
        if (car._id) {
            getServiceData(dispatch, car._id);
        }
    }, [car, dispatch]);

    const carserviceHistoryRef = useRef(null);
    useEffect(() => {
        if (props.activeNavClick === 'serviceHistorys') {
            scrollToRef(carserviceHistoryRef);
        }
    }, [props.activeNavClick]);

    return !serviceHistory.length ? null : (
        <div
            className={
                props.hasHash ? 'serviceHistory margin-top' : 'serviceHistory'
            }
            id="history"
            ref={carserviceHistoryRef}
        >
            <SecSeperator />
            <h2 className="title">{t('serviceHistory')}</h2>
            <div className="history-container">
                <Collapse expandIconPosition="end">
                    {serviceHistory?.map((items, index) => {
                        return (
                            <Panel
                                header={items.date.substring(0, 10)}
                                key={index}
                                extra={genExtra(index, t)}
                            >
                                <div className="service">
                                    <p className="service-title">
                                        {t('kilometers')}
                                    </p>
                                    <p className="service-value">
                                        {items.drivenKM}Km
                                    </p>
                                </div>
                                <div className="service">
                                    <p className="service-title">
                                        {t('serviceCenter')}
                                    </p>
                                    <p className="service-value">
                                        {items.serviceCenter}
                                    </p>
                                </div>
                            </Panel>
                        );
                    })}
                </Collapse>
            </div>
        </div>
    );
};

export default ServiceHistory;
