import { sellingCars } from '../../constants';
import { Api } from '../Api';
import { Dispatch } from 'redux';
import {
    CREATE_SELL_CAR_LOADING,
    CREATE_SELL_CAR_SUCCESS
} from '../types/sellCarTypes';
import { message } from 'antd';
import { request } from '../../helpers/request';

export const createSellingCar = async (dispatch: Dispatch, data: any) => {
    dispatch({ type: CREATE_SELL_CAR_LOADING });
    const resData: any = await await createSellingCars(data);
    if (resData.status === 200) {
        dispatch({
            type: CREATE_SELL_CAR_SUCCESS,
            payload: resData.data.sellingCars
        });
        return true;
    } else {
        // message.error(resData.response.data?.msg);
        return false;
    }
};

export const userRequirements = async (dispatch: Dispatch, data: any) => {
    dispatch({ type: CREATE_SELL_CAR_LOADING });
    const resData: any = await request.userRequirement(data);
    if (resData.status === 200) {
        dispatch({
            type: CREATE_SELL_CAR_SUCCESS,
            payload: resData.data.sellingCars
        });
        return true;
    } else {
        // message.error(resData.response.data?.msg);
        return false;
    }
};
export const createSellingCars = async (data: any) => {
    try {
        const result = await Api.post(`${sellingCars}`, data);
        return result;
    } catch (error) {
        return error;
    }
};
