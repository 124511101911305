import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';
import SummaryCard from './ui/SummaryCard';
import Loading from './ui/Loading';

import { ICombineReducerType } from '../redux/reducers';
import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';
import { $FIXME } from '../constants';
import scrollToRef from '../utils/Hooks/ScrollToElement';
import { formatMoney } from '../components/SearchFilter';
import { ICar } from '../interface/car';

const Summary = (props: $FIXME) => {
    const { t } = useTranslation();
    const { car, loading } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const carSummary = useRef(null);
    useEffect(() => {
        if (props.activeNavClick === 'summary') {
            scrollToRef(carSummary);
        }
    }, [props.activeNavClick]);
    const GetSummary = (car: ICar) => {
        const SummaryArray = [
            {
                icons: '/images/body-type.svg',
                title: 'bodyType',
                value: car?.bodyType
                    ? contentAccordToCurrentLang(car.bodyType)?.bodyType.toUpperCase()
                    : 'n/a'
            },
            {
                icons: '/images/Key.webp',
                title: 'numberofkeys',
                value: car?.noOfKeys ?? 'n/a',
                text: 'seeKeyPhotos'
            },
            {
                icons: '/images/Drop.webp',
                title: 'exteriorColor',
                value: car?.exteriorColor
                    ? contentAccordToCurrentLang(car.exteriorColor)?.color.toUpperCase()
                    : 'n/a'
            },
            {
                icons: '/images/funnel.webp',
                title: 'interiorColor',
                value: car?.interiorColor
                    ? contentAccordToCurrentLang(car.interiorColor)?.color.toUpperCase()
                    : 'n/a'
            },
            {
                icons: '/images/engine.webp',
                title: 'engineSize',
                value: car?.isElectric ? false : car?.engineSize
            },
            {
                icons: '/images/power.svg',
                title: 'fiscalPower',
                value: formatMoney(car?.fiscalPower)
            },
            {
                icons: '/images/power.svg',
                title: 'noOfOwners',
                value: formatMoney(car?.noOfOwners)
            },
            {
                icons: '/images/power.svg',
                title: 'condition',
                value: car?.condition && car?.condition.toUpperCase()
            }
        ];

        return SummaryArray.filter((item) => {
            return item?.value !== '0' && !!item?.value;
        });
    };
    const filteredData = GetSummary(car);

    return loading ? (
        <Loading />
    ) : (
        <div
            className={props.hasHash ? 'summary margin-top' : 'summary'}
            id="summary"
            ref={carSummary}
        >
            <SecSeperator />
            <h2 className="title">{t('Summary')}</h2>
            <div className="summary-container">
                {filteredData.map((items, index) => {
                    return (
                        <div className="summary-card-container" key={index}>
                            <SummaryCard
                                icons={items?.icons}
                                alt={t(items?.title)}
                                title={t(items?.title)}
                                value={items?.value}
                                text={items.text}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Summary;
