import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Button from './ui/Button';
import Card from './ui/Card';
import SecSeperator from './ui/SecSeperator';

import { $FIXME } from '../constants';

interface IPropType {
    title: string;
    subtitle: string;
    classes?: string;
    carData?: $FIXME;
    total?: number | null;
}

const NewCarList = (props: IPropType) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const goToListing = () => {
        navigate(t('/buy-cars', { ns: 'routes' }));
    };

    return props.carData.length ? (
        <div className="recently-added-container">
            <div className={`recently-added padding ${props.classes}`}>
                <SecSeperator />
                <div className="recently-header">
                    <h2 className="title">
                        {t(props.title, { ns: 'common' })}
                    </h2>
                    <p className="subtitle">{t(props.subtitle)}</p>
                </div>

                <div className="recent-card__holder">
                    {props?.carData?.map((items: $FIXME, index: number) => {
                        return (
                            <div key={index} className="card-holder">
                                <Card item={items} newCarFlag={true} />
                            </div>
                        );
                    })}
                </div>
                {!props.classes && (
                    <Button
                        text={`${props.total ?? ""} ${t(props.total === 1 ? 'carAvailable' : 'carsAvailable')}`}
                        onClick={goToListing}
                    />
                )}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default NewCarList;
