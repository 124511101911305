import {
    FacebookLogo,
    LinkedinLogo,
    TwitterLogo,
    WhatsappLogo
} from 'phosphor-react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';

import { blogPageUrl } from '../../constants';
import { IBlogDetailType } from '../../redux/types/BlogListTypes';

const SocailMedia = (item: IBlogDetailType) => {
    const SocailMediaArray = [
        <FacebookShareButton
            url={`${blogPageUrl}/${item._id}`}
            title={item.title}
            quote="Auto24"
        >
            <FacebookLogo size={20} color="#595959" weight="fill" />
        </FacebookShareButton>,
        <LinkedinShareButton
            url={`${blogPageUrl}/${item._id}`}
            title={item.title}
        >
            <LinkedinLogo size={20} color="#595959" weight="fill" />
        </LinkedinShareButton>,
        <TwitterShareButton
            url={`${blogPageUrl}/${item._id}`}
            title={item.title}
            via="auto24.asterdio.xyz"
        >
            <TwitterLogo size={20} color="#595959" weight="fill" />
        </TwitterShareButton>,
        <WhatsappShareButton
            url={`${blogPageUrl}/${item._id}`}
            title={item.title}
        >
            <WhatsappLogo size={20} color="#595959" weight="fill" />
        </WhatsappShareButton>
    ];
    return (
        <div className="social-media">
            {SocailMediaArray.map((items: any, index) => {
                return <div key={index}>{items}</div>;
            })}
        </div>
    );
};

export default SocailMedia;
