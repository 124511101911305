import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import ButtonComponent from '../ui/ButtonComponent';
import Modal from '../ui/Modal';
import Loading from '../ui/Loading';
import { BASE_URL } from '../../constants';
import { AuthTypes } from '../../redux/types/AuthTypes';
import { api } from '../../helpers/api';
import { request } from '../../helpers/request';

const AccountVerification = () => {
    const [validUrl, setValidUrl] = useState(false);
    const [loading, setLoading] = useState(true);

    const params = useParams();
    const { t } = useTranslation();
    const activeModal = 0;

    const TokenExpired = () => (
        <div className="reset-successful">
            <span>{t('tokenExpired')}</span>
            <img src="/images/check2.svg" alt="successImage" />
            <div className="reset-btn">
                <ButtonComponent
                    type="secondary"
                    className="reset-success-button"
                    onClick={resendingLink}
                >
                    {t('resendingLink')}
                </ButtonComponent>
            </div>
        </div>
    );

    const modalLayout: any[] = [
        {
            header: 'tokenExpiredTitle',
            backdropType: 'blur',
            data: <TokenExpired />,
            rightPadding: '40',
            closable: false,
            modalSize: 'medium',
            secSeperator: false
        }
    ];

    useEffect(() => {
        const verifyEmail = async () => {
            const url = `${BASE_URL}/auth/verify-email/${params.token}`;
            const { data } = await api.get(url);
            if (data.status === 200 || data.status === true) {
                setValidUrl(true);
            } else {
                // setMessage(data.msg);
                // setValidUrl(false);
                navigate(t('home', { ns: 'routes' }));
                dispatch({ type: AuthTypes.SHOW_LOGIN_MODAL });
            }
            setLoading(false);
        };
        verifyEmail();
    }, [params]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleCancel = () => navigate(t('home', { ns: 'routes' }));
    const showLoginModal = () => {
        navigate(t('home', { ns: 'routes' }));
        dispatch({ type: AuthTypes.SHOW_LOGIN_MODAL });
    };
    const resendingLink = () => {
        if (params.token)
            request
                .resendVerificationLink(params.token)
                .then((res: any) => message.success(res.data.msg))
                .catch((err) => {
                    navigate(t('home', { ns: 'routes' }));
                    dispatch({ type: AuthTypes.SHOW_LOGIN_MODAL });
                    // message.error(err.response.data.msg);
                });
    };
    return (
        <>
            {loading ? (
                <Loading />
            ) : validUrl && !loading ? (
                <>
                    <Modal
                        closable={false}
                        isLeftRequired={false}
                        isVisible={true}
                        handleCancel={handleCancel}
                        header={t('accountVerifiedSuccessfully')}
                        backdropType="blur"
                        rightPadding="40"
                        size="medium"
                        secSeperator={false}
                    >
                        <div className="reset-successful">
                            <span>
                                {t('accountVerifiedSuccessfullySubtitle')}
                            </span>
                            <img src="/images/check2.svg" alt="" />
                            <div className="reset-success-button-wrapper">
                                <ButtonComponent
                                    type="primary"
                                    onClick={showLoginModal}
                                    className="reset-success-button"
                                >
                                    {t('signIn')}
                                </ButtonComponent>
                                <ButtonComponent
                                    type="secondary"
                                    className="reset-success-button"
                                    onClick={() =>
                                        navigate(
                                            t('/buy-cars', { ns: 'routes' })
                                        )
                                    }
                                >
                                    {t('exploreCar', { ns: 'hero' })}
                                </ButtonComponent>
                            </div>
                        </div>
                    </Modal>
                </>
            ) : (
                <Modal
                    closable={modalLayout[activeModal].closable}
                    isLeftRequired={modalLayout[activeModal].isLeftRequired}
                    isVisible={true}
                    handleCancel={handleCancel}
                    header={t(modalLayout[activeModal].header)}
                    backdropType={modalLayout[activeModal].backdropType}
                    rightPadding={modalLayout[activeModal].rightPadding}
                    size={modalLayout[activeModal].modalSize}
                    secSeperator={modalLayout[activeModal].secSeperator}
                >
                    {modalLayout[activeModal].data}
                </Modal>
            )}
        </>
    );
};
export default AccountVerification;
