import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Tabs, Modal } from 'antd';
import { User } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import CountryCodeDropdown from './ui/CountryCodeDropdown';
import Selects from './ui/Select';
import LoadingButton from './ui/LoadingButton';

import { CAR_CONDITION, getYearList, mileageRange } from '../helpers/constants';
import { defaultDailing_Code } from '../helpers/countryCode';
import { IBrand } from '../interface/car';
import { getCarBrandsList } from '../redux/actions/carBrandsAction';
import { fetchCarModelByModel } from '../redux/actions/carModelsAction';
import { validateNumber } from '../redux/actions/countryActions';
import { createSellingCar } from '../redux/actions/sellCarAction';
import { ICombineReducerType } from '../redux/reducers';
import { fullNameValidate, returnOnlyNumber } from '../utils/Helper/jsHelpers';
import { $FIXME } from '../constants';
import { formatMoney } from './SearchFilter';

export const getMileageOptions = (t: $FIXME) =>
    mileageRange.map((item) => {
        if (typeof item.label === 'object') {
            return {
                label: t(item.label.key, {
                    X: formatMoney(item.label.X),
                    Y: formatMoney(item.label.Y)
                }),
                value: item.value
            };
        } else {
            return {
                label: t(item.label),
                value: item.value
            };
        }
    });

const SellDetails = () => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [brandList, setBrandList] = useState<any[]>([]);
    const [modelsList, setModelsList] = useState<any[]>([]);
    const [load, setLoad] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState<any>({
        brand: '',
        model: '',
        fuelType: '',
        transmission: '',
        condition: '',
        mileage: '',
        year: ''
    });
    let { user } = useSelector((state: ICombineReducerType) => state.auth);

    const [userData, setUserData] = useState({
        fullName: user?.name ?? '',
        phoneNumber: {
            code: user?.phoneNumber?.code ?? defaultDailing_Code,
            number: user?.phoneNumber?.number.toString() ?? ''
        }
    } as { fullName?: string; phoneNumber?: { number?: string; code?: string } });
    const [errors, setError] = useState({} as any);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { carModels, loading } = useSelector(
        (state: ICombineReducerType) => state.carModels
    );

    const codeSelect = (value: string, key: string) => {
        setUserData({
            ...userData,
            phoneNumber: {
                ...userData.phoneNumber,
                [key]: value
            }
        });
    };
    const { TabPane } = Tabs;

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const emptySelectedOptions = Object.keys(selectedOptions).filter(
        (k, val) => {
            return !selectedOptions[k];
        }
    );
    const [disabled, setDisabled] = useState(true);

    const goToNextTab = (key: string) => {
        if (emptySelectedOptions.length) {
        } else {
            setActiveTab(key);
        }
    };

    const { carBrands } = useSelector(
        (state: ICombineReducerType) => state.carBrands
    );

    useEffect(() => {
        if (!carBrands.length) getCarBrandsList(dispatch);
    }, []);
    useEffect(() => {
        if (carBrands.length > 0) {
            const brandsList: Array<any> = carBrands.map((items: IBrand) => ({
                label: items.brand,
                value: items._id,
                _id: items._id
            }));
            setBrandList(brandsList);
        }
    }, [carBrands]);
    useEffect(() => {
        if (selectedOptions.brand) {
            fetchCarModelByModel(dispatch, selectedOptions.brand);
        }
    }, [selectedOptions.brand]);
    useEffect(() => {
        if (carModels.length > 0) {
            const carModelsList = carModels.map((items) => ({
                label: `${items.model}`,
                value: items._id,
                _id: items._id
            }));
            setModelsList(carModelsList);
        }
    }, [carModels]);

    const handleInputChange = (e: any) => {
        setUserData({
            ...userData,
            fullName: e.target.value
        });
    };
    const onSelect = (value: string, key: string) => {
        delete errors[key];
        setError(errors);
        if(key === 'brand') {
            setModelsList([]);
            setSelectedOptions({ ...selectedOptions, [key]: value, model: '' });
        } else
        setSelectedOptions({ ...selectedOptions, [key]: value });
    };
    const handleSubmit = async () => {
        if (!userData.phoneNumber?.number) {
            setError((prev: any) => ({
                ...prev,
                phoneNumber: t('xIsrequired', { name: t('number') })
            }));
        }
        if (!userData.fullName) {
            setError((prev: any) => ({
                ...prev,
                fullName: t('xIsrequired', { name: t('fullName') })
            }));
            return;
        }

        if (userData?.phoneNumber?.code && userData?.phoneNumber?.number) {
            setLoad(true);
            validateNumber(
                userData?.phoneNumber?.code,
                userData?.phoneNumber?.number
            )
                .then(async (response) => {
                    if (Object.hasOwn(response, 'valid')) {
                        if (response.valid) {
                            setError((prev: any) => ({
                                ...prev
                            }));
                            const res = await createSellingCar(dispatch, {
                                ...selectedOptions,
                                ...userData
                            });
                            setLoad(false);
                            if (res) {
                                setIsModalVisible(true);
                                setSelectedOptions({
                                    brand: '',
                                    model: '',
                                    fuelType: '',
                                    transmission: '',
                                    condition: '',
                                    mileage: '',
                                    year: ''
                                });
                                setUserData({
                                    fullName: user?.name ?? '',
                                    phoneNumber: {
                                        code:
                                            user?.phoneNumber?.code ??
                                            defaultDailing_Code,
                                        number: user?.phoneNumber?.number ?? ''
                                    }
                                });
                                setIsModalVisible(true);
                                setActiveTab('1');
                            }
                        } else {
                            setError((prev: any) => ({
                                ...prev,
                                phoneNumber: 'phonenumberisnotvalid'
                            }));
                        }
                    } else {
                        setError((prev: any) => ({
                            phoneNumber: 'internalError'
                        }));
                    }
                })
                .catch((err) => {
                    setError((prev: any) => ({
                        ...prev,
                        phoneNumber: 'phonenumberisnotvalid'
                    }));
                })
                .finally(() => {
                    setLoad(false);
                });
        }
    };

    let { loadingSellData } = useSelector(
        (state: ICombineReducerType) => state.sellingCars
    );
    const fuelTypes = [
        { label: t('electric'), value: 'ev', _id: 1 },
        { label: t('hybrid'), value: 'hybrid', _id: 2 },
        { label: t('phev'), value: 'phev', _id: 3 },
        { label: t('diesel'), value: 'diesel', _id: 4 },
        { label: t('petrol'), value: 'petrol', _id: 5 },
        { label: t('carburant'), value: 'carburant', _id: 6 },
        { label: t('other'), value: 'other', _id: 7 }
    ];

    const transmissionTypes = [
        { label: t('manual'), value: 'manual', _id: 1 },
        { label: t('automatic'), value: 'automatic', _id: 2 }
    ];
    useEffect(() => {
        setSelectedOptions((prv: any) => ({
            ...prv,
            model: ''
        }));
    }, [selectedOptions.brand]);
    const goToHome = () => {
        setIsModalVisible(false);
        navigate(t('home', { ns: 'routes' }));
    };
    function setFieldsError() {
        if (!emptySelectedOptions.length) setDisabled(false);
        // else if (emptySelectedOptions.length < 7) {
        //     emptySelectedOptions.forEach((item) =>
        //         setError((prv: any) => ({
        //             ...prv,
        //             [item]: t('xIsrequired', { name: t(item) })
        //         }))
        //     );
        else setDisabled(true);
    }
    useEffect(() => {
        if (userData.fullName) {
            if (fullNameValidate(userData.fullName)) {
                setError({});
            }
        }
    }, [userData]);

    useEffect(() => {
        setFieldsError();
    }, [selectedOptions]);

    return (
        <div className="sell-details">
            <Tabs
                defaultActiveKey="1"
                activeKey={activeTab}
                onChange={goToNextTab}
                className="sell-details-tab"
            >
                <TabPane tab={`1. ${t('carDetails')}`} key="1">
                    <div className="sell-top">
                        <Selects
                            prefixicon={<img src="/icon/car.svg" alt="car" />}
                            placeholder={t('selectbrand')}
                            showSearch={true}
                            size="16"
                            weight="regular"
                            options={brandList}
                            onSelect={onSelect}
                            error={errors.brand}
                            name="brand"
                            value={selectedOptions.brand}
                            disabled={carBrands.length === 0}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={<img src="/icon/apps.svg" alt="apps" />}
                            placeholder={t('selectmodel')}
                            showSearch={true}
                            size="16"
                            weight="regular"
                            onSelect={onSelect}
                            name="model"
                            value={selectedOptions.model}
                            options={modelsList}
                            error={errors.model}
                            disabled={!selectedOptions.brand || loading}
                            class={
                                !selectedOptions.brand ? '' : 'select-cursor'
                            }
                        />
                        <Selects
                            prefixicon={
                                <img
                                    src="/icon/CalendarBlank.svg"
                                    alt={t('CalendarBlank')}
                                />
                            }
                            placeholder={t('selectYear')}
                            weight="regular"
                            size="16"
                            onSelect={onSelect}
                            name="year"
                            error={errors.year}
                            value={selectedOptions.year}
                            options={getYearList().map(
                                (item: any, index: number, arry) => {
                                    return index + 1 === arry.length
                                        ? {
                                              label: t('other'),
                                              value: 'other'
                                          }
                                        : {
                                              label: item,
                                              value: item
                                          };
                                }
                            )}
                            showSearch={true}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={
                                <img src="/icon/time.svg" alt={t('mileage')} />
                            }
                            placeholder={t('selectMileageRange')}
                            size="16"
                            weight="regular"
                            options={getMileageOptions(t)}
                            onSelect={onSelect}
                            name="mileage"
                            error={errors.mileage}
                            value={selectedOptions.mileage}
                            showSearch={true}
                            // disabled={!selectedOptions.mileage || loading}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={
                                <img
                                    src="/icon/Transmission.svg"
                                    alt={t('automatic')}
                                />
                            }
                            placeholder={t('selectTransmissionType')}
                            size="16"
                            weight="regular"
                            options={transmissionTypes}
                            onSelect={onSelect}
                            name="transmission"
                            error={errors.transmission}
                            value={selectedOptions.transmission}
                            showSearch={true}
                            // disabled={!selectedOptions.mileage || loading}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={
                                <img
                                    src="/icon/gaspump1.svg"
                                    alt={t('automatic')}
                                />
                            }
                            placeholder={t('selectFuelType')}
                            size="16"
                            weight="regular"
                            options={fuelTypes}
                            onSelect={onSelect}
                            error={errors.fuelType}
                            name="fuelType"
                            value={selectedOptions.fuelType}
                            showSearch={true}
                            // disabled={!selectedOptions.transmission || loading}
                            class="select-cursor"
                        />
                        <Selects
                            prefixicon={
                                <img src="/icon/condition.svg" alt="" />
                            }
                            placeholder={t('selectACondition')}
                            weight="regular"
                            size="16"
                            onSelect={onSelect}
                            name="condition"
                            error={errors.condition}
                            value={selectedOptions.condition}
                            showSearch={true}
                            // disabled={false}
                            options={CAR_CONDITION.map((item) => ({
                                label: t(item.name),
                                value: item.value
                            }))}
                            class="select-cursor"
                        />
                    </div>
                    <div className="sell-bottom">
                        <button
                            className="bottom-btn button-primary"
                            disabled={disabled}
                            onClick={() => goToNextTab('2')}
                        >
                            {t('continue')}
                        </button>
                    </div>
                </TabPane>
                <TabPane tab={`2. ${t('personalDetails')}`} disabled key="2">
                    <div>
                        <div className="personal-details">
                            <label>
                                <User size={24} color="#8b8b8b" />
                            </label>
                            <input
                                onBlur={() => {
                                    if (userData.fullName) {
                                        if (
                                            !fullNameValidate(userData.fullName)
                                        ) {
                                            setError({
                                                fullName: t(
                                                    'pleaseEnterFullName'
                                                )
                                            });
                                        }
                                    }
                                }}
                                type="text"
                                placeholder={t('enterFullName')}
                                name="fullName"
                                value={userData.fullName}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <span className="error-label fade-in">
                                {errors.fullName}
                            </span>
                        </div>
                    </div>
                    <CountryCodeDropdown
                        onInputChange={(e) => {
                            setUserData({
                                ...userData,
                                phoneNumber: {
                                    ...userData.phoneNumber,
                                    number: returnOnlyNumber(e.target.value)
                                }
                            });
                        }}
                        onSelect={codeSelect}
                        selectName="code"
                        inputName="number"
                        selectValue={userData?.phoneNumber?.code}
                        inputValue={userData.phoneNumber?.number}
                        inputStyle={{ backgroundColor: 'white' }}
                        error={errors.phoneNumber}
                        className="sell-number"
                    />
                    {load || loadingSellData ? (
                        <LoadingButton buttonText="sending" />
                    ) : (
                        <button
                            disabled={
                                !!userData.fullName &&
                                fullNameValidate(userData.fullName) &&
                                userData.phoneNumber?.number?.length === 10
                                    ? false
                                    : true
                            }
                            className="bottom-btn button-primary"
                            onClick={handleSubmit}
                        >
                            {t('getACallBack')}
                        </button>
                    )}
                    <Modal
                        className="sell-modal success-sell ants-modal"
                        title={t('cardetailssendSuccessfully')}
                        open={isModalVisible}
                        onCancel={handleCancel}
                        closable={false}
                        centered={true}
                    >
                        <p className="subtitle">
                            {t('thanyouforprovidingusdetail')}
                        </p>
                        <div className="done-icon">
                            <img src="/images/done.webp" alt="" />
                        </div>
                        <div className=" get-call-back">
                            <button
                                className="back-to-home"
                                onClick={() => goToHome()}
                            >
                                {t('backToHome')}
                            </button>
                        </div>
                    </Modal>
                </TabPane>
            </Tabs>
        </div>
    );
};

export default SellDetails;
