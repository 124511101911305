import { Dispatch } from 'redux';
import { modelByBrands } from '../../constants';
import { request } from '../../helpers/request';
import { Api } from '../Api';
import { FETCH_CAR_BRANDS_LOADING } from '../types/carBrandsTypes';
import {
    FETCH_CAR_AVAILABLE_MODEL,
    FETCH_CAR_MODELS_SUCCESS
} from '../types/carModelsTypes';

export const fetchCarModelByModel = async (dispatch: Dispatch, _id: string) => {
    dispatch({ type: FETCH_CAR_BRANDS_LOADING });
    const resData: any = await carModelsByBrand(_id);
    if (resData.status === 200) {
        dispatch({
            type: FETCH_CAR_MODELS_SUCCESS,
            payload: resData.data.carModels
        });
    } else {
    }
};

export const carModelsByBrand = async (_id: string) => {
    try {
        const result = await Api.get(`${modelByBrands}/${_id}`);
        return result;
    } catch (error) {
        return error;
    }
};
export const getAllCarModels = async (dispatch: Dispatch) => {
    const response = await request.getAllCarModels();
    if (response.status === 200) {
        dispatch({
            type: FETCH_CAR_MODELS_SUCCESS,
            payload: response.data?.carModels
        });
    } else if (response.status === 502) {
        // if networkfail error occur
        getAllCarModels(dispatch);
    }
};
export const getCarAvailablsModels = async (dispatch: Dispatch) => {
    try {
        const response = await request.getCarAvailablsModelsOnly();
        if (response.status === 200) {
            dispatch({
                type: FETCH_CAR_AVAILABLE_MODEL,
                payload: response.data?.carModels
            });
        }
    } catch (error) {
        console.log(error);
    }
};
export const getCarAvailableBrand = async (
    dispatch: Dispatch,
    slug: string
) => {
    try {
        const response = await request.getAvailModelsBYBrand(slug);
        if (response.status === 200) {
            dispatch({
                type: FETCH_CAR_AVAILABLE_MODEL,
                payload: response.data?.carModels
            });
        }
    } catch (error) {
        console.log(error);
    }
};
