import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IMAGE_URL } from '../constants';
import { financement } from '../redux/actions/financingAction';
import { ICombineReducerType } from '../redux/reducers';
import { IFinancingType } from '../redux/types/financingTypes';

import GetACallBack from './ui/GetACallBack';
import SecSeperator from './ui/SecSeperator';

const Finance = () => {
    const [open, setModalOpen] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        financement(dispatch);
    }, [dispatch]);
    const { financing } = useSelector(
        (state: ICombineReducerType) => state.financing
    );

    return (
        <div className="bank-details">
            <SecSeperator className="bank-details-sep" />
            <h3 className="title bank-details-title ">{t('financing')}</h3>
            <div className="bank-details-container">
                {financing.map((items: IFinancingType, index) => {
                    return (
                        <div className="bank-detail-holder" key={index}>
                            <BankDetail
                                image={items.image}
                                rateofinterest={items.rateOfInterest}
                                tenure={items.tenure}
                                emi={items.monthlyEMI}
                                setModalOpen={setModalOpen}
                            />
                        </div>
                    );
                })}
            </div>
            <GetACallBack
                open={open}
                setModalOpen={setModalOpen}
                category="financing"
            />
        </div>
    );
};

export default Finance;

const BankDetail = (props: any) => {
    const { t } = useTranslation();
    return (
        <div className="bank-detail">
            <div className="bank-logo">
                <img src={`${IMAGE_URL}${props.image}`} alt="" />
            </div>
            <div className="categories-container">
                {FinanceArray.map((items, index) => {
                    return (
                        <div className="categories border" key={index}>
                            <h5 className="category">{t(items.title)}</h5>
                            <p className="value">
                                {index === 0 ? (
                                    <>
                                        {t('from')} {props.rateofinterest}{' '}
                                    </>
                                ) : index === 1 ? (
                                    <>
                                        {' '}
                                        {t('upto')} {props.tenure} {t('years')}{' '}
                                    </>
                                ) : (
                                    <> {process.env.REACT_APP_DEFAULT_CURRENCY} {props.emi} </>
                                )}
                            </p>
                        </div>
                    );
                })}
            </div>
            <button
                className="bottom-btn"
                onClick={() => props.setModalOpen(true)}
            >
                {t('getACallBack')}
            </button>
        </div>
    );
};

const BankArray = [
    {
        banklogo: '/images/mansabank.webp',
        rateofinterest: ' 12 %',
        tenure: 5,
        emi: '30 000'
    },
    {
        banklogo: '/images/bicici.webp',
        rateofinterest: ' 12 %',
        tenure: 10,
        emi: '40 000'
    },
    {
        banklogo: '/images/mansabank.webp',
        rateofinterest: ' 12 %',
        tenure: 5,
        emi: '20 000'
    },
    {
        banklogo: '/images/nsia.webp',
        rateofinterest: ' 12 %',
        tenure: 10,
        emi: '30 000'
    }
];
const FinanceArray = [
    {
        title: 'rateOfInterest'
    },
    {
        title: 'tenure'
    },
    {
        title: 'monthlyEmi'
    }
];
