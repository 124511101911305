import { combineReducers } from 'redux';
import { ICarBrandsInitialType } from '../types/carBrandsTypes';
import { ICarCrtificationInitialType } from '../types/carCertificationTypes';
import { IInitialCarType } from '../types/carListTypes';
import { ICarModelInitialType } from '../types/carModelsTypes';
import { IContactUsInitialType } from '../types/contactUsTypes';
import { IQuestionnaireInitialType } from '../types/questionnaireTypes';
import { ISellingCarsInitialType } from '../types/sellCarTypes';
import { IServiceHistoryInitialType } from '../types/serviceHistoryTypes';
import { IWishListInitialType } from '../types/wishListTypes';
import carBrandsReducer from './carBrandsReducer';
import carCertificationReducer from './carCertificationReducer';
import carListingReducer from './carListingReducer';
import carModelsReducer from './carModelsReducer';
import carReducer, { ICarState } from './carReducer';
import contactReducer, { IContactState } from './contactReducer';
import contactUsReducer from './contactUsReducer';
import countryReducer, { ICountryState } from './countryReducer';
import customerGalleryReducer, {
    ICustomerGalleryState
} from './customerGalleryReducer';
import fetchDataReducer from './fetchDataReducer';
import homeReducer, { IHomeState } from './homeReducer';
import faqsReducer, { createFaqReducer } from './questionnaireReducer';
import sellingCarsReducer from './sellCarReducer';
import serviceHistoryReducer from './serviceHistoryReducer';
import storeReducer, { IStoreState } from './storeReducer';
import testimonialReducer, { ITestimonialState } from './testimonialReducer';
import wishListReducer from './wishlistReducer';
import { IPagesInitialType } from '../types/pagesType';
import { IBlogInitailType } from '../types/BlogListTypes';
import { IContactInformationInitialType } from '../types/contactInformationType';
import { IFinancePartnerInitialType } from '../types/financePartnerTypes';
import pagesReducer from './pagesReducer';
import blogReducer from './blogReducer';
import contactInformationReducer from './contactInformationReducer';
import financeReducer from './financePartnerReducer';
import { categoryReducer } from './categoriesReducer';
import { ICategoryInitailType } from '../types/categoryTypes';
import { authReducer, IAuthState } from './authReducer';
import { ISubscribeDataType } from '../types/subscribeTypes';
import subscribeReducer from './susbcribeReducer';
import financingReducer from './financingsReducer';
import { IFinancingInitialType } from '../types/financingTypes';
import FetchFeaturedCarsReducer, { IFeaturedCarsState } from './fetchFeaturedCarsReducer';
import { IFooterContentType } from '../types/footerContentTypes';
import FooterContentReducer from './footerContentReducer';
import appendClassInNavbarReducer, { IAppendClassToNavState } from './appendClassInNavbarReducer';
import fetchNewCarListReducer, { INewCarListState } from './fetchNewCarListReducer';

// you need to add all you new reducers here so that all reducers are combined
const rootReducer = combineReducers({
    auth: authReducer,
    fetchData: fetchDataReducer,
    carListing: carListingReducer,
    wishList: wishListReducer,
    faqs: faqsReducer,
    carCertification: carCertificationReducer,
    carBrands: carBrandsReducer,
    serviceHistory: serviceHistoryReducer,
    sellingCars: sellingCarsReducer,
    carModels: carModelsReducer,
    contactQuery: contactUsReducer,
    contactInformation: contactInformationReducer,
    pages: pagesReducer,
    financePartners: financeReducer,
    blogs: blogReducer,
    categories: categoryReducer,
    car: carReducer,
    home: homeReducer,
    featuredCars: FetchFeaturedCarsReducer,
    newCarList: fetchNewCarListReducer,
    testimonial: testimonialReducer,
    customerGallery: customerGalleryReducer,
    contact: contactReducer,
    store: storeReducer,
    country: countryReducer,
    subscriber: subscribeReducer,
    createFaq: createFaqReducer,
    financing: financingReducer,
    footerContent: FooterContentReducer,
    appendClassInNavbarReducer,
});

export default rootReducer;

export interface ICombineReducerType {
    fetchData: any;
    carListing: IInitialCarType;
    wishList: IWishListInitialType;
    faqs: IQuestionnaireInitialType;
    carCertification: ICarCrtificationInitialType;
    carBrands: ICarBrandsInitialType;
    sellingCars: ISellingCarsInitialType;
    carModels: ICarModelInitialType;
    serviceHistory: IServiceHistoryInitialType;
    contactQuery: IContactUsInitialType;
    auth: IAuthState;
    car: ICarState;
    home: IHomeState;
    featuredCars: IFeaturedCarsState;
    newCarList: INewCarListState;
    testimonial: ITestimonialState;
    customerGallery: ICustomerGalleryState;
    contact: IContactState;
    store: IStoreState;
    country: ICountryState;
    contactInformation: IContactInformationInitialType;
    pages: IPagesInitialType;
    financePartners: IFinancePartnerInitialType;
    blogs: IBlogInitailType;
    categories: ICategoryInitailType;
    subscriber: ISubscribeDataType;
    createFaq: IQuestionnaireInitialType;
    financing: IFinancingInitialType;    
    footerContent: IFooterContentType;
    appendClassInNavbarReducer: IAppendClassToNavState;
}
