import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getFooterContent } from '../redux/actions/footerContentActions';
import { ICombineReducerType } from '../redux/reducers';
import SecSeperator from './ui/SecSeperator';
import { useLocation } from 'react-router-dom';

interface IFooterDetails {
    footerTitle: string;        
    footerSubTitle: string;        
    footerDesc: string;}

const FooterContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const footerContentData = useSelector((state: ICombineReducerType) => state.footerContent);

    const [footerContent, setFooterContent] = useState<IFooterDetails>({
        footerTitle: "",
        footerSubTitle: "",
        footerDesc: "",
    })

    useEffect(() => {
        getFooterContent(dispatch);
    }, []);

    useEffect(() => {
        if(footerContentData) {
            const footerDetails = footerContentData?.footerContent?.details.find((item) => location.pathname.includes(`/${item.lang}`)) ?? footerContentData?.footerContent?.details.find((item) => item?.lang === "en");
            if(footerDetails) {
                setFooterContent({
                footerTitle: footerDetails.title,
                footerSubTitle: footerDetails.sub_title,
                footerDesc: footerDetails.description,
                })
            } else {
                setFooterContent({
                footerTitle: footerContentData?.footerContent?.details[0].title ?? "",
                footerSubTitle: footerContentData?.footerContent?.details[0].sub_title ?? "",
                footerDesc: footerContentData?.footerContent?.details[0].description ?? "",
                })

            }
        }

    }, [footerContentData])

    return (
        <>{footerContentData &&
        <div className="press">
            <div className="padding">
                <SecSeperator className="center" />
                <h2 className="title">{footerContent.footerTitle}</h2>
                <div className='subtitle' dangerouslySetInnerHTML={{__html: footerContent.footerSubTitle}} />
                <div className='subtitle' dangerouslySetInnerHTML={{__html: footerContent.footerDesc}} />
            </div>
        </div>
        }</>
    );
};

export default FooterContent;