import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Dropdown, Menu } from 'antd';
import { CaretDown, List, UserCircle } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

interface Props {
    type?: 'header' | 'other';
    items: any[];
    variant?: 'default' | 'sm';
}

const Sidebar = (props: Props) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const location = useLocation();
    const { t } = useTranslation();

    const { items = [], type = 'header' } = props || {};

    const menu = () => {
        return <Menu items={items} />;
    };

    const categoriesList = () => (
        <li>
            <div
                className={
                    location.pathname.split('/').includes('category')
                        ? 'categories-item active'
                        : 'categories-item'
                }
            >
                {t('categories')}
                <span className="caret-icon">
                    <CaretDown size={16} color="#595959" weight="bold" />
                </span>
            </div>
        </li>
    );
    const buttonType = () => (
        <button className="menu-btn">
            <UserCircle size={32} color="#454545" />
            <div className="seperator"></div>
            <List size={32} />
        </button>
    );
    const visbilityHandler = () => {
        if (window.scrollY >= 0) {
            setDropdownVisible(false);
        } else {
            setDropdownVisible(dropdownVisible);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', visbilityHandler);
        return () => window.removeEventListener('scroll', visbilityHandler);
    }, [dropdownVisible]);

    return (
        <div onClick={() => setDropdownVisible(!dropdownVisible)}>
            <Dropdown
                overlay={menu}
                overlayClassName="login-dropdown"
                trigger={['click']}
                placement="bottomRight"
                open={dropdownVisible}
                getPopupContainer={(trigger: any) => trigger.parentNode}
                onOpenChange={visbilityHandler}
            >
                {type === 'header' ? buttonType() : categoriesList()}
            </Dropdown>
        </div>
    );
};

export default Sidebar;
