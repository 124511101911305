import { useTranslation } from 'react-i18next';

import Loading from './ui/Loading';

import { $FIXME, IMAGE_URL } from '../constants';
import SecSeperator from './ui/SecSeperator';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const Brands = (props: $FIXME) => {
    const { t } = useTranslation();
    return (
        <div className="brands-name">
            <SecSeperator />
            <h2 className="title">{t('ourBrands')}</h2>
            <p className="subtitle">{t('ourBrandsSubtitle')}</p>
            <div className="brand-name-items">
                {props?.carBrandsArrays?.length ? (
                    props?.carBrandsArrays?.map((items: $FIXME) => {
                        return (
                            <div key={items._id}>
                                <div className="brand-image">
                                    <img
                                        src={IMAGE_URL + items.image}
                                        alt={items.brand}
                                        onError={addImageFallback}
                                    />
                                </div>
                                <p className="brand-name">{items?.brand}</p>
                            </div>
                        );
                    })
                ) : (
                    <Loading />
                )}
            </div>
        </div>
    );
};

export default Brands;
