import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';

import { $FIXME } from '../constants';

type ProcessType = 'buy' | 'sell';

interface ProcessSwitchProps {
    value: ProcessType;
    onChange: (value: ProcessType) => void;
}

const ProcessSwitch = ({ value, onChange }: ProcessSwitchProps) => {
    const { t } = useTranslation();
    return (
        <button
            className="process-switch"
            onClick={() => onChange(value === 'buy' ? 'sell' : 'buy')}
        >
            <div
                className={`process-switch-toggle ${
                    value === 'buy' ? 'process-switch-toggle-checked' : ''
                }`}
            >
                <span>{t('howtoBuy')}</span>
            </div>

            <div
                className={`process-switch-toggle ${
                    value === 'sell' ? 'process-switch-toggle-checked' : ''
                }`}
            >
                <span>{t('howtoSell')}</span>
            </div>
        </button>
    );
};

const Process = (props: any) => {
    const { t } = useTranslation();
    const [processValue, setProcessValue] = useState<ProcessType>('buy');

    const onChange = (value: ProcessType) => {
        setProcessValue(value);
    };

    return (
        <div className="process-container">
            <div className="process">
                <SecSeperator className="why-sep" />
                <h2 className="title">{t(props.title)}</h2>
                {props.showSwitch && (
                    <div className="process-headers">
                        <ProcessSwitch
                            value={processValue}
                            onChange={onChange}
                        />
                    </div>
                )}
                <div className={processValue === 'sell' ? 'show' : '  hide'}>
                    <SellPane />
                </div>
                <div className={processValue === 'buy' ? 'show' : '  hide'}>
                    <BuyPane whyAuto={props.whyAUTO24} />
                </div>
            </div>
        </div>
    );
};

export default Process;

const BuyPane = (props: $FIXME) => {
    return (
        <>
            <div className="buy-pane-desktop">
                <ProcessUnit
                    image="/images/search.svg"
                    alt="exploreCar"
                    title="discovercar"
                    desc="discovercarSubtitle"
                />
                <div className="down-image">
                    <img src="/images/down.webp" alt="downward dots" />
                </div>
                <ProcessUnit
                    image="/images/check.svg"
                    alt="chooseCar"
                    title="chooseCar"
                    desc={
                        props.whyAuto
                            ? 'chooseACarSubtitle'
                            : 'chooseACarSubtitles'
                    }
                />
                <div className="up-image">
                    <img src="/images/up.webp" alt="upward dots" />
                </div>
                <ProcessUnit
                    image="/images/car.svg"
                    alt="buyACar"
                    title="buyACar"
                    desc={props.whyAuto ? 'buyACarDesc' : 'buyACarDescs'}
                />
            </div>
        </>
    );
};
const SellPane = () => {
    return (
        <>
            <div className="buy-pane-desktop">
                <ProcessUnit
                    image="/images/car.svg"
                    alt="yourCar"
                    title="yourCar"
                    desc="carsellInfo"
                />
                <div className="down-image">
                    <img src="/images/down.webp" alt="downward dots" />
                </div>
                <ProcessUnit
                    image="/images/details.svg"
                    alt="valuation"
                    title="valuation"
                    desc="valuationInfo"
                />
                <div className="up-image">
                    <img src="/images/up.webp" alt="upward dots" />
                </div>
                <ProcessUnit
                    image="/images/cash.svg"
                    alt="sellCar"
                    title="sellCar"
                    desc="sellCarInfo"
                />
            </div>
        </>
    );
};

const ProcessUnit = (props: any) => {
    const { t } = useTranslation(['hero']);
    return (
        <div className="process-unit">
            <div className="process-image">
                <img src={props.image} alt={t(props.alt)} />
            </div>
            <h3 className="process-title">{t(props.title)}</h3>
            <p className="process-desc">{t(props.desc)}</p>
        </div>
    );
};
const ProcessComp = (props: {
    imagesrc: string;
    alt: string;
    descs: { key: string; number: number } | string;
    description?: string;
    whiteBg?: boolean;
}) => {
    const { t } = useTranslation();
    const desc = () => {
        if (typeof props.descs === 'object')
            return t(props.descs?.key, { number: props.descs?.number });
        if (typeof props.descs === 'string') return t(props.descs);
    };

    return (
        <div className="process-comp">
            <div
                className={
                    props.whiteBg
                        ? 'process-comp-image whiteBg'
                        : 'process-comp-image '
                }
            >
                <img src={props.imagesrc} alt={t(props.alt)} />
            </div>
            <p className="process-comp-desc">
                {props.description && t(props.description)}
            </p>
            <p className="process-comp-descs">{desc()}</p>
        </div>
    );
};
export const ProcessBackground = (props: $FIXME) => {
    const { t } = useTranslation();
    return (
        <div className={`process-background ${props.class}`}>
            {props.showHeader ? (
                <>
                    <SecSeperator
                        className="center"
                        borderColor="#fff"
                        backgroundColor="#fff"
                    />
                    <h2>AUTO24 {t('Certified')}</h2>{' '}
                </>
            ) : (
                ''
            )}
            <div className="process-comp-container padding">
                {props.StatsArray.map((items: $FIXME, index: number) => {
                    return (
                        <ProcessComp
                            imagesrc={items.imagesrc}
                            alt={items.alt}
                            description={items.description}
                            descs={items.descs}
                            key={index}
                            whiteBg={true}
                        />
                    );
                })}
            </div>
        </div>
    );
};
