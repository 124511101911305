import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ArrowLeft, Car, CaretRight, Eye, GasPump } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import 'react-phone-input-2/lib/style.css';

import BreadCrumbs from '../components/ui/BreadCrumbs';
import GetACallBack from '../components/ui/GetACallBack';
import { formatMoney } from '../components/SearchFilter';
import Loading from '../components/ui/Loading';
import SecSeperator from '../components/ui/SecSeperator';
import Layout from '../layout/Layout';

import { $FIXME, IMAGE_URL } from '../constants';
import { ICombineReducerType } from '../redux/reducers';
import { getDetail } from '../redux/actions/carListingAction';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

interface Prop {
    icon: any;
    title: string;
    content: string;
    onClick: any;
}

const Iminterested = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();
    const { car, loading } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const navigate = useNavigate();

    const showModal = (e: any) => {
        setIsModalVisible(true);
        e.preventDefault();
    };

    useEffect(() => {
        if (id) getDetail(dispatch, id);
    }, [id, dispatch]);
    const { t } = useTranslation();
    let image = '';
    if (car.images) image = JSON.parse(car?.images)[0];

    const InterestedCardData = [
        {
            icon: <Car size={24} color="#091731" />,
            title: 'bookThisCar',
            content: 'bookThisCarSubtitle'
        },
        {
            icon: <Eye size={24} color="#091731" />,
            title: 'scheduleAVisit',
            content: 'scheduleAVisitSubtitle'
        }
    ];
    const goBack = () => {
        navigate(-1);
    };
    const goBackToListing = () => {
        navigate(t('/buy-cars', { ns: 'routes' }));
    };
    return (
        <Layout showWhiteNavbar showInterestNav>
            <div className="interested interested-padding">
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        <BreadCrumbs
                            current="Car"
                            currentLocation={`${car?.brand?.brand} ${
                                car?.model?.model
                            } ${car?.modelYear} - ${formatMoney(
                                car.price
                            )} ${process.env.REACT_APP_DEFAULT_CURRENCY}`}
                            currentLocation1="imInterested"
                        />
                        <SecSeperator />
                        <h2 className="title">{t('interestcartitle')}</h2>
                        <p className="subtitle">{t('interestcarsubtitle')}</p>
                        {car && (
                            <CarCard car={car} image={image} onclick={goBack} />
                        )}
                    </>
                )}
                <div className="interested-card-container">
                    {InterestedCardData.map((items, index) => {
                        return (
                            <InterestedCard
                                key={index}
                                icon={items.icon}
                                title={items.title}
                                content={items.content}
                                onClick={showModal}
                            />
                        );
                    })}
                </div>
                <GetACallBack
                    open={isModalVisible}
                    setModalOpen={setIsModalVisible}
                    category="i am interested"
                    carId={id}
                />
                <button onClick={goBack} className="back-to-card">
                    <ArrowLeft
                        size={20}
                        color="#091731"
                        weight="bold"
                        onClick={goBack}
                    />
                    {t('backToTheCarDescription')}
                </button>
                <button
                    onClick={goBackToListing}
                    className="bottom-btn interest-btn"
                >
                    {t('backToTheCarListing')}
                </button>
            </div>
        </Layout>
    );
};

export default Iminterested;

const InterestedCard = (prop: Prop) => {
    const { t } = useTranslation();
    return (
        <div className="interested-card" onClick={prop.onClick}>
            <div className="interested-card__top">
                <div className="interested-card__title">
                    {prop.icon}{' '}
                    <h3 className="interested-card-title">{t(prop.title)}</h3>
                </div>
                <CaretRight size={20} color="#454545" />
            </div>
            <p>{t(prop.content)}</p>
        </div>
    );
};

export const CarCard = (props: $FIXME) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="car-card">
                <div className="car-card__left">
                    <div className="car-image" onClick={props.onclick}>
                        <img
                            src={IMAGE_URL + props.image}
                            alt={props.car.brand}
                            onError={addImageFallback}
                        />
                    </div>
                    <div className="car-deets">
                        <h5 className="car-name" onClick={props.onclick}>
                            {props?.car?.brand?.brand}{' '}
                            {props?.car?.model?.model} {props?.car?.modelYear}
                        </h5>
                        <div className="car-features">
                            <div className="features-container">
                                <div className="features-icons">
                                    <img
                                        src="/logos/automatic.webp"
                                        alt="automatic"
                                    />
                                </div>
                                {props?.car?.transmission &&
                                    t(props.car.transmission)}
                            </div>
                            <span className="features-container">
                                <GasPump size={17} color="#454545" />
                                {props?.car?.fuelType && t(props?.car.fuelType)}
                            </span>
                            <div className="features-container">
                                <div className="features-icons">
                                    <img
                                        src="/logos/timeline.webp"
                                        alt="timeline"
                                    />
                                </div>
                                {formatMoney(props.car.mileage)} km
                            </div>
                        </div>
                    </div>
                </div>
                <div className="car-card__right">
                    <h4 className="new-price">
                        {props.car?.price
                            ?.toLocaleString()
                            .replaceAll(',', ' ')}{' '}
                        {process.env.REACT_APP_DEFAULT_CURRENCY}
                    </h4>
                </div>
            </div>
        </>
    );
};
