import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Footer from '../layout/Footer';
import Navbar from '../layout/Navbar';
import Loading from './ui/Loading';

import { api } from '../helpers/api';

export default function UnsubscribeBlog() {
    const navigate = useNavigate();

    const params = useParams();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (params?.token) {
            const options = {
                method: 'PUT',
                url: `/subscribers`,
                headers: {
                    Authorization: `Bearer ${params.token}`
                }
            };
            api.request(options)
                .then((res) => {})
                .catch((err) => navigate(t('home', { ns: 'routes' })))
                .finally(() => setLoading(false));
        }
    }, []);
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Navbar whiteBackground />
                    <div className="unsubscribe-blog">
                        {t('unSubscribeMessage')}
                    </div>
                    <Footer setIsFooterVisible={() => {}} />
                </>
            )}
        </>
    );
}
