import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Checkbox, Form, message } from 'antd';
import { Envelope, LockSimple, Phone, SpinnerGap, User } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import ButtonComponent from '../ui/ButtonComponent';
import TextBox from '../ui/TextBox';
import { useForm } from 'antd/lib/form/Form';
import { login } from '../../redux/actions/authActions';
import { defaultDailing_Code } from '../../helpers/countryCode';
import { $FIXME } from '../../constants';
import { addToWishList } from '../../redux/actions/wishlistAction';

interface Props {
    handleActiveModal: (id: number) => void;
    handleCancel: () => void;
    checkLoggedIn: () => void;
    setNumber: $FIXME;
    liked_id: string | undefined;
    setToken: $FIXME;
}

const SignInForm = ({
    handleActiveModal,
    handleCancel,
    setNumber,
    setToken,
    liked_id
}: Props) => {
    const [formData, setFormData] = useState({
        code: defaultDailing_Code,
        email: '',
        number: '',
        password: '',
        remember: false
    } as any);
    const [isemail, setMethod] = useState(true);
    const [error, setError] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [form] = useForm();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const Location = useLocation();
    const lang: string = i18n.language;

    const signInFormData = [
        isemail
            ? {
                  placeHolder: 'enteremailAddress',
                  valuePrefix: 'email',
                  prefix: <User weight="bold" />,
                  type: 'input',
                  rules: [
                      { required: true, message: t('pleaseInputYourEmail') },
                      {
                          type: 'email',
                          message: t('pleaseInputValidEmail')
                      }
                  ]
              }
            : {
                  placeHolder: 'phoneNumber',
                  valuePrefix: 'number',
                  prefix: <Phone weight="bold" />,
                  type: 'phone-number',
                  rules: [
                      {
                          required: true,
                          message: t('pleaseInputYourPhoneNumber')
                      }
                  ]
              },
        {
            placeHolder: 'enterPassword',
            valuePrefix: 'password',
            prefix: <LockSimple weight="bold" />,
            type: 'password',
            rules: [{ required: true, message: t('pleaseInputYourPassword') }]
        }
    ];
    useEffect(() => {
        setError('');
        form.setFields([
            {
                name: 'password',
                errors: []
            }
        ]);
    }, [isemail]);

    const handleChange = (key: any, value: any) => {
        setError('');
        setFormData((formData: any) => ({ ...formData, [key]: value }));
    };
    const onSelect = (value: string, key: string) => {
        setError('');
        setFormData((formData: any) => ({ ...formData, [key]: value }));
    };
    const checkButtonStatus = () => {
        const buttonDisabledCheck = form
            .getFieldsError()
            .filter((field) => field.errors.length > 0);
        if (isemail) {
            formData.email && formData.password && !buttonDisabledCheck.length
                ? setButtonDisabled(false)
                : setButtonDisabled(true);
        } else {
            formData.password &&
            formData.number &&
            formData.number.length === 10
                ? setButtonDisabled(false)
                : setButtonDisabled(true);
        }
    };

    const handleSubmit = (e: any) => {
        setLoading(true);
        let pay = isemail
            ? {
                  email: formData.email,
                  password: formData.password,
                  remember: formData.remember
              }
            : {
                  code: formData.code ?? defaultDailing_Code,
                  number: formData.number,
                  password: formData.password,
                  remember: formData.remember
              };
        login(dispatch, pay)
            .then(async (msg) => {
                handleCancel();
                if (liked_id) {
                    await addToWishList(dispatch, liked_id);
                }
            })
            .catch(({ msg, phoneNumberVerificationToken = null }) => {
                let err = typeof msg === 'string' ? msg : msg[lang];
                if (!isemail && !!phoneNumberVerificationToken) {
                    message.info(t(err));
                    setNumber({
                        code: pay.code,
                        number: pay.number
                    });
                    setToken(phoneNumberVerificationToken);
                    handleActiveModal(5);
                } else {
                    setError(t('verifyEmail'));
                }
            })
            .finally(() => {
                setLoading(false);
            });
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleError = () => {};
    return (
        <div className="signin-form-wrapper">
            <Form
                onFinish={handleSubmit}
                onFinishFailed={handleError}
                onFieldsChange={() => checkButtonStatus()}
                form={form}
                autoComplete="true"
            >
                {signInFormData.map((formType: any, idx: any) => (
                    <Form.Item
                        key={idx}
                        name={formType.valuePrefix}
                        rules={formType.rules}
                        className={
                            idx !== signInFormData.length - 1
                                ? 'user-input-box margin overflow-visible'
                                : 'user-input-box overflow-visible'
                        }
                    >
                        <TextBox
                            name={formType.valuePrefix}
                            key={idx * 10}
                            placeholder={t(formType.placeHolder)}
                            type={formType.type}
                            onChange={(data) =>
                                handleChange(formType.valuePrefix, data)
                            }
                            onSelect={onSelect}
                            prefix={formType.prefix}
                            value={formData[formType.valuePrefix]}
                        />
                    </Form.Item>
                ))}
                {error && <p className="error-message">{error}</p>}
                <div className="remember-forgot-section">
                    <div className="remember-me">
                        <Checkbox
                            className="check-box"
                            id="rememberMe"
                            onChange={(e) =>
                                handleChange('remember', e.target.checked)
                            }
                        />
                        <label htmlFor="rememberMe">{t('rememberMe')}</label>
                    </div>
                    <span
                        onClick={() => handleActiveModal(2)}
                        className="forgot-password"
                    >
                        {t('forgotPassword')}?
                    </span>
                </div>

                <Form.Item>
                    <ButtonComponent
                        type="primary"
                        htmlType="submit"
                        className={`sign-in-button ${
                            loading ? 'loading-btn' : ''
                        }`}
                        disabled={loading || buttonDisabled}
                    >
                        {loading ? (
                            <>
                                <SpinnerGap
                                    size={32}
                                    weight="bold"
                                    className="rotate"
                                />
                                {t('signingIn')}
                            </>
                        ) : (
                            t('signIn')
                        )}
                    </ButtonComponent>
                    {/*<div className="or">{t('or')}</div>*/}
                    {/*<div*/}
                    {/*    onClick={() => setMethod(!isemail)}*/}
                    {/*    className="button-wrapper  sign-in-button button-secondary flex-justify-between"*/}
                    {/*>*/}
                    {/*    {!isemail ? (*/}
                    {/*        <>*/}
                    {/*            <Envelope className="icon" />*/}
                    {/*            <div>{t('signWithEmail')}</div>*/}
                    {/*        </>*/}
                    {/*    ) : (*/}
                    {/*        <>*/}
                    {/*            <Phone className="icon" />*/}
                    {/*            <div> {t('signWithPhone')}</div>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                </Form.Item>
            </Form>

            <span className="sign-up-redirect">
                {t('donthaveanaccount')}?{' '}
                <span onClick={() => handleActiveModal(0)}>{t('signUp')}</span>
            </span>
        </div>
    );
};
export default SignInForm;
