import { message } from 'antd';
import { Dispatch } from 'redux';
import { subscription } from '../../constants';

import { Api } from '../Api';
import {
    SUBSCRIBE_ERROR,
    SUBSCRIBE_LOADING,
    SUBSCRIBE_SUCCESS
} from '../types/subscribeTypes';
import i18n from '../../localization';

const acceptLanguage = "Accept-Language";

export const getSubscription = async (dispatch: Dispatch, data: any) => {
    dispatch({ type: SUBSCRIBE_LOADING });
    const resData: any = await subscribe(data);

    if (resData.status === 200) {
        dispatch({
            type: SUBSCRIBE_SUCCESS,
            payload: resData.data.subscriber
        });
        message.success(i18n.t('subscribedSuccessfully') as string);
        return true;
    } else if (resData.response.data.status === false) {
        dispatch({
            type: SUBSCRIBE_ERROR
        });
        // message.error(i18n.t('alreadySubscribed') as string);
    } else {
        // message.error(resData.response.data?.msg);
    }
};

export const subscribe = async (data: any) => {
    try {
        const result = await Api.post(`${subscription}`, { email: data }, {
            headers: {
                [acceptLanguage]: i18n.language
            }
        });
        return result;
    } catch (error) {
        return error;
    }
};
