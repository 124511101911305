import { message } from 'antd';
import { Dispatch } from 'redux';
import { financePartner } from '../../constants';
import { Api } from '../Api';
import {
    FETCH_FINANCE_PARTNER_ERROR,
    FETCH_FINANCE_PARTNER_LOADING,
    FETCH_FINANCE_PARTNER_SUCCESS
} from '../types/financePartnerTypes';

export const financePartners = async (dispatch: Dispatch, _id: string) => {
    dispatch({ type: FETCH_FINANCE_PARTNER_LOADING });
    const resData: any = await getFinacePartner(_id);
    if (resData.status === 200) {
        dispatch({
            type: FETCH_FINANCE_PARTNER_SUCCESS,
            payload: resData.data.financingPartners
        });
    } else {
        dispatch({ type: FETCH_FINANCE_PARTNER_ERROR });
        // message.error(resData.response.data?.msg);
    }
};

export const getFinacePartner = async (_id: string) => {
    try {
        const result = await Api.get(`${financePartner}?page=${_id}`);
        return result;
    } catch (error) {
        return error;
    }
};
