import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';

import { IMAGE_URL } from '../constants';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

interface IPropType {
    title: string;
    subtitle?: boolean;
    financingPartners?: any[];
    class?: string;
    subtitles: string;
}

const FinancingPartner = (props: IPropType) => {
    const { t } = useTranslation();
    if (props?.financingPartners && props?.financingPartners.length > 0) {
        return (
            <div className='finance-partner'>
                <div className='financing-partner padding'>
                    <SecSeperator />
                    <h2 className='title'>{t(props.title, { ns: 'common' })}</h2>
                    <p className='subtitle'>
                        {t(props.subtitles, { ns: 'common' })}
                    </p>
                    <div className='finance-partners'>
                        {props?.financingPartners?.map((items, index) => {
                            return (
                                <div
                                    className={`partner-images ${props.class}`}
                                    key={index}
                                >
                                    {items.link ? (
                                        <Link
                                            to={{ pathname: items.link }}
                                            target='_blank'
                                        >
                                            <img
                                                src={IMAGE_URL + items.image}
                                                alt='partners'
                                                onError={addImageFallback}
                                            />
                                        </Link>
                                    ) : (
                                        <img
                                            src={IMAGE_URL + items.image}
                                            alt='partners'
                                            onError={addImageFallback}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default FinancingPartner;
