import { ICar } from '../../interface/car';

export const CAR_LIST_LOADING = 'CAR_LIST_LOADING';
export const CAR_LIST_ERROR = 'CAR_LIST_ERROR';
export const CAR_LIST_SUCCESS = 'CAR_LIST_SUCCESS';

export const GET_CARS_DETAILS = 'GET_CARS_DETAILS';

export const CAR_BRANDS_LOADING = 'CARS_BRANDS_LOADING';
export const CAR_BRANDS_ERROR = 'CAR_BRANDS_ERROR';
export const CAR_BRANDS_SUCCESS = 'CAR_BRANDS_SUCCESS';

export interface IInitialCarType {
    loading: boolean;
    error: string | null;
    cars: Array<ICar>;
    car: ICar;
    similarCars: Array<ICar>;
    totalCar?: number;
}
