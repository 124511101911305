import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { User } from 'phosphor-react';

import CountryCodeDropdown, { FullNameInputComp } from './CountryCodeDropdown';
import LoadingButton from './LoadingButton';
import SecSeperator from './SecSeperator';

import { defaultDailing_Code } from '../../helpers/countryCode';
import { IBuyCar, request } from '../../helpers/request';
import { ICombineReducerType } from '../../redux/reducers';
import { $FIXME, ROUTES } from '../../constants';
import {
    emailValidate,
    fullNameValidate,
    numcheck,
    returnOnlyNumber
} from '../../utils/Helper/jsHelpers';
import { setFullNameError } from '../Certification';

interface IBuyCarConfirm {
    open: boolean;
    setModalOpen: (open: boolean) => void;
    carId: string;
}

const BuyCarConfirm = (props: IBuyCarConfirm) => {
    const {open, setModalOpen, carId } = props;
    const { t } = useTranslation();
    const [isvisiable, setVisiable] = useState(false);
    const [error, setError] = useState<any>({});
    const [disabled, setDisabled] = useState(true);
    let { user } = useSelector((state: ICombineReducerType) => state.auth);
    const [userData, setUserData] = useState({
        phoneNumber: {
            code: user?.phoneNumber?.code ?? defaultDailing_Code,
            number: user?.phoneNumber?.number ?? ''
        },
        fullName: user?.name ?? '',
        email: user?.email ?? '',
    });
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        setUserData({
            phoneNumber: {
                code: user?.phoneNumber?.code ?? defaultDailing_Code,
                number: user?.phoneNumber?.number ?? ''
            },
            fullName: user?.name ?? '',
            email: user?.email ?? ''
        });
        setError({});
    }, [open]);

    function handleChange(e: any) {
        setUserData((prev) => ({
            ...prev,
            phoneNumber: {
                ...prev.phoneNumber,
                [e.target.name]: returnOnlyNumber(e.target.value)
            }
        }));
    }
    useEffect(() => {
        if (userData.fullName) {
            if (fullNameValidate(userData.fullName)) {
                setError({});
            }
        }
    }, [userData.fullName]);

    useEffect(() => {
        if (!userData.fullName && !userData.phoneNumber.number && !userData.email) {
            setError({});
        }
        if (
            fullNameValidate(userData.fullName) 
            && userData.phoneNumber.number.toString().length === 10 
            && emailValidate(userData.email)
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [userData]);

    const setEmailError = function (
        email: $FIXME,
        setErrors: $FIXME,
        t: $FIXME
    ) {
        if (!emailValidate(email)) {
            setErrors({
                email: t('pleaseInputValidEmail')
            });
        }
    };

    async function submit(e: any) {
        e.preventDefault();
        setLoading(true);
        try {
            if (true || (await numcheck(userData.phoneNumber, setError))) {
                let {
                    fullName,
                    phoneNumber: { code, number },
                    email
                } = userData;
                let requestData: IBuyCar = {
                    fullName,
                    code,
                    number,
                    email,
                    car: carId,
                };
                await request.sendBuyCarInfo(requestData);
                setModalOpen(false);
                setVisiable(true);
            }
        } catch (err: any) {
            // message.error(t('somethingWentWrong'));
        } finally {
            setLoading(false);
        }
    }

    const closeModal = () => {
        setModalOpen(false);
        setLoading(false);
    };

    const goToHome = () => {
        navigate(t(ROUTES.home, { ns: 'routes' }));
        setVisiable(false);
    };

    const SuccessModal = () => {
        return (
            <Modal
                className="save-changes-modal ants-modal"
                title={t('Detailssentsuccessfully')}
                visible={isvisiable}
                onCancel={() => setVisiable(false)}
                closable={true}
                centered={true}
            >
                <p className="subtitle">{t('DetailChangeSubtitle')}</p>
                <div className="done-icon">
                    <img src="/images/done.webp" alt="" />
                </div>
                <div className="get-call-back">
                    <button className="back-to-home" onClick={() => goToHome()}>
                        {t('backToHome')}
                    </button>
                </div>
            </Modal>
        );
    };

    return (
        <>
            <Modal
                className="callback-modal ants-modal "
                visible={open}
                closable={true}
                onCancel={closeModal}
            >
                <SecSeperator className="modal-sec-seperator" />
                <div className="getacallback-header">
                    {t('provideUsYourDetails')}
                </div>
                <span className="subtitle">{t('callBackSubtitle')}</span>
                <form onSubmit={submit}>
                    <div className=" phone-input overflow  relative  ">
                        <label>
                            <User size={24} color="#8b8b8b" />
                        </label>
                        <input
                            onBlur={() => {
                                if (userData.email) {
                                    setEmailError(
                                        userData.email,
                                        setError,
                                        t
                                    );
                                }
                            }}
                            type="text"
                            placeholder={t('enteremailAddress')}
                            onChange={(e: $FIXME) => {
                                setUserData({
                                    ...userData,
                                    [e.target.name]: e.target.value
                                });
                            }}
                            name="email"
                            value={userData.email}
                            required
                            autoComplete="off"
                            disabled={user?.email ? true : false}
                        />
                        {error.email && (
                            <div
                                className="error-label absolute fade-in "
                                style={{ bottom: '-25px', left: 0 }}
                            >
                                {t(error.email)}
                            </div>
                        )}
                    </div>
                    <FullNameInputComp
                        setFullNameError={() => {
                            if (userData.fullName) {
                                setFullNameError(
                                    userData.fullName,
                                    setError,
                                    t
                                );
                            }
                        }}
                        handleChange={(e: $FIXME) => {
                            setUserData({
                                ...userData,
                                [e.target.name]: e.target.value
                            });
                        }}
                        value={userData.fullName}
                        t={t}
                        err={error.fullName}
                    />
                    <div className="phone-number">
                        <CountryCodeDropdown
                            onInputChange={handleChange}
                            onSelect={(value, key) =>
                                setUserData((prev) => ({
                                    ...prev,
                                    phoneNumber: {
                                        ...prev.phoneNumber,
                                        [key]: value
                                    }
                                }))
                            }
                            selectName="code"
                            inputName="number"
                            error={error.phoneNumber ?? null}
                            inputValue={userData?.phoneNumber.number}
                            selectValue={userData?.phoneNumber?.code}
                            inputStyle={{ backgroundColor: 'white' }}
                        />
                    </div>
                    <div className="cert-submit callback-footer">
                        <span>
                            {t('byClickingXButton', {
                                name: t('send')
                            })}{' '}
                            <Link
                                to={t('/terms-of-use', { ns: 'routes' })}
                                className="termandpolicy"
                            >
                                {t('termOfUse')}
                            </Link>{' '}
                            {t('and')}{' '}
                            <Link
                                to={t('/privacy-policy', { ns: 'routes' })}
                                className="termandpolicy"
                            >
                                {t('privacyPolicy')}
                            </Link>
                        </span>
                        {loading ? (
                            <LoadingButton buttonText="sending" />
                        ) : (
                            <button
                                className="bottom-btn"
                                disabled={disabled}
                                type="submit"
                            >
                                {t('send')}
                            </button>
                        )}
                    </div>
                </form>
            </Modal>
            <SuccessModal />
        </>
    );
};
export default BuyCarConfirm;