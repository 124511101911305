import { message } from 'antd';
import { Dispatch } from 'redux';
import { pages } from '../../constants';
import { Api } from '../Api';
import {
    FETCH_PAGES_CONTENT_ERROR,
    FETCH_PAGES_CONTENT_LOADING,
    FETCH_PAGES_CONTENT_SUCCESS,
    RESET_PAGES_CONTENT_SUCCESS
} from '../types/pagesType';

export const pagesContent = async (dispatch: Dispatch, _id: string) => {
    dispatch({ type: FETCH_PAGES_CONTENT_LOADING });
    const resData: any = await getPages(dispatch, _id);
    if (resData.status === 200) {
        dispatch({
            type: FETCH_PAGES_CONTENT_SUCCESS,
            payload: resData.data.pages
        });
    } else {
        dispatch({ type: FETCH_PAGES_CONTENT_ERROR });

        // message.error(resData.response.data?.msg);
    }
};

export const getPages = async (dispatch: any, _id: string) => {
    try {
        const result = await Api.get(`${pages}?page=${_id}`);

        return result;
    } catch (error) {
        return error;
    }
};

export const resetPagesContent = (dispatch: Dispatch) => {
    dispatch({ type: RESET_PAGES_CONTENT_SUCCESS });
}