import { useLocation } from 'react-router-dom';

import NavWithSearch from './NavWithSearch';

import Loading from '../components/ui/Loading';
import Footer from './Footer';
import Navbar from './Navbar';

const Layout = ({
    showWhiteNavbar,
    ShowdetailsNav,
    showAccountNav,
    showInterestNav,
    headerText,
    SearchBar,
    children,
    isLoading = false,
    setIsFooterVisible,
    setActiveNavClick,
    newCarFlag = false
}: any) => {
    const handleScroll = (n: boolean) => {
        if (setIsFooterVisible) {
            setIsFooterVisible(n);
        }
    };

    return (
        <div>
            {SearchBar ? (
                <NavWithSearch />
            ) : (
                <Navbar
                    whiteBackground={showWhiteNavbar}
                    ShowdetailsNav={ShowdetailsNav}
                    showAccountNav={showAccountNav}
                    showInterestNav={showInterestNav}
                    headerText={headerText}
                    setActiveNavClick={setActiveNavClick}
                    newCarFlag={newCarFlag}
                />
            )}

            <main>
                {' '}
                {isLoading ? (
                    <div className="loading-container">
                        <Loading />
                    </div>
                ) : (
                    children
                )}
            </main>

            <Footer setIsFooterVisible={handleScroll} />
        </div>
    );
};

export default Layout;
