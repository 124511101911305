import { Modal as ModalComponent } from 'antd';
import { useTranslation } from 'react-i18next';

import SecSeperator from './SecSeperator';

interface Props {
    children: React.ReactNode;
    isLeftRequired?: boolean;
    isVisible: boolean;
    handleCancel: () => void;
    secSeperator?: boolean;
    header: string;
    backdropType: 'blur' | 'opaque';
    closable?: boolean;
    rightPadding?: string;
    size: 'large' | 'medium' | 'small';
}

const Modal = ({
    children,
    isVisible,
    handleCancel,
    isLeftRequired = false,
    secSeperator = true,
    header,
    backdropType,
    closable = true,
    rightPadding,
    size
}: Props) => {
    const { t } = useTranslation();
    return (
        <ModalComponent
            open={isVisible}
            onCancel={handleCancel}
            closable={closable}
            maskClosable
            footer={null}
            width={isLeftRequired ? 'auto' : 'max-content'}
            maskStyle={
                backdropType === 'blur'
                    ? {
                          background: 'rgba(102, 144, 228, 0.3)',
                          backdropFilter: 'blur(50px)'
                      }
                    : { background: '#454545', opacity: '0.5' }
            }
            className={
                size === 'large'
                    ? 'modal-wrapper'
                    : size === 'medium'
                    ? 'modal-wrapper-medium'
                    : 'modal-wrapper-small'
            }
            centered={true}
        >
            <div className="modal-content-wrapper">
                {isLeftRequired ? (
                    <div className="modal-left-content-wrapper">
                        <div className="modal-top-image">
                            <img src="/images/auto24-logo.webp" alt="auto24" />
                        </div>
                        <div className="modal-left-content">
                            {loginSignUpLeftData(t)}
                        </div>
                    </div>
                ) : null}
                <div
                    className={`modal-right-content-wrapper ${
                        !isLeftRequired ? 'border-radius-fix' : ''
                    } ${rightPadding ? `right-padding-${rightPadding}` : ''}`}
                >
                    {secSeperator ? (
                        <SecSeperator className="modal-sec-seperator" />
                    ) : null}
                    <div
                        className={`modal-right-content ${
                            !secSeperator ? 'modal-right-content-center' : null
                        }`}
                    >
                        <span className="right-header">{header}</span>
                        {children}
                    </div>
                </div>
            </div>
        </ModalComponent>
    );
};

const loginSignUpLeftData = (t: any) => {
    const staticData = [
        {
            icon: <img src="/svgs/certified-icon.svg" alt="certified" />,
            header: 'certifiedUsedCars',
            body: 'certifiedUsedCarsSubtitle'
        },
        {
            icon: <img src="/svgs/inspection-point.svg" alt="inspection" />,
            header: 'inpectionsPoints',
            body: 'inpectionsPointsSubtitle'
        },
        {
            icon: <img src="/svgs/refund-icon.svg" alt="refund" />,
            header: 'refundPolicy',
            body: 'refundPolicySubtitle'
        },
        {
            icon: <img src="/svgs/warranty-icon.svg" alt="warranty" />,
            header: 'warrentyTitle',
            body: 'warrentySubtitles'
        }
    ];
    return (
        <div>
            {staticData.map((data, idx) => {
                return (
                    <div
                        className={
                            staticData.length - 1 !== idx
                                ? 'left-data-wrapper bottom-margin'
                                : 'left-data-wrapper'
                        }
                        key={idx * 8}
                    >
                        <div className="modal-icon">{data.icon}</div>
                        <div className="left-content">
                            <span className="left-content-header">
                                {t(data.header)}
                            </span>
                            <span className="left-content-body">
                                {t(data.body)}
                            </span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Modal;
