import axios, { AxiosError, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { request } from '../../helpers/request';
import { CategoryTypes } from '../types/categoryTypes';

export const getAllCategories = (dispatch: Dispatch) => {
    dispatch({ type: CategoryTypes.LOADING });
    return request
        .getAllCategories()
        .then((res: AxiosResponse) => {
            dispatch({
                type: CategoryTypes.SUCCESS,
                data: res.data?.categories
            });
            return Promise.resolve(res.data);
        })
        .catch((error: AxiosError | Error) => {
            if (axios.isAxiosError(error)) {
            }
            return Promise.reject(error.message);
        });
};
export const getBlogByCat = (
    dispatch: Dispatch,
    id: string,
    page: number,
    limit: number
) => {
    return request
        .getBlogByCat(id, page, limit)
        .then((res: AxiosResponse) => Promise.resolve(res.data))
        .catch((err: AxiosError | Error) => {
            if (axios.isAxiosError(err)) {
                return Promise.reject(err.response?.data);
            }
            return Promise.reject(err.message);
        });
};
