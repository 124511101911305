import { Dispatch } from 'redux';
import { footerContent } from '../../constants';
import { Api } from '../Api';
import { FOOTER_CONTENT_ERROR, FOOTER_CONTENT_LOADING, FOOTER_CONTENT_SUCCESS } from '../types/footerContentTypes';

export const getFooterContent = async (dispatch: Dispatch) => {
    dispatch({ type: FOOTER_CONTENT_LOADING });
    try {
        const resData = await Api.get(`/${footerContent}`);
        if (resData.status === 200) {
            console.log(resData.data?.footerContent[0])
            dispatch({ type: FOOTER_CONTENT_SUCCESS, payload: resData?.data?.footerContent[0] });
        }
    } catch (error) {
        dispatch({ type: FOOTER_CONTENT_ERROR, payload: error });
    }
};