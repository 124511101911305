import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Benefits from '../components/Benefits';
import CarOverview from '../components/CarOverview';
import CarView, { ButtonContainer } from '../components/CarView';
import Features from '../components/Features';
import InspectionReport from '../components/InspectionReport';
import LoanCalculator from '../components/LoanCalculator';
import Process from '../components/Process';
import Questionnaire from '../components/Questionnaire';
import RecentlyAdded from '../components/RecentlyAdded';
import { formatMoney } from '../components/SearchFilter';
import ServiceHistory from '../components/ServiceHistory';
import Services from '../components/Services';
import Specifications from '../components/Specifications';
import Summary from '../components/Summary';
import Loading from '../components/ui/Loading';
import SecSeperator from '../components/ui/SecSeperator';
import Layout from '../layout/Layout';

import { IMAGE_URL } from '../constants';
import { ICar } from '../interface/car';
import { getDetail } from '../redux/actions/carListingAction';
import { ICombineReducerType } from '../redux/reducers';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';
import LoanPaymentSimulator from './LoanPaymentSimulator';
import CarIssue from '../components/CarIssue';

const DetailPage = () => {
    const [scrolled, setScrolled] = useState(false);
    const [isFooterVisible, setIsFooterVisible] = useState(false);
    const [activeNavClick, setActiveNavClick] = useState('');
    let { id }: any = useParams();
    const dispatch = useDispatch();
    const [car, setCar] = useState<ICar>();
    const { similarCars } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );

    useEffect(() => {
        getDetail(dispatch, id)
            .then((res) => setCar(res.car))
            .catch(() => {});
        window.scrollTo(0, 0);
    }, [id]);

    const { t, i18n } = useTranslation();
    const detectScroll = () => {
        if (window.scrollY >= 60) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };
    const metaTitle = car
        ? t('carDetailPageTitle', {
              ns: 'meta',
              make: car?.name,
              price: formatMoney(car?.price)
          })
        : 'Loading...';
    const metaDes = t('carDetailPageDesc', {
        ns: 'meta',
        make: car?.name,
        PRIX: formatMoney(car?.price)
    });
    useEffect(() => {
        window.addEventListener('scroll', detectScroll);
        return () => window.removeEventListener('scroll', detectScroll);
    }, []);

    const filteredDetails = car?.details?.filter(
        (item: any) => item.lang === i18n.language
    )[0];

    return (
        <>
            <div className="detail-page">
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="description" content={metaDes}></meta>
                </Helmet>
                <Layout
                    showWhiteNavbar
                    ShowdetailsNav
                    setIsFooterVisible={setIsFooterVisible}
                    setActiveNavClick={setActiveNavClick}
                    newCarFlag={car?.isNewCar}
                >
                    {!car ? (
                        <div style={{ height: '20vh' }}>
                            <Loading />
                        </div>
                    ) : (
                        <>
                            <CarView car={car} newCarFlag={car?.isNewCar} />
                            <Services />
                            {car?.details?.length &&
                            filteredDetails?.description &&
                            filteredDetails.description !== '.' ? (
                                <div className="discover padding">
                                    <SecSeperator className="center" />
                                    <h2 className="title">{t('discover')}</h2>
                                    <p className="discover-content">
                                        {filteredDetails?.description}
                                    </p>
                                </div>
                            ) : null}
                            <CarOverview activeNavClick={activeNavClick} />
                            <Summary activeNavClick={activeNavClick} />
                            <Specifications activeNavClick={activeNavClick} />
                            <Features activeNavClick={activeNavClick} />
                            {car.technicalVisitCertificate === null &&
                            car.keys === null ? null : (
                                <div className="additional padding discover">
                                    <SecSeperator className="center" />
                                    <h2 className="title">
                                        {t('additionalPhotos')}
                                    </h2>

                                    <div className="additional-photos">
                                        {car.technicalVisitCertificate && (
                                            <div className="additional-content">
                                                <p>{t('technicalVisit')}</p>
                                                <div className="image-container">
                                                    <img
                                                        src={`${IMAGE_URL}${car.technicalVisitCertificate}`}
                                                        alt="Technical visiti certificates"
                                                        onError={
                                                            addImageFallback
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {car.keys && (
                                            <div className="additional-content">
                                                <p>{t('keysPhotos')}</p>
                                                <div className="image-container">
                                                    <img
                                                        src={`${IMAGE_URL}${car.keys}`}
                                                        alt="Keys"
                                                        onError={
                                                            addImageFallback
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <ServiceHistory activeNavClick={activeNavClick} />
                            {car.inspectionReport ? (
                                <InspectionReport {...car.inspectionReport} />
                            ) : null}
                            <CarIssue />
                            <Process title="whyAUTO24" whyAUTO24 />
                            <Benefits />
                            {/* <LoanCalculator
                                carPrice={car?.price}
                                carId={car?._id}
                            /> */}
                            <LoanPaymentSimulator carPrice={car?.price} />
                            <RecentlyAdded
                                title="similarResults"
                                subtitle="hereyoucanseedifferentsimilarcars"
                                carData={
                                    similarCars.length > 6
                                        ? similarCars.slice(0, 6)
                                        : similarCars
                                }
                                total={similarCars.length}
                            />
                            <Questionnaire slug={car?.slug} />
                            {scrolled && !isFooterVisible && (
                                <ButtonContainer />
                            )}
                        </>
                    )}
                </Layout>
            </div>
        </>
    );
};

export default DetailPage;
