import { ALL_LANGUAGES } from "../../constants"
import heroArabic from "./arabic";
import heroEnglish from "./english";
import heroFrench from "./french";
import heroKinyarwanda from "./kinyarnwanda";
import heroXhosa from "./xhosa";
import heroZulu from "./zulu";
import heroWolof from "./wolof";

const heroLang = {
    [ALL_LANGUAGES.english.value]: heroEnglish,  
    [ALL_LANGUAGES.french.value]: heroFrench, 
    [ALL_LANGUAGES.arabic.value]: heroArabic,  
    [ALL_LANGUAGES.zulu.value]: heroZulu, 
    [ALL_LANGUAGES.xhosa.value]: heroXhosa,
    [ALL_LANGUAGES.kinyarnwanda.value]: heroKinyarwanda, 
    [ALL_LANGUAGES.wolof.value]: heroWolof, 
}

export default heroLang;