import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';

import { StatsArray2 } from '../utils/Stats';

const Benefits = () => {
    const { t } = useTranslation();
    const desc = (item: any) => {
        if (typeof item.descs === 'object')
            return t(item.descs.key, { number: item.descs.number });
        return t(item.descs);
    };
    return (
        <div className="benefits">
            <div className="padding">
                <SecSeperator
                    className="centers"
                    bordercolor="#091731"
                    background="#091731"
                    borderColor="#ffffff"
                    backgroundColor="#ffffff"
                />
                <h2 className="title center">{t('ourBenefits')}</h2>
                <div className="certified-container">
                    {StatsArray2.map((items: any, index: number) => {
                        return (
                            <div className="certified" key={index}>
                                <div className="certified-image">
                                    <img src={items.imagesrc} alt={items.alt} />
                                </div>
                                <p>{desc(items)}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Benefits;
