import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { message, Modal } from 'antd';
import { SpinnerGap } from 'phosphor-react';

import Blog from '../components/Blog';
import FinanceTop from '../components/FinanceTop';
import FinancingPartner from '../components/FinancingPartner';
import Questionnaire from '../components/Questionnaire';
import ButtonComponent from '../components/ui/ButtonComponent';
import Layout from '../layout/Layout';
import CountryCodeDropdown, {
    FullNameInputComp
} from '../components/ui/CountryCodeDropdown';

import { defaultDailing_Code } from '../helpers/countryCode';
import { request } from '../helpers/request';
import { financePartners } from '../redux/actions/finacePartnerAction';
import { pagesContent, resetPagesContent } from '../redux/actions/pagesAction';
import { ICombineReducerType } from '../redux/reducers';
import {
    fullNameValidate,
    numcheck,
    returnOnlyNumber,
    SetMeta
} from '../utils/Helper/jsHelpers';
import { $FIXME } from '../constants';
import { setFullNameError } from '../components/Certification';

const Insure = () => {
    const { user } = useSelector((state: ICombineReducerType) => state.auth);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        code: user?.phoneNumber.code ?? defaultDailing_Code,
        number: user?.phoneNumber.number,
        fullName: user?.name ?? '',
        category: 'insurance',
        subCategory: ''
    });
    const [error, setError] = useState<any>({
        phoneNumber: ''
    });
    useEffect(() => {
        if (user) {
            setState((prev: $FIXME) => ({
                ...prev,
                code: user?.phoneNumber.code,
                number: user?.phoneNumber.number,
                fullName: user?.name
            }));
        } else {
            setState({
                code: defaultDailing_Code,
                number: '',
                fullName: '',
                category: 'insurance',
                subCategory: ''
            });
        }
    }, [user]);
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const { pages } = useSelector((state: ICombineReducerType) => state.pages);

    useEffect(() => {
        pagesContent(dispatch, 'insure');
        financePartners(dispatch, 'insure');
        return () => {
            resetPagesContent(dispatch);
        }
    }, []);
    const { t } = useTranslation();

    function handleChange(e: any) {
        let { value, name } = e.target;
        setState({
            ...state,
            [name]: value
        });
    }
    async function submit(e: any) {
        e.preventDefault();
        setLoading(true);
        try {
            if (
                await numcheck(
                    { code: state.code, number: state.number },
                    setError
                )
            ) {
                await request
                    .sendInquiry({ ...state })
                    .then((res) => {
                        setVisible(true);
                    })
                    .catch();
            }
        } catch (err) {
            // message.error(t('somethingWentWrong'));
        } finally {
            setLoading(false);
        }
    }

    const navigate = useNavigate();
    const goToHome = () => {
        navigate(t('home', { ns: 'routes' }));
        setVisible(false);
    };
    const { financingPartners } = useSelector(
        (state: ICombineReducerType) => state.financePartners
    );

    useEffect(() => {
        if (!state.fullName && !state.number) {
            setError({});
        }
        if (
            fullNameValidate(state.fullName) &&
            state.number.toString().length === 10
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [state]);
    useEffect(() => {
        if (state.fullName) {
            if (fullNameValidate(state.fullName)) {
                setError({});
            }
        }
    }, [state.fullName]);

    return (
        <>
            <SetMeta title="insurePageTitle" desc="insurePageDesc" keywords='insurePageKeywords'
            properties={[
                {
                    key: "og:title",
                    value: "insurePageTitleOg"
                },
                {
                    key: "og:description",
                    value: "insurePageDescOg"
                },
                {
                    key: "og:keywords",
                    value: "insurePageKeywordOg"
                }
            ]}
            />
            <div className="Finance">
                <Layout showWhiteNavbar>
                    <FinanceTop
                        pages={pages}
                        image="/images/financeimage.webp"
                    />

                    <div className="loan-calculator car-insurance padding">
                        <form onSubmit={submit}>
                            <h2 className="title center">
                                {t('lookingForBestInsurance')}
                            </h2>
                            <p className="sub-title">
                                {t(
                                    'ourCustomerRepresentativesWillGetBackInTouchWithYou'
                                )}
                            </p>
                            <FullNameInputComp
                                setFullNameError={() => {
                                    if (state.fullName) {
                                        setFullNameError(
                                            state.fullName,
                                            setError,
                                            t
                                        );
                                    }
                                }}
                                handleChange={handleChange}
                                value={state.fullName}
                                err={error.fullName}
                                t={t}
                            />
                            <CountryCodeDropdown
                                onInputChange={(e: any) => {
                                    let { name, value } = e.target;
                                    setState((prev) => ({
                                        ...prev,
                                        [name]: returnOnlyNumber(value)
                                    }));
                                }}
                                selectValue={state.code}
                                onSelect={(value, name) =>
                                    setState((prev) => ({
                                        ...prev,
                                        [name]: value
                                    }))
                                }
                                inputName="number"
                                selectName="code"
                                inputValue={state.number}
                                error={error.phoneNumber}
                            />
                            <div className="sub-title term-of-use">
                                <span>
                                    {t('byClickingXButton', {
                                        name: t('getACallBack')
                                    })}{' '}
                                    <Link
                                        to={t('/terms-of-use', {
                                            ns: 'routes'
                                        })}
                                        className="termandpolicy"
                                    >
                                        {t('termOfUse')}
                                    </Link>{' '}
                                    {t('and')}{' '}
                                    <Link
                                        to={t('/privacy-policy', {
                                            ns: 'routes'
                                        })}
                                        className="termandpolicy"
                                    >
                                        {t('privacyPolicy')}
                                    </Link>
                                </span>
                            </div>
                            <div className="callback-btn">
                                <ButtonComponent
                                    className="padding-15"
                                    type="primary"
                                    htmlType="submit"
                                    disabled={disabled || loading}
                                >
                                    {loading ? (
                                        <>
                                            <SpinnerGap
                                                size={32}
                                                weight="bold"
                                                className="rotate"
                                            />
                                            {t('sending')}
                                        </>
                                    ) : (
                                        t('getACallBack')
                                    )}
                                </ButtonComponent>
                            </div>
                        </form>
                    </div>
                    <Modal
                        className="save-changes-modal ants-modal"
                        title={t('Detailssentsuccessfully')}
                        visible={visible}
                        onCancel={() => setVisible(false)}
                        closable={true}
                        centered={true}
                    >
                        <p className="subtitle">{t('DetailChangeSubtitle')}</p>
                        <div className="done-icon">
                            <img src="/images/done.webp" alt="" />
                        </div>
                        <div className=" get-call-back">
                            <button
                                className="back-to-home"
                                onClick={() => goToHome()}
                            >
                                {t('backToHome')}
                            </button>
                        </div>
                    </Modal>
                    {/* <FinancingPartner
                        title="ourInsurePartners"
                        financingPartners={financingPartners}
                        subtitles="insureSubtitle"
                        class="insure"
                    /> */}
                    <Blog />
                    <Questionnaire />
                </Layout>
            </div>
        </>
    );
};

export default Insure;
