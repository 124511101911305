import { useState } from 'react';

import { CheckCircle } from 'phosphor-react';

const FeatureCard = (props: any) => {
    const [show, setShow] = useState(false);

    return props.value ? (
        <>
            <div className="feature-card" onClick={() => setShow(!show)}>
                <div className="feature-card-header">
                    <h3 className="feature-title">{props.title}</h3>
                </div>

                <div className="feature-verified">
                    <CheckCircle size={24} color="#009E60" weight="bold" />
                </div>
            </div>
        </>
    ) : null;
};

export default FeatureCard;
