import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '../components/ui/Loading';
import SecSeperator from '../components/ui/SecSeperator';
import Layout from '../layout/Layout';

import { pagesContent, resetPagesContent } from '../redux/actions/pagesAction';
import { ICombineReducerType } from '../redux/reducers';
import { contentAccordToCurrentLang, SetMeta } from '../utils/Helper/jsHelpers';
import i18n from '../localization';
import { IPageDetails } from '../redux/types/pagesType';

const TermsOfUse = () => {
    const dispatch = useDispatch();
    const { pages } = useSelector((state: ICombineReducerType) => state.pages);

    const [showDynamicContent, setShowDynamicContent] = useState(false);

    useEffect(() => {
        pagesContent(dispatch, 'terms');
        return () => {
            resetPagesContent(dispatch);
        }
    }, [dispatch]);

    const page = pages.find((page) => page.page === 'terms');
    const pageViewStatus = pages[0]?.status;

    useEffect(() => {
        if(page && page?.details) {
            const containsLang = page?.details.some((item: IPageDetails) => item?.lang === i18n.language );
            setShowDynamicContent(containsLang);
        }
    }, [i18n, page])

    return (
        <>
            <SetMeta title="termsNusePageTitle" />
            <Layout showWhiteNavbar>
                {!page ? (
                    <Loading />
                ) : (
                    <>
                    { showDynamicContent && pageViewStatus &&
                    <div className="terms-and-policy ">
                        <div className="top-title">
                            <div className="align padding">
                                <SecSeperator />
                                <h2 className="title">
                                    {contentAccordToCurrentLang(page).title}
                                </h2>
                            </div>
                        </div>
                        <div className=" padding">
                            <div
                                className="terms-and-policy__content"
                                dangerouslySetInnerHTML={{
                                    __html: contentAccordToCurrentLang(page)
                                        ?.body
                                }}
                            ></div>
                        </div>
                    </div>
                    }</>
                )}
            </Layout>
        </>
    );
};

export default TermsOfUse;
