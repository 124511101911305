import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { IMAGE_URL, whatsappNumber } from '../../constants';
import { getContactInfo } from '../../redux/actions/contactActions';
import { useDispatch, useSelector } from 'react-redux';
import { ICombineReducerType } from '../../redux/reducers';

function WhatsAppChatBox() {
    const dispatch = useDispatch();

    const { whatsapp, whatsapp_img, phoneNumber } = useSelector(
        (state: ICombineReducerType) => state.contact.contact
    );
    useEffect(() => {
        //@ts-ignore
        $('#example_3').whatsappChatSupport({
            defaultMsg: ''
        });
    }, []);

    useEffect(() => {
        getContactInfo(dispatch);
    }, []);

    const { t } = useTranslation();
    return (
        <div className="whatsapp_chat_support wcs_fixed_right" id="example_3">
            <div className="wcs_button_label">{t('askQuestionOnWhatsapp')}</div>
            {/* <div className="wcs_button wcs_button_circle">
                <span className="fa fa-whatsapp"></span>
            </div> */}
            <img src="/images/auto24-whatsapp.svg" style={{width: "50px", height: "50px"}} alt="whatsapp-icon" />

            <div className="wcs_popup">
                <div className="wcs_popup_close">
                    <span className="fa fa-close"></span>
                </div>
                <div className="wcs_popup_content">
                    <div className="wcs_popup_header">
                        <span className="fa fa-whatsapp"></span>
                        <strong>{t('customerSupport')}</strong>

                        <div className="wcs_popup_header_description">
                            {t('needHelpChatWithUs')}
                        </div>
                    </div>
                    <div
                        className="wcs_popup_input"
                        data-number={
                            Number(
                                `${phoneNumber?.code?.replace(
                                    '+',
                                    ''
                                )}${whatsapp}`
                            ) || whatsappNumber
                        }
                    >
                        <input
                            type="text"
                            placeholder={t('whatsappsPlaceholder')}
                        />
                        <i className="fa fa-play"></i>
                    </div>
                </div>
                <div className="wcs_popup_avatar">
                    <img
                        src={
                            whatsapp_img
                                ? IMAGE_URL + whatsapp_img
                                : '/images/static_image.webp'
                        }
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
}

export default WhatsAppChatBox;
