import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Pagination } from 'antd';
import { useTranslation } from 'react-i18next';

import SecSeperator from '../../src/components/ui/SecSeperator';
import Buysell from '../components/Buysell';
import CategoriesCard from '../components/CategoriesCard';
import Layout from '../layout/Layout';
import BreadCrumbs from '../components/ui/BreadCrumbs';

import { getBlogByCat } from '../redux/actions/categoryAction';
import { IBlogDetailType } from '../redux/types/BlogListTypes';
import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';

const Categories = () => {
    const [current, setCurrent] = useState({ page: 1, limit: 5 });
    const { id } = useParams();
    const [Blogs, setBlogs] = useState([] as IBlogDetailType[]);
    const [error, setError] = useState<any>();
    const [catName, setCatName] = useState();
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const onChange = (page: number) => {
        setCurrent({ ...current, page });
    };
    useEffect(() => {
        setBlogs([]);
        setTotal(0);
        setCurrent({ ...current, page: 1 });
    }, [id]);
    useEffect(() => {
        if (id) {
            setIsLoading(true);
            getBlogByCat(dispatch, id, current.page, current.limit)
                .then((data) => {
                    setBlogs(data.blogs);
                    setTotal(data.total);
                    setError('');
                    setIsLoading(false);
                })
                .catch((err) => {
                    setError(err.msg ? error.msg : ' something');
                    setIsLoading(false);
                });
            window.scrollTo(0, 0);
        }
    }, [id, dispatch, current.page]);
    useEffect(() => {
        if (!!Blogs.length) {
            setCatName(contentAccordToCurrentLang(Blogs[0].category).category);
        }
    }, [Blogs]);
    return (
        <div className="categories-page">
            <Layout SearchBar isLoading={isLoading}>
                <div className="categories-container padding">
                    <BreadCrumbs currentLocation={catName} />
                    <SecSeperator className="category-seperator" />
                    <h2 className="title">{t('category') + ' : ' + catName}</h2>

                    <div className="category-cards">
                        {error && !isLoading ? (
                            <h2>{error}</h2>
                        ) : (
                            Blogs.map((items, index) => {
                                return (
                                    <CategoriesCard item={items} key={index} />
                                );
                            })
                        )}
                    </div>
                </div>
                <Pagination
                    current={current.page}
                    onChange={onChange}
                    total={total}
                    pageSize={current.limit}
                    showSizeChanger={false}
                    responsive
                />
                <Buysell />
            </Layout>
        </div>
    );
};
export default Categories;
