import { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import i18n from '../localization';
import SecSeperator from './ui/SecSeperator';

const Hero = () => {
    const navigate = useNavigate();
    const { t } = useTranslation(['hero']);

    const [searchText, setSearchText] = useState('');

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const handleSearch = () => {
        navigate(
            `${t('/buy-cars', { ns: 'routes' })}${
                !!searchText ? `?search=${searchText}` : ''
            }`
        );
    };

    return (
        <div className='hero-section'>
        <div className='hero-overlay'></div>
            <div className='padding'>
                <div className='hero-content'>
                    <SecSeperator
                        borderColor='#fff'
                        backgroundColor='#fff'
                        className='hero'
                    />
                    <div
                        className={
                            i18n.language === 'en'
                                ? 'hero-text-container'
                                : 'hero-text-container fre'
                        }
                    >
                        <h1 className='hero-text'>{t('searchBarTitle')} </h1>
                        <div
                            className={
                                i18n.language === 'en'
                                    ? 'french-flag'
                                    : i18n.language === 'fr'
                                        ? 'french-flag fr'
                                        : 'french-flag ar'
                            }
                        >
                            <img
                                src='/images/flag-morocco.webp'
                                alt='french-flag'
                            />{' '}
                        </div>
                        {' '}
                    </div>
                    <form className='search-form'>
                        <input
                            type='text'
                            className='search-input'
                            placeholder={t('searchBarPlaceholder')}
                            onChange={handleSearchChange}
                        />{' '}
                        <button
                            className='search-button'
                            onClick={handleSearch}
                        >
                            {t('common:search')}
                        </button>
                    </form>
                    <div className='sell-now'>
                        <p>{t('willingToSellYourUsedCar')}</p>
                        <button
                            className='sell-now-btn'
                            onClick={() =>
                                navigate(t('/sell-car', { ns: 'routes' }))
                            }
                        >
                            {t('common:sellNow')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
