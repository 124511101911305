import { BASE_URL, emailRegs } from '../constants';
import { ILogin, ISignUp } from '../interface/auth';
import { ICarParams } from '../interface/car';
import i18n from '../localization';
import { api, protectedAPI } from './api';

const acceptLanguage = "Accept-Language";

let auth = {
    login: (body: ILogin) => api.post('/auth/login', body),
    register: (body: ISignUp) => api.post('/auth/register', body),
    verifyOtp: (opt: string, token: string) =>
        api.post(
            BASE_URL + '/auth/verify-otp',
            { otp: opt },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ),
    resendOtp: (body: { code: string; number: string }) =>
        api.post('/auth/get-otp', body),
    createPassword: (
        body: { password: string; confirmPassword: string },
        token: string
    ) =>
        api.post(BASE_URL + '/auth/forgot-password-update', body, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }),
    verifyToken: (token: string) =>
        api.get(BASE_URL + '/auth/verify-forgot-password-token', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }),
    getForgetPasswordLink: (body: { email: string }) =>
        api.post('/auth/forgot-password', body),
    verifyForgetPasswordOtp: (otp: string, token: string) =>
        api.post(
            BASE_URL + '/auth/verify-forgot-password-otp',
            { otp },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    [acceptLanguage]: i18n.language
                }
            }
        )
};

let cars = {
    getCarDetail: (_id: string) => api.get(`/cars/${_id}`),
    getCars: (params?: ICarParams) => api.get(`/cars`, { params }),
    getFeaturedCars: () => api.get(`/cars/get-featured`),
    getNewCarList: () => api.get(`/cars?isNewCar=true`),
    getCarBodyTypes: () => api.get('/car-body-types'),
    getAvailableCarBodyTypes: () => api.get('/car-body-types/by-car'),
    getCarBrands: () => api.get('/car-brands'),
    getAllCarModels: () => api.get('/car-models'),
    getCarAvailablsModelsOnly: () => api.get('/car-models/by/car'),
    getCarColors: () => api.get('/car-colors'),
    getAvailableCarColors: () => api.get('/car-colors/by-car'),
    getAvailModelsBYBrand: (slug: string) =>
        api.get(`/car-models/car-has/by-brand/${slug}`)
};

let blog = {
    getTopStories: () => api.get('block'),
    getBuyCars: () => api.get('bycard'),
    getSellCars: () => api.get('sellcar'),
    getCategories: () => api.get('Categories'),
    getBlogs: () => api.get('/blogs'),
    getAllBlogs: () => api.get('blogs'),
    getOneBlog: (slug: string) => api.get(`blogs/${slug}`),
    getBlogByCat: (_id: string, page: number, limit: number) =>
        api.get(`blogs/by-category/${_id}?limit=${limit}&page=${page}`)
};

let resendVerificationLink = (payload: string) => {
    let data = payload.match(emailRegs)
        ? { email: payload }
        : { token: payload };
    return api.post('auth/get-email-verification-token', data);
};
let testimonials = {
    getTestimonials: () =>
        api.get('/testimonials?resultsPerPage=18&sort=latest')
};

let customerGallery = {
    getCustomerGallery: () => api.get('/customer-galleries')
};

let contact = {
    getContactInfo: () => api.get('/contact-information')
};

let store = {
    getAllStores: () => api.get('/stores')
};
export interface InquiryType {
    fullName: string;
    code: string;
    number: string;
    category?: string;
    subCategory?: string;
    car_id?: string;
    interestRate?: number;
    timeFrame?: number;
    downPayment?: number;
}
let inquiry = {
    sendInquiry: (body: InquiryType) =>
        protectedAPI.post('/search-inquiries', body),
    userRequirement: (body: any) =>
        protectedAPI.post('/user-requirements', body)
};

export interface IBuyCar {
    email?: string;
    fullName: string;
    code: string;
    number: string;
    car: string;
}

let buyCar = {
    sendBuyCarInfo: (body: IBuyCar) =>
        protectedAPI.post('/booking', body)
};

let categories = {
    getAllCategories: () => api.get('categories'),
    storeCategories: (body: any) => api.post('categories', body),
    updateCategories: (_id: string, body: any) =>
        api.put('categories' + _id, body),
    deleteCategories: (_id: string) => api.delete('categories' + _id)
};
export interface IUserUpdateBody {
    email: string;
    phoneNUmber: string;
    bio: string;
    name: string;
}
export interface IForgetPass {
    password: string;
    confirmPassword: string;
    oldPassword: string;
}
let user = {
    editProfile: (body: any) => protectedAPI.put('users/update/profile', body),
    deleteProfile: () => protectedAPI.delete('users/delete/profile'),
    resetPw: (body: IForgetPass) =>
        protectedAPI.put('users/update/password', body)
};

export const request = {
    ...auth,
    ...cars,
    ...blog,
    ...testimonials,
    ...customerGallery,
    ...contact,
    ...store,
    ...categories,
    ...user,
    ...inquiry,
    ...buyCar,
    resendVerificationLink
};
