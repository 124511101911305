import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';
import Loading from './ui/Loading';

import { ICombineReducerType } from '../redux/reducers';
import { IMAGE_URL } from '../constants';
import text_truncate from '../utils/Helper/TextTruncate';
import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const Featured = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { featuredBlogs, loading } = useSelector(
        (state: ICombineReducerType) => state.blogs
    );
    const Maplist = () => (
        <div className="featured-top">
            {featuredBlogs?.slice(0, 5)?.map((item, index) => {
                return (
                    <div
                        className={index === 0 ? 'left-image' : 'right-image'}
                        key={index}
                        onClick={() =>
                            navigate(
                                `${t('/blog', { ns: 'routes' })}/${item.slug}`
                            )
                        }
                    >
                        <img
                            src={IMAGE_URL + item.image}
                            alt=""
                            onError={addImageFallback}
                        />
                        <div className="contents">
                            <div className="blog-type">
                                {
                                    contentAccordToCurrentLang(item.category)
                                        .category
                                }
                            </div>

                            <span className="type">{item?.type}</span>
                            <h3 className="content-title">
                                {index === 0
                                    ? text_truncate(
                                          contentAccordToCurrentLang(item)
                                              ?.title,
                                          50,
                                          '...'
                                      )
                                    : text_truncate(
                                          contentAccordToCurrentLang(item)
                                              ?.title,
                                          28,
                                          '...'
                                      )}
                            </h3>
                            <span className="author">{item?.author?.name}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
    return (
        <div>
            {loading ? null : (
                <div className="featured padding">
                    <SecSeperator />
                    <h2 className="title">{t('featuredPosts')}</h2>
                    <p className="subtitle">{t('featuredPostSubtitle')}</p>
                    <div className="featured-container">
                        <Maplist />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Featured;
