import { ICar } from '../../interface/car';

const GetFeaturedList = (car: ICar) => {
    const StandardData = [
        {
            title: 'speedRegular',
            value: car?.speedRegulator
        },
        {
            title: 'airCondition',
            value: car?.airCondition
        },
        {
            title: 'sequential',
            value: car?.sequential
        },
        {
            title: 'CDPlayer',
            value: car?.cdPlayer
        },
        {
            title: 'reversingCamera',
            value: car?.reverseCamera
        },
        {
            title: 'reversingRadar',

            value: car?.reverseCamera
        },
        {
            title: 'GPSNavigation',
            value: car?.gpsNavigation
        },
        {
            title: 'parkAssist',
            value: car?.parkAssist
        },
        {
            title: 'startAndStop',
            value: car?.startAndStop
        },
        {
            title: 'airbag',
            value: car?.airBag
        },
        {
            title: 'ABS',
            value: car?.abs
        },
        {
            title: 'computer',
            value: car?.computer
        },
        {
            title: 'rims',
            value: car?.rims
        },
        {
            title: 'sunroof',
            value: car?.sunRoof
        },
        {
            title: 'electricMirrors',
            value: car?.electricMirror
        },
        {
            title: 'electricWindows',
            value: car?.electricWindow
        },
        {
            title: 'bluetooth',
            value: car?.bluetooth
        },
        {
            title: 'maisonMere',
            value: car?.maisonMere
        },
        {
            title: 'imported',
            value: car?.imported
        },
        {
            title: 'tamperedOdometer',
            value: car?.tamperedOdometer
        }
    ];
    console.log(car, "CarHere")
    return StandardData.filter((item) => {
        if (item.value) {
            return item;
        }
    });
};

export default GetFeaturedList;
