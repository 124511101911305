import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SecSeperator from './ui/SecSeperator';
import Loading from './ui/Loading';

import { formatDate } from './BlogDetail';

import text_truncate from '../utils/Helper/TextTruncate';
import { useMediaQueryHook } from '../utils/Hooks/MediaQuery';
import { ICombineReducerType } from '../redux/reducers';
import { $FIXME, IMAGE_URL } from '../constants';
import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';
import i18n from '../localization';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const PopularBlogs = (props: any) => {
    const { loading } = useSelector(
        (state: ICombineReducerType) => state.blogs
    );

    const { t } = useTranslation();
    return (
        <div>
            {loading ? null : (
                <div className={`popular-blogs padding ${props.className}`}>
                    <SecSeperator className='popular-sep' />
                    <h2 className='title'>{t(props?.title)}</h2>
                    <p className='subtitle'>{t(props?.subtitle)}</p>
                    <div className='popular-blogs__container'>
                        {props?.latestBlogs
                            ?.slice(0, 4)
                            .map((item: $FIXME, index: number) => {
                                return (
                                    <div className='blog-holder' key={index}>
                                        <Link
                                            to={`${t('/blog', { ns: 'routes' })}/${
                                                item.slug
                                            }`}
                                        >
                                            <PopularCard
                                                image={item?.image}
                                                title={
                                                    contentAccordToCurrentLang(item)
                                                        ?.title
                                                }
                                                desc={
                                                    contentAccordToCurrentLang(item)
                                                        ?.description
                                                }
                                                type={
                                                    contentAccordToCurrentLang(
                                                        item.category
                                                    ).category
                                                }
                                                date={item?.createdAt}
                                                author={item?.author?.name}
                                            />
                                        </Link>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
        </div>
    );
};
export default PopularBlogs;

export const PopularCard = (props: $FIXME) => {
    return (
        <div className='popular-card'>
            <div className='popular-image'>
                <img
                    src={IMAGE_URL + props.image}
                    alt='cars'
                    onError={addImageFallback}
                />
            </div>
            <div className='popular-content'>
                <div className='blog-type'>{props.type}</div>
                <h4 className='popular-title'>
                    {text_truncate(props.title, 35, '...')}
                </h4>
                <div
                    className={
                        i18n.language === 'en'
                            ? 'popular-description'
                            : 'popular-description fr'
                    }
                    dangerouslySetInnerHTML={{
                        __html: text_truncate(props.desc, 135, '...') as string
                    }}
                ></div>
                <div className='date-author'>
                    {useMediaQueryHook() ? (
                        <p className='author-name'>
                            {text_truncate(props.author, 10, '...') ??
                                'Auto24 Team'}{' '}
                        </p>
                    ) : (
                        <p className='author-name'>
                            {props.author ?? 'Auto24 Team'}
                        </p>
                    )}
                    <p className='date'>
                        {formatDate(props.date.substring(0, 10))}
                    </p>
                </div>
            </div>
        </div>
    );
};
