const heroFrench = {
    searchBarTitle: "Achetez & Vendez Vos Voitures Facilement",
    searchBarPlaceholder: "Trouvez votre voiture",
    willingToSellYourUsedCar: "Vous vendez votre voiture?",
    searchByCategory: "Rechercher par catégorie",
    searchCarByTheirBodyType: "Rechercher par type carrosserie",
    searhcarbybrands: "Rechercher par marque de voiture",
    exploreCar: "Découvrir nos Voitures",
    chooseCar: "Choisissez une voiture",
    buyACar: "Achetez votre voiture",
    buyACarDesc: "Faites votre achat et détendez vous, on s'occupe de tout !",
    buyACarDescs: "Faites votre achat et détendez vous, on s'occupe de tout !",
    weAreAroundTheArea: "Où nous trouver?",
    youcanfindusaroundthislocation: "Venez nous rendre visite dans notre nouveau showroom à Casablanca !",
    SearchByLocation: "Voir nos voitures en stock",
    questionSubtitle: "Les questions fréquentes de nos clients",
    askQuestions: "Poser une Question",
    questions: "Questions & Réponses",
    carsellInfo: "Fournissez-nous les informations sur votre voiture",
    yourCar: "Vos Détails",
    valuation: "Visite",
    valuationInfo: "Nous venons évaluer votre voiture chez vous ou chez nous",
    sellCar: "Vendez",
    sellCarInfo: " Recevez votre argent en moins de 24 heures !",
    happycustomerHappyus: "Clients heureux, nous aussi !",
    questionTextAreaPlaceHolder: "Quelle question voulez-vous poser ?",
    discovercar: "Découvrez nos voitures",
    discovercarSubtitle: "Parcourez notre catalogue de voitures certifiées",
    chooseACarSubtitle: "Sélectionnez la voiture que vous voulez",
    chooseACarSubtitles: "Sélectionnez la voiture que vous voulez"
}

export default heroFrench;