import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Navbar from '../layout/Navbar';
import { useState } from 'react';
import Loading from '../components/ui/Loading';

const Notfound = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showNotFound, setShowNotFound] = useState(false);

    setTimeout(() => {
        setShowNotFound(true);
    }, 1000);

    const goBackHome = () => {
        navigate(t('home', { ns: 'routes' }));
    };

    return (
        <>
        { showNotFound ?
        <div className="not-found">
            <Navbar whiteBackground />
            <div className="vects-container">
                <img src="/svgs/vects.svg" alt="cloud" />
            </div>
            <div className="not-found__container padding">
                <div className="not-found__container-left">
                    <div className="image-container">
                        <img src="/svgs/cloud.svg" alt="cloud" />
                    </div>
                    <div className="fourofour">
                        <h2>4</h2>
                        <div className="wheel-container">
                            <img src="/svgs/wheel.svg" alt="cloud" />
                        </div>
                        <h2>4</h2>
                    </div>
                    <h2 className="content">Ooops !</h2>
                    <h2 className="content">{t('pageNotFound')}</h2>
                    <p className="subtitle">{t('pageRemoved')}</p>
                    <button className="bottom-btn" onClick={goBackHome}>
                        {t('backToHome')}
                    </button>
                </div>
                <div className="not-found__container-right">
                    <div className="image-container one">
                        <img src="/svgs/cloud.svg" alt="cloud" />
                    </div>
                    <div className="image-container two">
                        <img src="/svgs/cloud.svg" alt="cloud" />
                    </div>
                    <div className="image-container three">
                        <img src="/svgs/cloud.svg" alt="cloud" />
                    </div>
                    <div className="vector-container">
                        <img src="/svgs/Main.svg" alt="cloud" />
                    </div>
                </div>
            </div>
        </div> :
        <div className='loader'>
            <div className='loader__abs'>
                <Loading />
            </div>
        </div>
        }
        </>
    );
};

export default Notfound;
