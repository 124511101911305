import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Modal from '../ui/Modal';
import CreateNewPassword from './CreateNewPassword';
import ResetPasswordForm from './ResetPasswordForm';
import ResetSuccessful from './ResetSuccessful';
import SignInForm from './SignInForm';
import SignUpForm from './SignUpForm';
import OtpVerification from './OtpVerification';

const AuthFlow = ({
    isVisible,
    handleCancel,
    checkLoggedIn,
    like_id
}: {
    isVisible: boolean;
    handleCancel: any;
    checkLoggedIn?: any;
    like_id?: string;
}) => {
    const [activeModal, setActiveModal] = useState(1);
    const [email, setEmail] = useState(null);
    const [fromForgetPassword, setfromForgetPassword] = useState(false);
    const [optverifyToken, setToken] = useState('');
    const [phoneNumber, setNumber] = useState({
        code: '',
        number: ''
    });

    const { t } = useTranslation();
    const handleActiveModal = (id: number) => {
        setActiveModal(id);
    };

    const modalLayout: any[] = [
        {
            header: 'signUp',
            backdropType: 'blur',
            isLeftRequired: true,
            data: (
                <SignUpForm
                    handleActiveModal={(id: any) => handleActiveModal(id)}
                    handleCancel={handleCancel}
                    setEmail={setEmail}
                    setNumber={setNumber}
                    setToken={setToken}
                />
            ),
            modalSize: 'large'
        },
        {
            header: 'signIn',
            backdropType: 'blur',
            isLeftRequired: true,
            data: (
                <SignInForm
                    setToken={setToken}
                    handleActiveModal={(id: any) => handleActiveModal(id)}
                    handleCancel={handleCancel}
                    setNumber={setNumber}
                    checkLoggedIn={checkLoggedIn}
                    liked_id={like_id}
                />
            ),
            rightPadding: '80',
            modalSize: 'large'
        },
        {
            header: 'resetPassword',
            backdropType: 'blur',
            data: (
                <ResetPasswordForm
                    setEmail={setEmail}
                    setToken={setToken}
                    setfromForgetPassword={setfromForgetPassword}
                    handleCancel={handleCancel}
                    handleActiveModal={(id) => handleActiveModal(id)}
                />
            ),
            rightPadding: '40',
            closable: false,
            modalSize: 'medium'
        },
        {
            header: 'createNewPassword',
            backdropType: 'blur',
            data: (
                <CreateNewPassword
                    token={optverifyToken}
                    handleCancel={handleCancel}
                    handleActiveModal={(id) => handleActiveModal(id)}
                />
            ),
            rightPadding: '40',
            closable: false,
            modalSize: 'medium'
        },
        {
            header: 'passwordresetsuccessfully',
            backdropType: 'blur',
            data: (
                <ResetSuccessful
                    handleCancel={handleCancel}
                    handleActiveModal={(id: any) => handleActiveModal(id)}
                />
            ),
            rightPadding: '40',
            closable: false,
            modalSize: 'medium',
            secSeperator: false
        },
        {
            header: 'verifyAccount',
            backdropType: 'blur',
            data: (
                <OtpVerification
                    email={email}
                    handleCancel={handleCancel}
                    token={optverifyToken}
                    phonenumber={phoneNumber}
                    fromForget={fromForgetPassword}
                    handleActiveModal={(id: any) => handleActiveModal(id)}
                />
            ),
            rightPadding: '40',
            closable: false,
            modalSize: 'small',
            secSeperator: true
        }
        // {
        //     header: 'verificationLinkSent',
        //     backdropType: 'blur',
        //     data: (
        //         <OtpVerification
        //             email={email}
        //             handleActiveModal={(id: any) => handleActiveModal(id)}
        //         />
        //     ),
        //     rightPadding: '40',
        //     closable: false,
        //     modalSize: 'small',
        //     secSeperator: true
        // }
    ];

    return (
        <div>
            <Modal
                closable={modalLayout[activeModal].closable}
                isLeftRequired={modalLayout[activeModal].isLeftRequired}
                isVisible={isVisible}
                handleCancel={handleCancel}
                header={t(modalLayout[activeModal].header)}
                backdropType={modalLayout[activeModal].backdropType}
                rightPadding={modalLayout[activeModal].rightPadding}
                size={modalLayout[activeModal].modalSize}
                secSeperator={modalLayout[activeModal].secSeperator}
            >
                {modalLayout[activeModal].data}
            </Modal>
        </div>
    );
};

export default AuthFlow;
