import { useEffect, useState } from 'react';
import { IPageDetails } from '../redux/types/pagesType';
import SecSeperator from './ui/SecSeperator';

import { contentAccordToCurrentLang } from '../utils/Helper/jsHelpers';
import { useTranslation } from 'react-i18next';
import { getTopLevelDomain } from '../utils/translation';

const FinanceTop = (props: any) => {
    const { i18n } = useTranslation(['meta']);

    const [showDynamicContent, setShowDynamicContent] = useState(false);
    const pageViewStatus = props.pages[0]?.status;

    useEffect(() => {
        const containsLang = props.pages[0]?.details.some((item: IPageDetails) => item?.lang === i18n.language );
        setShowDynamicContent(containsLang);
    }, [i18n, props.pages])

    const aboutUsDataByCurrentLang = () => {
        let aboutUsContent = [];
        aboutUsContent = aboutUsDataArr.filter(
            (item) => item.lang === i18n.language
        );
        if (aboutUsContent.length < 1)
            aboutUsContent = aboutUsDataArr.filter(
                (item) => item.lang === 'en'
            );
        return aboutUsContent[0];
    };

    const topLevelDomain = getTopLevelDomain();
    const auto24Domain = `AUTO24.${topLevelDomain}`;

        return (
            <>
            { showDynamicContent && pageViewStatus &&
            <div className={`finance-top padding ${props.class}`}>
                <div className="about-finance">
                    <SecSeperator />
                    <h2 className="title">
                        {contentAccordToCurrentLang(props.pages[0])?.title}
                    </h2>

                    <p
                        className="content"
                        dangerouslySetInnerHTML={{
                            __html: contentAccordToCurrentLang(props.pages[0])?.body
                        }}
                    ></p>
                </div>
                <div className="about-finance-image">
                    <img src={props.image} alt="" />
                </div>
            </div>
            }

            <div className={`finance-top padding ${props.class}`}>
                <div className="about-finance-image">
                    <img src={props.image} alt="" />
                </div>
                <div className="about-finance">
                    <SecSeperator />
                    <h2 className="title">
                        { i18n.language === "ar" ?
                        `لماذا تختار ${auto24Domain}؟` :
                        i18n.language === "fr" ?
                        `Pourquoi Choisir ${auto24Domain} ?`:
                        `Why Choose ${auto24Domain}`
                        }
                    </h2>
                    <ol style={{fontWeight: "bold", padding: 0, margin: "16px"}}>
                    {
                        aboutUsDataByCurrentLang().content.map((item, index) => 
                            <li key={index}>
                            <p style={{fontWeight: "bold"}}>{item.title}</p>
                            {item.isList ?
                                <ul  style={{padding: 0, margin: "16px"}}>
                                    {item.description.map((descItem, descIndex) => <li><p key={descIndex}>{descItem}</p></li>)}
                                </ul>
                            :
                                item.description.map((descItem, descIndex) => <p key={descIndex}>{descItem}</p>)
                            }
                            </li>
                        )
                    }
                    </ol>
                </div>
            </div>

            <div className={`finance-top padding ${props.class}`}>

                <div className="about-finance">
                    <SecSeperator />
                    <h2 className="title">
                        { i18n.language === "ar" ?
                        "الجودة والثقة والشفافية" :
                        i18n.language === "fr" ?
                        "Qualité, Confiance et Transparence" :
                        "Quality, Trust, and Transparency"
                        }
                    </h2>
                    { i18n.language === "ar" ?
                    <>
                    <p className="content">
                    {auto24Domain} ليست مجرد سوق، بل هي مجتمع تبنى فيه الثقة طويلة الأمد. نهدف إلى مساعدتك على اتخاذ القرارات المستنيرة، موفرين تجربة لا تضاهى لن تجدها في أي مكان آخر
                    </p>
                    <p className="content">
                    سواء كنت مشتريًا لأول مرة أو سائقًا ذو خبرة، {auto24Domain} لديها الكثير لتقدمه. تفضل بزيارتنا واكتشاف عالم من الفرص المتاحة لك.

                    </p>
                    </>
                    :
                    i18n.language === "fr" ?
                    <>
                    <p className="content">
                    {auto24Domain} est plus qu'une plateforme ; c'est une communauté où la confiance durable est construite. Nous visons à vous aider à prendre des décisions éclairées, offrant une expérience inégalée que vous ne trouverez nulle part ailleurs.
                    </p>
                    <p className="content">
                        Que vous soyez un acheteur pour la première fois ou un automobiliste expérimenté, {auto24Domain} a quelque chose à vous offrir. Venez explorer le monde des possibilités avec nous.
                    </p> 
                    </>:
                    <>
                    <p className="content">
                    {auto24Domain} is more than a marketplace; it's a community where long-lasting trust is built. We aim to assist you in making informed decisions, offering an unrivaled experience you won’t find elsewhere.
                    </p>
                    <p className="content">
                        Whether you're a first-time buyer or an experienced motorist, {auto24Domain} has something to offer you. Come explore the world of possibilities with us.

                    </p> 
                    </>
                    }
                </div>
                <div className="about-finance-image">
                    <img src={props.image} alt="" />
                </div>
            </div>
            </>
        );
};

export default FinanceTop;


const aboutUsDataArrEng = [
    {
        title: "Legacy of AUTO24",
        isList: false,
        description: ["We've earned our place as the leading retailer of used cars across Africa, establishing a legacy built on years of exceptional service, customer satisfaction, and a focus on value."]
    },
    {
        title: "Rigorous Inspection",
        isList: false,
        description: ["Our cars go through a stringent 120-point inspection to guarantee you are purchasing a high-quality vehicle. From the engine to the upholstery, every detail is meticulously examined by our expert technicians."]
    },
    {
        title: "Unique Perks",
        isList: true,
        description: [
            "5-day Refund Policy: If you're unsatisfied with your purchase, our 5-day refund policy has you covered.",
            "6-Month Warranty: We provide a 6-month warranty on all our cars to ensure your peace of mind.",
            "1-Year Insurance Package: Drive safely with our 1-year insurance package.",
            "1-Year Maintenance Package: Relax, we offer a maintenance package for the first year of ownership."
            
        ]
    },
    {
        title: "Document Verification",
        isList: false,
        description: ["All paperwork and vehicle history are thoroughly checked to ensure your asset's authenticity."]
    },
    {
        title: "Hassle-Free Trade-ins",
        isList: false,
        description: [
            "We offer quick and secure trade-ins and sales, making your experience as convenient as possible.",
        ]
    }
];

const aboutUsDataArrFr = [
    {
        title: "L'Héritage d'AUTO24",
        isList: false,
        description: ["Nous avons gagné notre place en tant que principal détaillant de voitures d'occasion à travers l'Afrique, établissant un héritage basé sur des années de service exceptionnel, la satisfaction du client, et une focalisation sur la valeur."]
    },
    {
        title: "Inspection Rigoureuse",
        isList: false,
        description: ["Nos voitures passent par une inspection rigoureuse de 120 points pour vous garantir que vous achetez un véhicule de haute qualité. Du moteur à la garniture, chaque détail est méticuleusement examiné par nos techniciens experts."]
    },
    {
        title: "Avantages Uniques",
        isList: true,
        description: [
            "Politique de Remboursement de 5 Jours: Si vous n'êtes pas satisfait de votre achat, notre politique de remboursement de 5 jours vous couvre.",
            "Garantie de 6 Mois: Nous fournissons une garantie de 6 mois sur toutes nos voitures pour assurer votre tranquillité d'esprit.",
            "Forfait d'Assurance d'1 An: Conduisez en toute sécurité avec notre forfait d'assurance d'un an.",
            "Forfait d'Entretien d'1 An: Détendez-vous, nous offrons un forfait d'entretien pour la première année de propriété."
            
        ]
    },
    {
        title: "Vérification des Documents",
        isList: false,
        description: ["Tous les documents et l'historique du véhicule sont minutieusement vérifiés pour garantir l'authenticité de votre bien."]
    },
    {
        title: "Reprises Sans Souci",
        isList: false,
        description: [
            "Nous offrons des reprises rapides et sécurisées, rendant votre expérience aussi pratique que possible.",
        ]
    }
];

const aboutUsDataArrAr = [
    {
        title: "إرث AUTO24",
        isList: false,
        description: ["لقد كسبنا مكانتنا كأكبر تجار سيارات مستعملة عبر أفريقيا، حيث نشأت سمعتنا على سنوات من الخدمة الاستثنائية ورضا العملاء والتركيز على القيمة."]
    },
    {
        title: "الفحص الدقيق",
        isList: false,
        description: ["تمر سياراتنا بفحص شامل يشمل 120 نقطة لضمان شرائك لسيارة عالية الجودة. من المحرك إلى الفرش، يتم فحص كل تفصيل بدقة من قبل فنيينا المحترفين."]
    },
    {
        title: "المزايا الفريدة",
        isList: true,
        description: [
            "سياسة استرداد لمدة 5 أيام: إذا كنت غير راضٍ عن عملية الشراء، فإن سياسة استرداد الأموال لدينا تغطيك لمدة 5 أيام.",
            "الضمان لمدة 6 أشهر: نقدم ضمانًا لمدة 6 أشهر على جميع سياراتنا لضمان راحتك النفسية.",
            "حزمة التأمين لمدة عام: قد بأمان مع حزمة التأمين لدينا التي تستمر لمدة عام.",
            "حزمة الصيانة لمدة عام: استرح، نقدم لك حزمة صيانة للسيارة خلال العام الأول من الاستخدام."

        ]
    },
    {
        title: "التحقق من الوثائق",
        isList: false,
        description: ["يتم فحص جميع الأوراق وتاريخ السيارة بعناية لضمان أصالة ممتلكاتك."]
    },
    {
        title: "عمليات البيع والاستبدال السريعة",
        isList: false,
        description: [
            "نقدم لك خدمة بيع واستبدال سريعة وآمنة، لتوفير تجربة مريحة قدر الإمكان.",
        ]
    }
];


const aboutUsDataArr = [
    {
        lang: "en",
        content: aboutUsDataArrEng
    },
    {
        lang: "fr",
        content: aboutUsDataArrFr
    },
    {
        lang: "ar",
        content: aboutUsDataArrAr
    },
]