import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import {
    LightgalleryProvider,
    LightgalleryItem,
    useLightgallery
} from 'react-lightgallery';

import { ICombineReducerType } from '../../redux/reducers';
import { IMAGE_URL } from '../../constants';
import { addImageFallback } from '../../utils/Helper/ImagePlaceholder';

const SummaryCard = (props: any) => {
    const { car } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const { t } = useTranslation();

    const PhotoItem = ({ image, thumb, group }: any) => (
        <div>
            <LightgalleryItem group={group} src={image} thumb={thumb}>
                <img src={image} alt="" onError={addImageFallback} />
            </LightgalleryItem>
        </div>
    );

    const SeeKeyPhotos = (props: any) => {
        const { openGallery } = useLightgallery();

        return (
            <p {...props} onClick={() => openGallery('seePhoto')}>
                ({t('seeKeyPhotos')})
            </p>
        );
    };

    return (
        <div className="summary-card">
            <div className="summary-icon-title">
                <div className="summary-icons">
                    <img src={props?.icons} alt={props?.alt} />
                </div>
                <h3 className="summary-title">{props?.title}</h3>
            </div>
            <div className="summary-value">
                {props?.value} {props.unit} &nbsp;
                <LightgalleryProvider
                    lightgallerySettings={{
                        getCaptionFromTitleOrAlt: true
                    }}
                >
                    <div className="photo-preview">
                        <PhotoItem
                            image={`${IMAGE_URL}${car.keys}`}
                            group="seePhoto"
                        />
                    </div>
                    {props?.text && car.keys ? <SeeKeyPhotos /> : null}
                </LightgalleryProvider>
            </div>
        </div>
    );
};

export default SummaryCard;
