export const numVerifyKey = 'OiN0wUGRZYjcyA2R2KmsgHIJXqFm3cWb';

//CARS
export const GET_CARS = 'GET_CARS';
export const GET_CAR_BODY_TYPES = 'GET_CAR_BODY_TYPES';
export const GET_CAR_AVAIL_BODY_TYPES = 'GET_CAR_AVAIL_BODY_TYPES';
export const GET_CAR_BRANDS = 'GET_CAR_BRANDS';
export const GET_CAR_COLORS = 'GET_CAR_COLORS';
export const GET_CAR_AVAIL_COLOR = 'GET_CAR_AVAIL_COLOR';

export const GET_CAR_MODEL = 'GET_CAR_MODEL';

//HOME
export const GET_HOME_RECENTLY_ADDED = 'GET_HOME_RECENTLY_ADDED';
export const GET_HOME_BLOGS = 'GET_HOME_BLOGS';
export const FETCH_FEATURED_CARS = 'FETCH_FEATURED_CARS';
export const FETCH_NEW_CAR_LIST = 'FETCH_NEW_CAR_LIST';

//TESTIMONIAL
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';

//CUSTOMER GALLERY
export const GET_CUSTOMER_GALLERY = 'GET_CUSTOMER_GALLERY';

//CONTACTS
export const GET_CONTACTINFO = 'GET_CONTACTINFO';

//STORE
export const GET_STORES = 'GET_STORES';

//COUNTRY
export const GET_COUNTRIES = 'GET_COUNTRIES';

//APPEND class to navbar
export const APPEND_CLASS_TO_NAV = 'APPEND_CLASS_TO_NAV';

export const CAR_CONDITION = [
    {
        name: 'excellentCondition',
        value: 'excellent'
    },
    {
        name: 'goodCondition',
        value: 'good'
    },
    {
        name: 'averageCondition',
        value: 'average'
    },
    {
        name: 'damagedCar',
        value: 'damaged'
    }
];
export const mileageRange = [
    {
        // ('Less than 10,000 Km');

        label: 'lessThan10',
        value: '<10000',
        id: 1
    },
    {
        // Between 10,000 and 50,000 Km

        label: { key: 'betweenXAndY', X: 10000, Y: 50000 },
        value: '10000-50000',
        id: 2
    },
    {
        // Between 50,000 and 100,000 Km
        label: { key: 'betweenXAndY', X: 50000, Y: 100000 },
        value: '50000-100000',
        id: 3
    },
    {
        // More than 100,000 Km
        label: 'moreThan100',
        value: '>100000',
        id: 4
    }
];

export const getYearList = () => {
    const year = new Date().getFullYear() + 1;
    return Array.from(new Array(25), (val, index) => {
        if (index === 0) {
            return year;
        } else {
            return year - index;
        }
    });
};
