import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Dropdown, Menu, Space } from 'antd';
import { HeartStraight, ShareNetwork } from 'phosphor-react';
import { useDispatch, useSelector } from 'react-redux';
import {
    LinkedinShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share';
import { useTranslation } from 'react-i18next';

import { carPageUrl, CSWIPER, IMAGE_URL } from '../../constants';
import { ICar } from '../../interface/car';
import {
    RemoveWhiteSpacesFromString,
    ReplaceWhiteSpaceWithDashString
} from '../../utils/Helper/jsHelpers';
import { formatMoney } from '../SearchFilter';
import {
    addToWishList,
    removeFromWishList
} from '../../redux/actions/wishlistAction';
import { ICombineReducerType } from '../../redux/reducers';
import AuthFlow from '../AuthFlow/AuthFlow';
import { addImageFallback } from '../../utils/Helper/ImagePlaceholder';
import { Swiper, SwiperSlide } from 'swiper/react';
import { NextArrow, PrevArrow } from '../icons/common.icons';
import { Navigation } from 'swiper';

interface IPropType {
    item: ICar;
    onClick?: (id: string) => void;
    border?: string;
    newCarFlag?: boolean;
}

export function getDiscountedPriceIfNotSold (price: number, discount: number) {
    // const discountedPrice = price - (price * (discountPercent / 100));
    const discountedPrice = price - discount;
    return formatMoney(Math.round(discountedPrice));
}

export const shareMenu = (car: ICar) => {
    const url = `${carPageUrl}/${RemoveWhiteSpacesFromString(
        car.brand?.brand
    )}/${ReplaceWhiteSpaceWithDashString(car?.model?.model)}/${
        car.yearOfManufacture
    }/${car?.uId}`;

    return (
        <Menu
            style={{ paddingLeft: 16, paddingRight: 16, borderRadius: 5 }}
            className="share-menu"
        >
            <Space
                size={16}
                direction="horizontal"
                style={{ paddingTop: 8, paddingBottom: 8 }}
                className="share-space"
            >
                <FacebookShareButton url={url} quote="Auto24">
                    <img alt="facebook" src="/icon/facebook.svg" />
                </FacebookShareButton>
                <LinkedinShareButton title={car.name} url={url}>
                    <img alt="linkedin" src="/icon/linkedin.svg" />
                </LinkedinShareButton>
                <TwitterShareButton
                    url={url}
                    title={car.name}
                    via="auto24.asterdio.xyz"
                >
                    <img alt="twitter" src="/icon/twitter.svg" />
                </TwitterShareButton>
                <WhatsappShareButton url={url} title={car.name}>
                    <img alt="whatsapp" src="/icon/auto24-whatsapp.svg" />
                </WhatsappShareButton>
            </Space>
        </Menu>
    );
};
export const LikeOrNot = ({
    id,
    setAuthFlow
}: {
    id: string;
    setAuthFlow: any;
}) => {
    const [liked, setLiked] = useState(false);
    const { wishList } = useSelector(
        (state: ICombineReducerType) => state.wishList
    );
    const dispatch = useDispatch();

    const loggedIn = useSelector(
        (state: ICombineReducerType) => state.auth.isloggedIn
    );
    useEffect(() => {
        if (!loggedIn) {
            setLiked(false);
        }
    }, [loggedIn]);
    useEffect(() => {
        if (id && wishList.length) {
            const likedOrNot = !!wishList?.find((i) => i.car?._id === id);
            setLiked(likedOrNot);
        }
    }, [wishList, id]);
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
                if (loggedIn) {
                    if (!liked) {
                        addToWishList(dispatch, id);
                    } else {
                        removeFromWishList(dispatch, id);
                    }
                    setLiked(!liked);
                } else {
                    setAuthFlow(true);
                }
            }}
            className={`pointer  ${liked && 'active'}`}
        >
            {!!id && (
                <HeartStraight
                    size={27}
                    color={!liked ? '#454545' : '#091731'}
                    weight={!liked ? 'regular' : 'fill'}
                />
            )}
        </div>
    );
};
const Card = ({ item, border, newCarFlag = false }: IPropType) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    let image = [];
    if (item?.images) image = JSON.parse(item?.images);
    const goToCarDetails = (car: ICar) => {
        navigate(
            `../${t('/buy', { ns: 'routes' })}/${RemoveWhiteSpacesFromString(
                car.brand?.brand
            )}/${ReplaceWhiteSpaceWithDashString(car?.model?.model)}/${
                car.modelYear
            }/${car.price}/${car.uId}`
        );
    };
    const [showAuthFlow, setAuthFlow] = useState(false);
    const [activeSlide, setActiveSlide] = useState<number>(
        CSWIPER.startOfSlider
    );
    const sliderRef = useRef<any>(null);

    const handlePrev = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slidePrev();
        setActiveSlide((prevActiveSlide) => prevActiveSlide - 1);
    }, []);

    const handleNext = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!sliderRef.current) return;
        sliderRef.current.swiper.slideNext();
        setActiveSlide((prevActiveSlide) => prevActiveSlide + 1);
    }, []);

    return (
        <>
            <div
                className={`card ${border ?? ''}`}
                onClick={() => {
                    !!item._id && goToCarDetails(item);
                }}
            >
                {item?.status === 'sold' && (
                    <div className="card-status">
                        <span className="card-status-text">{t('sold')}</span>
                    </div>
                )}
                {item?.status !== 'sold' &&
                    String(item?.discount) !== '0' && (
                        <div
                            className="card-status"
                            style={{ backgroundColor: '#FF4637' }}
                        >
                            <span className="card-status-text">
                                {t('discountOff').replace(
                                    '{{X}}',
                                    `${String(item?.discount)} ${process.env.REACT_APP_DEFAULT_CURRENCY}`
                                )}
                            </span>
                        </div>
                    )}
                {item.isNewCar && (
                    <div className="card-status-new">
                        <img alt="new" src="/icon/new.svg" />
                    </div>
                )}

                <div className="card-imageContainer">
                    {/* <img
                        src={`${IMAGE_URL}${image}`}
                        alt="car"
                        onError={addImageFallback}
                    /> */}
                    <Swiper
                        ref={sliderRef as any}
                        className="mySwiper"
                        modules={[Navigation]}
                        navigation={false}
                        speed={1000}
                    >
                        {image.map((item: string, index: number) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img
                                        src={`${IMAGE_URL}${item}`}
                                        alt="car"
                                        onError={addImageFallback}
                                        loading="lazy"
                                    />
                                </SwiperSlide>
                            );
                        })}

                        {activeSlide !== 0 && (
                            <button
                                type="button"
                                className="cardArrow cardArrow__left"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    handlePrev(e);
                                }}
                            >
                                <PrevArrow />
                            </button>
                        )}

                        {activeSlide !== image.length - 1 && (
                            <button
                                type="button"
                                className="cardArrow cardArrow__right"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleNext(e);
                                }}
                            >
                                <NextArrow />
                            </button>
                        )}
                    </Swiper>
                </div>

                {!!item.brand?.image && (
                    <div className="card-brand-logo">
                        <img
                            src={IMAGE_URL + item.brand.image}
                            alt={item.brand.brand}
                            onError={addImageFallback}
                            loading="lazy"
                        />
                    </div>
                )}

                <div className="card-contents">
                    <span className="card-model">{item?.name}</span>
                    <div className="card-price-buttons">
                        <div>
                            <span className="card-price">
                                {item?.status !== 'sold' &&
                                String(item?.discount) !== '0' &&
                                String(item?.price) !== '0'
                                    ? `${getDiscountedPriceIfNotSold(
                                          item?.price ?? 0,
                                          item.discount ?? 0
                                      )} ${process.env.REACT_APP_DEFAULT_CURRENCY}`
                                    : `${formatMoney(item.price)} ${process.env.REACT_APP_DEFAULT_CURRENCY}`}
                            </span>
                            {item?.status !== 'sold' &&
                                String(item?.discount) !== '0' && (
                                    <span className="card-old-price">
                                        {`${formatMoney(item.price)} ${process.env.REACT_APP_DEFAULT_CURRENCY}`}
                                    </span>
                                )}
                        </div>
                        <span className="card-buttons">
                            <div onClick={(e) => e.stopPropagation()}>
                                <Dropdown
                                    overlay={shareMenu(item)}
                                    placement="bottomRight"
                                    trigger={['click']}
                                >
                                    <ShareNetwork size={24} color="#454545" />
                                </Dropdown>
                            </div>
                            {item?._id && (
                                <LikeOrNot
                                    id={item._id}
                                    setAuthFlow={setAuthFlow}
                                />
                            )}
                        </span>
                    </div>
                    <div className="card-features">
                        <span className="features-container">
                            <img alt="manualgear" src="/icon/manualgear.svg" />

                            {item.transmission && t(item.transmission)}
                        </span>

                        <span className="features-container">
                            <img alt="gaspump" src="/icon/gaspump.svg" />

                            {item.fuelType && t(item.fuelType)}
                        </span>

                        { !newCarFlag &&
                        <span className="features-container">
                            <img
                                alt="speedometer"
                                src="/icon/speedometer.svg"
                            />
                            {formatMoney(item.mileage)} KM
                        </span>
                        }
                    </div>

                    <div className="card-bottom">
                        <Space align="center" className="space-container">
                            <div className="card-cert">
                                <img
                                    alt="cretified"
                                    src="/icon/certified.svg"
                                />

                                <span>{t('Certified')}</span>
                            </div>
                            <div className="dot"></div>

                            <div className="card-cert">
                                <img
                                    alt="inspected"
                                    src="/icon/inspected.svg"
                                />

                                <span>{t('Inspected')}</span>
                            </div>

                            <div className="dot"></div>
                            <div className="card-cert">
                                <img
                                    alt="warranted"
                                    src="/icon/warranted.svg"
                                />

                                <span>6&nbsp;{t('months')}</span>
                            </div>
                        </Space>
                    </div>
                </div>
            </div>
            {showAuthFlow && (
                <AuthFlow
                    isVisible={showAuthFlow}
                    like_id={item._id}
                    handleCancel={() => {
                        setAuthFlow(!showAuthFlow);
                    }}
                />
            )}
        </>
    );
};

export default Card;
