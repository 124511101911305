const heroArabic = {
    searchBarTitle: "طريقة سريعة وسهلة لشراء أو بيع السيارات",
    searchBarPlaceholder: "ابحث عن سيارتك التي تريدها",
    willingToSellYourUsedCar: "هل ترغب في بيع سيارتك المستعملة؟",
    searchByCategory: "البحث حسب الفئة",
    searchCarByTheirBodyType: "البحث عن سيارة حسب نوع هيكلها",
    searhcarbybrands: "البحث عن سيارة حسب نوع هيكلها",
    exploreCar: "استكشف السيارات",
    chooseCar: "اختر سيارة",
    buyACar: "اشترِ سيارتك",
    buyACarDesc: "شرائه والاسترخاء ، وسوف نعتني بالباقي!",
    buyACarDescs: "اشترِ بأمان واسترخ، سنهتم بكل شيء!",
    weAreAroundTheArea: "نحن في منطقة الدار البيضاء",
    youcanfindusaroundthislocation: "يمكنك أن تجدنا في هذا الموقع",
    SearchByLocation: "البحث حسب الموقع",
    questionSubtitle: "هنا أسئلة مختلفة من أشخاص مختلفين يمكن أن تعرفنا عنا",
    askQuestions: "أطرح الأسئلة",
    questions: "الأسئلة",
    carsellInfo: "قدم لنا تفاصيل حول سيارتك",
    yourCar: "سيارتك",
    valuation: "التثمين",
    valuationInfo: "نأتي لفحص سيارتك في مكانك أو تأتي إلينا",
    sellCar: "باع",
    sellCarInfo: "استلم أموالك في أقل من 24 ساعة!",
    happycustomerHappyus: "زبائننا السعداء، يسعدنا",
    questionTextAreaPlaceHolder: "ما هو السؤال الذي ترغب في طرحه؟",
    discovercar: "اكتشف سياراتنا",
    discovercarSubtitle: "تصفح قائمتنا من السيارات المستعملة المعتمدة",
    chooseACarSubtitle: "اختر السيارة التي تناسبك أكثر",
    chooseACarSubtitles: "اختر السيارة التي تعجبك وترغب فيها"
}

export default heroArabic;