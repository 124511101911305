import { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CaretLeft, GasPump } from 'phosphor-react';

import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AuthFlow from '../components/AuthFlow/AuthFlow';
import LogoutModal from '../components/ui/LogoutModal';
import Dropdown from '../components/ui/Dropdown';
import { formatMoney } from '../components/SearchFilter';

import { $FIXME, IMAGE_URL, whatsappUrl } from '../constants';
import { ICombineReducerType } from '../redux/reducers';
import { AuthTypes } from '../redux/types/AuthTypes';
import LanguageDropdown from '../components/ui/LanguageDropDown';
import useScrollspy from '../utils/Hooks/ScrollSpy';
import GetFeaturedList from '../utils/Hooks/GetFeaturedList';
import { WISH_LIST_SUCCESS } from '../redux/types/wishListTypes';
import GetSpecification, {
    GetSpecificationWeight
} from '../utils/Hooks/GetSpecification';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';
import i18n from '../localization';
import { getDiscountedPriceIfNotSold } from '../components/ui/Card';
import BuyCarConfirm from '../components/ui/BuyCarConfirm';

interface Props {
    whiteBackground?: boolean;
    ShowdetailsNav?: boolean;
    showAccountNav?: boolean;
    showInterestNav?: boolean;
    headerText?: string;
    setActiveNavClick?: $FIXME;
    newCarFlag?: boolean;
}

const Navbar = (props: Props) => {
    const { newCarFlag = false } = props;
    const { showLoginModal, isloggedIn } = useSelector(
        (state: ICombineReducerType) => state.auth
    );

    const { phoneNumber, whatsapp } = useSelector(
        (state: ICombineReducerType) => state.contact.contact
    );
    const { car } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const { appendClassToNav } = useSelector(
        (state: ICombineReducerType) => state.appendClassInNavbarReducer
    );

    const [navbar, setNavbar] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(showLoginModal);
    const [showLogout, setShowLogout] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const lang = i18n.language;
    const [showBuyCarModal, setShowBuyCarModal] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', changebackground);
        return () => window.removeEventListener('scroll', changebackground);
    }, []);

    const handleLogoutClick = () => {
        setShowLogout(!showLogout);
    };
    const logout = () => {
        setLoading(true);
        dispatch({ type: AuthTypes.LOG_OUT });
        dispatch({
            type: WISH_LIST_SUCCESS,
            payload: []
        });
        setShowLogout(false);
    };

    const changebackground = () => {
        if (window.scrollY >= 60) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };
    const isMobileorTab = useMediaQuery({ query: '(max-width: 480px)' });

    const dispatch = useDispatch();
    const changeModal = () => {
        dispatch({ type: AuthTypes.RESET_LOGIN_MODAL });
        setShowAuthModal(!showAuthModal);
    };

    const { t } = useTranslation();
    const loggedinItems = [
        {
            key: '1',
            label: (
                <Link
                    to={`${t('/account', { ns: 'routes' })}${t(
                        '/edit-profile',
                        { ns: 'routes' }
                    )}`}
                >
                    {t('editProfile')}
                </Link>
            )
        },
        {
            key: '2',
            label: (
                <Link
                    to={`${t('/account', { ns: 'routes' })}${t(
                        '/sell-your-car',
                        {
                            ns: 'routes'
                        }
                    )}`}
                >
                    {t('sellYourCar')}
                </Link>
            )
        },
        {
            key: '3',
            label: (
                <Link
                    to={`${t('/account', { ns: 'routes' })}${t('/financing', {
                        ns: 'routes'
                    })}`}
                >
                    {t('financing')}
                </Link>
            )
        },
        {
            key: '4',
            label: (
                <Link
                    to={`${t('/account', { ns: 'routes' })}${t('/favourites', {
                        ns: 'routes'
                    })}`}
                >
                    {t('favourites')}
                </Link>
            )
        },
        {
            key: '5',
            label: (
                <Link
                    to={`${t('/account', { ns: 'routes' })}${t(
                        '/reset-password',
                        {
                            ns: 'routes'
                        }
                    )}`}
                >
                    {t('resetPassword')}
                </Link>
            )
        },
        {
            key: '6',
            label: (
                <button onClick={handleLogoutClick} className="auth-button">
                    {t('logout')}
                </button>
            )
        }
    ];

    const loggedinItemsMobile = [
        isMobileorTab && {
            key: '30',
            label: (
                <Link to={`${t('/buy-cars', { ns: 'routes' })}`}>
                    {t('buy')}
                </Link>
            )
        },
        isMobileorTab && {
            key: '31',
            label: (
                <Link to={`${t('/sell-car', { ns: 'routes' })}`}>
                    {t('sell')}
                </Link>
            )
        },
        {
            key: '1',
            label: (
                <Link to={`${t('/finance', { ns: 'routes' })}`}>
                    {t('finance')}
                </Link>
            )
        },
        {
            key: '2',
            label: (
                <Link to={`${t('/car-insurance', { ns: 'routes' })}`}>
                    {t('insure')}
                </Link>
            )
        },
        {
            key: '3',
            label: (
                <Link to={`${t('/about', { ns: 'routes' })}`}>
                    {t('about')}
                </Link>
            )
        },
        {
            key: '4',
            label: (
                <Link to={`${t('/blog', { ns: 'routes' })}`}>{t('blog')}</Link>
            )
        },
        {
            key: '5',
            label: (
                <Link to={`${t('/account', { ns: 'routes' })}`}>
                    {t('account')}
                </Link>
            )
        },
        {
            key: '6',
            label: (
                <button onClick={handleLogoutClick} className="auth-button">
                    {t('logout')}
                </button>
            )
        }
    ];

    const loggedoutItemsMobile = [
        isMobileorTab && {
            key: '30',
            label: (
                <Link to={`${t('/buy-cars', { ns: 'routes' })}`}>
                    {t('buy')}
                </Link>
            )
        },
        isMobileorTab && {
            key: '31',
            label: (
                <Link to={`${t('/sell-car', { ns: 'routes' })}`}>
                    {t('sell')}
                </Link>
            )
        },
        {
            key: '1',
            label: (
                <Link to={`${t('/finance', { ns: 'routes' })}`}>
                    {t('finance')}
                </Link>
            )
        },
        {
            key: '2',
            label: (
                <Link to={`${t('/car-insurance', { ns: 'routes' })}`}>
                    {t('insure')}
                </Link>
            )
        },

        {
            key: '3',
            label: (
                <Link to={`${t('/blog', { ns: 'routes' })}`}>{t('blog')}</Link>
            )
        },
        {
            key: '4',
            label: (
                <button onClick={changeModal} className="auth-button">
                    {t('signIn')} / {t('signUp')}
                </button>
            )
        }
    ];

    const loggedoutItems = [
        {
            key: '1',
            label: (
                <button onClick={changeModal} className="auth-button">
                    {t('signIn')} / {t('signUp')}
                </button>
            )
        }
    ];

    const sidebarProps = { items: isloggedIn ? loggedinItems : loggedoutItems };
    const sidebarPropsMobile = {
        items: isloggedIn ? loggedinItemsMobile : loggedoutItemsMobile
    };

    const goToAccount = () => {
        navigate(t('/account', { ns: 'routes' }));
    };
    const goBack = () => {
        navigate(-1);
    };
    const goToInterested = (id: string | undefined) => {
        navigate(`/interested/${id}`);
    };
    const goToBuyOnline = (id: string | undefined) => {
        navigate(`/buy-online/${id}`);
    };
    let image = '';
    if (car.images) image = JSON.parse(car?.images)[0];

    if (props.ShowdetailsNav && navbar) {
        return (
            <DetailsNav
                image={image}
                car={car}
                onclick={() => {
                    if(newCarFlag)
                        setShowBuyCarModal(true);
                    else goToInterested(car?._id)
                }}
                t={t}
                sidebarProps={sidebarPropsMobile}
                setActiveNav={props.setActiveNavClick}
                newCarFlag={newCarFlag}
                showBuyCarModal={showBuyCarModal}
                setShowBuyCarModal={setShowBuyCarModal}
            />
        );
    } else {
        return (
            <>
                <div
                    className={
                        navbar &&
                        props.whiteBackground &&
                        location.pathname.slice(1, 5) === t('/car')
                            ? `navbar active detail-nav ${(showAuthModal || appendClassToNav) ? "authModal" : ""}`
                            : navbar || props.whiteBackground
                            ? `navbar active  hide-logo ${(showAuthModal || appendClassToNav) ? "authModal" : ""}`
                            : `navbar padding ${(showAuthModal || appendClassToNav) ? "authModal" : ""}`
                    }
                >
                    <div
                        className={
                            navbar &&
                            props.whiteBackground &&
                            location.pathname.slice(1, 5) === t('/car')
                                ? ''
                                : navbar || props.whiteBackground
                                ? 'padding padding-container'
                                : 'padding'
                        }
                    >
                        <Link
                            // to={t('/', {
                            //     ns: 'routes'
                            // })}
                            to={lang !== "en" ? `/${lang}` : `/`}
                            className={
                                props.showAccountNav || props.showInterestNav
                                    ? 'hide'
                                    : 'show'
                            }
                        >
                            <div className="logo-container">
                                <img
                                    src={
                                        navbar || props.whiteBackground
                                            ? '/logos/logo-text-blue.svg'
                                            : '/logos/logo-text-white.svg'
                                    }
                                    alt="auto24"
                                />
                            </div>
                        </Link>

                        <div
                            className={
                                props.whiteBackground &&
                                (props.showAccountNav || props.showInterestNav)
                                    ? 'navbar-right accounting-nav'
                                    : 'navbar-right'
                            }
                        >
                            <div className="navbar-right_linksMobile">
                                {props.showAccountNav ||
                                props.showInterestNav ? (
                                    <AccountNav
                                        onclick={
                                            props.showAccountNav
                                                ? goToAccount
                                                : goBack
                                        }
                                        sidebarProps={sidebarProps}
                                        text={
                                            props.showInterestNav
                                                ? t('backToCarDetail')
                                                : props.headerText
                                        }
                                    />
                                ) : (
                                    <>
                                        {!isMobileorTab && (
                                            <ul className="navbar-right-links">
                                                {NavbarArray.slice(0, 2).map(
                                                    (items) => {
                                                        return (
                                                            <li key={items.to}>
                                                                <NavLink
                                                                    to={t(
                                                                        items.to,
                                                                        {
                                                                            ns: 'routes'
                                                                        }
                                                                    )}
                                                                    className={({
                                                                        isActive
                                                                    }) =>
                                                                        isActive
                                                                            ? 'navbar-right-link-active'
                                                                            : 'navbar-right-link'
                                                                    }
                                                                >
                                                                    {t(
                                                                        items.title
                                                                    )}
                                                                </NavLink>
                                                            </li>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        )}
                                        <LanguageDropdown
                                            class={
                                                navbar || props.whiteBackground
                                                    ? 'active'
                                                    : ''
                                            }
                                            mobile={true}
                                        />
                                        <div>
                                            <Dropdown
                                                {...sidebarPropsMobile}
                                            ></Dropdown>
                                        </div>{' '}
                                    </>
                                )}
                            </div>

                            <div className="navbar-right__desktop">
                                <div className="navbar-right__links">
                                    <ul className="navbar-right-links">
                                        {NavbarArray.map((items) => {
                                            return (
                                                <li key={items.to}>
                                                    <NavLink
                                                        to={t(items.to, {
                                                            ns: 'routes'
                                                        })}
                                                        className={({
                                                            isActive
                                                        }) =>
                                                            isActive
                                                                ? 'navbar-right-link-active'
                                                                : 'navbar-right-link'
                                                        }
                                                    >
                                                        {t(items.title)}
                                                    </NavLink>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>

                                {!!phoneNumber && (
                                    <a
                                        className="navbar-contact"
                                        href={`${whatsappUrl}${phoneNumber.code?.replace(
                                            '+',
                                            ''
                                        )}${whatsapp ?? phoneNumber.number}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="whatsapp-logo-container">
                                            <img
                                                src="/images/auto24-whatsapp.svg"
                                                alt="whatsapp"
                                            />
                                        </div>
                                        <div className="contact-number">
                                            <span className="contact-number-call">
                                                {t('callUsAt')}
                                            </span>
                                            <span className="contact-number-number">
                                                {phoneNumber.code}{' '}
                                                {phoneNumber.number}
                                            </span>
                                        </div>
                                    </a>
                                )}
                                <LanguageDropdown
                                    class={
                                        navbar || props.whiteBackground
                                            ? 'active'
                                            : ''
                                    }
                                />
                                <div>
                                    <Dropdown {...sidebarProps}></Dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showAuthModal ? (
                    <AuthFlow
                        isVisible={showAuthModal}
                        handleCancel={changeModal}
                    />
                ) : null}
                <LogoutModal
                    visible={showLogout}
                    okHandler={logout}
                    cancelHandler={handleLogoutClick}
                    loading={loading}
                />
            </>
        );
    }
};

export default Navbar;

const NavbarArray = [
    {
        title: 'buy',
        to: '/buy-cars'
    },
    {
        title: 'sell',
        to: '/sell-car'
    },
    {
        title: 'finance',
        to: '/finance'
    },
    {
        title: 'insure',
        to: '/car-insurance'
    },
    {
        title: 'blog',
        to: '/blog'
    }
];
const DetailsNav = (props: $FIXME) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { phoneNumber } = useSelector(
        (state: ICombineReducerType) => state.contact.contact
    )

    const pageUrl = window.location.href;

    const message = `${t('whatsappUrlText')}  ${props?.car?.brand?.brand} ${
        props?.car?.model?.model
    } ${props?.car.modelYear}   `;
    const whatsappShareUrl = `${whatsappUrl}${phoneNumber!.number}/?text=${message} on ${pageUrl}`;

    const goBack = () => {
        navigate(-1);
    };

    return useMediaQuery({ query: '(max-width: 1023px)' }) ? (
        <div className="details-account-nav">
            <AccountNav
                classname="padding"
                gotoCarListing="backToTheCarListing"
                onclick={goBack}
                sidebarProps={props.sidebarProps}
            />
            <StickyNav setActiveNav={props.setActiveNav} />
        </div>
    ) : (
        <>
        <BuyCarConfirm
            open={props.showBuyCarModal}
            setModalOpen={props.setShowBuyCarModal}
            carId={props?.car?._id ?? "0"}
        />
        <div className="detailsnav">
            <div className="padding details-wrapper">
                <div className="car-card__left">
                    <div className="car-image">
                        <img
                            src={IMAGE_URL + props.image}
                            alt="carImage"
                            onError={addImageFallback}
                        />
                    </div>
                    <div className="car-deets">
                        <h5 className="car-name">
                            {props?.car?.brand?.brand} {props.car?.model?.model}{' '}
                            {props?.car?.modelYear}
                        </h5>
                        <div className="car-features">
                            <div className="features-container">
                                <div className="features-icons">
                                    <img
                                        src="/logos/automatic.webp"
                                        alt="automatic"
                                    />
                                </div>
                                {props?.car?.transmission &&
                                    t(props.car.transmission)}
                            </div>
                            <div className="features-container">
                                <GasPump size={17} color="#454545" />
                                {props?.car?.fuelType && t(props?.car.fuelType)}
                            </div>

                            <div className="features-container">
                                <div className="features-icons">
                                    <img
                                        src="/logos/timeline.webp"
                                        alt="timeline"
                                    />
                                </div>
                                <span>
                                    {' '}
                                    {formatMoney(props?.car?.mileage) ??
                                        'N/A'}{' '}
                                    km
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="car-card__price">
                        {/* <h4 className="new-price">
                            {' '}
                            {props.car.price &&
                                props.car.price
                                    .toLocaleString()
                                    .replaceAll(',', ' ')}{' '}
                            {process.env.REACT_APP_DEFAULT_CURRENCY}
                        </h4> */}
                        <span>
                            {' '}
                            {props.car?.status === 'sold' && formatMoney(props.car?.price)}
                            {props.car?.status !== 'sold' &&
                                ((String(props?.car?.discount) !== '0' && props.car?.price && String(props.car?.price) !== '0') ? getDiscountedPriceIfNotSold(props.car?.price, props.car.discount) :
                                formatMoney(props.car?.price))
                            }{' '} {process.env.REACT_APP_DEFAULT_CURRENCY}
                        </span>
                        {props.car?.status !== 'sold' &&
                            String(props.car?.discount) !== '0' && (
                                <span>
                                    {' '}
                                    {props.car.price &&
                                        props.car.price
                                            .toLocaleString()
                                            .replaceAll(',', ' ')}{' '}
                                    {process.env.REACT_APP_DEFAULT_CURRENCY}
                                </span>
                            )}
                    </div>
                </div>
                <div className="button-container">
                    <button className={`bottom-btn ${props?.car?.status.toLowerCase() === "sold" && "button-disabled"}`} onClick={props.onclick}
                    disabled={props?.car?.status.toLowerCase() === "sold"}
                    >
                        { props.newCarFlag ? props.t('buyOnline') : props.t('imInterested')}
                    </button>

                    <a
                        className=" whatsapp  car-btn"
                        target="_blank"
                        href={whatsappShareUrl}
                        rel="noreferrer"
                    >
                        <div className="whatsapp-logo-container">
                            <img src="/images/auto24-whatsapp.svg" alt="whatsapp" />
                        </div>
                        WhatsApp
                    </a>
                </div>
            </div>
        </div>
        </>
    );
};
const AccountNav = (props: $FIXME) => {
    const { t } = useTranslation();

    return (
        <div className={`account-nav ${props.classname}`}>
            <div className="account-nav-left">
                <CaretLeft size={20} color="#454545" onClick={props.onclick} />

                <p className="text">
                    {props.gotoCarListing
                        ? t(props.gotoCarListing)
                        : props.text
                        ? t(props.text)
                        : props.showAccountNav
                        ? t('Account') || 'Account'
                        : t('Back To Account') || 'Back To Account'}
                </p>
            </div>
            <div>
                <Dropdown {...props.sidebarProps}></Dropdown>
            </div>
        </div>
    );
};
const StickyNav = (props: $FIXME) => {
    const { car } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );
    const { serviceHistory } = useSelector(
        (state: ICombineReducerType) => state.serviceHistory
    );
    const { t } = useTranslation();

    const StickyNavArray = [
        'overview',
        'summary',
        'specification',
        'features',
        'history'
    ];
    const activeId = useScrollspy(StickyNavArray, 120);
    const filteredFeatureList = GetFeaturedList(car);

    const filteredSpecification = GetSpecification(car);
    const filteredDataWeight = GetSpecificationWeight(car);

    return (
        <div className="sticky-nav padding">
            <ul>
                <li
                    key={1}
                    onClick={() => props.setActiveNav('carOverView')}
                    className={activeId === 'overview' ? 'active' : ''}
                >
                    {t('carOverview')}
                </li>
                <li
                    key={2}
                    onClick={() => props.setActiveNav('summary')}
                    className={activeId === 'summary' ? 'active' : ''}
                >
                    {t('Summary')}
                </li>
                {filteredSpecification.length === 0 &&
                filteredDataWeight.length === 0 ? null : (
                    <li
                        key={3}
                        onClick={() => props.setActiveNav('specifications')}
                        className={activeId === 'specification' ? 'active' : ''}
                    >
                        {t('specifications')}
                    </li>
                )}
                {filteredFeatureList.length > 0 && (
                    <li
                        key={4}
                        onClick={() => props.setActiveNav('features')}
                        className={activeId === 'features' ? 'active' : ''}
                    >
                        {t('features')}
                    </li>
                )}
                {serviceHistory.length !== 0 && (
                    <li
                        key={5}
                        onClick={() => props.setActiveNav('serviceHistorys')}
                        className={activeId === 'history' ? 'active' : ''}
                    >
                        {t('serviceHistory')}
                    </li>
                )}
            </ul>
        </div>
    );
};
