import Account from './Account';

const Tab = () => {
    return (
        <div className="account-mobile">
            <Account showAccount />
        </div>
    );
};

export default Tab;
