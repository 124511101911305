import { message } from 'antd';
import { Dispatch } from 'redux';
import { getfaqs } from '../../constants';
import { Api } from '../Api';
import {
    CREATE_QUESTIONNAIRE_ERROR,
    CREATE_QUESTIONNAIRE_LOADING,
    CREATE_QUESTIONNAIRE_SUCCESS,
    FETCH_ALL_QUESTIONNAIRE_SUCCESS,
    QUESTIONNAIRE_ERROR,
    QUESTIONNAIRE_LOADING,
    QUESTIONNAIRE_SUCCESS
} from '../types/questionnaireTypes';
export const getFaqData = async (dispatch: Dispatch, slug?: string) => {
    dispatch({ type: QUESTIONNAIRE_LOADING });
    const resData: any = await getFaqList(slug);

    if (resData.status === 200) {
        dispatch({
            type: QUESTIONNAIRE_SUCCESS,
            payload: resData.data.faqs
        });
    } else {
        dispatch({
            type: QUESTIONNAIRE_ERROR,
            payload: resData.response.data.msg || 'something went wrong'
        });

        // message.error(resData.response.data.msg || 'something went wrong');
    }
};

export const addQuestion = async (
    dispatch: Dispatch,
    data: any,
    handleCancel: any
) => {
    dispatch({ type: CREATE_QUESTIONNAIRE_LOADING });
    const resData: any = await addFaq(data);
    if (resData.status === 200) {
        dispatch({ type: CREATE_QUESTIONNAIRE_SUCCESS });
        handleCancel();
    } else {
        dispatch({ type: CREATE_QUESTIONNAIRE_ERROR });
        // message.error(resData.response.data.msg);
        // message.error(resData.message || 'something went wrong')
    }
};

export const getAllFaqData = async (dispatch: Dispatch) => {
    dispatch({ type: QUESTIONNAIRE_LOADING });
    const resData: any = await getAllFaq();

    if (resData.status === 200) {
        dispatch({
            type: FETCH_ALL_QUESTIONNAIRE_SUCCESS,
            payload: resData.data.faqs
        });
    } else {
        dispatch({
            type: QUESTIONNAIRE_ERROR,
            payload: resData.response.data?.msg || 'something went wrong'
        });
        // message.error(resData.response.data?.msg || 'something went wrong');
    }
};

export const addFaq = async (data: any) => {
    try {
        const result = await Api.post(`/customer-queries`, {
            ...data
        });
        return result;
    } catch (error) {
        return error;
    }
};

export const getFaqList = async (slug?: string) => {
    try {
        const result = await Api.get(`/${getfaqs}`, { params: { slug } });
        return result;
    } catch (error) {
        return error;
    }
};

export const getAllFaq = async () => {
    try {
        const result = await Api.get(`${getfaqs}`);
        return result;
    } catch (error) {
        return error;
    }
};
