interface Props {
  type: 'primary' | 'secondary' | 'tertiary'
  children: React.ReactNode
  disabled?: boolean
  className?: string
  onClick?: () => void
  htmlType?: 'button' | 'submit' | 'reset'
}

const ButtonComponent = ({
  type = 'primary',
  children,
  disabled = false,
  className,
  onClick,
  htmlType = 'submit',
}: Props) => {
  return (
    <button
      disabled={disabled}
      className={`button-wrapper ${
        type === 'primary'
          ? 'button-primary'
          : type === 'secondary'
          ? 'button-secondary'
          : 'button-ternary'
      } ${className}`}
      onClick={onClick}
      type={htmlType}
    >
      {children}
    </button>
  )
}

export default ButtonComponent
