import { Api } from "../Api"
import { contactInformation } from "../../constants"
import { Dispatch } from "redux"
import { FETCH_CONTACT_INFORMATION_ERROR, FETCH_CONTACT_INFORMATION_LOADING, FETCH_CONTACT_INFORMATION_SUCCESS } from "../types/contactInformationType"

export const contactInformationList = async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_CONTACT_INFORMATION_LOADING})
    const resData: any = await getContactInformation()
    if (resData.status === 200) {
        dispatch ({ type: FETCH_CONTACT_INFORMATION_SUCCESS, payload: resData.data.contactInformation})
    }else {
        dispatch ({ type: FETCH_CONTACT_INFORMATION_ERROR})
    }
    
}

 const getContactInformation = async () => {
    try {
        const result = await Api.get(`/${contactInformation}`)
        return result
    } catch (error) {
        return error
    }
 }