import moment from 'moment';
import 'moment/min/locales.min'; // for language change of date
import i18n from '../localization';

import SecSeperator from './ui/SecSeperator';
import BreadCrumbs from './ui/BreadCrumbs';

import { IBlogDetailType } from '../redux/types/BlogListTypes';
import { $FIXME, IMAGE_URL } from '../constants';
import { contentAccordToCurrentLang, SetMeta } from '../utils/Helper/jsHelpers';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

export const formatDate = (date: $FIXME) => {
    if (i18n.language === 'fr') {
        return moment(date).locale('fr').format('dddd DD MMMM YYYY');
    } else {
        return moment(date).format('dddd DD MMMM YYYY');
    }
};
const BlogDetail: React.FC<IBlogDetailType> = (blog) => {
    const date = new Date(blog.createdAt.substring(0, 10));
    return (
        <>
            <SetMeta
                title={contentAccordToCurrentLang(blog).title + '-AUTO24'}
            />
            <div className="blog-detail padding">
                <div className="blog-detail">
                    <BreadCrumbs
                        currentLocation={contentAccordToCurrentLang(blog).title}
                    />
                    <SecSeperator className="blog-details-sep" />
                    <h2 className="title">
                        {contentAccordToCurrentLang(blog).title}
                    </h2>
                    <div className="author-details">
                        <p className="type">
                            {blog.author?.name ?? 'Auto24 Team'}
                        </p>
                        <p className="date">{formatDate(date)}</p>
                    </div>
                    <div className="details-section">
                        <div className="blog-detail__image">
                            <img
                                src={IMAGE_URL + blog.image}
                                alt=""
                                onError={addImageFallback}
                            />
                        </div>
                        <p
                            className="description"
                            dangerouslySetInnerHTML={{
                                __html:
                                    contentAccordToCurrentLang(blog)
                                        .description ?? 'n/a'
                            }}
                        ></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogDetail;
