import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import SellDetails from '../components/SellDetails';
import SecSeperator from '../components/ui/SecSeperator';
import Layout from '../layout/Layout';

import i18n from '../localization';
import { SetMeta } from '../utils/Helper/jsHelpers';

const SellPage = () => {
    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <SetMeta title="sellPageTitle" desc="sellPageDesc" keywords='sellPageKeywords'
            properties={[
                {
                    key: "og:title",
                    value: "sellPageTitleOg"
                },
                {
                    key: "og:description",
                    value: "sellPageDescOg"
                },
                {
                    key: "og:keywords",
                    value: "sellPageKeywordOg"
                }
            ]}
            />
            <div className="sell-page">
                <Layout showWhiteNavbar>
                    <div
                        className={
                            i18n.language === 'en'
                                ? 'sell-hero'
                                : 'sell-hero fre'
                        }
                    >
                        <div className="desktop-hide">
                            <SecSeperator
                                borderColor="#091731"
                                backgroundColor="#091731"
                            />
                        </div>
                        <div className="mobile-hide">
                            <SecSeperator borderColor="#fff" />
                        </div>
                        <h2
                            className={
                                i18n.language === 'en'
                                    ? 'sell-title'
                                    : 'sell-title fr'
                            }
                        >
                            {t('sellPageTitle')}
                        </h2>
                        <p className="sell-subtitle">{t('sellPageSubtitle')}</p>
                    </div>
                    <div className="sell-vector">
                        <div className="vector-container">
                            <img src="/images/sellpagevector.webp" alt="" />
                        </div>
                        <SellDetails />
                    </div>
                </Layout>
            </div>
        </>
    );
};

export default SellPage;
