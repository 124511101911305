import { RcFile } from 'antd/lib/upload';
import React, { Dispatch, SetStateAction, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { $FIXME, emailRegs } from '../../constants';
import i18n from '../../localization';
import { validateNumber } from '../../redux/actions/countryActions';
import { useLocation } from 'react-router-dom';

export const RemoveWhiteSpacesFromString = (str: string | any) => {
    if (str) {
        return str.split(' ').join('');
    }
    return '';
};
export const ReplaceWhiteSpaceWithDashString = (str: string | any) => {
    if (str) {
        return str.split(' ').join('-');
    }
    return '';
};
interface InumcheckUserType {
    code?: string;
    number?: string;
}
export const numcheck = (phoneNumber: InumcheckUserType, setError: $FIXME) => {
    if (phoneNumber?.code && phoneNumber?.number) {
        return validateNumber(phoneNumber?.code, phoneNumber?.number)
            .then((response) => {
                if (Object.hasOwn(response, 'valid')) {
                    if (response.valid) {
                        setError((prev: $FIXME) => ({
                            ...prev,
                            phoneNumber: ''
                        }));
                        return true;
                    } else {
                        setError({
                            phoneNumber: 'phonenumberisnotvalid'
                        });
                        return false;
                    }
                } else {
                    setError({
                        phoneNumber: 'pleaseTryAfterSomeTime'
                    });
                    return false;
                }
            })
            .catch((error) => {
                setError({
                    phoneNumber: 'pleaseTryAfterSomeTime'
                });
                return false;
            });
    }
};
export const returnOnlyNumber = (str: string) => str.replace(/\D/g, '');

export const returnNumberWithDecimals = (str: string) =>
    str.replace(/[^\d.-]/g, '');
export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
};

// content filter from backend according to current lang
export const currentLang = function () {
    return i18n.language === 'en' ? [i18n.language, 'eng'] : [i18n.language];
};
export const contentAccordToCurrentLang = function (content: any) {
    let result;

    if (content && Array.isArray(content)) {
        result = content.map((item: $FIXME) => {
            return (
                item?.details?.find((fqs: any) =>
                    currentLang().includes(fqs.lang)
                ) ?? item?.details[0]
            );
        });
    } else {
        result =
            content?.details?.find((item: any) =>
                currentLang().includes(item.lang)
            ) ?? content.details[0];
    }
    return result?.length === 1 ? result[0] : result;
};
export const contentAccordToCurrentLangs = function (content: any) {
    let result;
    if (Array.isArray(content)) {
        result = content.map((item: $FIXME) => {
            return (
                item.openingFields?.find((fqs: any) =>
                    currentLang().includes(fqs.lang)
                ) ?? item.openingFields[0]
            );
        });
    } else {
        result =
            content?.openingFields?.find((item: any) =>
                currentLang().includes(item.lang)
            ) ?? content.openingFields[0];
    }
    return result?.length === 1 ? result[0] : result;
};
export const contentAccordToCurrentLangTime = function (content: any) {
    let result;
    if (Array.isArray(content)) {
        result = content.map((item: $FIXME) => {
            return (
                item.openingFields?.find((fqs: any) =>
                    currentLang().includes(fqs.lang)
                ) ?? item.openingFields[0]
            );
        });
    } else {
        result =
            content?.openingFields?.find((item: any) =>
                currentLang().includes(item.lang)
            ) ?? content.openingFields[0];
    }
    return result?.length === 1 ? result[0] : result;
};

export const formatKm = function (n: any) {
    return (
        (Math.round(n * 100) / 100).toLocaleString().replaceAll(',', '  ') +
        ' KM'
    );
};

export const Stringfy = function (data: any) {
    if (Array.isArray(data) && data?.length) {
        return JSON.stringify(data);
    }
    return null;
};
export const useOutsideClick = (callback: any) => {
    const ref = useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const handleClick = (event: any) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [ref]);

    return ref;
};

export const SetMeta = function SetMeta(props: {
    title?: string;
    desc?: string;
    keywords?: string;
    properties?: {key: string, value: string}[]
}) {
    
    const location = useLocation();
    const { t } = useTranslation(['meta']);
    const isArabic = location.pathname.includes("/ar");
    return (
        <Helmet>
            {/* <title>{t(props?.title ?? '')}</title> */}
            <title>{props?.title ? isArabic ? t(props?.title).split("").reverse().join("") : t(props?.title) : ''}</title>
            {/* <meta name="description" content={t(props.desc ?? '')}></meta> */}
            <meta name="description" content={props?.desc ? isArabic ? t(props?.desc).split("").reverse().join("") : t(props?.desc) : ''}></meta>
            {/* <meta name="keywords" content={t(props.keywords ?? '')}></meta> */}
            <meta name="keywords" content={props?.keywords ? isArabic ? t(props?.keywords).split("").reverse().join("") : t(props?.keywords) : ''}></meta>
            {
                props.properties && props.properties.map((item) => (
                    // <meta name={item.key} content={t(item.value ?? '')} />
                    <meta name={item.key} content={item.value ? isArabic ? t(item.value).split("").reverse().join("") : t(item.value) : ''} />
                ))
            }
        </Helmet>
    );
};
export const nameListFilter = (name: string, input: string) => {
    return name.toLowerCase().startsWith(input.toLowerCase());
};
export const fullNameValidate = function (fullName: string) {
    return fullName.split(' ').filter((item: any) => item).length > 1;
};
export const emailValidate = function (email: string) {
    return emailRegs.test(email)
};
