import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { message, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { EnvelopeSimple, User } from 'phosphor-react';
import { useMediaQuery } from 'react-responsive';

import SecSeperator from './ui/SecSeperator';
import CountryCodeDropdown from './ui/CountryCodeDropdown';
import LoadingButton from './ui/LoadingButton';

import {
    userDeleteProfile,
    userEditProfile
} from '../redux/actions/authActions';
import { ICombineReducerType } from '../redux/reducers';
import { $FIXME, emailRegs, IMAGE_URL } from '../constants';
import {
    fullNameValidate,
    numcheck,
    returnOnlyNumber
} from '../utils/Helper/jsHelpers';
import { defaultDailing_Code } from '../helpers/countryCode';
import { AuthTypes } from '../redux/types/AuthTypes';
import { WISH_LIST_SUCCESS } from '../redux/types/wishListTypes';
import { addImageFallback } from '../utils/Helper/ImagePlaceholder';

const EditProfiles = (props: any) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [changeSaved, setchangeSaved] = useState(false);
    const [error, setError] = useState<any>({});
    const [deleteAccount, setDeleteAccount] = useState(false);
    const [deleteAfterAccount, setDeleteAfterAccount] = useState(false);

    let { user } = useSelector((state: ICombineReducerType) => state.auth);

    const [updateData, setUpdate] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const dispatch = useDispatch();

    const handleChange = (e: $FIXME) => {
        setError({
            phoneNumber: ''
        });
        let { value, name } = e.target;
        if (name === 'code' || name === 'number') {
            setUpdate({
                ...updateData,
                [name]: returnOnlyNumber(value)
            });
        } else {
            setUpdate({
                ...updateData,
                [name]: value
            });
        }
        buttonDisable(e);
    };

    function buttonDisable(e: $FIXME) {
        switch (e.target.name) {
            case 'name':
                if (!fullNameValidate(e.target.value)) {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
                return;
            case 'email':
                if (emailRegs.test(e.target.value)) {
                    setDisabled(false);
                } else {
                    setDisabled(true);
                    setError({ ...error, email: 'pleaseInputValidEmail' });
                }
                return;
            case 'number':
                if (returnOnlyNumber(e.target.value).trim().length === 10) {
                    setDisabled(false);
                } else {
                    setDisabled(true);
                }
                return;
        }
    }
    //saveModalab
    useEffect(() => {
        if (!props.activeTab) {
            setUpdate({});
            setError('');
        }
    }, [props]);
    const showModal = async (e: any) => {
        e.preventDefault();
        let code = user.phoneNumber.code,
            number = user.phoneNumber.number;

        let isNumberCorrect =
            updateData.code || updateData.number
                ? await numcheck(
                      {
                          code: updateData.code ?? code,
                          number: updateData.number ?? number
                      },
                      setError
                  )
                : true;

        if (isNumberCorrect) {
            setIsModalVisible(true);
        }
    };
    const handleCancel = (e: any) => {
        setIsModalVisible(false);
    };

    const saveUpdatedData = async () => {
        var formData = new FormData();
        for (const key of Object.keys(updateData)) {
            formData.append(key, updateData[key]);
        }
        setLoading(true);

        userEditProfile(dispatch, formData)
            .then((msg) => {
                message.success(msg);
                setchangeSaved(true);
            })
            .catch(({ msg }) => {
                // message.error(msg);
                setError(msg);
            })
            .finally(() => {
                setIsModalVisible(false);

                setUpdate({
                    number: user.phoneNumber.number,
                    code: user.phoneNumber.code
                });
                setLoading(false);
            });
    };

    // aftersavemodal
    const goToListing = () => {
        setchangeSaved(false);
        navigate(t('/buy-cars', { ns: 'routes' }));
    };
    const goToHome = () => {
        setchangeSaved(false);
        navigate(t('home', { ns: 'routes' }));
    };
    const onCancle = () => {
        setchangeSaved(false);
        setIsModalVisible(false);
    };
    const [disable, setDisabled] = useState(false);
    // deletemodal
    const deleteAccounts = (e: any) => {
        setDeleteAccount(true);
        e.preventDefault();
    };
    const cancelHandler = () => {
        setDeleteAccount(false);
    };

    const confirmDelete = (e: any) => {
        setDeleteLoading(true);
        e.preventDefault();
        userDeleteProfile(dispatch)
            .then(() => {
                dispatch({ type: AuthTypes.LOG_OUT });
                dispatch({
                    type: WISH_LIST_SUCCESS,
                    payload: []
                });
                setDeleteAccount(false);
                navigate(t('home', { ns: 'routes' }));
                setDeleteAfterAccount(true);
            })
            .catch(() => {})
            .finally(() => {
                setDeleteLoading(false);
            });
    };

    //after account delete
    const exploreListing = () => {
        setDeleteAfterAccount(false);
        navigate(t('/buy-cars', { ns: 'routes' }));
    };
    const onCancel = () => {
        setDeleteAfterAccount(false);
    };
    const profileInput = useRef<any>(null);
    function updateProfile(e: any) {
        profileInput.current.click();
    }
    function profileUpdate(e: any) {
        const { files } = e.target;

        setUpdate({
            ...updateData,
            profile: files[0]
        });
    }
    function removeProfile() {
        user.profile = null;
        setUpdate({
            ...updateData,
            profile: ''
        });
    }

    const ismobile = useMediaQuery({ query: '(max-width:420px)' });

    return (
        <div className="edit-profiles">
            <SecSeperator className="edit-sep" />
            <h3 className="title edit-title">{t('editProfile')}</h3>
            <div className="profile-container">
                <div className="profile-image">
                    <img
                        src={
                            !!updateData.profile
                                ? URL.createObjectURL(updateData.profile)
                                : !!user?.profile
                                ? IMAGE_URL + user?.profile
                                : '/images/usericon.webp'
                        }
                        alt=""
                        onError={addImageFallback}
                    />
                </div>
                <span
                    onClick={updateProfile}
                    className="upload-btn button-primary"
                >
                    {ismobile ? t('upload') : t('profileUpload')}
                    <input
                        hidden
                        ref={profileInput}
                        type="file"
                        autoFocus
                        onChange={profileUpdate}
                        name="profile-image"
                    />
                </span>
                <span
                    onClick={removeProfile}
                    className="remove-profile-btn button-secondary"
                >
                    {t('remove')}
                </span>
            </div>

            <div className="edit-form-container">
                <div className="edit-form">
                    <h4 className="title">{t('personalDetails')}</h4>
                    <form onSubmit={showModal} className="form">
                        <span className="profile-input overflow relative ">
                            <span className="icon">
                                <User size={20} color="#8b8b8b" />
                            </span>
                            <input
                                onBlur={() => {
                                    if (updateData.name) {
                                        if (
                                            !fullNameValidate(updateData.name)
                                        ) {
                                            setError({
                                                ...error,
                                                fullName: 'pleaseEnterFullName'
                                            });
                                        }
                                    }
                                }}
                                type="text"
                                placeholder={t('enterFullName')}
                                name="name"
                                required
                                value={updateData?.name ?? user?.name}
                                onChange={handleChange}
                            />{' '}
                            {error && (
                                <div
                                    className="error-label absolute fade-in "
                                    style={{ bottom: '-25px', left: 0 }}
                                >
                                    {t(error.fullName)}
                                </div>
                            )}
                        </span>

                        <CountryCodeDropdown
                            onInputChange={handleChange}
                            inputClass="profile-number"
                            selectClass="input-background"
                            onSelect={(value, key) => {
                                setUpdate((prev: $FIXME) => ({
                                    ...prev,
                                    [key]: value
                                }));
                            }}
                            selectName="code"
                            required={true}
                            inputName="number"
                            error={error?.phoneNumber}
                            inputStyle={
                                error?.phoneNumber ? { borderColor: 'red' } : {}
                            }
                            inputValue={
                                updateData?.number ?? user?.phoneNumber?.number
                            }
                            selectValue={
                                updateData?.code ??
                                user?.phoneNumber?.code ??
                                defaultDailing_Code
                            }
                        />
                        <span className="profile-input">
                            <span className="icon">
                                <EnvelopeSimple size={20} color="#8b8b8b" />
                            </span>
                            <input
                                type="email"
                                placeholder={t('enteremailAddress')}
                                name="email"
                                required
                                value={updateData?.email ?? user?.email}
                                onChange={handleChange}
                            />
                        </span>

                        <textarea
                            name="bio"
                            id=""
                            cols={30}
                            rows={5}
                            placeholder={t('shortBio')}
                            value={updateData?.bio ?? user?.bio ?? ''}
                            onChange={handleChange}
                        ></textarea>
                        <div className="form-btn-container">
                            <button
                                className="form-btn send button-primary"
                                disabled={disable}
                                type="submit"
                            >
                                {t('Save')}
                            </button>
                            <button className="form-btn cancel">
                                {t('cancel')}
                            </button>
                        </div>
                    </form>
                    <div className="delete">
                        <p>{t('doyouwantDeleteAccount')}</p>
                        <button onClick={deleteAccounts}>{t('delete')}</button>
                    </div>
                </div>
            </div>
            <Modal
                className="save-modal ants-modal"
                open={isModalVisible}
                onCancel={handleCancel}
                closable={false}
                centered={true}
            >
                <h3 className="title">{t('saveChanges')}</h3>
                <p className="subtitle">
                    {t('youcanstillchangetheseinformation')}
                </p>
                <div className="btn-container">
                    {loading ? (
                        <LoadingButton buttonText="saving" />
                    ) : (
                        <button className="send-btn" onClick={saveUpdatedData}>
                            {t('Save')}
                        </button>
                    )}
                    <button className="cancel" onClick={handleCancel}>
                        {t('cancel')}
                    </button>
                </div>
            </Modal>
            <div className="account-modal">
                <Modal
                    className="save-changes-modal ants-modal"
                    title={t('changessavedsuccessfully')}
                    visible={changeSaved}
                    onCancel={onCancle}
                    closable={false}
                    centered={true}
                >
                    <p className="subtitle">
                        {t('yourchangessavedsuccessfully')}
                    </p>
                    <div className="done-icon">
                        <img src="/images/done.webp" alt="" />
                    </div>
                    <div className="modal-btn-container">
                        <button className="explore" onClick={goToListing}>
                            {t('exploreCar', { ns: 'hero' })}
                        </button>
                        <button className="back-to-home" onClick={goToHome}>
                            {t('backToHome')}
                        </button>
                    </div>
                </Modal>
            </div>
            <Modal
                className="delete-modal ants-modal"
                title={t('deleteyourAccount')}
                open={deleteAccount}
                onCancel={cancelHandler}
                closable={false}
                centered={true}
            >
                <p className="subtitle">{t('deleteAccountsubtitile')}</p>
                <div className="modal-btn-container">
                    <button className="cancel" onClick={cancelHandler}>
                        {t('cancel')}
                    </button>
                    {deleteLoading ? (
                        <LoadingButton buttonText="deleting" />
                    ) : (
                        <button
                            className="logout-btn delete"
                            onClick={confirmDelete}
                        >
                            {t('delete')}
                        </button>
                    )}
                </div>
            </Modal>
            <Modal
                className="delete-changes-modal ants-modal"
                title={t('accountdeletedsuccessfully')}
                open={deleteAfterAccount}
                onCancel={onCancel}
                closable={false}
                centered={true}
            >
                <p className="subtitle">
                    {t('youcanstillexpolrecarwithouthavinganaccount')}
                </p>
                <div className="done-icon">
                    <img src="/images/done.webp" alt="" />
                </div>
                <div className="modal-btn-container">
                    <button className="explore" onClick={exploreListing}>
                        {t('exploreCar', { ns: 'hero' })}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default EditProfiles;
