import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { Dispatch } from 'redux';
import { sessionExpireDate } from '../../constants';
import { IForgetPass, request } from '../../helpers/request';
import TokenService from '../../helpers/TokenService';
import { ILogin, ISignUp } from '../../interface/auth';
import { AuthTypes } from '../types/AuthTypes';
import i18n from '../../localization';

export interface ResponseUser {
    id: string;
    authToken: string;
    refreshToken: string;
    msg: string;
}

export const register = (dispatch: Dispatch, body: ISignUp) => {
    return request
        .register(body)
        .then((response: AxiosResponse) => {
            return Promise.resolve(response.data);
        })
        .catch((error: AxiosError | Error) => {
            if (axios.isAxiosError(error)) {
                let data = error.response?.data;
                return Promise.reject<Object>(data);
            }
            return Promise.reject(error);
        });
};

export const login = (dispatch: Dispatch, body: ILogin) => {
    return request
        .login(body)
        .then((response: AxiosResponse) => {
            const { authToken, refreshToken, user } = response.data;
            TokenService.setUser(user);
            if (body.remember) {
                Cookies.set('remember', 'true', {
                    expires: sessionExpireDate,
                    secure: true
                });
            }
            TokenService.updateLocalAccessToken(authToken, refreshToken);
            dispatch({
                type: AuthTypes.LOG_IN,
                payload: {
                    user
                }
            });
        })
        .catch((error: AxiosError | Error) => {
            if (axios.isAxiosError(error)) {
                return Promise.reject<Object>(error.response?.data);
            } else {
            }
        });
};

export const userEditProfile = (dispatch: Dispatch, body: any) => {
    return request
        .editProfile(body)
        .then((res: AxiosResponse) => {
            dispatch({ type: AuthTypes.UPDATE_USER, payload: res.data });
            return Promise.resolve(res.data?.msg);
        })
        .catch((error: AxiosError | Error) => {
            if (axios.isAxiosError(error)) {
                return Promise.reject(error.response?.data);
            }
        });
};
export const userDeleteProfile = (dispatch: Dispatch) => {
    return request
        .deleteProfile()
        .then((res: AxiosResponse) => {
            dispatch({ type: AuthTypes.LOG_OUT });
            return Promise.resolve(res.data);
        })
        .catch((err: AxiosError | Error) =>
            axios.isAxiosError(err) ? Promise.reject(err.request?.data) : ''
        );
};
export const resetPw = (dispatch: Dispatch, body: IForgetPass) => {
    return request
        .resetPw(body)
        .then((res: AxiosResponse) => {
            return Promise.resolve(res.data?.msg);
        })
        .catch((err: AxiosError | Error) => {
            if (axios.isAxiosError(err))
                return Promise.reject(
                    err.response?.data ||
                        (i18n.t('somethingWentWrong') as string)
                );
        });
};
