import { EnvelopeSimple } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import LoadingButton from './ui/LoadingButton';
import SecSeperator from './ui/SecSeperator';

import { emailRegs } from '../constants';
import { getSubscription } from '../redux/actions/subscribeAction';
import { ICombineReducerType } from '../redux/reducers';

const NewsLetter = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let { user } = useSelector((state: ICombineReducerType) => state.auth);
    const [email, setEmail] = useState(user?.email ?? '');

    const subscribeToBlog = (e: any) => {
        e.preventDefault();
        getSubscription(dispatch, email).finally(() =>
            setEmail(user?.email ?? '')
        );
    };
    const [disabled, setDisabled] = useState(true);
    useEffect(() => {
        if (email && emailRegs.test(email)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [email]);

    const { loading } = useSelector(
        (state: ICombineReducerType) => state.subscriber
    );

    return (
        <div className="news-letter">
            <SecSeperator />
            <h2 className="title">{t('newsLetterTitle')}</h2>
            <p className="subtitle">{t('newsLetterSubtitle')}</p>
            <form className="email-send" onSubmit={subscribeToBlog}>
                <div className="email">
                    {' '}
                    <span className="envelope">
                        {' '}
                        <EnvelopeSimple size={20} color="#8b8b8b" />
                    </span>{' '}
                    <input
                        type="email"
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                        placeholder={t('enteremailAddress')}
                        required
                    />
                </div>
                {loading ? (
                    <LoadingButton buttonText="sending" />
                ) : (
                    <button
                        className="bottom-btn button-primary"
                        disabled={disabled}
                        type="submit"
                    >
                        {t('send')}
                    </button>
                )}
            </form>
        </div>
    );
};

export default NewsLetter;
