const getMetaFrench = (auto24Domain: string) => ({
    homePageTitle: `${auto24Domain} - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays`,
    homePageDesc: `Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.`,
    homePageKeywords: `Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc`,
    homePageTitleOg: `${auto24Domain} - Voitures d'Occasion Certifiées au Maroc | Achetez et Vendez dans Tout le Pays`,
    homePageDescOg: `Votre plateforme de choix au Maroc pour acheter et vendre des voitures d'occasion certifiées. Profitez d'évaluations gratuites, de ventes rapides et d'une assurance qualité à travers le pays.`,
    homePageKeywordsOg: `Voitures d'Occasion Certifiées, Acheter Voiture Maroc, Vendre Voiture Maroc, Marketplace Automobile, AUTO24 Maroc, Voitures de Qualité, Vente Auto Maroc`,
    buyPageTitle: `Achetez des Voitures d'Occasion de Qualité au Maroc | ${auto24Domain}`,
    buyPageDesc: `Découvrez une large sélection de voitures d'occasion certifiées sur ${auto24Domain} . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.`,
    buyPageKeywords: `Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc`,
    buyPageTitleOg: `Achetez des Voitures d'Occasion de Qualité au Maroc | ${auto24Domain}`,
    buyPageDescOg: `Découvrez une large sélection de voitures d'occasion certifiées sur ${auto24Domain} . Nous simplifions votre recherche de la voiture idéale qui s'adapte à votre budget et à votre style de vie dans tout le Maroc.`,
    buyPageKeywordsOg: `Acheter Voitures Maroc, Voitures d'Occasion Certifiées, AUTO24 Maroc, Shopping de Voitures, Voitures Abordables Maroc`,
    sellPageTitle: `Vendez Votre Voiture Rapidement et Facilement au Maroc | ${auto24Domain}`,
    sellPageDesc: `Vous souhaitez vendre votre voiture? ${auto24Domain} offre une expérience sans tracas pour le faire, fournissant des évaluations gratuites et des ventes rapides dans tout le pays.`,
    sellPageKeywords: `Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc`,
    sellPageTitleOg: `Vendez Votre Voiture Rapidement et Facilement au Maroc | ${auto24Domain}`,
    sellPageDescOg: `Vous souhaitez vendre votre voiture? ${auto24Domain} offre une expérience sans tracas pour le faire, fournissant des évaluations gratuites et des ventes rapides dans tout le pays.`,
    sellPageKeywordsOg: `Vendre Voiture Maroc, Vente de Voitures d'Occasion au Maroc, Ventes Rapides de Voitures, AUTO24 Vendre Voiture, Évaluation de Voiture Maroc`,
    aboutPageTitle: `À Propos de ${auto24Domain} - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc`,
    aboutPageDesc: `En savoir plus sur ${auto24Domain} , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.`,
    aboutPageKeywords: ` À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc`,
    aboutPageTitleOg: `À Propos de ${auto24Domain} - Votre Marketplace de Voitures d'Occasion de Confiance dans tout le Maroc`,
    aboutPageDescOg: `En savoir plus sur ${auto24Domain} , la première plateforme de voitures d'occasion certifiées au Maroc. Nous offrons des voitures de qualité et des expériences d'achat et de vente fluides à travers le pays.`,
    aboutPageKeywordsOg: ` À Propos de AUTO24 Maroc, Marketplace de Voitures d'Occasion Certifiées, Ventes de Voitures de Confiance, Concessionnaire au Maroc`,
    financePageTitle: `Financez Facilement Votre Voiture d'Occasion avec ${auto24Domain} au Maroc`,
    financePageDesc: `Découvrez des options de financement flexibles pour votre voiture d'occasion avec ${auto24Domain} . Simplifiez l'achat de voitures dans tout le Maroc.`,
    financePageKeywords: `Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto`,
    financePageTitleOg: `Financez Facilement Votre Voiture d'Occasion avec ${auto24Domain} au Maroc`,
    financePageDescOg: `Découvrez des options de financement flexibles pour votre voiture d'occasion avec ${auto24Domain} . Simplifiez l'achat de voitures dans tout le Maroc.`,
    financePageKeywordsOg: `Financement de Voiture, Crédit Auto, AUTO24 Maroc, Options de Financement, Prêt Auto`,
    insurePageTitle: `Assurez Votre Voiture de Manière Fiable avec ${auto24Domain} au Maroc`,
    insurePageDesc: `Obtenez une assurance auto abordable et fiable pour votre voiture d'occasion avec ${auto24Domain} . Choisissez parmi une gamme d'options pour répondre à vos besoins dans tout le pays.`,
    insurePageKeywords: `Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance`,
    insurePageTitleOg: `Assurez Votre Voiture de Manière Fiable avec ${auto24Domain} au Maroc`,
    insurePageDescOg: `Obtenez une assurance auto abordable et fiable pour votre voiture d'occasion avec ${auto24Domain} . Choisissez parmi une gamme d'options pour répondre à vos besoins dans tout le pays.`,
    insurePageKeywordsOg: `Assurance Auto, Couverture d'Assurance, AUTO24 Maroc, Assurance Voiture d'Occasion, Options d'Assurance`,
    blogPageTitle: `Blog ${auto24Domain} - Conseils, Astuces et Actualités sur le Marché Automobile au Maroc`,
    blogPageDesc: `Restez à jour avec les dernières nouvelles, conseils et astuces sur le marché automobile marocain. ${auto24Domain} est votre ressource incontournable pour tout ce qui concerne les voitures d'occasion.`,
    blogPageKeywords: `Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture`,
    blogPageTitleOg: `Blog ${auto24Domain} - Conseils, Astuces et Actualités sur le Marché Automobile au Maroc`,
    blogPageDescOg: `Restez à jour avec les dernières nouvelles, conseils et astuces sur le marché automobile marocain. ${auto24Domain} est votre ressource incontournable pour tout ce qui concerne les voitures d'occasion.`,
    blogPageKeywordsOg: `Blog AUTO24, Actualités Auto, Conseils sur les Voitures, Marché Automobile Maroc, Astuces Voiture`,
    qNaPageTitle: `AUTO24 - Questions & réponses fréquentes`,
    contactUsPageTitle: `AUTO24 - Nous contacter`,
    privacyPolicyPageTitle: `AUTO24 - Politique de confidentialité`,
    termsNusePageTitle: `AUTO24 - Conditions générales d’utilisation`,
    carDetailPageTitle: `Acheter {{make}},{{price}} - AUTO24`,
    carDetailPageDesc: ` Acheter une {{make}} en occasion certifiée à {{PRIX}} DH avec AUTO24, Abidjan, Côte d’Ivoire`,
    buyPageDescWhenBrand: `Les meilleurs prix et services pour les voitures d’occasion {{BRAND}} à Abidjan en Côte d’Ivoire. Satisfait ou remboursé, garantie, assurance, maintenance et plus !`
})

export default getMetaFrench;