import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { message, Modal } from 'antd';
import { Eye, EyeSlash, LockSimple } from 'phosphor-react';

import SecSeperator from './ui/SecSeperator';
import LoadingButton from './ui/LoadingButton';

import { resetPw } from '../redux/actions/authActions';
import { IForgetPass } from '../helpers/request';

const ResetPw = () => {
    const [reset, setReset] = useState(false);
    const [resetDone, setResetDone] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPassNew, setShowPassNew] = useState(false);
    const [showPassConfirm, setShowPassConfirm] = useState(false);
    const [isMatched, setPassMatched] = useState(true);
    const [err, setError] = useState('');
    const [resetPassPaylaod, setPayload] = useState({
        oldPassword: '',
        password: '',
        confirmPassword: ''
    } as IForgetPass);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const SaveChanges = (e: any) => {
        const { confirmPassword, password } = resetPassPaylaod;
        if (confirmPassword !== password) {
            setPassMatched(false);
            e.preventDefault();
            return;
        }
        setReset(true);
        e.preventDefault();
    };

    const handleCancel = () => {
        resetFields();
        setReset(false);
    };

    const confirmResetPw = () => {
        setLoading(true);
        resetPw(dispatch, resetPassPaylaod)
            .then(
                (msg) => (
                    setResetDone(true),
                    setReset(false),
                    message.success(t('passwordresetsuccessfully')),
                    (resetPassPaylaod.oldPassword = ''),
                    (resetPassPaylaod.password = ''),
                    (resetPassPaylaod.confirmPassword = '')
                )
            )
            .catch((data) => {
                setReset(false);
                setError(data.msg);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const goToListing = () => {
        setResetDone(false);
        navigate(t('/buy-cars', { ns: 'routes' }));
    };
    const goToHome = () => {
        setResetDone(false);
        navigate(t('home', { ns: 'routes' }));
    };
    const onCancel = () => {
        setResetDone(false);
    };
    const resetInput = (e: any) => {
        resetFields();
        setPassMatched(true);
        e.preventDefault();
    };
    const resetFields = () => {
        setPayload({
            oldPassword: '',
            confirmPassword: '',
            password: ''
        });
    };
    const handleChange = (e: any) => {
        setError('');
        setPassMatched(true);
        const { value, name } = e.target;
        setPayload({
            ...resetPassPaylaod,
            [name]: value
        });
    };
    const isDisable = () => {
        if (
            Object.values(resetPassPaylaod).filter((item) => item).length ===
            Object.keys(resetPassPaylaod).length
        )
            return false;
        return true;
    };
    const { t } = useTranslation();
    return (
        <div className="reset-password">
            <SecSeperator className="reset-sep" />
            <h3 className="title reset-title">{t('resetPassword')} </h3>
            <form className="reset-input-container" autoComplete="off">
                <div className={`reset-input relative`}>
                    <div className="icon">
                        <LockSimple size={24} color="#b3b3b3" weight="bold" />
                    </div>
                    <input
                        type={showPass ? '' : 'password'}
                        onChange={handleChange}
                        value={resetPassPaylaod.oldPassword}
                        name="oldPassword"
                        placeholder={t('enterOldPassword')}
                    />
                    <div className="image">
                        {!showPass ? (
                            <EyeSlash
                                cursor="pointer"
                                onClick={() => setShowPass(!showPass)}
                                size={20}
                                color="#b3b3b3"
                            />
                        ) : (
                            <Eye
                                cursor="pointer"
                                onClick={() => setShowPass(!showPass)}
                                size={20}
                                color="#b3b3b3"
                            />
                        )}
                    </div>
                </div>
                <div className="reset-input">
                    <div className="icon">
                        <LockSimple size={24} color="#b3b3b3" weight="bold" />
                    </div>
                    <input
                        type={showPassNew ? '' : 'password'}
                        onChange={handleChange}
                        value={resetPassPaylaod.password}
                        name="password"
                        placeholder={t('enterNewPassword')}
                    />
                    <div className="image">
                        {!showPassNew ? (
                            <EyeSlash
                                cursor="pointer"
                                onClick={() => setShowPassNew(!showPassNew)}
                                size={20}
                                color="#b3b3b3"
                            />
                        ) : (
                            <Eye
                                cursor="pointer"
                                onClick={() => setShowPassNew(!showPassNew)}
                                size={20}
                                color="#b3b3b3"
                            />
                        )}
                    </div>
                </div>
                <div className="reset-input">
                    <div className="icon">
                        <LockSimple size={24} color="#b3b3b3" weight="bold" />
                    </div>
                    <input
                        type={showPassConfirm ? '' : 'password'}
                        onChange={handleChange}
                        value={resetPassPaylaod.confirmPassword}
                        name="confirmPassword"
                        placeholder={t('confirmNewPassword')}
                    />
                    <div className="image">
                        {!showPassConfirm ? (
                            <EyeSlash
                                cursor="pointer"
                                onClick={() =>
                                    setShowPassConfirm(!showPassConfirm)
                                }
                                size={20}
                                color="#b3b3b3"
                            />
                        ) : (
                            <Eye
                                cursor="pointer "
                                onClick={() =>
                                    setShowPassConfirm(!showPassConfirm)
                                }
                                size={20}
                                color="#b3b3b3"
                            />
                        )}
                    </div>
                </div>

                <div className="form-btn-container relative">
                    {!isMatched && (
                        <div className="error-label absolute">
                            {t('bothPasswordMustMatch')}
                        </div>
                    )}
                    {err && <span className="error-label absolute">{err}</span>}

                    <button
                        className={
                            isDisable() ? 'form-btn  disable' : 'form-btn send'
                        }
                        disabled={isDisable()}
                        onClick={SaveChanges}
                    >
                        {t('Save')}
                    </button>
                    <button className="form-btn cancel" onClick={resetInput}>
                        {t('cancel')}
                    </button>
                </div>
            </form>
            <Modal
                className="save-modal ants-modal reset-modal"
                open={reset}
                onCancel={handleCancel}
                closable={false}
                centered={true}
            >
                <h3 className="title">{t('resetPasswordModalTitle')}</h3>
                <div className="btn-container">
                    {loading ? (
                        <LoadingButton buttonText="saving" />
                    ) : (
                        <button className="send-btn" onClick={confirmResetPw}>
                            {t('confirm')}{' '}
                        </button>
                    )}
                    <button className="cancel" onClick={handleCancel}>
                        {t('cancel')}
                    </button>
                </div>
            </Modal>
            <Modal
                className="save-changes-modal ants-modal"
                title={t('passwordChangeSuccessfully')}
                open={resetDone}
                onCancel={onCancel}
                closable={false}
                centered={true}
            >
                <p className="subtitle">
                    {t('yourPasswordChangedSuccessfully')}
                </p>
                <div className="done-icon">
                    <img src="/images/done.webp" alt="" />
                </div>
                <div className="modal-btn-container">
                    <button className="explore" onClick={goToListing}>
                        {t('exploreCar', { ns: 'hero' })}
                    </button>
                    <button className="back-to-home" onClick={goToHome}>
                        {t('backToHome')}
                    </button>
                </div>
            </Modal>
        </div>
    );
};

export default ResetPw;
