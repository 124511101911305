import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Select, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretDown } from 'phosphor-react';
import { useDebouncedCallback } from 'use-debounce';

import ListingCard from './ListingCard';
import Card from './ui/Card';
import Loading from './ui/Loading';
import { NoCarFound } from './ui/NoCarFound';
import { SellYourCarForm } from './Certification';

import { getCarData } from '../redux/actions/carListingAction';
import { ICombineReducerType } from '../redux/reducers';
import { $FIXME } from '../constants';
import { CarFilterType, CarSortType } from '../interface/car';
import { Stringfy } from '../utils/Helper/jsHelpers';
import { appendClassInNavbarAction } from '../redux/actions/appendClassInNavbarActions';
import { api } from '../helpers/api';

const { Option } = Select;

const Listings = (props: $FIXME) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { loading, cars, totalCar } = useSelector(
        (state: ICombineReducerType) => state.carListing
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const showModal = (e: any) => {
        setIsModalVisible(true);
        appendClassInNavbarAction(dispatch, true);
        e.preventDefault();
    };
    const handleCancel = () => {
        setIsModalVisible(false);
        appendClassInNavbarAction(dispatch, false);
    };
    const handleOk = () => {
        setIsModalVisible(false);
        appendClassInNavbarAction(dispatch, false);
    };
    const debounced = useDebouncedCallback(fetchData, 1000);
    async function fetchData() {
        const modelYearParams = searchParams.getAll('modelYear');
        let currentYearAndAbove = [];
        if(modelYearParams && Array.isArray(modelYearParams) && modelYearParams.length > 0) {
            const modelYearsAPIData = await api.get('/cars/get-car-years');
            const currentYear = modelYearParams[0].split("").filter(item => ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(item)).join("");
            currentYearAndAbove = modelYearsAPIData.data.modelYears.filter((modelYr: string) => modelYr >= currentYear);
        }
        getCarData(dispatch, {
            search: searchParams.get('search') ?? undefined,
            brands: Stringfy(searchParams.getAll('brand')) ?? undefined,
            models: Stringfy(searchParams.getAll('model')) ?? undefined,
            minPrice: searchParams.get('minPrice') ?? undefined,
            maxPrice: searchParams.get('maxPrice') ?? undefined,
            colors: Stringfy(searchParams.getAll('color')) ?? undefined,
            transmissions:
                Stringfy(searchParams.getAll('transmission')) ?? undefined,
            bodyTypes: Stringfy(searchParams.getAll('bodyType')) ?? undefined,
            fuelType: searchParams.get('fuelType') ?? undefined,
            modelYears: Stringfy(currentYearAndAbove) ?? undefined,
            sort: (searchParams.get('sort') as CarSortType) ?? '-price',
            isNewCar: (searchParams.get('isNewCar') as CarFilterType) ?? '',
            minMileage: searchParams.get('minMileage') ?? undefined,
            maxMileage: searchParams.get('maxMileage') ?? undefined,
            minMonthlyInstallment:
                searchParams.get('minMonthlyInstallment') ?? undefined,
            maxMonthlyInstallment:
                searchParams.get('maxMonthlyInstallment') ?? undefined
        });
    }

    useEffect(() => {
        if (searchParams.toString()) {
            debounced();
        }
    }, [searchParams]);
    const sortByArray = [
        { name: 'highestPriceFirst', value: '-price' },
        { name: 'lowestPriceFirst', value: 'price' },
        { name: 'highestMileageFirst', value: '-mileage' },
        { name: 'lowestMileageFirst', value: 'mileage' }
    ];
    const filterByArray = [
        { name: 'all', value: '' },
        { name: 'new', value: 'true' },
        { name: 'used', value: 'false' }
    ];
    const minimumCars = 5;

    const onSortSelect = (sortValue: string, e: $FIXME) => {
        searchParams.set('sort', sortValue);
        setSearchParams(searchParams);
    };
    const onFilterSelect = (filterValue: string, e: $FIXME) => {
        filterValue === ''
            ? searchParams.delete('isNewCar')
            : searchParams.set('isNewCar', filterValue);
        setSearchParams(searchParams);
        debounced();
    };

    const Listing = () => (
        <div className="listing-container">
            <div className="listing__holder padding ">
                {cars?.slice(0, 4).map((items: $FIXME, index: number) => {
                    return (
                        <div key={index} className="card-holder">
                            <Card item={items} />
                        </div>
                    );
                })}
                {cars.length >= minimumCars ? (
                    <div className="card-holder">
                        <Link to={t('/finance', { ns: 'routes' })}>
                            <ListingCard
                                color="dark-blue"
                                imagesrc="/images/listingcar.webp"
                                title="loanTitle"
                                subtitle="loanHappyCusSubtitle"
                                btntext="loanButton"
                            />
                        </Link>
                    </div>
                ) : (
                    ''
                )}
                {cars?.slice(4, 5).map((items: $FIXME, index: number) => {
                    return (
                        <div key={index} className="card-holder">
                            <Card item={items} />
                        </div>
                    );
                })}
            </div>

            <div className="listing__holder padding ">
                {cars?.slice(5, 9).map((items: any, index: number) => {
                    return (
                        <div key={index} className="card-holder">
                            <Card item={items} />
                        </div>
                    );
                })}
                {cars.length >= minimumCars + minimumCars && (
                    <div className="card-holder">
                        <ListingCard
                            imagesrc="/images/listingcar.webp"
                            color="white"
                            title={t('sellYourCar')}
                            subtitle={t(
                                'knowaboutyourcarbetterandgetevaluation'
                            )}
                            btntext={t('sellYourCar')}
                            onClick={showModal}
                        />
                    </div>
                )}
                {cars?.slice(9).map((items: any, index: number) => {
                    return (
                        <div key={index} className="card-holder">
                            <Card item={items} />
                        </div>
                    );
                })}
            </div>
            <Modal
                className="listing-modal ants-modal"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered={true}
            >
                <SellYourCarForm
                    setIsModalVisible={setIsModalVisible}
                    from="listing-modal"
                />
            </Modal>
        </div>
    );
    const SortBy = () => (
        <div className="sort-by">
            {t('sortBy')}
            <Select
                defaultValue={searchParams.get('sort') ?? sortByArray[0].value}
                className="sort-car-select"
                defaultActiveFirstOption={true}
                bordered={false}
                // disabled={cars.length ? false : true}
                getPopupContainer={(trigger: $FIXME) => trigger.parentNode}
                onChange={onSortSelect}
                suffixIcon={
                    <CaretDown size={20} color="#175adc" weight="bold" />
                }
            >
                {sortByArray.map((options, index) => {
                    return (
                        <Option
                            value={options.value}
                            key={index}
                            dropdownClassName="sortby-dropdown"
                        >
                            {t(options.name)}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );
    const FilterBy = () => (
        <div className="sort-by">
            {t('filterBy')}
            <Select
                defaultValue={
                    searchParams.get('isNewCar') ?? filterByArray[0].value
                }
                className="filter-car-select"
                defaultActiveFirstOption={true}
                bordered={false}
                // disabled={cars.length ? false : true}
                getPopupContainer={(trigger: $FIXME) => trigger.parentNode}
                onChange={onFilterSelect}
                suffixIcon={
                    <CaretDown size={20} color="#175adc" weight="bold" />
                }
            >
                {filterByArray.map((options, index) => {
                    return (
                        <Option
                            value={options.value}
                            key={index}
                            dropdownClassName="sortby-dropdown"
                        >
                            {t(options.name)}
                        </Option>
                    );
                })}
            </Select>
        </div>
    );

    return (
        <div className="listings ">
            <div className="listing-tops padding">
                <h3 className="listing-title">
                    {cars.length > 0 &&
                        t('xlistingPageResultTitle', {
                            number: totalCar
                        })}{' '}
                </h3>
                <div style={{ display: 'flex', gap: '24px' }}>
                    <FilterBy />
                    <SortBy />
                </div>
            </div>
            {loading ? (
                <Loading style={{ display: 'block' }} />
            ) : cars.length ? (
                <Listing />
            ) : (
                <NoCarFound {...props} />
            )}
        </div>
    );
};

export default Listings;
